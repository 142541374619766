import IInputValidation from '../../../validation/input-validation';
import ISelectable from '../../../internal/selectable-extension/selectable';

export interface ICheckboxArgs {
  isValid?: boolean;
  isDisabled?: boolean;
  withValidation?: boolean;
  validationMessage?: string;
  selected?: boolean;
  label?: string;
}

export default class Checkbox implements IInputValidation, ISelectable {
  isValid = true;
  isDisabled = false;
  withValidation = false;
  validationMessage = '';
  selected = false;
  label = 'Label';

  constructor(data?: ICheckboxArgs) {
    this.withValidation = data?.withValidation ?? this.withValidation;
    this.validationMessage = data?.validationMessage ?? this.validationMessage;
    this.selected = data?.selected ?? this.selected;
    this.label = data?.label ?? this.label;
    this.isValid = data?.isValid ?? this.withValidation ? this.isValid : true;
    this.isDisabled = data?.isDisabled ?? false;
  }

  toggle(): void {
    this.selected = !this.selected;
    this.validate();
  }

  validate(): boolean {
    if (this.withValidation) {
      this.isValid = this.selected;
    } else {
      this.isValid = true;
    }

    return this.isValid;
  }
}
