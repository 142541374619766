import { Language } from './../../../travia/language';
import ISelectable from '../../../internal/selectable-extension/selectable';
import PropertyType from '../../../travia/property-type';
import PropertyAmenity from '../../../travia/property-amenity';
import Country from '../../../travia/country';
import Location from '../../../caren/location-info';
import TransmissionDto from '../../../caren/system/transmission-dto';
import GroupDto from '../../../caren/system/group-dto';
import FuelDto from '../../../caren/system/fuel-dto';
import { ExtrasDto } from '../../../../models/caren/system/extras-dto';
import { IActivityLocation } from '../../../../models/internal/activity/activity-location';
import IRoom from '../../../../models/travia/room';
import Languages from '../../../../models/travia/languages';

export interface ISelectOption {
  id?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  name?: string;
  selected?: boolean;
  image?: string;
  disabled?: boolean;
  type?: string;
  groupedIds?: number[];
}

export class SelectOption implements ISelectOption, ISelectable {
  id?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  name: string;
  selected: boolean;
  image?: string;
  disabled: boolean;
  type?: string;
  universalName = '';
  groupedIds?: number[];

  constructor(data: ISelectOption) {
    this.id = data.id;
    this.value = data.value;
    this.name = data.name ? data.name : data.value.toString().toUpperCase();
    this.selected = data.selected ?? false;
    this.image = data.image;
    this.disabled = data.disabled ?? false;
    this.type = data.type;

    this.universalName = this.name?.removeDiacriticToLower() ?? '';
    this.groupedIds = data.groupedIds;
  }

  public toggle() {
    this.selected = !this.selected;
  }

  public includesText(str: string): boolean {
    return this.universalName.includes(str.removeDiacriticToLower());
  }

  public static fromPropertyTypes(data: PropertyType[]): SelectOption[] {
    return data.map((x: any) => new SelectOption({ ...x, value: x.code }));
  }

  public static fromPropertyAmenities(data: PropertyAmenity[]): SelectOption[] {
    return data.map((x: any) => new SelectOption({ ...x, value: x.code }));
  }

  public static fromCountries(data: Country[]) {
    return data.map(
      (country: Country) =>
        new SelectOption({ ...country, value: country.code })
    );
  }

  public static fromLanguages(data: Language[]): SelectOption[] {
    return data.map(
      (language) =>
        new SelectOption({
          ...language,
          name: Languages.getLanguageName(language.code),
          value: language.code
        })
    );
  }

  public static fromRoom(data: IRoom[]) {
    return data.map((room) => new SelectOption({ ...room, value: room.name }));
  }

  public static fromDropOffPickUpLocations(
    data: Location[],
    selectedLocationId?: number
  ): SelectOption[] {
    return data.map(
      (location) =>
        new SelectOption({
          ...location,
          name: location.name ?? '',
          value: location.id,
          selected: location.id === selectedLocationId
        })
    );
  }

  public static fromStringList(data: string[]): SelectOption[] {
    return data.map((item) => new SelectOption({ name: item, value: item }));
  }

  public static fromTransmissions(data: TransmissionDto[]): SelectOption[] {
    return data.map(
      (x: TransmissionDto) => new SelectOption({ ...x, value: x.name })
    );
  }

  public static fromGroups(data: GroupDto[]): SelectOption[] {
    return data.map((x: GroupDto) => new SelectOption({ ...x, value: x.name }));
  }

  public static fromFuels(data: FuelDto[]): SelectOption[] {
    return data.map((x: FuelDto) => new SelectOption({ ...x, value: x.name }));
  }

  public static fromExtras(data: ExtrasDto[]): SelectOption[] {
    return data.map(
      (x: ExtrasDto) => new SelectOption({ ...x, value: x.name })
    );
  }

  public static fromActivityLocations(data: IActivityLocation[]) {
    return data.map(
      (location) =>
        new SelectOption({
          ...location,
          id: location.placeId,
          name: location.placeDescription ?? location.title ?? '',
          value: location.placeDescription ?? location.title
        })
    );
  }
}
