import WidgetSettingsCancelDto from 'src/models/internal/widget-settings/widget-settings-cancel-page-dto';
import WidgetSettingsDto from 'src/models/internal/widget-settings/widget-settings-dto';
import WidgetSettingsSuccessPageDto from 'src/models/internal/widget-settings/widget-settings-success-page-dto';
import WidgetSettingsTermsDto from 'src/models/internal/widget-settings/widget-settings-terms-dto';
import BaseHttpClientService from '../base-http-client-service';

interface IWidgetSettingsControllerService {
  getWidgetSettingsTerms(lang: string): Promise<WidgetSettingsTermsDto> ;
  getWidgetSettingsSuccessPage(lang: string): Promise<WidgetSettingsSuccessPageDto>;
  getWidgetSettingsCancel(lang: string): Promise<WidgetSettingsCancelDto>;
  getWidgetSettings(): Promise<WidgetSettingsDto>;
}

// TODO: think about a way of assigning site id
const siteId = 'bbb558d2-c3ae-413b-be7f-3e3dfa3c52c0';

class WidgetSettingsControllerService
  extends BaseHttpClientService
  implements IWidgetSettingsControllerService {
  getWidgetSettingsTerms(lang: string): Promise<WidgetSettingsTermsDto> {
    return this.httpClient
      .get(`api/widget-settings/${siteId}/terms/${lang}`)
      .then((resp) => this.prepareResponse(resp).content);
  }

  getWidgetSettingsSuccessPage(lang: string): Promise<WidgetSettingsSuccessPageDto> {
    return this.httpClient
      .get(`api/widget-settings/${siteId}/successpage/${lang}`)
      .then((resp) => this.prepareResponse(resp).content);
  }

  getWidgetSettingsCancel(lang: string): Promise<WidgetSettingsCancelDto> {
    return this.httpClient
      .get(`api/widget-settings/${siteId}/cancel/${lang}`)
      .then((resp) => this.prepareResponse(resp).content);
  }

  getWidgetSettings(): Promise<WidgetSettingsDto> {
    return this.httpClient
      .get(`api/widget-settings/${siteId}/payment`)
      .then((resp) => this.prepareResponse(resp).content);
  }
}

export default new WidgetSettingsControllerService() as IWidgetSettingsControllerService;
