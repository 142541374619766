import IInputValidation from '../../validation/input-validation';
import Dropdown from '../elements/dropdown/dropdown';
import EmailWithConfirmation from '../elements/email-with-confirmation/email-with-confirmation';
import PhoneInput from '../elements/phone-input/phone-input';
import TextInput from '../elements/text-input/text-input';
import ICustomerDetails from '../../internal/payment/customer-details';
import StringFormatUtils from '../../../utils/stringFormatUtils';

export interface ICustomerDetailsArgs {
  nameInput?: TextInput;
  lastNameInput?: TextInput;
  emailWithConfirmation?: EmailWithConfirmation;
  phoneInput?: PhoneInput;
  countriesDropdown?: Dropdown;
  isValid?: boolean;
  withValidation?: boolean;
  validationMessage?: string;
}

export default class CustomerDetails implements IInputValidation {
  nameInput: TextInput = new TextInput({
    withValidation: true,
    defaultMessage: 'Enter your name',
    label: 'First name',
    placeholder: 'Enter your first name',
    maxCharacters: 255,
    required: true
  });
  lastNameInput: TextInput = new TextInput({
    withValidation: true,
    defaultMessage: 'Enter your last name',
    label: 'Last name',
    placeholder: 'Enter your last name',
    maxCharacters: 255,
    required: true
  });
  emailWithConfirmation: EmailWithConfirmation = new EmailWithConfirmation();
  phoneInput: PhoneInput = new PhoneInput({
    withValidation: true,
    maxCharacters: 255,
    required: true
  });
  countriesDropdown: Dropdown = new Dropdown({
    withValidation: true,
    validationMessage: 'Pick a country',
    label: 'Nationality',
    placeholder: 'Select your country',
    required: true
  });
  isValid = false;
  withValidation = true;
  validationMessage = '';

  constructor(data?: ICustomerDetailsArgs) {
    this.nameInput = data?.nameInput ?? this.nameInput;
    this.lastNameInput = data?.lastNameInput ?? this.lastNameInput;
    this.emailWithConfirmation =
      data?.emailWithConfirmation ?? this.emailWithConfirmation;
    this.phoneInput = data?.phoneInput ?? this.phoneInput;
    this.countriesDropdown = data?.countriesDropdown ?? this.countriesDropdown;
    this.isValid = data?.isValid ?? this.isValid;
    this.withValidation = data?.withValidation ?? this.withValidation;
    this.validationMessage = data?.validationMessage ?? this.validationMessage;
  }

  emitInputValues(): ICustomerDetails {
    return {
      firstName: this.nameInput.value.toString() ?? '',
      lastName: this.lastNameInput.value.toString() ?? '',
      email: this.emailWithConfirmation.email.value.toString() ?? '',
      phoneNo: this.phoneInput.value.toString() ?? '',
      countryCode: this.countriesDropdown.selectedValue ?? ''
    };
  }

  validate(): boolean {
    if (!this.withValidation) {
      if (this.nameInput.withValidation) {
        this.nameInput.withValidation = false;
        this.nameInput.isValid = true;
      }
      if (this.lastNameInput.withValidation) {
        this.lastNameInput.withValidation = false;
        this.lastNameInput.isValid = true;
      }
      if (this.emailWithConfirmation.withValidation) {
        this.emailWithConfirmation.withValidation = false;
        this.emailWithConfirmation.validate();
      }
      if (this.phoneInput.withValidation) {
        this.phoneInput.withValidation = false;
        this.phoneInput.isValid = true;
      }
      if (this.countriesDropdown.withValidation) {
        this.countriesDropdown.withValidation = false;
        this.countriesDropdown.isValid = true;
      }
      return (this.isValid = true);
    }

    return (this.isValid =
      this.nameInput.isValid &&
      this.lastNameInput.isValid &&
      this.emailWithConfirmation.isValid &&
      this.phoneInput.isValid &&
      this.countriesDropdown.isValid &&
      !!this.nameInput.value &&
      !!this.lastNameInput.value &&
      !!this.phoneInput.value &&
      !!this.countriesDropdown.selectedValue);
  }

  formatNameWithUpperCases() {
    this.nameInput.value = StringFormatUtils.startWordsWithUpperCase(
      this.nameInput.value.toString()
    );
  }

  formatLastNameWithUpperCases() {
    this.lastNameInput.value = StringFormatUtils.startWordsWithUpperCase(
      this.lastNameInput.value.toString()
    );
  }
}
