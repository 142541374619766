import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex align-items-center mb-2"
}
const _hoisted_2 = { class: "w-100" }
const _hoisted_3 = ["title"]
const _hoisted_4 = ["title"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "row px-3" }
const _hoisted_7 = { class: "field-icon-inline d-flex align-items-center no-highlight col-auto" }
const _hoisted_8 = {
  key: 0,
  class: "col"
}
const _hoisted_9 = { class: "text-end text-gray2" }
const _hoisted_10 = { class: "text-end field-container-fields-content text-black" }
const _hoisted_11 = {
  key: 1,
  class: "col d-flex justify-content-end align-items-center"
}
const _hoisted_12 = { key: 2 }
const _hoisted_13 = { class: "row px-3" }
const _hoisted_14 = { class: "d-flex align-items-center no-highlight col-auto icon-container-irregular" }
const _hoisted_15 = {
  key: 0,
  class: "col lh-sm fields-container-irregular"
}
const _hoisted_16 = { class: "text-start text-black irregular-field-title" }
const _hoisted_17 = { class: "text-start field-container-fields-content g__text--helper text-black" }
const _hoisted_18 = {
  key: 1,
  class: "col d-flex justify-content-end align-items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.iconWithText.inline && _ctx.iconWithText.regular)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", {
            class: _normalizeClass([`${_ctx.isDomainIS ? 'icon-container-is' : 'text-secondary bg-secondary-lighter'} rounded icon-container py-3 d-flex align-items-center justify-content-center`])
          }, [
            _createElementVNode("i", {
              class: _normalizeClass(`bi bi-${_ctx.iconWithText.icon} p-1`)
            }, null, 2)
          ], 2),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("p", {
              title: _ctx.iconWithText.title,
              class: "mb-0 mx-2 text-gray4 g__text--helper text-start text-gray2"
            }, _toDisplayString(_ctx.iconWithText.title), 9, _hoisted_3),
            _createElementVNode("p", {
              title: _ctx.iconWithText.content,
              class: "mb-0 mx-2 g__text--label text-start text-truncate col-9 text-black"
            }, _toDisplayString(_ctx.iconWithText.content), 9, _hoisted_4)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.iconWithText.inline && _ctx.iconWithText.regular)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("i", {
                class: _normalizeClass(`bi bi-${_ctx.iconWithText.icon}`)
              }, null, 2)
            ]),
            (_ctx.iconWithText.content !== null)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.iconWithText.title), 1),
                  _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.iconWithText.content), 1)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.iconWithText.title), 1))
          ])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.iconWithText.regular)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("i", {
                class: _normalizeClass(`bi bi-${_ctx.iconWithText.icon}`)
              }, null, 2)
            ]),
            (_ctx.iconWithText.content !== null)
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.iconWithText.title), 1),
                  _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.iconWithText.content), 1)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_18, _toDisplayString(_ctx.iconWithText.title), 1))
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}