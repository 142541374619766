
import {defineComponent, reactive, ref} from "vue";
import Product from '../product/product.vue'
import CarouselCmp from '../../standalone/carousel/carousel.vue'
import IconWithText from '../../elements/icon-with-text/icon-with-text.vue'
import PropertyDetails from "../../../models/components/standalone/property/property-details";
import AppUtils from "../../../utils/app-utils";

export default defineComponent({
  name: 'PropertyDetails',
  components: {
    'carousel': CarouselCmp,
    'icon': IconWithText,
    'product': Product
  },
  props: {
    propertyDetailsModel: {
      type: Object as () => PropertyDetails,
      required: true
    }
  },

  setup(props) {
    return {
      propertyDetails: reactive(props.propertyDetailsModel) as PropertyDetails,
      themePrefix: ref(AppUtils.getFallbackDomain())
    }
  },
  data() {
    return {
      isReadingMore: false,
      isClamped: false
    }
  },
  mounted() {
    this.toggleIsClamped();
  },
  methods: {
    toggleReadingMore() {
      this.isReadingMore = !this.isReadingMore
    },
    toggleIsClamped() {
      this.isClamped = this.isLineClamped(this.$refs['propertyDescriptionRef'] as HTMLDivElement)
    },
    isLineClamped(elem: HTMLDivElement) {
      return elem.scrollHeight > elem.clientHeight;
    }
  }
})

