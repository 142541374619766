
import {defineComponent, reactive} from "vue";
import successCmp from '../components/block/payment-successful/payment-successful.vue'
import {useStore} from "vuex";
import IStore from "../models/internal/global/store/store";
import PaymentSuccessful from "../models/components/block/paymentSuccessful";
import {toHome, toNotFound} from "../router";

export default defineComponent({
  name: 'SuccessfulPaymentPage',
  components: {
    'successful-payment': successCmp
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  beforeRouteEnter(_to, _from) {
    document.body.style.overflow = '';
    document.body.style.paddingRight = '';
  },
  setup() {
    const store = useStore<IStore>();
    const paymentInfo = store.getters.successfulPaymentInformation;
    if (paymentInfo === null) {
      toNotFound();
    }
    
    return {
      paymentSuccess: reactive(new PaymentSuccessful({...paymentInfo, textBold: true}))
    }
  },
  methods: {
    onContinueClick() {
      toHome();
    }
  }
})

