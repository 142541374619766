import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Suspense as _Suspense, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7f6ba19e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-xl p-0" }
const _hoisted_2 = { class: "col-12 col-md-8 col-xl-4 mx-auto" }
const _hoisted_3 = { class: "row justify-content-center" }
const _hoisted_4 = { class: "col-12 col-lg-5 pb-lg-0 pb-3" }
const _hoisted_5 = { class: "d-flex flex-column gap-3" }
const _hoisted_6 = { class: "d-lg-none d-grid gap-3 mt-3" }
const _hoisted_7 = { class: "col-12 col-lg-3 mb-4" }
const _hoisted_8 = { class: "side__content d-grid gap-3" }
const _hoisted_9 = { class: "d-lg-grid d-none gap-3 mt-3 mt-xl-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_stepper = _resolveComponent("stepper")!
  const _component_product_summaries = _resolveComponent("product-summaries")!
  const _component_stepper_content = _resolveComponent("stepper-content")!
  const _component_customer_details = _resolveComponent("customer-details")!
  const _component_booking_details = _resolveComponent("booking-details")!
  const _component_credit_card_inputs = _resolveComponent("credit-card-inputs")!
  const _component_terms_and_conditions = _resolveComponent("terms-and-conditions")!
  const _component_terms_and_conditions_modal = _resolveComponent("terms-and-conditions-modal")!
  const _component_policy_modal = _resolveComponent("policy-modal")!
  const _component_order_summary = _resolveComponent("order-summary")!
  const _component_promo_code = _resolveComponent("promo-code")!
  const _component_stepper_buttons = _resolveComponent("stepper-buttons")!
  const _component_icons_card = _resolveComponent("icons-card")!
  const _component_loader = _resolveComponent("loader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_stepper, {
        stepperModel: _ctx.checkout.stepper
      }, null, 8, ["stepperModel"])
    ]),
    (!_ctx.checkout.loading)
      ? (_openBlock(), _createBlock(_Suspense, { key: 0 }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_stepper_content, {
                  stepNumber: 1,
                  stepperModel: _ctx.checkout.stepper,
                  title: 'View Cart Summary'
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_product_summaries, {
                      productSummaryModel: _ctx.checkout.productSummaries,
                      onRemoveProduct: _cache[0] || (_cache[0] = (product) => _ctx.onRemoveProduct(product)),
                      onProductClicked: _cache[1] || (_cache[1] = (product) => _ctx.onProductClicked(product)),
                      onExtraSelected: _cache[2] || (_cache[2] = (extra) => _ctx.onExtraSelected(extra))
                    }, null, 8, ["productSummaryModel"])
                  ]),
                  _: 1
                }, 8, ["stepperModel"]),
                _createVNode(_component_stepper_content, {
                  stepNumber: 2,
                  stepperModel: _ctx.checkout.stepper,
                  title: 'Confirm Payment'
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_5, [
                      _createVNode(_component_customer_details, {
                        customerDetailsModel: _ctx.checkout.contactQuestions
                      }, null, 8, ["customerDetailsModel"]),
                      _createVNode(_component_booking_details, {
                        tours: _ctx.checkout.productSummaries.tourSummaries
                      }, null, 8, ["tours"]),
                      _createVNode(_component_credit_card_inputs, {
                        creditCardInputsModel: _ctx.checkout.creditCardInputs,
                        isCardPayment: _ctx.isCardPayment,
                        "payment-provider": _ctx.checkout.paymentProvider,
                        stripeCheckout: _ctx.checkout.stripeCheckout
                      }, null, 8, ["creditCardInputsModel", "isCardPayment", "payment-provider", "stripeCheckout"]),
                      _createVNode(_component_terms_and_conditions, {
                        userConsentInputsModel: _ctx.checkout.userConsent,
                        onOnCancellationPolicyClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.toggleCancellationPolicies())),
                        onOnTermsAndConditionsClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.toggleTermsAndConditions()))
                      }, null, 8, ["userConsentInputsModel"])
                    ]),
                    _createVNode(_component_terms_and_conditions_modal, {
                      showModalInput: _ctx.showTermsAndConditions,
                      termsTextInput: _ctx.terms?.text || ''
                    }, null, 8, ["showModalInput", "termsTextInput"]),
                    _createVNode(_component_policy_modal, {
                      cancelTextInput: _ctx.cancel?.text || '',
                      cancellationPolicies: _ctx.checkout.cart.cancellationPolicies,
                      showModal: _ctx.showPolicies
                    }, null, 8, ["cancelTextInput", "cancellationPolicies", "showModal"])
                  ]),
                  _: 1
                }, 8, ["stepperModel"]),
                _createElementVNode("div", _hoisted_6, [
                  (!_ctx.checkout.promoCode.isLoading)
                    ? (_openBlock(), _createBlock(_component_order_summary, {
                        key: 0,
                        productSummariesModel: _ctx.checkout.productSummaries
                      }, null, 8, ["productSummariesModel"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_promo_code, {
                    promoCodeModel: _ctx.checkout.promoCode,
                    originalPrice: _ctx.checkout.productSummaries.getSummaryTotal(),
                    "is-disabled": _ctx.checkout.hasItemsFromBundle,
                    discountedPrice: 
                  _ctx.checkout.productSummaries.getSummaryTotal({
                    isDiscounted: true
                  })
                ,
                    currency: _ctx.checkout.cart.currency,
                    onPromoCodeApplied: _ctx.onPromoCodeApplied,
                    onPromoCodeRemoved: _ctx.onPromoCodeRemoved
                  }, null, 8, ["promoCodeModel", "originalPrice", "is-disabled", "discountedPrice", "currency", "onPromoCodeApplied", "onPromoCodeRemoved"])
                ]),
                _createVNode(_component_stepper_buttons, {
                  class: "mb-3",
                  stepperButtonsModel: _ctx.checkout.stepper,
                  totalPrice: 
                _ctx.checkout.productSummaries.getSummaryTotal({
                  isDiscounted: _ctx.checkout.promoCode.isApplied
                })
              ,
                  "payment-button-id": 'payment-request-button',
                  currency: _ctx.currency,
                  payDisabled: 
                !_ctx.checkout.hasAcceptedTermsAndPolicies ||
                _ctx.checkout.stripeCheckout.loading
              ,
                  productSummary: _ctx.checkout.productSummaries,
                  stripeCheckout: _ctx.checkout.stripeCheckout,
                  isCardPayment: _ctx.isCardPayment,
                  hasError: _ctx.checkout.stepper.isLastStep() && !_ctx.checkout.isValid,
                  onOnPayClicked: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onPayClicked())),
                  onOnNextClicked: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onNextClicked()))
                }, null, 8, ["stepperButtonsModel", "totalPrice", "currency", "payDisabled", "productSummary", "stripeCheckout", "isCardPayment", "hasError"])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    (!_ctx.checkout.promoCode.isLoading)
                      ? (_openBlock(), _createBlock(_component_order_summary, {
                          key: 0,
                          productSummariesModel: _ctx.checkout.productSummaries
                        }, null, 8, ["productSummariesModel"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_promo_code, {
                      promoCodeModel: _ctx.checkout.promoCode,
                      originalPrice: _ctx.checkout.productSummaries.getSummaryTotal(),
                      "is-disabled": _ctx.checkout.hasItemsFromBundle,
                      discountedPrice: 
                    _ctx.checkout.productSummaries.getSummaryTotal({
                      isDiscounted: true
                    })
                  ,
                      currency: _ctx.checkout.cart.currency,
                      onPromoCodeApplied: _ctx.onPromoCodeApplied,
                      onPromoCodeRemoved: _ctx.onPromoCodeRemoved
                    }, null, 8, ["promoCodeModel", "originalPrice", "is-disabled", "discountedPrice", "currency", "onPromoCodeApplied", "onPromoCodeRemoved"])
                  ]),
                  (_ctx.checkout.stepper.isLastStep())
                    ? (_openBlock(), _createBlock(_component_icons_card, {
                        key: 0,
                        iconsWithTextCardModel: _ctx.checkout.reassuranceCard
                      }, null, 8, ["iconsWithTextCardModel"]))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ]),
          fallback: _withCtx(() => [
            _createVNode(_component_loader)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}