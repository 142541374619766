import IInputValidation from '../../validation/input-validation';

export interface IInputArgs {
  isValid?: boolean;
  value?: string | number;
  withValidation?: boolean;
  label?: string;
  placeholder?: string | number;
  defaultMessage?: string;
  withAutocomplete?: boolean;
  maxCharacters?: number;
  required?: boolean;
  pattern?: RegExp;
}

export default class Input implements IInputValidation {
  value: string | number = '';
  isValid = true;
  withValidation = false;
  validationMessage = '';
  label = '';
  required = false;
  placeholder?: string | number;
  withAutocomplete?: boolean;
  maxCharacters?: number;
  pattern?: RegExp;
  readonly defaultMessage: string = '';

  constructor(data?: IInputArgs) {
    this.value = data?.value ?? this.value;
    this.isValid = data?.isValid ?? this.isValid;
    this.withValidation = data?.withValidation ?? this.withValidation;
    this.validationMessage = data?.defaultMessage ?? this.validationMessage;
    this.label = data?.label ?? this.label;
    this.placeholder = data?.placeholder;
    this.withAutocomplete = data?.withAutocomplete;
    this.maxCharacters = data?.maxCharacters;
    this.required = data?.required ?? this.required;
    this.pattern = data?.pattern ?? this.pattern;

    this.defaultMessage = data?.defaultMessage ?? this.defaultMessage;
  }

  setInvalid(message?: string): boolean {
    this.validationMessage = message ?? this.defaultMessage;
    return (this.isValid = false);
  }

  formatInput() {
    if (!this.pattern) return;
    const regexPattern = new RegExp(this.pattern);
    this.value = regexPattern.test(String(this.value)) ? this.value : '';
  }

  validate() {
    this.value = this.value.toString();

    if (this.maxCharacters) {
      this.value =
        this.value.length > this.maxCharacters
          ? this.value.slice(0, this.maxCharacters)
          : this.value;
    }

    if (!this.withValidation) {
      this.isValid = true;
      return this.isValid;
    }

    if (this.value.trim().length > 0) return (this.isValid = true);

    return this.setInvalid();
  }
}
