import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6175971a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "position-relative" }
const _hoisted_2 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_3 = ["disabled"]
const _hoisted_4 = ["selected"]
const _hoisted_5 = ["value", "disabled", "selected"]
const _hoisted_6 = {
  id: "inputValidationFeedback",
  class: "invalid-feedback"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.dropdownSelect.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: "g__text--label text-gray4 form-label",
          style: _normalizeStyle(_ctx.styleObject.labelStyle)
        }, [
          (_ctx.dropdownSelect.required)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, "*"))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(_ctx.$translate(_ctx.dropdownSelect.label)), 1)
        ], 4))
      : _createCommentVNode("", true),
    _createElementVNode("select", {
      id: "inputValidation",
      class: _normalizeClass(`form-select ${_ctx.dropdownSelect.isValid ? '' : 'is-invalid'}`),
      "aria-label": "Default select example",
      disabled: _ctx.dropdownSelect.disabled,
      onChange: _cache[0] || (_cache[0] = (e) => _ctx.select(e.target.value))
    }, [
      (_ctx.dropdownSelect.showPlaceholder)
        ? (_openBlock(), _createElementBlock("option", {
            key: 0,
            selected: !Boolean(_ctx.dropdownSelect.selectedValue)
          }, _toDisplayString(_ctx.dropdownSelect.placeholder), 9, _hoisted_4))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dropdownSelect.options, (option, index) => {
        return (_openBlock(), _createElementBlock("option", {
          key: index,
          value: option.value,
          disabled: option.disabled,
          selected: option.selected
        }, _toDisplayString(option.name), 9, _hoisted_5))
      }), 128))
    ], 42, _hoisted_3),
    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.dropdownSelect.validationMessage), 1)
  ]))
}