
import { reactive, defineComponent } from 'vue';
import DateInput from '../../../models/components/elements/date-input/date-input';

export default defineComponent({
  name: 'DateInput',
  props: {
    dateInputModel: {
      type: Object as () => DateInput,
      required: true
    }
  },
  setup(props) {
    return {
      dateInput: reactive(props.dateInputModel)
    };
  }
});
