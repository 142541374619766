import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import head from 'vue-head';

import '@fortawesome/fontawesome-free/js/all.js';
import '@fortawesome/fontawesome-free/css/all.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './assets/custom.scss';
import './assets/global.scss';
import './extensions/string-extension';
import Notifications from '@kyvg/vue3-notification';
import updateAppVersion from './updateVersionInStorage';
import translate from './plugins/translate';

updateAppVersion();

createApp(App)
  .use(head)
  .use(router)
  .use(store)
  .use(translate)
  .use(Notifications)
  .mount('#app');
/*

general: Vue3 and storybook

styling: using bootstrap v5 out of the box with an addition of custom global css
    find out more: bootstrap v5 | src/assets/global.css

store: store or Vuex (vue state manager) is reachable by using this syntax:
    this.$store.state.{any props that are defined in the state}
    find out more in src/store/index.ts

router: for managing displayable pages
    find out more src/router/index.ts
    
notifications: https://github.com/kyvg/vue3-notification
    to invoke - use this.$notify(args)
    mostly used for error handling

*/
