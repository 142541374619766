import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-48d0373e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-start" }
const _hoisted_2 = {
  key: 0,
  id: "inputValidation",
  class: "g__text--label text-gray4 form-label"
}
const _hoisted_3 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_4 = { class: "input-group has-validation" }
const _hoisted_5 = ["autocomplete", "placeholder"]
const _hoisted_6 = {
  key: 1,
  class: "invalid-feedback"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.dateInput.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, [
          (_ctx.dateInput.required)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(_ctx.dateInput.label), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _withDirectives(_createElementVNode("input", {
        id: "inputValidation",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dateInput.value) = $event)),
        autocomplete: _ctx.dateInput.withAutocomplete ? 'on' : 'off',
        type: "text",
        class: _normalizeClass({
          'form-control': true,
          'is-invalid': !_ctx.dateInput.isValid,
          'datetime-input': _ctx.dateInput.isDateTime
        }),
        placeholder: String(_ctx.dateInput.placeholder),
        onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dateInput.validate())),
        onKeyup: _cache[2] || (_cache[2] = ($event: any) => (_ctx.dateInput.formatDateInput()))
      }, null, 42, _hoisted_5), [
        [_vModelText, _ctx.dateInput.value]
      ]),
      (_ctx.dateInput.isDateTime)
        ? _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 0,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dateInput.time) = $event)),
            type: "text",
            class: _normalizeClass({
          'form-control': true,
          'is-invalid': !_ctx.dateInput.isValid
        }),
            placeholder: "HH:MM",
            onBlur: _cache[4] || (_cache[4] = ($event: any) => (_ctx.dateInput.validate())),
            onKeyup: _cache[5] || (_cache[5] = ($event: any) => (_ctx.dateInput.formatTimeInput()))
          }, null, 34)), [
            [_vModelText, _ctx.dateInput.time]
          ])
        : _createCommentVNode("", true),
      (!_ctx.dateInput.isValid)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.dateInput.validationMessage), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}