
import {
  defineAsyncComponent,
  defineComponent,
  reactive,
  ref
} from '@vue/runtime-core';
import CustomerDetails from '../customer-details/customer-details.vue';
import CreditCardInputs from '../credit-card-inputs/credit-card-inputs.vue';
import Checkout from '../../../models/components/block/checkout';
import AccommodationProductItem from '../../../models/components/standalone/detailed-product-item/accommodation-product-item';
import AppUtils from '../../../utils/app-utils';
import IconsWithTextCard from '../../standalone/icons-with-text-card/icons-with-text-card.vue';
import CancellationPolicyModalCmp from '../../elements/cancellation-policy-modal/cancellation-policy-modal.vue';
import TermsAndConditions from '../terms-and-conditions/terms-and-conditions.vue';
import TermsAndConditionsModal from '../../standalone/terms-and-conditions-modal/terms-and-conditions-modal.vue';
import IUnifiedCart, {
  getTotalAmount
} from '../../../models/internal/cart/unified-cart';
import BookingDetailsCmp from '../booking-details/booking-details.vue';
import { IActivityBookingCount } from '../../../models/internal/activity/activity-booking-count';
import widgetSettingsControllerService from '../../../services/controllers/widget-settings-controller-service';
import WidgetSettingsTermsDto from '../../../models/internal/widget-settings/widget-settings-terms-dto';
import WidgetSettingsCancelDto from '../../../models/internal/widget-settings/widget-settings-cancel-page-dto';
import WidgetSettingsDto from '../../../models/internal/widget-settings/widget-settings-dto';
import Loader from '../../elements/loader/loader.vue';
import { CurrencyType } from '../../../models/internal/currency/currency';

const Stepper = () =>
  import(
    /* webpackChunkName: "component.unifiedCheckoutWidget" */ '../stepper/stepper.vue'
  );
const StepperButtons = () =>
  import(
    /* webpackChunkName: "component.unifiedCheckoutWidget" */ '../stepper/stepper-buttons.vue'
  );
const StepperContent = () =>
  import(
    /* webpackChunkName: "component.unifiedCheckoutWidget" */ '../stepper/stepper-content.vue'
  );
const PromoCode = () =>
  import(
    /* webpackChunkName: "component.unifiedCheckoutWidget" */ '../promo-code/promo-code.vue'
  );
const OrderSummary = () =>
  import(
    /* webpackChunkName: "component.unifiedCheckoutWidget" */ '../order-summary/order-summary.vue'
  );
const ProductSummaries = () =>
  import(
    /* webpackChunkName: "component.unifiedCheckoutWidget" */ '../product-summaries/product-summaries.vue'
  );

export default defineComponent({
  name: 'CheckoutCmp',
  components: {
    'customer-details': CustomerDetails,
    'booking-details': BookingDetailsCmp,
    'product-summaries': defineAsyncComponent(ProductSummaries),
    stepper: defineAsyncComponent(Stepper),
    'stepper-buttons': defineAsyncComponent(StepperButtons),
    'promo-code': defineAsyncComponent(PromoCode),
    'order-summary': defineAsyncComponent(OrderSummary),
    'stepper-content': defineAsyncComponent(StepperContent),
    'credit-card-inputs': CreditCardInputs,
    'icons-card': IconsWithTextCard,
    'policy-modal': CancellationPolicyModalCmp,
    'terms-and-conditions': TermsAndConditions,
    'terms-and-conditions-modal': TermsAndConditionsModal,
    loader: Loader
  },
  props: {
    checkoutModel: {
      type: Object as () => Checkout,
      required: true
    }
  },
  emits: [
    'remove-product',
    'product-clicked',
    'pay-clicked',
    'extra-selected',
    'next-step-clicked',
    'promo-code-applied',
    'promo-code-removed',
    'terms-conditions-opened'
  ],
  setup(props) {
    const shouldShowPolicies = false;
    const shouldShowTermsAndConditions = false;

    return {
      checkout: reactive(props.checkoutModel),
      themePrefix: ref(AppUtils.getFallbackDomain()),
      showPolicies: ref(shouldShowPolicies),
      showTermsAndConditions: ref(shouldShowTermsAndConditions)
    };
  },
  data() {
    let terms: WidgetSettingsTermsDto = null as any;
    let cancel: WidgetSettingsCancelDto = null as any;
    let widgetSettings: WidgetSettingsDto = null as any;
    return {
      terms,
      cancel,
      widgetSettings
    };
  },
  computed: {
    isCardPayment() {
      return !this.checkout.creditCardInputs.isGoogleApplePayment;
    },
    currency() {
      return this.checkout.creditCardInputs.isChineseYuanPayment
        ? CurrencyType.CNY
        : this.checkout.cart.currency;
    }
  },
  mounted() {
    this.getCancelPageInfo();
    this.getTermsInfo();
    this.getWidgetSettings();
  },
  methods: {
    getTermsInfo(): void {
      widgetSettingsControllerService
        .getWidgetSettingsTerms(this.getCurrentTranslation())
        .then((data) => {
          this.terms = data;
        });
    },
    getCancelPageInfo(): void {
      widgetSettingsControllerService
        .getWidgetSettingsCancel(this.getCurrentTranslation())
        .then((data) => {
          this.cancel = data;
        });
    },
    getWidgetSettings(): void {
      widgetSettingsControllerService.getWidgetSettings().then((data) => {
        this.widgetSettings = {
          ...data,
          params: JSON.parse(data.params as string)
        };
      });
    },
    getCurrentTranslation(): string {
      return (localStorage.getItem('AH-Translation') ?? 'en-US').split('-')[0];
    },
    getCartTotal(): number {
      return getTotalAmount(this.checkout.cart as IUnifiedCart);
    },
    onPayClicked() {
      this.checkout.validate();
      if (this.checkout.isValid) return this.$emit('pay-clicked');
    },
    onNextClicked() {
      this.$emit('next-step-clicked');
    },
    onProductClicked(product: AccommodationProductItem) {
      this.$emit('product-clicked', product);
    },
    onRemoveProduct(product: AccommodationProductItem) {
      this.$emit('remove-product', product);
    },
    onExtraSelected(extra: IActivityBookingCount & { activityId: string }) {
      this.$emit('extra-selected', extra);
    },
    onPromoCodeApplied() {
      this.$emit('promo-code-applied');
    },
    onPromoCodeRemoved() {
      this.$emit('promo-code-removed');
    },
    toggleCancellationPolicies() {
      this.showPolicies = !this.showPolicies;
    },
    toggleTermsAndConditions() {
      this.showTermsAndConditions = !this.showTermsAndConditions;
      this.$emit('terms-conditions-opened');
    }
  }
});
