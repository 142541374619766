
import { reactive, defineComponent } from 'vue';
import { SelectOption } from '../../../models/components/elements/options/select-option';

export default defineComponent({
  name: 'SearchDropdownMultiselect',
  props: {
    options: {
      type: Object as () => SelectOption[],
      required: true
    },
    inputLabel: {
      type: String,
      required: false,
      default: ''
    },
    inputLabelColor: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    optionColor: {
      type: String,
      required: false,
      default: ''
    },
    prepend: {
      type: String,
      required: false,
      default: ''
    },
    selectionRequired: {
      type: Boolean,
      required: false,
      default: false
    },
    shouldAppearOnTop: {
      type: Boolean,
      required: false,
      default: false
    },
    shouldShowPills: {
      type: Boolean,
      required: false,
      default: false
    },
    maxPillsCount: {
      type: Number,
      required: false,
      default: 3
    }
  },
  emits: ['option-click'],
  setup(props) {
    return {
      selectOptions: reactive(props.options) as SelectOption[],
      requireSelection: props.selectionRequired
    };
  },
  data() {
    return {
      filteredList: this.convertToSelectOptions() as SelectOption[],
      input: '' as string,
      dropdownActive: false as boolean
    };
  },
  computed: {
    styleObject() {
      return {
        '--color-hover': this.$props.optionColor
      };
    }
  },
  watch: {
    options(val) {
      this.selectOptions = this.convertToSelectOptions(val);
      this.filterOptions();
    }
  },
  mounted() {
    document.addEventListener('mouseup', (e: MouseEvent) => {
      if (
        !e
          .composedPath()
          .some((x: any) => x.id === 'select' && x.id !== 'input-field')
      ) {
        this.dropdownActive = false;
        this.input = '';
        this.filterOptions();
      }
    });
  },
  methods: {
    convertToSelectOptions(options?: any | undefined): SelectOption[] {
      if (!options) {
        options = this.$props.options;
      }

      return options?.map((x: any) =>
        x instanceof SelectOption ? x : new SelectOption(x)
      ) as SelectOption[];
    },
    filterOptions(): void {
      this.filteredList = this.selectOptions.filter((item: SelectOption) =>
        item.includesText(this.input.trim())
      );
    },
    toggleOptionSelect(value: any): void {
      let model = this.selectOptions.find(
        (option: SelectOption) => option.value === value
      );
      if (!model) throw 'Option not found';

      model.toggle();
      this.filterOptions();
      this.$emit('option-click', model.id, model.selected, model.type);
    }
  }
});
