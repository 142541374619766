import IconWithText from "../../elements/icon-with-text/icon-with-text";

export interface IIconsWithTextCard{
    title: string;
    iconsWithText: IconWithText[];
}

export default class IconsWithTextCard implements IIconsWithTextCard{
    title: string;
    iconsWithText: IconWithText[];

    constructor(data: IIconsWithTextCard){
        this.title = data.title;
        this.iconsWithText = data.iconsWithText;
    }
}