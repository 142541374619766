import moment from 'moment';
import { LocationQuery } from 'vue-router';

import DateRange, {
  IDateRangeWithValidation
} from '../../internal/date-range/date-range';
import { SearchQueryParams } from '../../internal/query-params/search-query';
import LocationInput from '../../internal/input/location-input';
import ISearchProperties from '../../internal/global/search-properties';
import LocationOrProperty from '../../travia/locations-and-properties';
import Calendar from '../standalone/calendar/calendar';
import CalendarModal from '../standalone/modals/calendar-modal/calendar-modal';
import AccommodationModal, {
  IAccommodationBasicArgs
} from '../standalone/modals/accommodation-modal/accommodation-modal';
import ILocation from '../../travia/location';
import CartFabBadge from '../elements/cart-fab-badge/cart-fab-badge';
import IDateRangeValidation from '../../validation/date-range-validation';

export interface IPropertySearchPanel {
  accommodationBasicArgs: IAccommodationBasicArgs;
  availableLocations?: LocationOrProperty[];
  selectedLocations?: LocationOrProperty[];
  locationList: number[];
  propertyList: number[];
  dates?: IDateRangeWithValidation;
  startDate?: string;
  endDate?: string;
  calendarModal?: CalendarModal;
  accommodationModal?: AccommodationModal;
  cartFabBadge?: CartFabBadge;

  formValid?: boolean;
  loading?: boolean;
}

export default class PropertySearchPanel {
  locationInput: LocationInput;
  locationList: number[];
  propertyList: number[];
  calendarModal: CalendarModal;
  accommodationModal: AccommodationModal;
  cartFabBadge: CartFabBadge;
  startDate?: string;
  endDate?: string;

  formValid = false;
  loading = true;

  private calendarValidation: IDateRangeValidation = {
    withValidation: true,
    isValid: true,
    earliestDate: new Date()
  };

  constructor(data?: IPropertySearchPanel) {
    this.calendarModal = new CalendarModal({
      btnColorType: 'btn-light',
      calendar: new Calendar({
        dateRange:
          data?.startDate && data?.endDate
            ? new DateRange(
                {
                  startDate: moment.utc(data?.startDate),
                  endDate: moment.utc(data?.endDate)
                },
                this.calendarValidation
              )
            : new DateRange({}, this.calendarValidation),
        isRange: true
      })
    });

    this.accommodationModal = data?.accommodationModal
      ? data.accommodationModal
      : data?.accommodationBasicArgs
      ? AccommodationModal.fromIAccommodationBasicArgs(
          data.accommodationBasicArgs
        )
      : new AccommodationModal({ btnColorType: 'btn-light' });

    this.locationList = data?.locationList ?? [];
    this.propertyList = data?.propertyList ?? [];

    this.locationInput = new LocationInput({
      models: data?.availableLocations ?? [],
      selectedLocationIds: data?.locationList ?? [],
      selectedPropertyIds: data?.propertyList ?? [],
      selectedModels: data?.selectedLocations ?? []
    });

    this.cartFabBadge = data?.cartFabBadge ?? new CartFabBadge();

    this.formValid = data?.formValid ?? this.formValid;
    this.loading = data?.loading ?? this.loading;
  }

  get selectedDates(): Record<'startDate' | 'endDate', Date> {
    return this.calendarModal.calendar.dateRange.asDatesDefinite;
  }

  get selectedLocations(): ILocation[] {
    return this.locationInput.selectedModels
      .filter((model) => model.type === 'location')
      .map(
        (model) =>
          model.location ?? {
            city: model.value,
            geoPoint: { lon: 0, lat: 0 }
          }
      );
  }

  get selectedProperties(): number[] {
    return this.locationInput.selectedModels
      .filter((model) => model.type === 'property')
      .map((loc) => loc.id);
  }

  get numberOfGuests(): number {
    return this.accommodationModal.adultsInput.value;
  }

  get numberOfRooms(): number {
    return this.accommodationModal.roomsInput.value;
  }

  emitValues(): SearchQueryParams {
    const locations = this.locationInput.selectedModels
      .filter(({ type }) => type === 'location')
      .map(({ id }) => id);
    const properties = this.locationInput.selectedModels
      .filter(({ type }) => type === 'property')
      .map(({ id }) => id);

    return {
      guests: this.accommodationModal.adultsInput.value.toString() ?? '0',
      children: this.accommodationModal.childrenCount.toString() ?? '0',
      rooms: this.accommodationModal.roomsInput.value.toString() ?? '0',
      startDate: moment(this.calendarModal.calendar.dateRange.startDate).format(
        'YYYY-MM-DD'
      ),
      endDate: moment(this.calendarModal.calendar.dateRange.endDate).format(
        'YYYY-MM-DD'
      ),
      locations: JSON.stringify(locations),
      properties: JSON.stringify(properties)
    };
  }

  public validate(): boolean {
    this.formValid =
      this.calendarModal.calendar.dateRange.validate() &&
      this.accommodationModal.roomsInput.validate() &&
      this.accommodationModal.adultsInput.validate() &&
      this.locationInput.validate();

    return this.formValid;
  }

  /**
   * Validating input as:
   * - greater than 0
   * - less than or equal to 100
   * - not a decimal.
   */
  private static isValidGuestRoomParam(param) {
    const numericInput = Number(param);
    const minVal = 0;
    const maxVal = 100;
    return (
      numericInput > minVal && numericInput <= maxVal && numericInput % 1 === 0
    );
  }

  /**
   * Validating input as:
   * - not a decimal
   * - should not be less than zero (Some ids are zero)
   */
  private static isValidLocationPropertyParam(param) {
    const numericInput = Number(param);
    return numericInput % 1 === 0 && numericInput >= 0;
  }

  static isQueryParamsValid(params: LocationQuery): boolean {
    try {
      const query = params as SearchQueryParams;

      // Basic guest, rooms, and locations validation.
      // Check if integer input is 1-100 and not a decimal value
      const isGuestsValid =
        !!query?.guests && this.isValidGuestRoomParam(query?.guests);
      const isRoomsValid =
        !!query?.rooms && this.isValidGuestRoomParam(query?.rooms);

      const locations = JSON.parse(query?.locations);
      const properties = JSON.parse(query?.properties);

      const isLocationsInitiallyValid = !!locations;
      const isPropertiesInitiallyValid = !!properties;

      let isLocationsValid = false;
      let isPropertiesValid = false;

      if (isLocationsInitiallyValid && isPropertiesInitiallyValid) {
        isLocationsValid = locations.every(this.isValidLocationPropertyParam);
        isPropertiesValid = properties.every(this.isValidLocationPropertyParam);
      }

      /**
       * Basic date validation.
       * Ensure dates are valid, startDate is either today or tomorrow, and
       * endDate starts tomorrow.
       */
      const startDate = moment.utc(query.startDate);
      const endDate = moment.utc(query.endDate);
      const isStartDateValid =
        startDate.isValid() && startDate.isSameOrAfter(moment.now(), 'day');
      const isEndDateValid = endDate.isValid() && endDate.isAfter(startDate);
      const isDateValid = isStartDateValid && isEndDateValid;

      return (
        isGuestsValid &&
        isRoomsValid &&
        isLocationsValid &&
        isPropertiesValid &&
        isDateValid
      );
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  static fromSearchProperties(
    searchProperties: ISearchProperties
  ): PropertySearchPanel {
    return new PropertySearchPanel({
      accommodationBasicArgs: {
        guests: searchProperties.accommodation.guests,
        rooms: searchProperties.accommodation.rooms,
        children: searchProperties.accommodation.children
      },
      selectedLocations: searchProperties.locations,
      locationList: searchProperties.locationList ?? [],
      propertyList: searchProperties.properties ?? [],
      startDate: searchProperties.startDate,
      endDate: searchProperties.endDate
    });
  }
}
