import AccommodationProductItem from '../../models/components/standalone/detailed-product-item/accommodation-product-item';
import TourProductItem from '../../models/components/standalone/detailed-product-item/tour-product-item';
import { CONTENT_TYPES } from './constants';
import { CONTENT_CATEGORIES } from '../constants';

export const extractRemoveBookingData = (
  product: TourProductItem | AccommodationProductItem,
  totalPrice: number,
  currency: string
) => {
  if (TourProductItem.isTourProduct(product)) {
    return {
      value: totalPrice,
      currency: currency,
      content_type: CONTENT_TYPES.PRODUCT,
      content_category: CONTENT_CATEGORIES.BOOKING,
      content_ids: [`${product.activityId}`]
    };
  }

  if (AccommodationProductItem.isAccommodationProduct(product)) {
    return {
      value: totalPrice,
      currency: currency,
      content_name: product.propertyName,
      content_type: CONTENT_TYPES.PRODUCT,
      content_category: CONTENT_CATEGORIES.UPSELL,
      content_ids: [`${product.propertyId}`]
    };
  }
};

export const extractInitiateCheckoutData = (
  bookings: (AccommodationProductItem | TourProductItem)[],
  totalPrice: number,
  currency: string
) => {
  const bookingIds = bookings.map((product) => product.bookingId.toString());
  return {
    value: totalPrice,
    currency: currency,
    content_name: 'BOOKING',
    content_category: CONTENT_CATEGORIES.ACTIVITIES,
    content_ids: bookingIds,
    num_items: bookingIds.length
  };
};

export const extractAddPromoCodeData = (
  bookings: (AccommodationProductItem | TourProductItem)[],
  promoCode: string,
  totalPrice: number,
  currency: string
) => {
  const initialParams = extractInitiateCheckoutData(
    bookings,
    totalPrice,
    currency
  );
  const numItems = bookings.length;
  return {
    ...initialParams,
    promo: promoCode,
    content_type:
      numItems > 1 ? CONTENT_TYPES.PRODUCT_GROUP : CONTENT_TYPES.PRODUCT
  };
};
