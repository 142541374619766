
import { defineComponent, reactive } from "vue";

import CarTravelDetails from '../../../models/components/block/car-travel-details';
import CarInsurance from "../car-insurance/car-insurance.vue";
import CarExtras from "../car-extras/car-extras.vue";

export default defineComponent({
  components: {
    "car-insurance": CarInsurance,
    "car-extras": CarExtras,
  },
  props: {
    carTravelDetailsModel: {
      type: Object as () => CarTravelDetails,
      required: true
    }
  },
  emits: ['addToCartClicked'],
  setup(props) {
    return {
      carTravelDetails: reactive(props.carTravelDetailsModel),
    }
  },
  methods: {
    addToCart(): void {
      this.$emit('addToCartClicked');
    }
  },
})
