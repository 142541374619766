
import { defineComponent, reactive } from "@vue/runtime-core"
import Footer from "../../../models/components/standalone/footer/footer"
import AdvComSubFooter from "../subfooter/adv-com-subfooter.vue"
import AdvIsSubFooter from "../subfooter/adv-is-subfooter.vue"

  export default defineComponent({
    name: "FooterCmp",
    components: {
      'adv-com-subfooter': AdvComSubFooter,
      'adv-is-subfooter': AdvIsSubFooter
    },
    props: {
      footerModel: {
        type: Object as () => Footer,
        required: true
      },
    },
    setup(props) {
      return {
        footer: reactive(props.footerModel)
      }
    },
  })
