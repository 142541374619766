import axios, { AxiosInstance, AxiosResponse } from 'axios';
import GenericResponse from '../models/response';

export default class BaseHttpClientService {
  httpClient: AxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL?.toString(),
    headers: {}
  });

  prepareResponse(response: AxiosResponse): GenericResponse {
    let genericResp: GenericResponse;
    if (response.status === 200) {
      genericResp = response.data as GenericResponse;

      if (genericResp.statusCode !== 200)
        throw {
          message: genericResp.message,
          statusCode: genericResp.statusCode
        } as GenericResponse;
    } else {
      genericResp = {
        content: null,
        statusCode: response.status,
        message: response.statusText
      } as GenericResponse;
    }

    return genericResp;
  }
}
