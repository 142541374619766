

import {defineComponent} from "vue";
import {toHome} from "../router";

export default defineComponent({
  name: 'NotFound',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  beforeRouteEnter(_to, _from) {
    document.body.style.overflow = '';
    document.body.style.paddingRight = '';
  },
  setup(): {toHome: () => void} {
    return {
      toHome: (): void => toHome()
    }
  },
})

