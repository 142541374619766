import Input, { IInputArgs } from '../../../internal/input/input';

export type ICardExpiryInput = IInputArgs;

export default class CardExpiryInput extends Input {
  constructor(data?: ICardExpiryInput) {
    super({
      ...data,
      defaultMessage: data?.defaultMessage ?? `Please enter a valid expiry date`
    });

    this.label = data?.label ?? `Expiration date`;
    this.placeholder = data?.placeholder ?? `MM/YY`;
    this.withValidation = data?.withValidation ?? true;
  }

  get expiration(): Record<'year' | 'month', number> {
    const enteredValue = this.value.toString().split('/');
    return {
      year: parseInt(enteredValue[1]),
      month: parseInt(enteredValue[0])
    };
  }

  formatInput() {
    this.value = String(this.value)
      .replace(
        /[^0-9]/g,
        '' // To allow only numbers
      )
      .replace(
        /^([2-9])$/g,
        '0$1' // If first number isn't 1 add preceding 0
      )
      .replace(
        /^(1{1})([3-9]{1})$/g,
        '0$1/$2' //To handle numbers above 12. 13 -> 01/3
      )
      .replace(
        /^0{1,}/g,
        '0' // To handle double zeroes. 00 -> 0
      )
      .replace(
        /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g,
        '$1/$2' // To handle 113 > 11/3
      );

    this.value = this.value[0] != '0' && this.value[0] != '1' ? '' : this.value;
  }

  validate(): boolean {
    this.value = this.value.toString();

    if (!this.withValidation) return (this.isValid = true);

    let dateValid = false;

    if (this.value.length > 4) {
      const currentTime = new Date();
      const currentMonth = currentTime.getMonth() + 1;
      const currentYear = parseInt(
        currentTime.getFullYear().toString().slice(2, 4)
      );

      const expiration = this.expiration;

      dateValid =
        expiration.year > currentYear
          ? true
          : expiration.year >= currentYear && expiration.month >= currentMonth;

      if (!dateValid) this.setInvalid('Card is expired');
    }

    if (this.value.length < 5) this.validationMessage = this.defaultMessage;

    return (this.isValid = dateValid && this.value.toString().length > 4);
  }
}
