import { CurrencyType } from '../../../internal/currency/currency';
import CarSearchResult from '../../../internal/vehicle/car-search-result';
import IconWithText from '../../elements/icon-with-text/icon-with-text';
import ProductItem, { IProductItem } from './product-item';
import ILogo from '../../../../../src/models/travia/logo';

export interface ICarProductItem extends IProductItem {
  isLoading?: boolean;
  category?: string;
  transmissionType?: string;
  model?: string;
  seats?: number;
  doors?: number;
  fuelType?: string;
  smallBags?: number;
  largeBags?: number;
  airCondition?: boolean;
  forHighland?: boolean;
  transmissionId: number;
  fuelId: number;
  groups: ICarGroup[];
}

export interface ICarGroup {
  id: number;
  name: string;
  description: string;
}

export default class CarProductItem extends ProductItem {
  isLoading?: boolean = false;

  category?: string = '';
  model?: string = '';
  seats?: number = 0;
  doors?: number = 0;
  fuelType?: string = '';
  smallBags?: number = 1;
  largeBags?: number = 1;
  airCondition?: boolean = false;
  forHighland?: boolean = false;
  transmissionType?: string;
  transmissionId: number;
  fuelId: number;
  groups: ICarGroup[];

  static imageBaseURL = process.env.VUE_APP_CAREN_BASE_URL;

  constructor(data: ICarProductItem) {
    super(data);
    this.isLoading = data?.isLoading ?? this.isLoading;

    this.productItemType = 'CAR';
    this.category = data?.category ?? this.category;
    this.transmissionType = data?.transmissionType ?? this.transmissionType;
    this.model = data?.model ?? this.model;
    this.seats = data?.seats ?? this.seats;
    this.doors = data?.doors ?? this.doors;
    this.fuelType = data?.fuelType ?? this.fuelType;
    this.smallBags = data?.smallBags ?? this.smallBags;
    this.largeBags = data?.largeBags ?? this.largeBags;
    this.airCondition = data?.airCondition ?? this.airCondition;

    this.transmissionType = data.transmissionType;
    this.category = data.category;
    this.fuelType = data.fuelType;
    this.transmissionId = data.transmissionId;
    this.fuelId = data.fuelId;
    this.groups = data.groups;
  }

  public static getImageURL(path: string): string {
    return `${this.imageBaseURL}${path}`;
  }

  public static getIconMappings(car: CarSearchResult): IconWithText[] {
    const icons: IconWithText[] = [];
    icons.push(
      new IconWithText({
        code: 'GR',
        title: 'Transmission',
        content: car.transmissionName ?? null
      }),
      new IconWithText({
        code: 'DB',
        title: 'Fuel Type',
        content: car.fuelName ?? null
      }),
      new IconWithText({
        code: 'BC',
        title: 'Small Bags',
        content: car.smallBags?.toString() ?? null
      }),
      new IconWithText({
        code: 'BC',
        title: 'Large Bags',
        content: car.largeBags?.toString() ?? null
      }),
      new IconWithText({
        code: 'SP',
        title: 'Seats',
        content: car.seats?.toString() ?? null
      }),
      new IconWithText({
        code: 'SP',
        title: 'Doors',
        content: car.doors?.toString() ?? null
      }),
      new IconWithText({
        code: 'AICO',
        title: 'Air-condition',
        content: car.airCondition ? 'Yes' : 'No'
      }),
      new IconWithText({
        code: 'FD',
        title: 'Model',
        content: car.model ?? null
      }),
      new IconWithText({
        code: 'OF',
        title: 'For Highland',
        content: car.forHighland ? 'Yes' : 'No'
      }),
      new IconWithText({
        title: 'Supplier',
        code: '',
        content: car.rental.name
      })
    );
    return icons.filter(
      (item: IconWithText) => !!item.content && item.content !== '0'
    );
  }

  public static fromClassListResponse(
    data: CarSearchResult[],
    selectedCurrency: CurrencyType
  ) {
    return data.map((car: CarSearchResult) => {
      const productImages = [] as ILogo[];
      productImages.push({
        thumbnailPath: this.getImageURL(car.image),
        filePath: car.image
      });
      car.images.length > 0 &&
        car.images.map((ci) =>
          productImages.push({ thumbnailPath: ci.url, filePath: ci.url })
        );

      return new CarProductItem({
        ...car,
        productName: car.name,
        transmissionType: car.transmissionName,
        category: car.groupName,
        productPrice: car.totalPrice,
        productTypeName: car.groupName, //change to something else? or remove category?
        currencyType: selectedCurrency,
        iconsWithText: this.getIconMappings(car),
        productImages: productImages,
        fuelType: car.fuelName
      });
    });
  }
}
