import Input, { IInputArgs } from '../../../internal/input/input';

export type IEmailInputArgs = IInputArgs

export default class EmailInput extends Input {
  constructor(data?: IEmailInputArgs) {
    super(data);

    this.placeholder = data?.placeholder ?? 'Enter your email address.';
    this.label = data?.label ?? 'Email';
  }

  validate(): boolean {
    this.value = this.value.toString();
    // eslint-disable-next-line no-control-regex
    const matchesRegex = /^(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[A-Za-z0-9-]*[A-Za-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/.test(
      this.value
    );

    // Email regex taken from here: https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression
    // With addition of capital letters

    // 1. Valid:
    //  1) Typing local part between quotation marks allows everything, example - "....?/,';:[{}]|\=+-_~`!@#$%^&*()"@ma-il.co-m
    //  2) Valid examples:
    //      2.1) my.mail.yup@email.et.smth.com
    //      2.2) mAil@mail.com, my!#$%.^&*_-=+'/}{`~mail@mail.com, "m.....ai,l"@mail.com
    //      2.3) mail@m-m.ail.co-m
    //      2.4) MAIL@MAIL.COM

    // 2. Invalid:
    //  1) Adding a dot at the end or start of the email, right before or after the "@" symbol is not valid, example - .mail.@.mail.com.
    //      1.1) Consecutive dots are not allowed, most of the symbols are not allowed in the domain part except "-"
    //  2) Invalid examples:
    //      2.1) m.....ail@mail.com
    //      2.2) m..ail@m..mail.co..m
    //      2.3) mail@m~!#$%^&*_-=+{}'/`mail.com
    //      2.4) mail@mail'mine.co*m
    //      2.5) ma@il@mail.com

    if (this.maxCharacters) {
      this.value =
        this.value.length > this.maxCharacters
          ? this.value.slice(0, this.maxCharacters)
          : this.value;
    }

    if (!this.withValidation) return (this.isValid = true);

    return (this.isValid = matchesRegex);
  }
}
