enum StepStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

interface IStep {
  number: number;
  label: string;
  status?: StepStatus;
  isDone: boolean;
}

interface IStepper {
  activeStep?: number;
  steps: IStep[];
}

export default class Stepper implements IStepper {
  activeStep = 1;
  steps = [] as IStep[];

  constructor(data?: IStepper) {
    this.activeStep = data?.activeStep ?? this.activeStep;

    this.steps =
      data?.steps.map((step) => ({
        ...step,
        isDone: false,
        status:
          data.activeStep && data.activeStep === step.number
            ? StepStatus.ACTIVE
            : StepStatus.INACTIVE
      })) ?? this.steps;
  }

  nextStep() {
    const prevStep = this.activeStep;
    const nextStep = Math.min(this.activeStep + 1, this.steps.length);

    this.activeStep = nextStep;
    this.updateStatus(prevStep);
  }

  prevStep() {
    const prevStep = this.activeStep;
    const nextStep = Math.max(this.activeStep - 1, 1);

    this.activeStep = nextStep;

    this.updateStatus(prevStep);
  }

  isFirstStep(): boolean {
    return this.steps[0].number === this.activeStep;
  }

  isLastStep(): boolean {
    return this.steps.length === this.activeStep;
  }

  updateStatus(prevStep: number) {
    // Last step should not be set to done automatically
    // as it should be only marked done when payment is complete.
    this.steps = this.steps.map((step) => ({
      ...step,
      isDone: prevStep === step.number && this.steps.length !== step.number,
      status:
        step.number === this.activeStep
          ? StepStatus.ACTIVE
          : StepStatus.INACTIVE
    }));
  }

  getStatusColor(step: IStep) {
    if (step.isDone) return 'success';
    if (step.status === StepStatus.ACTIVE) return 'primary';
    if (step.status === StepStatus.INACTIVE) return 'gray2';
  }
}
