import Input, { IInputArgs } from '../../../internal/input/input';

export type ICardNumberInputArgs = IInputArgs;

export default class CardNumberInput extends Input {
  constructor(data?: ICardNumberInputArgs) {
    super({
      ...data,
      defaultMessage: data?.defaultMessage ?? 'Please enter a valid card number'
    });

    this.label = data?.label ?? 'Card number';
    this.placeholder = data?.placeholder ?? '1234 5678 9012 3456';
    this.withValidation = data?.withValidation ?? true;
  }

  validate(): boolean {
    this.value = String(this.value).replace(/[^0-9 ]/g, '');

    if (!this.withValidation) return (this.isValid = true);
    return (this.isValid = this.value.toString().length > 0);
  }
}
