import NumericInput from '../../../elements/numeric-input/numeric-input';

interface IAccommodationModal {
  roomsInput?: NumericInput;
  adultsInput?: NumericInput;
  childrenInput?: NumericInput;
  showRoomsInput?: boolean;
  isHorizontal?: boolean;
  justify?: string;
  btnColorType?: string;
  modalClasses?: string;
}

export interface IAccommodationBasicArgs {
  rooms?: number;
  guests?: number;
  children?: number;
}

export default class AccommodationModal {
  roomsInput: NumericInput;
  adultsInput: NumericInput;
  childrenInput: NumericInput;
  showRoomsInput = true;
  isHorizontal = false;

  constructor(data?: IAccommodationModal) {
    this.showRoomsInput = data?.showRoomsInput ?? this.showRoomsInput;
    this.isHorizontal = data?.isHorizontal ?? this.isHorizontal;

    this.roomsInput =
      data?.roomsInput ??
      new NumericInput(
        {
          label: 'Rooms',
          value: 1
        },
        {
          isValid: true,
          minValue: 1,
          maxValue: 100,
          withValidation: true,
          validationMessage: 'At least one room'
        }
      );

    this.adultsInput =
      data?.adultsInput ??
      new NumericInput(
        {
          label: 'Adults',
          value: 1,
          helperText: '(16+ y.o)'
        },
        {
          isValid: true,
          minValue: 1,
          maxValue: 100,
          withValidation: true,
          validationMessage: 'At least one guest'
        }
      );

    this.childrenInput =
      data?.childrenInput ??
      new NumericInput(
        {
          label: 'Children',
          value: 0,
          helperText: '(0 - 15 y.o)'
        },
        {
          isValid: true,
          minValue: 0,
          maxValue: 100,
          withValidation: false
        }
      );
  }

  get adultsCount(): number {
    return this.adultsInput.displayValue;
  }

  get childrenCount(): number {
    return this.childrenInput.displayValue;
  }

  get guestsCount(): number {
    return this.adultsCount + this.childrenCount;
  }

  get roomsCount(): number {
    return this.roomsInput.displayValue;
  }

  get adultsDisplayText(): string {
    const adultText = `adult${this.adultsCount > 1 ? 's' : ''}`;
    return `${this.adultsCount} ${adultText}`;
  }

  get childrenDisplayText(): string {
    const childrenText = `child${this.childrenCount > 1 ? 'ren' : ''}`;
    return `${this.childrenCount} ${childrenText}`;
  }

  get roomsDisplayText(): string {
    const roomsText = `room${this.roomsCount > 1 ? 's' : ''}`;
    return `${this.roomsCount} ${roomsText}`;
  }

  get isValid(): boolean {
    return (
      this.adultsInput.isValid &&
      this.roomsInput.isValid &&
      this.childrenInput.isValid
    );
  }

  static fromIAccommodationBasicArgs(
    args: IAccommodationBasicArgs
  ): AccommodationModal {
    return new AccommodationModal({
      adultsInput: new NumericInput(
        {
          label: 'Adults',
          value: args.guests ?? 1,
          helperText: '(16+ y.o)'
        },
        {
          isValid: true,
          minValue: 1,
          maxValue: 100,
          withValidation: true,
          validationMessage: 'At least one guest'
        }
      ),
      childrenInput: new NumericInput(
        {
          label: 'Children',
          value: args.children,
          helperText: '(0 - 15 y.o)'
        },
        {
          isValid: true,
          minValue: 0,
          maxValue: 100,
          withValidation: false
        }
      ),
      roomsInput: new NumericInput(
        {
          label: 'Rooms',
          value: args.rooms ?? 1
        },
        {
          isValid: true,
          minValue: 1,
          maxValue: 100,
          withValidation: true,
          validationMessage: 'At least one room'
        }
      ),
      btnColorType: 'btn-light'
    });
  }
}
