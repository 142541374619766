import { CurrencyType } from "../../../internal/currency/currency";

export interface IDualRangeSliderArgs{
    minValue?: number | string;
    maxValue?: number | string;
    minInitialValue: number;
    maxInitialValue: number;
    step?: number;
    currency?: CurrencyType;
}

export default class DualRangeSlider{
    minValue: number | string;
    maxValue: number | string;
    minInitialValue: number;
    maxInitialValue: number;
    step = 10;
    currency?: CurrencyType;

    constructor(data: IDualRangeSliderArgs){
        this.step = data.step ?? this.step;
        this.minInitialValue = this.roundValue(data.minInitialValue, 'floor');
        this.maxInitialValue = this.roundValue(data.maxInitialValue, 'ceil');
        this.minValue = data.minValue ?? this.minInitialValue;
        this.maxValue = data.maxValue ?? this.maxInitialValue;
        this.currency = data.currency;
    }

    checkLowValue(){
        this.minValue = Number(this.minValue);
        this.maxValue = Number(this.maxValue);

        // handles slider value movement
        if(this.minValue === this.maxValue){
            // if the values are the same adjust it to have a space of one slider step
            this.minValue = this.minValue - this.step;
        }else if(this.minValue > this.maxValue && this.minValue < this.maxInitialValue){
            // if minValue gets higher than max increase the maxValue if min is not higher than maxInitial
            this.maxValue = this.minValue + this.step;
        }else if(this.minValue === this.maxInitialValue){
            // if minValue equals maxInitial set it a step back and set max to maxInitial
            this.minValue = this.maxInitialValue - this.step;
            this.maxValue = this.maxInitialValue;
        }
    }

    // works similar to checkLowValue, but in the opposite way
    checkHighValue(){
        this.minValue = Number(this.minValue);
        this.maxValue = Number(this.maxValue);

        if(this.minValue === this.maxValue){
            this.maxValue = this.maxValue + this.step;
        }else if(this.maxValue < this.minValue && this.maxValue > this.minInitialValue){
            this.minValue = this.maxValue - this.step;
        }else if(this.maxValue === this.minInitialValue){
            this.maxValue = this.minInitialValue + this.step;
            this.minValue = this.minInitialValue;
        }
    }

    // rounds the values to prettier numbers
    roundValue(numberToRound: number, ceilOrFloor: 'ceil' | 'floor'){
        const numLength = Math.round(numberToRound).toString().length - 1;
        let rounder: number | string = '1';

        for (let i = 0; i < numLength; i++) {
            rounder += '0';
        }
        rounder = Number(rounder);

        if(ceilOrFloor === 'ceil'){
            return numberToRound = Math.ceil(numberToRound / rounder) * rounder;
        }else if(ceilOrFloor === 'floor'){
            return numberToRound = Math.floor(numberToRound / rounder) * rounder;
        }else{
            return numberToRound;
        }
    }

    resetValues(){
        this.minValue = this.minInitialValue;
        this.maxValue = this.maxInitialValue;
    }
}
