
import {defineComponent, reactive} from 'vue'
import DualRangeSlider from '../../../models/components/elements/dual-range-slider/dual-range-slider'

export default defineComponent({
    name: 'DualRangeSlider',
    props: {
            dualRangeSliderModel: {
            type: Object as () => DualRangeSlider,
            required: true
        }
    },
    emits: [
        'inputs-changed'
    ],
    setup(props) {
        return{
            dualRangeSlider: reactive(props.dualRangeSliderModel)
        }
    },
    methods: {
        onMinValueChange(){
            this.dualRangeSlider.checkLowValue();
            this.$emit("inputs-changed", this.dualRangeSlider.minValue, this.dualRangeSlider.maxValue);
        },
        onMaxValueChange(){
            this.dualRangeSlider.checkHighValue();
            this.$emit("inputs-changed", this.dualRangeSlider.minValue, this.dualRangeSlider.maxValue);
        },
        onReset(){
            this.dualRangeSlider.resetValues();
            this.$emit("inputs-changed", this.dualRangeSlider.minValue, this.dualRangeSlider.maxValue);
        }
    },
})
