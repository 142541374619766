import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container my-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CarSearchPanelCmp = _resolveComponent("CarSearchPanelCmp")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CarSearchPanelCmp, {
      "car-search-panel-inputs": _ctx.carSearchPanelInputs,
      onSearchClicked: _ctx.onSearchClicked
    }, null, 8, ["car-search-panel-inputs", "onSearchClicked"])
  ]))
}