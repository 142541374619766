
import { defineComponent, reactive } from 'vue';
import Carousel from '../../../models/components/standalone/carousel/carousel';

export default defineComponent({
  name: 'CarouselCmp',
  props: {
    carouselModel: {
      type: Object as () => Carousel,
      default: () =>
        reactive(
          new Carousel({
            carouselItems: []
          })
        ),
      required: false
    }
  },
  setup(props) {
    return {
      carousel: reactive(props.carouselModel)
    };
  },
  computed: {
    styledObject() {
      return {
        '--max-vh': this.carousel?.carouselSize.valueOf()
        // '--max-vw': this.carousel?.carouselSize.valueOf() + 'vw'
      };
    }
  }
});
