
import { defineComponent, reactive } from 'vue';
import { useStore } from 'vuex';

import SearchPanel from '../components/block/search-panels/property-search-panel.vue';
import SearchProperties from '../models/internal/global/search-properties';
import PropertySearchPanel from '../models/components/block/property-search-panel';
import searchController from '../services/controllers/search-controller-service';
import LocationOrProperty from '../models/travia/locations-and-properties';
import Notification from '../models/internal/notification/notification';
import { openInNewTab, toCheckout } from '../router';

export default defineComponent({
  name: 'HomeCmp',
  components: {
    'search-panel': SearchPanel
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  beforeRouteEnter(_to, _from) {
    document.body.style.overflow = '';
    document.body.style.paddingRight = '';
  },
  props: {},
  setup() {
    const store = useStore();
    const inputModels = store.getters.inputOptions as SearchProperties;
    const model = inputModels
      ? PropertySearchPanel.fromSearchProperties(inputModels)
      : new PropertySearchPanel();

    return {
      propertySearchPanelInputs: reactive(model)
    };
  },
  mounted() {
    this.getLocations();
  },
  methods: {
    toCheckoutClick() {
      toCheckout();
    },
    onSearchClicked() {
      openInNewTab(
        'results',
        this.propertySearchPanelInputs.emitValues(),
        null,
        null
      );
    },
    getLocations() {
      searchController
        .getLocationsAsync()
        .then((res: LocationOrProperty[]) => {
          this.propertySearchPanelInputs.locationInput.models = res;
        })
        .catch((err) => {
          this.$notify(Notification.error(err.message));
          this.propertySearchPanelInputs.locationInput.selectedModels = [];
          this.propertySearchPanelInputs.locationInput.models = [];
        })
        .finally(() => {
          this.propertySearchPanelInputs.loading = false;
          // only update store when inputs are coming from inputOptions
          this.propertySearchPanelInputs.locationInput.mapIdsToLocationOrProperty();
          this.propertySearchPanelInputs.locationInput.initSelections();
          this.propertySearchPanelInputs.locationInput.setDefaultSelected();
          if (this.$store.getters.inputOptions) {
            this.$store.commit(
              'updateSearchProperties',
              this.propertySearchPanelInputs.emitValues()
            );
          }
        });
    }
  }
});
