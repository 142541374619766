import GroupDto from './group-dto';

export interface IGroupsArgs {
  groupsList: GroupDto[];
}

export default class Groups {
  groupsList: GroupDto[];

  constructor(data: GroupDto[]) {
    this.groupsList = data;
  }
}
