
import { defineComponent } from '@vue/runtime-core';
import TourProductItem from '../../../models/components/standalone/detailed-product-item/tour-product-item';
import DynamicInput from '../../../components/elements/dynamic-input/dynamic-input.vue';

export default defineComponent({
  name: 'BookingDetails',
  components: {
    'dynamic-input': DynamicInput
  },
  props: {
    tours: {
      type: Object as () => TourProductItem[],
      required: true
    }
  },
  computed: {
    shouldShowBookingDetails: function () {
      return this.tours.some((tour) => !!tour.bookingQuestions.length);
    }
  }
});
