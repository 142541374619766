
import { defineComponent, computed, reactive } from 'vue';
import DayModel from '../../../models/internal/day/day';

export default defineComponent({
  name: 'DayCmp',
  props: {
    dayModel: {
      type: Object as () => DayModel,
      required: true
    },
    isRange: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['click'],
  setup(props, { emit }) {
    return {
      day: reactive(props.dayModel),
      classes: props.isRange
        ? computed(() => ({
            'day-disabled text-gray1': props.dayModel.isDisabled,
            selected: props.dayModel.isSelected,
            included: props.dayModel.isIncluded,
            'c-rounded':
              !props.isRange &&
              !props.dayModel.isIncluded &&
              !props.dayModel.isSelected,
            'day-item': true
          }))
        : computed(() => ({
            'day-disabled text-gray1': props.dayModel.isDisabled,
            selected: props.dayModel.isSelected,
            'day-item': true
          })),
      onSelect() {
        emit('click', props.dayModel.fullDate);
      }
    };
  }
});
