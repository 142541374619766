import {PopupStatus} from '../../../enums/popup-status';

export interface IPopupModel {
    text?: string;
    isVisible?: boolean;
    status?: PopupStatus;
}

export default class PopupModel {
    text = '';
    isVisible = false;
    status: PopupStatus = PopupStatus.Success;
    timedClose: NodeJS.Timeout | undefined;

    constructor(data?: IPopupModel) {
        this.text = data?.text ?? this.text;
        this.isVisible = data?.isVisible ?? this.isVisible;
        this.status = data?.status ?? this.status;
    }

    show(text: string, closeInMilliseconds: number | null = null) {
        this.text = text;
        this.isVisible = true;

        if (!!closeInMilliseconds)
            this.timedClose = setTimeout(() => {
                this.hide();
            }, closeInMilliseconds);
    }

    hide() {
        if (!!this.timedClose)
            clearTimeout(this.timedClose);

        this.text = '';
        this.isVisible = false;
    }
}
