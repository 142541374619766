
import { defineComponent, reactive, ref } from 'vue';
import SearchResult from '../search-result/search-result.vue';
import Sidebar from '../sidebar/sidebar.vue';
import ResultsPage from '../../../models/components/block/results-page';
import ProductItem from '../../../models/components/standalone/product/product-item';
import SearchProperties from '../../../models/internal/global/search-properties';
import SidebarCar from '../sidebar-car/sidebar-car.vue';
import CarProductItem from '../../../models/components/standalone/product/car-product-item';
import AppUtils from '../../../utils/app-utils';
import { ICarSearchProperties } from '../../../models/internal/global/car-search-properties';

export default defineComponent({
  name: 'ResultsPage',
  components: {
    'search-result': SearchResult,
    sidebar: Sidebar,
    'sidebar-car': SidebarCar
  },
  props: {
    resultsPageModel: {
      type: Object as () => ResultsPage,
      required: true,
      default: () => reactive(new ResultsPage())
    }
  },
  emits: [
    'inputs-changed',
    'property-clicked',
    'search-clicked',
    'sidebar-filters-changed',
    'sorting-changed',
    'price-filters-changed'
  ],
  setup(props) {
    return {
      resultsPage: reactive(props.resultsPageModel),
      themePrefix: ref(AppUtils.getFallbackDomain())
    };
  },
  data() {
    return {
      loadingResults: true
    };
  },
  mounted() {
    this.onSortingChanged();
  },
  methods: {
    onPropertyClicked(product: ProductItem | CarProductItem) {
      this.$emit('property-clicked', product);
    },
    onInputChange(searchProperties: SearchProperties | ICarSearchProperties) {
      this.$emit('inputs-changed', searchProperties);
    },
    onSidebarFiltersChanged() {
      this.$emit('sidebar-filters-changed');
    },
    onSearchClicked() {
      this.$emit('search-clicked');
    },
    onSortingChanged() {
      this.$emit('sorting-changed');
    }
  }
});
