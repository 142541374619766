
import {defineComponent, reactive} from 'vue'
import iconWithTextVue from '../../elements/icon-with-text/icon-with-text.vue'
import IconsWithTextCardModel from '../../../models/components/standalone/icons-with-text-card/icons-with-text-card'

export default defineComponent({
    name: 'IconsWithTextCard',
    components: {
        'icon': iconWithTextVue
    },
    props: {
        iconsWithTextCardModel: {
            type: Object as () => IconsWithTextCardModel,
            required: true
        }
    },
    setup(props) {
        return{
            iconsWithTextCard: reactive(props.iconsWithTextCardModel)
        }
    },
    methods: {

    },
})
