import { BING_ACTIONS, BING_EVENT_CATEGORY } from './constants';

class BingTracker {
  isEnabled = false;

  init() {
    this.isEnabled = window.uetq;
  }

  removeFromCart(params) {
    if (!this.isEnabled) return;
    window.uetq.push('event', BING_ACTIONS.REMOVE_FROM_CART, {
      event_category: BING_EVENT_CATEGORY.CART,
      event_label: BING_ACTIONS.REMOVE_FROM_CART,
      ...params
    });
  }

  beginCheckout(params) {
    if (!this.isEnabled) return;
    window.uetq.push('event,', BING_ACTIONS.BEGIN_CHECKOUT, {
      event_category: BING_EVENT_CATEGORY.CART,
      event_label: BING_ACTIONS.BEGIN_CHECKOUT,
      ...params
    });
  }
}

export default BingTracker;
