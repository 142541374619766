import ProductItem from '../product/product-item';
import Ribbon from '../ribbon/ribbon';
import Carousel from '../carousel/carousel';
import AvailableProperty from '../../../travia/available-property';
import { CurrencyType } from '../../../internal/currency/currency';
import ILogo from '../../../travia/logo';
import RibbonItem from '../ribbon/ribbon-item';
import IRoom from '../../../travia/room';
import IPropertyMap from '../../../internal/prop-maps/property-map';
import RoomProductItem from '../product/room-product-item';
import ICancellationPolicy from '../../../internal/cancellation-policy/cancellation-policy';

export interface IPropertyDetails {
  propertyId: number;
  propertyName: string | null;
  carousel: Carousel;
  ribbon?: Ribbon;
  description: string;
  rooms?: ProductItem[];
  cancellationPolicies?: ICancellationPolicy[];
}

export default class PropertyDetails implements IPropertyDetails {
  propertyId: number;
  propertyName: string | null;
  carousel: Carousel = new Carousel();
  ribbon: Ribbon = new Ribbon();
  description = '';
  rooms: ProductItem[] = [];
  cancellationPolicies?: ICancellationPolicy[];

  constructor(data?: IPropertyDetails) {
    this.propertyName = data?.propertyName ?? null;
    this.carousel = data?.carousel ?? this.carousel;
    this.ribbon = data?.ribbon ?? this.ribbon;
    this.description = data?.description ?? this.description;
    this.rooms = data?.rooms ?? this.rooms;
    this.propertyId = data?.propertyId ?? 0;
    this.cancellationPolicies = data?.cancellationPolicies;
  }

  get carouselHasValues(): boolean {
    return this.carousel.carouselItems.length > 0;
  }

  get ribbonHasValues(): boolean {
    return this.ribbon.items.length > 0;
  }

  static fromAvailableProperty(
    availableProperty: AvailableProperty,
    selectedCurrency: CurrencyType,
    propertyMap: IPropertyMap
  ): PropertyDetails {
    return new PropertyDetails({
      propertyName: availableProperty.name,
      rooms: availableProperty.rooms.map((room: IRoom) =>
        RoomProductItem.fromIRoom(room, selectedCurrency, propertyMap)
      ),
      description: availableProperty.description,
      propertyId: availableProperty.id ?? -1,
      carousel: new Carousel({
        maxItemsForIndicators: 10,
        carouselItems: availableProperty.images.map((img: ILogo) => {
          return {
            imageSrc: img.filePath ?? img.thumbnailPath,
            imageAlt: `${availableProperty.name}-${img.orderNumber}`
          };
        })
      }),
      ribbon: new Ribbon({
        items: availableProperty.propertyAmenityCodes.map((x: string) => {
          const codeMap = propertyMap.propertyAmenityMap.find(
            (am) => am.code === x
          );
          return new RibbonItem({
            title: codeMap?.name ?? x,
            text: codeMap?.description ?? x,
            code: codeMap?.code ?? x,
            content: null
          });
        })
      }),
      cancellationPolicies: availableProperty.cancellationPolicies?.map(
        (cp: ICancellationPolicy) => {
          return {
            propertyId: cp.propertyId,
            propertyName: cp.propertyName,
            description: cp.description,
            isDefault: cp.isDefault,
            name: cp.name,
            cancellationPolicyRules: cp.cancellationPolicyRules
          };
        }
      )
    });
  }
}
