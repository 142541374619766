import PriceExtraRequestDto from 'src/models/caren/extras/price-extra-request-dto';
import IExtraDetailedDto from '../../../models/caren/extras/extra-detailed-dto';
import Checkbox from '../elements/checkbox/checkbox';
import NumericInput from '../elements/numeric-input/numeric-input';

interface ICarExtrasOption {
  isCheckbox: boolean;
  details: IExtraDetailedDto;
  input: NumericInput | Checkbox;
}

interface ICarExtras {
  isLoading?: boolean;
  list?: ICarExtrasOption[];
  selectedList?: PriceExtraRequestDto[];
  numberOfDays?: number;
}

export default class CarExtras implements ICarExtras {
  isLoading: boolean;
  list: ICarExtrasOption[];
  selectedList: PriceExtraRequestDto[];
  numberOfDays: number;

  constructor(data?: ICarExtras) {
    this.isLoading = data?.isLoading ?? false;
    this.list = data?.list ?? [];
    this.selectedList = data?.selectedList ?? [];
    this.numberOfDays = data?.numberOfDays ?? 0;
  }

  /** Sorts the list where inputs that are checkboxes are placed first */
  get sortedList(): ICarExtrasOption[] {
    return this.list.sort((extra) => (extra.isCheckbox ? -1 : 1));
  }

  /** Multiplies the price of the selected extra to the quantity */
  get totalPrice(): number {
    return this.selectedObjects().reduce((prev, curr) => {
      const selectedExra = this.selectedList.find(
        (item) => item.id === curr.details.id
      );
      if (!selectedExra) return prev;
      const subTotal = curr.details.price * selectedExra?.quantity;
      const subTotalForDays = subTotal * this.numberOfDays;

      /** If extra is priced per day, it is multipled to number of days. */
      const selectedSubTotal = curr.details.pricePerDay
        ? subTotalForDays
        : subTotal;

      return prev + selectedSubTotal;
    }, 0);
  }

  selectedObjects(): ICarExtrasOption[] {
    const listOfIds = this.selectedList.map((item) => item.id);
    return this.list.filter((insurance) =>
      listOfIds.includes(Number(insurance.details.id))
    );
  }

  selectExtra(selectedExtra: PriceExtraRequestDto) {
    const selectedExtraIdx = this.selectedList.findIndex(
      (extra) => selectedExtra.id === extra.id
    );
    /** If not existing yet, add to list */
    if (selectedExtraIdx === -1) {
      return this.selectedList.push(selectedExtra);
    }
    /** Remove from list if quantity is zero */
    if (selectedExtra.quantity === 0) {
      return this.selectedList.splice(selectedExtraIdx, 1);
    }
    /** Replace existing object if id exists. */
    return this.selectedList.splice(selectedExtraIdx, 1, selectedExtra);
  }
}
