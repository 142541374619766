import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-664be4fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-start" }
const _hoisted_2 = {
  key: 0,
  id: "inputValidation",
  class: "g__text--label text-gray4 form-label"
}
const _hoisted_3 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_4 = ["autocomplete", "placeholder"]
const _hoisted_5 = {
  key: 1,
  class: "invalid-feedback"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.textInput.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, [
          (_ctx.textInput.required)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(_ctx.$translate(_ctx.textInput.label)), 1)
        ]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("input", {
      id: "inputValidation",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.textInput.value) = $event)),
      autocomplete: _ctx.textInput.withAutocomplete ? 'on' : 'off',
      type: "text",
      class: _normalizeClass(`form-control ${_ctx.textInput.isValid ? '' : 'is-invalid'}`),
      placeholder: _ctx.textInput.placeholder,
      onKeyup: _cache[1] || (_cache[1] = ($event: any) => (_ctx.textInput.formatInput())),
      onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.textInput.validate()))
    }, null, 42, _hoisted_4), [
      [_vModelText, _ctx.textInput.value]
    ]),
    (!_ctx.textInput.isValid)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.textInput.validationMessage), 1))
      : _createCommentVNode("", true)
  ]))
}