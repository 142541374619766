
import { defineComponent, reactive, ref } from 'vue';
import PropertyDetailsComponent from '../../standalone/property/details.vue';
import TravelDetails from '../travel-details/travel-details.vue';
import Property from '../../../models/components/block/property';
import IconWithText from '../../elements/icon-with-text/icon-with-text.vue';
import AppUtils from '../../../utils/app-utils';

export default defineComponent({
  name: 'PropertyCmp',
  components: {
    'property-details': PropertyDetailsComponent,
    'travel-details': TravelDetails,
    icon: IconWithText
  },
  props: {
    propertyModel: {
      type: Object as () => Property,
      required: true
    }
  },
  emits: [
    'search-clicked',
    'add-to-cart-clicked',
    'rooms-guests-changed',
    'to-checkout-clicked'
  ],
  setup(props) {
    return {
      property: reactive(props.propertyModel) as Property,
      themePrefix: ref(AppUtils.getFallbackDomain())
    };
  },
  methods: {
    onRoomGuestsChanged() {
      this.$emit('rooms-guests-changed');
    },
    searchClicked() {
      this.$emit('search-clicked');
    },
    addToCart(proceedToCheckout: boolean) {
      this.$emit('add-to-cart-clicked', proceedToCheckout);
    },
    onToCheckoutClicked() {
      this.$emit('to-checkout-clicked');
    }
  }
});
