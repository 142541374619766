export interface IIconWithText {
  code: string;
  title: string;
  inline?: boolean;
  regular?: boolean;
  content: string | null;
}

export default class IconWithText {
  inline = false;
  regular = true;
  code: string;
  title: string;
  content: string | null;
  icon: string;

  constructor(data: IIconWithText) {
    this.inline = data.inline ?? this.inline;
    this.regular = data.regular ?? this.regular;
    this.title = data.title;
    this.code = data.code;
    this.content = data.content;
    this.icon = this.setIconByField(data.code);
  }

  setIconByField(code: string) {
    let iconClass;
    const category = this.categorize(code);
    switch (category) {
      case 'c_PTN':
        iconClass = 'house';
        break;
      case 'c_LOC':
        iconClass = 'geo-alt';
        break;
      case 'Check-in & out':
        iconClass = 'clock';
        break;
      case 'Check-in':
        iconClass = 'clock';
        break;
      case 'Check-out':
        iconClass = 'clock';
        break;
      case 'Room type':
        iconClass = 'house';
        break;
      case 'SPA':
        iconClass = 'bricks';
        break;
      case 'AICO':
        iconClass = 'thermometer-snow';
        break;
      case 'RE':
        iconClass = 'cup';
        break;
      case 'KW':
        iconClass = 'cup';
        break;
      case 'BAR':
        iconClass = 'cup-straw';
        break;
      case 'MB':
        iconClass = 'cup-straw';
        break;
      case 'FD':
        iconClass = 'clock';
        break;
      case 'BC':
        iconClass = 'briefcase';
        break;
      case 'VM':
        iconClass = 'pc';
        break;
      case 'SA':
        iconClass = 'safe2';
        break;
      case 'WIFI':
        iconClass = 'wifi';
        break;
      case 'AC':
        iconClass = 'alarm';
        break;
      case 'RA':
        iconClass = 'boombox';
        break;
      case 'DVD':
        iconClass = 'pc-horizontal';
        break;
      case 'FAX':
        iconClass = 'pc-display-horizontal';
        break;
      case 'COM':
        iconClass = 'pc-display-horizontal';
        break;
      case 'APPLIANCES':
        iconClass = 'plug';
        break;
      case 'DISPLAYS':
        iconClass = 'display';
        break;
      case 'FURNITURES':
        iconClass = 'lamp';
        break;
      case 'VIEWS':
        iconClass = 'flower1';
        break;
      case 'WELLNESS':
        iconClass = 'bicycle';
        break;
      case 'HEATING':
        iconClass = 'thermometer-sun';
        break;
      case 'GAMES':
        iconClass = 'controller';
        break;
      case 'WATER':
        iconClass = 'water';
        break;
      case 'BATH':
        iconClass = 'droplet';
        break;
      case 'SAFETY':
        iconClass = 'shield-check';
        break;
      case 'OUTDOORS':
        iconClass = 'tree-fill';
        break;
      case 'GEARS':
        iconClass = 'gear-fill';
        break;
      case 'DASHBOARD':
        iconClass = 'speedometer';
        break;
      case 'EXTRAS':
        iconClass = 'tag';
        break;
      case 'SPECIALS':
        iconClass = 'stars';
        break;
      case 'WALLET':
        iconClass = 'wallet2';
        break;
      case 'HEADSET':
        iconClass = 'headset';
        break;
      case 'LOCK':
        iconClass = 'lock';
        break;
      case 'TELEPHONE':
        iconClass = 'telephone';
        break;
      default:
        iconClass = 'check2-circle';
    }
    return iconClass;
  }

  categorize(code: string): string {
    const appliances = [
      'DR',
      'DR',
      'CM',
      'MW',
      'EK',
      'DW',
      'TS',
      'ST',
      'OV',
      'AD',
      'SNTB',
      'IR',
      'WM',
      'TCM',
      'TEL',
      'HD',
      'RF'
    ];
    const furnitures = ['HC', 'SO', 'DRFC', 'CR', 'DE'];
    const displays = ['SS', 'CCH', 'SC', 'TV', 'FSTV'];
    const views = ['PPA', 'PA', 'PTER', 'QSV', 'GV', 'TER', 'BA'];
    const wellness = ['GYM', 'FC'];
    const heating = ['PHT', 'HT', 'HE', 'FP'];
    const games = ['BG', 'GC', 'IPAD'];
    const water = ['PV, RV', 'OV', 'LV'];
    const bath = ['FT', 'BR', 'BD', 'TP', 'LIN', 'TWL', 'PB', 'SH', 'BT'];
    const safety = ['BSG', 'FE', 'SA', 'PE'];
    const outdoors = ['OF', 'ODA', 'CV', 'MV', 'SV'];
    const extras = [
      'RS',
      'SSK',
      'ABE',
      'WUS',
      'BRB',
      'WG',
      'SL',
      'HP',
      'TP',
      'NFP',
      'PA',
      'EB',
      'WIC',
      'FUB',
      'SP',
      'SB',
      'CCC',
      'KCN'
    ];

    const gears = ['GR'];
    const dashboard = ['DB'];
    const specials = ['SPE'];

    if (appliances.includes(code)) return 'APPLIANCES';
    if (furnitures.includes(code)) return 'FURNITURES';
    if (displays.includes(code)) return 'DISPLAYS';
    if (views.includes(code)) return 'VIEWS';
    if (wellness.includes(code)) return 'WELLNESS';
    if (heating.includes(code)) return 'HEATING';
    if (games.includes(code)) return 'GAMES';
    if (water.includes(code)) return 'WATER';
    if (bath.includes(code)) return 'BATH';
    if (safety.includes(code)) return 'SAFETY';
    if (outdoors.includes(code)) return 'OUTDOORS';
    if (extras.includes(code)) return 'EXTRAS';
    if (gears.includes(code)) return 'GEARS';
    if (dashboard.includes(code)) return 'DASHBOARD';
    if (specials.includes(code)) return 'SPECIALS';

    return code;
  }
}
