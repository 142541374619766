
import { reactive, defineComponent } from 'vue';
import Dropdown from '../../../models/components/elements/dropdown/dropdown';

export default defineComponent({
  name: 'SearchDropdown',
  props: {
    dropdownModel: {
      type: Object as () => Dropdown,
      required: true
    }
  },
  emits: ['option-click'],
  setup(props) {
    props = reactive(props);
    return {
      dropdown: reactive(props.dropdownModel)
    };
  },
  computed: {
    styleObject() {
      return {
        labelStyle: this.dropdown.labelStyle
      };
    }
  },
  mounted() {
    document.addEventListener('mouseup', (e: MouseEvent) => {
      if (
        !e
          .composedPath()
          .some((x: any) => x.id === 'select' && x.id !== 'input-field')
      ) {
        this.dropdown.dropdownActive = false;
      }
    });
  },
  methods: {
    buttonValue(): string {
      let selected = this.dropdown.selectedName;
      return selected !== null ? selected : this.dropdown.placeholder;
    },
    clearSelection() {
      this.dropdown.clearSelection();
      this.dropdown.dropdownActive = false;
      this.dropdown.validate();
    },
    selectValue(value: any) {
      this.dropdown.value = '';
      this.dropdown.select(value);
      if (this.dropdown.validate()) {
        let selectedOption = this.dropdown.options.find(
          (option) => option.selected
        );
        this.$emit('option-click', selectedOption?.value);
      }
    }
  }
});
