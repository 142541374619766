import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-51fcb350"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-start" }
const _hoisted_2 = {
  key: 0,
  id: "inputValidation",
  class: "g__text--label text-gray4 form-label"
}
const _hoisted_3 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_4 = ["autocomplete", "placeholder"]
const _hoisted_5 = {
  id: "inputValidationFeedback",
  class: "invalid-feedback"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.digitInput.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, [
          (_ctx.digitInput.required)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(_ctx.$translate(_ctx.digitInput.label)), 1)
        ]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("input", {
      id: "inputValidation",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.digitInput.value) = $event)),
      autocomplete: _ctx.digitInput.withAutocomplete ? 'on' : 'off',
      type: "string",
      placeholder: _ctx.digitInput.placeholder,
      class: _normalizeClass(`form-control ${_ctx.digitInput.isValid ? '' : 'is-invalid'}`),
      onKeyup: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onKeyUp())),
      onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onBlur()))
    }, null, 42, _hoisted_4), [
      [_vModelText, _ctx.digitInput.value]
    ]),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.digitInput.validationMessage), 1)
  ]))
}