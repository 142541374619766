import Dropdown from '../elements/dropdown/dropdown';
import { SelectOption } from '../elements/options/select-option';
import SidebarCarFilters from './sidebar/sidebar-filters-car';
import { SortTypeEnum } from '../../enums/sort-type';
import CarSearchPanel from './car-search-panel';

export interface ISidebarCar {
  carSearchPanel?: CarSearchPanel;
  sidebarFilters?: SidebarCarFilters;
  loading?: boolean
}

export default class SidebarCar implements ISidebarCar {
  carSearchPanel: CarSearchPanel;
  sidebarFilters: SidebarCarFilters;
  sorting: Dropdown;

  loading = false;

  constructor(data?: ISidebarCar) {
    this.carSearchPanel = data?.carSearchPanel ?? new CarSearchPanel();
    this.sidebarFilters = data?.sidebarFilters ?? new SidebarCarFilters();
    this.sorting = new Dropdown({
      options: [
        new SelectOption({
          name: 'Lowest price',
          value: SortTypeEnum.PriceFromLowest,
          selected: true
        }),
        new SelectOption({
          name: 'Highest price',
          value: SortTypeEnum.PriceFromHighest
        })
      ],
      value: 'Lowest price',
      withValidation: false,
      withInput: false
    });
    this.loading = data?.loading ?? this.loading;
  }
}
