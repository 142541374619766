import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container my-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_successful_payment = _resolveComponent("successful-payment")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_successful_payment, {
      "payment-successful-model": _ctx.paymentSuccess,
      onContinueClick: _ctx.onContinueClick
    }, null, 8, ["payment-successful-model", "onContinueClick"])
  ]))
}