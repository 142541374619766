import moment from 'moment';
import Input, { IInputArgs } from '../../../internal/input/input';

export interface IDateInputArgs extends IInputArgs {
  isDateTime: boolean;
  time?: string;
  validationMessage?: string;
}

export default class DateInput extends Input {
  isDateTime = false;
  time?: string;
  validationMessage: string;

  constructor(data: IDateInputArgs) {
    super(data);
    this.isDateTime = data.isDateTime;
    this.validationMessage =
      data.validationMessage ?? 'This field is required.';
    this.time = data.time;
  }

  private DATE_FORMAT = 'YYYY/MM/DD';
  private TIME_FORMAT = 'HH:mm';

  private get isTimeOptionalAndEmpty() {
    return (
      !this.required && (typeof this.time !== 'string' || this.time === '')
    );
  }

  private get isDateOptionalAndEmpty() {
    return !this.required && this.value === '';
  }

  private get isDateTimeOptionalAndEmpty() {
    return this.isDateOptionalAndEmpty && this.isTimeOptionalAndEmpty;
  }

  private get isTimeFormatValid() {
    // time is valid if it's required and it's empty
    // time is valid if format is correct
    return (
      moment(this.time, this.TIME_FORMAT).format(this.TIME_FORMAT) ===
        this.time && moment(this.time, this.TIME_FORMAT).isValid()
    );
  }

  private get isDateFormatValid() {
    // time is valid if it's required and it's empty
    // time is valid if format is correct
    return (
      moment(this.value, this.DATE_FORMAT).format(this.DATE_FORMAT) ===
        this.value && moment(this.value, this.DATE_FORMAT).isValid()
    );
  }

  private get isDateTimeValid() {
    return this.isDateFormatValid && this.isTimeFormatValid;
  }

  private validateTime() {
    // if there's a value, validate format
    // else if it's empty, check if it's required
    if (!this.isDateTime) return;

    if (typeof this.time === 'string' && !!this.time) {
      this.isValid = this.isDateTime
        ? this.isDateTimeValid
        : this.isTimeFormatValid;
    } else {
      this.isValid = this.isDateTime
        ? this.isDateTimeOptionalAndEmpty
        : this.isDateTimeOptionalAndEmpty;
    }
  }

  private validateDate() {
    // if there's a value, validate format
    // else if it's empty, check if it's required
    if (typeof this.value === 'string' && !!this.value) {
      this.isValid = this.isDateTime
        ? this.isDateTimeValid
        : this.isDateFormatValid;
    } else {
      this.isValid = this.isDateTime
        ? this.isDateTimeOptionalAndEmpty
        : this.isDateOptionalAndEmpty;
    }
  }

  get dateValue() {
    if (!this.isDateFormatValid) return null;
    return moment(this.value).format('YYYY-MM-DD');
  }

  get dateTimeValue() {
    if (this.isDateTime && !this.isDateTimeValid) return null;
    if (!this.isDateTime && !this.isDateFormatValid) return null;
    return moment(`${this.value} ${this.time}`).format('YYYY-MM-DD HH:mm');
  }

  validate() {
    this.validateDate();
    this.validateTime();

    if (!this.isValid) {
      this.validationMessage = this.isDateTime
        ? 'Please enter a valid date and time'
        : 'Please enter a valid date.';
    }

    return this.isValid;
  }

  formatTimeInput() {
    this.time = String(this.time)
      .replace(/[^0-9:]/g, '') // only numbers and colon (:)
      .replace(/([0-9]{2})([0-9]{1})/g, '$1:$2') // 123 -> 12:3
      .substring(0, 5);
  }

  formatDateInput() {
    this.value = String(this.value)
      .replace(/[^0-9/]/g, '') // only numeric characters
      .replace(/([0-9]{4})([0-9]{1})/g, '$1/$2') // 19824 -> 1982/4
      .replace(/\/([0-9]{2})([0-9]{1})/g, '/$1/$2') // 1982/052 -> 1982/05/2
      .substring(0, 10);
  }
}
