import BaseHttpClientService from '../base-http-client-service';
import Countries from '../../models/travia/countries';
import Languages from '../../models/travia/languages';

interface ICountriesControllerService {
  getCountriesLanguagesAsync(): Promise<{
    countries: Countries;
    languages: Languages;
  }>;
}

class CountriesControllerService
  extends BaseHttpClientService
  implements ICountriesControllerService
{
  getCountriesLanguagesAsync() {
    return this.httpClient
      .get('api/booking/v1/get-countries-languages')
      .then((res) => {
        const response = this.prepareResponse(res);
        if (response.statusCode !== 200 || !response.content) throw Error();
        const { countries, languages } = response.content;
        return {
          countries: new Countries({
            countryList: countries.length > 0 ? countries : []
          }),
          languages: new Languages({
            languages: languages.length > 0 ? languages : []
          })
        };
      })
      .catch((err) => {
        console.error(err);
        return {
          countries: new Countries({
            countryList: []
          }),
          languages: new Languages({ languages: [] })
        };
      });
  }
}

export default new CountriesControllerService() as ICountriesControllerService;
