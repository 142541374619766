import { Nullable } from 'src/models/generics/generics';

export enum SelectionType {
  PRESELECTED = 'PRESELECTED',
  OPTIONAL = 'OPTIONAL',
  UNAVAILABLE = 'UNAVAILABLE'
}

export enum PricingType {
  INCLUDED_IN_PRICE = 'INCLUDED_IN_PRICE',
  PRICED_SEPARATELY = 'PRICED_SEPARATELY'
}

export interface IRateConfig {
  id: number;
  activityExtraId: number;
  selectionType: SelectionType;
  pricingType: PricingType;
  pricedPerPerson: boolean;
  bundleItemDayNo: Nullable<number>;
}

export interface ILocationConfig {
  id: Nullable<number>;
  activityPickupId: Nullable<number>;
  activityDropoffId: Nullable<number>;
  activityPlaceGroupId: Nullable<number>;
  selectionType: SelectionType;
  pricingType: PricingType;
  pricedPerPerson: boolean;
  customAllowed: boolean;
  bundleItemId: Nullable<number>;
  bundleItemTitle: Nullable<string>;
  bundleItemDayNo: Nullable<number>;
}

export interface IActivityRate {
  id: number;
  title: string;
  pricingType: string;
  minPerBooking: Nullable<number>;
  maxPerBooking: Nullable<number>;
  pricingCategoryIds: number[];
  extraConfigs: IRateConfig[];
  pickupConfigs: ILocationConfig[];
  dropoffConfigs: ILocationConfig[];
}
