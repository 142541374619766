import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_car = _resolveComponent("car")!

  return (_openBlock(), _createBlock(_component_car, {
    travelDetailsModel: _ctx.carTravelDetails,
    searchPanelInputs: _ctx.carSearchPanelInputs,
    onAddToCartClicked: _ctx.addToCart
  }, null, 8, ["travelDetailsModel", "searchPanelInputs", "onAddToCartClicked"]))
}