import IconWithText, {
  IIconWithText
} from '../../elements/icon-with-text/icon-with-text';

export interface IRibbonItem extends IIconWithText {
  icon?: string;
  title: string;
  text: string;
}

export default class RibbonItem extends IconWithText implements IRibbonItem {
  icon: string;
  title: string;
  text: string;

  constructor(data: IRibbonItem) {
    super(data);
    this.title = data.title;
    this.text = data.text;
    this.icon = data.icon ?? this.setIconByField(data.code);
  }
}
