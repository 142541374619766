import Calendar from '../../calendar/calendar';

interface ICalendarModal {
  calendar?: Calendar;
  justify?: string;
  btnColorType?: string;
  buttonName?: string;
  includeLabels?: boolean;
  isHorizontal?: boolean;
}

export default class CalendarModal implements ICalendarModal {
  calendar: Calendar;
  buttonName = 'Button';
  includeLabels = false;
  isHorizontal = true;

  constructor(data?: ICalendarModal) {
    this.calendar = data?.calendar ?? new Calendar({ isRange: true });
    this.buttonName = data?.buttonName ?? this.buttonName;
    this.includeLabels = data?.includeLabels ?? this.includeLabels;
    this.isHorizontal = data?.isHorizontal ?? this.isHorizontal;
  }
}
