import TransmissionDto from './transmission-dto';

export interface ITransmissionsArgs {
  transmissionsList: TransmissionDto[];
}

export default class Transmissions {
  transmissionsList: TransmissionDto[];

  constructor(data: TransmissionDto[]) {
    this.transmissionsList = data;
  }
}
