import Country from './country';

export interface ICountriesArgs {
  countryList: Country[];
}

export default class Countries {
  countryList: Country[];

  constructor(data: ICountriesArgs) {
    this.countryList = data.countryList;
  }
}
