import AppConstants from '../constants/app-constants';

export default class NumberUtils {
  /**
   * Properly rounds total to two decimal places
   * @param input
   * @returns rounded-off number in two decimal places
   */
  public static round = (input: number) => {
    return Math.round(input * 100 + Number.EPSILON) / 100;
  };

  public static convertCurrencyToISK = ({ from, amount }): number => {
    const localRates = localStorage.getItem(AppConstants.APP_CURRENCY_RATES);
    if (!localRates || !('rates' in JSON.parse(localRates))) {
      console.warn('Rates have not been set locally. Please refresh the page.');
      return 0;
    }

    const { rates } = JSON.parse(localRates);
    const codes = rates.map(({ code }) => code);
    if (!codes.includes(from)) return 0;

    const inputCurrency = rates.find(({ code }) => code === from);
    if (!inputCurrency) return 0;

    return NumberUtils.round(amount / inputCurrency.rate);
  };

  public static convertCurrency = ({ from, to, amount }): number => {
    if (from === to) return amount;

    const localRates = localStorage.getItem(AppConstants.APP_CURRENCY_RATES);
    if (!localRates || !('rates' in JSON.parse(localRates))) {
      console.warn('Rates have not been set locally. Please refresh the page.');
      return amount;
    }

    const { rates } = JSON.parse(localRates);
    const codes = rates.map(({ code }) => code);

    if (!codes.includes(from) && !codes.includes(to)) return 0;
    if (from === to) return NumberUtils.round(amount);

    const amountInISK =
      from === 'ISK'
        ? amount
        : NumberUtils.convertCurrencyToISK({ from, amount });

    if (to === 'ISK') return amountInISK;

    const outputRate = rates.find(({ code }) => code === to);
    if (!outputRate) return 0;

    return NumberUtils.round(amountInISK * outputRate.rate);
  };
}
