import AccommodationProductItem from '../../models/components/standalone/detailed-product-item/accommodation-product-item';
import TourProductItem from '../../models/components/standalone/detailed-product-item/tour-product-item';

export const extractRemoveBookingData = (
  product: TourProductItem | AccommodationProductItem,
  totalPrice: number,
  currency: string
) => {
  return {
    currency: currency,
    revenue_value: totalPrice,
    event_value: TourProductItem.isTourProduct(product)
      ? product.activityId
      : product.propertyId
  };
};

export const extractInitiateCheckoutData = (
  cartId: number,
  bookings: (TourProductItem | AccommodationProductItem)[],
  totalPrice: number,
  currency: string
) => {
  const bookingIds = bookings.map((product) => product.bookingId.toString());
  return {
    event_value: cartId.toString(),
    revenue_value: totalPrice,
    currency: currency,
    items: bookingIds
  };
};

export const extractPaymentData = (
  cartId: number,
  bookings: (TourProductItem | AccommodationProductItem)[],
  totalPrice: number,
  currency: string
) => {
  const bookingIds = bookings.map((product) => product.bookingId.toString());

  return {
    event_value: cartId.toString(),
    revenue_value: totalPrice,
    currency: currency,
    items: bookingIds,
    transaction_id: cartId.toString()
  };
};
