import { CurrencyType } from '../../../internal/currency/currency';
import IconWithText from '../../elements/icon-with-text/icon-with-text';
import Carousel, { CarouselSizes } from '../carousel/carousel';
import ILogo from '../../../travia/logo';

export type productItemTypes =
  | 'BASE'
  | 'PROPERTY'
  | 'ROOM'
  | 'CAR'
  | 'ACCOMMODATION_PRODUCT_ITEM'
  | 'TOUR_PRODUCT_ITEM';

export interface IProductItem {
  id: number;
  productImages?: ILogo[];
  productName: string;
  productTypeName: string;
  productPrice: number | null;
  productDiscountedPrice?: number;
  priceStable?: boolean;
  currencyType: CurrencyType;
  iconsWithText: IconWithText[];
  pricePerUnit?: boolean;
  unitOfMeasure?: string;
  durationUnit?: string;
  available: boolean;
  isHorizontal?: boolean;
}

export default class ProductItem {
  productItemType: productItemTypes;

  id = 0;
  productImages: ILogo[] = [];
  productName = '';
  productTypeName = '';
  productPrice: number | null = null;
  productDiscountedPrice = 0;
  pricePerUnit = false;
  durationUnit = '';
  unitOfMeasure = '';

  available = false;
  isHorizontal = false;

  priceStable = false;
  currencyType: CurrencyType = CurrencyType.ISK;
  iconsWithText: IconWithText[] = [];

  constructor(data?: IProductItem) {
    this.productItemType = 'BASE';

    this.pricePerUnit = data?.pricePerUnit ?? this.pricePerUnit;
    this.unitOfMeasure = data?.unitOfMeasure ?? this.unitOfMeasure;
    this.productImages = data?.productImages ?? this.productImages;
    this.productName = data?.productName ?? this.productName;
    this.productPrice = data?.productPrice ?? this.productPrice;
    this.productDiscountedPrice =
      data?.productDiscountedPrice ?? this.productDiscountedPrice;
    this.currencyType = data?.currencyType ?? this.currencyType;
    this.iconsWithText = data?.iconsWithText ?? this.iconsWithText;
    this.priceStable = data?.priceStable ?? this.priceStable;
    this.productTypeName = data?.productTypeName ?? this.productTypeName;
    this.durationUnit = data?.durationUnit ?? this.durationUnit;

    this.available = data?.available ?? this.available;
    this.isHorizontal = data?.isHorizontal ?? this.isHorizontal;
    this.id = data?.id ?? this.id;
  }

  get carousel(): Carousel {
    return new Carousel({
      rounded: false,
      carouselSize: CarouselSizes.xxs,
      activeIndex: 0,
      maxItemsForIndicators: 4,
      carouselItems: this.productImages.map((image: ILogo) => {
        return {
          withInterval: false,
          imageSrc: image?.filePath ?? image?.thumbnailPath ?? '',
          imageAlt: this.productName
        };
      })
    });
  }
}
