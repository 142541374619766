
import { reactive, defineComponent } from 'vue';
import CardNumberInput from '../../../models/components/elements/card-number-input/card-number-input';

export default defineComponent({
  name: 'CardNumberInput',
  props: {
    cardNumberInputModel: {
      type: Object as () => CardNumberInput,
      required: true
    }
  },
  emits: ['key-up', 'on-blur'],
  setup(props) {
    return {
      cardNumberInput: reactive(props.cardNumberInputModel)
    };
  },
  methods: {
    onKeyUp() {
      this.$emit('key-up');
    },
    onBlur() {
      this.cardNumberInput.validate();
      this.$emit('on-blur');
    }
  }
});
