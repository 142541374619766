import { notify } from '@kyvg/vue3-notification';
import {
  Stripe,
  PaymentRequest,
  loadStripe,
  StripePaymentRequestButtonElement,
  StripeCardElement
} from '@stripe/stripe-js';

import { Nullable } from '../../generics/generics';
import Notification from '../notification/notification';

export interface IStripeCheckout {
  stripeInstance: Nullable<Stripe>;
  cardInstance: Nullable<StripeCardElement>;
  cardRef: Nullable<HTMLElement>;
  paymentRequest: Nullable<PaymentRequest>;
  paymentButton: Nullable<StripePaymentRequestButtonElement>;
  isCardValid: boolean;
  isGoogleApplePayAvailable: boolean;
  loading: boolean;
  clientSecret: Nullable<string>;
  paymentQR: Nullable<string>;
}

export default class StripeCheckout implements IStripeCheckout {
  stripeInstance: Nullable<Stripe> = null;
  cardInstance: Nullable<StripeCardElement> = null;
  cardRef: Nullable<HTMLElement> = null;
  paymentRequest: Nullable<PaymentRequest> = null;
  paymentButton: Nullable<StripePaymentRequestButtonElement> = null;
  isCardValid = true;
  isGoogleApplePayAvailable = false;
  loading = false;
  clientSecret: Nullable<string> = '';
  paymentQR: Nullable<string> = '';

  get isCardEmpty() {
    if (!this.cardRef) return true;

    const isEmpty = this.cardRef.classList.contains('StripeElement--empty');
    return isEmpty;
  }

  async initializeStripe(apiKey: string) {
    try {
      this.stripeInstance = await loadStripe(apiKey);

      if (!this.stripeInstance) return;
      this.cardInstance = this.stripeInstance.elements().create('card', {
        hidePostalCode: true
      });
    } catch (error) {
      notify(
        Notification.error('Error - Stripe: Failed to create stripe instance.')
      );
      console.error(error);
    }
  }

  async initializePaymentRequest(
    amount: number,
    currency: string,
    paymentRequestCallback: (ev) => void,
    paymentButtonCallback: (ev) => void
  ) {
    if (!this.stripeInstance) return;
    this.paymentRequest = this.stripeInstance.paymentRequest({
      country: 'US',
      currency: currency.toLowerCase(),
      total: {
        label: 'Total',
        amount: Math.round(amount * 100)
      },
      requestPayerName: true,
      requestPayerEmail: true
    });

    this.paymentButton = this.stripeInstance
      .elements()
      .create('paymentRequestButton', {
        paymentRequest: this.paymentRequest
      });

    this.paymentButton.on('click', paymentButtonCallback);

    const canMakePayment = await this.paymentRequest.canMakePayment();
    this.isGoogleApplePayAvailable = canMakePayment !== null;

    if (canMakePayment) {
      // This function or event listener
      // is triggered when an apple or google pay payment is made.
      this.paymentRequest.on('paymentmethod', (ev) => {
        // verbose return call to properly see that event listener
        // returns `PaymentRequestMethodEvent`
        paymentRequestCallback(ev);
      });
    }
  }

  listenAndMountCard(htmlElem: HTMLElement) {
    if (!this.cardInstance) return;

    this.cardRef = htmlElem;
    this.cardInstance.mount(htmlElem);
    this.cardInstance.on('change', (event) => {
      this.isCardValid = event.complete;
      if (event.complete) {
        this.setCardError('');
      }

      if (!event.error) return;
      this.setCardError(event.error.message);
    });
  }

  validateCard() {
    if (this.isCardValid && !this.isCardEmpty) return;

    this.isCardValid = false;
    this.setCardError('Please enter valid card information');
  }

  setCardError(message: string) {
    if (!this.cardRef?.nextElementSibling) return;
    this.cardRef.nextElementSibling.textContent = message;
  }
}
