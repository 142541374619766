import Input, { IInputArgs } from '../../../internal/input/input';

export interface IDigitInputArgs extends IInputArgs {
  maxLength?: number;
  minLength?: number;
  maxValue?: number;
  minValue?: number;
}

export default class DigitInput extends Input {
  maxLength?: number;
  maxValue?: number;
  minValue?: number;
  minLength?: number;

  constructor(data?: IDigitInputArgs) {
    super(data);
    this.maxLength = data?.maxLength;
    this.minLength = data?.minLength;
    this.minValue = data?.minValue;
    this.maxValue = data?.maxValue;
    this.value = '';
  }

  formatInput(){
    this.value = String(this.value).replace(/[^0-9]+/g, '');
    if (this.maxLength) this.value = this.value.slice(0, this.maxLength);
  }

  validate(): boolean {
    if (!this.withValidation) return (this.isValid = true);

    if (this.value) this.value = Number(this.value);

    if (
      this.maxValue &&
      this.minValue &&
      this.value.toString().length >
        (this.minLength || this.minLength === 0 ? this.minLength - 1 : 0)
    )
      return (this.isValid =
        this.value <= this.maxValue && this.value >= this.minValue);
    else if (
      this.minValue &&
      this.value.toString().length >
        (this.minLength || this.minLength === 0 ? this.minLength - 1 : 0)
    )
      return (this.isValid = this.value >= this.minValue);
    else if (
      this.maxValue &&
      this.value.toString().length >
        (this.minLength || this.minLength === 0 ? this.minLength - 1 : 0)
    )
      return (this.isValid = this.value <= this.maxValue);

    return (this.isValid =
      this.value.toString().length >
      (this.minLength || this.minLength === 0 ? this.minLength - 1 : 0));
  }
}
