import CardNumberInput from '../elements/card-number-input/card-number-input';
import CardExpiryInput from '../elements/card-expiry-input/card-expiry-input';
import DigitInput from '../elements/digit-input/digit-input';
import IInputValidation from '../../validation/input-validation';
import IPaymentDetails from '../../internal/payment/payment-details';

import validator from 'card-validator';
import TextInput from '../elements/text-input/text-input';
import AppConstants from '../../../constants/app-constants';
import { StripePaymentMethod } from '../../../models/enums/payment-methods';
import { PaymentProvider } from '../../../models/enums/payment-provider';

export interface ICreditCardInputsArgs {
  cardholderNameInput?: TextInput;
  cardNumber?: CardNumberInput;
  cardExpiry?: CardExpiryInput;
  cardVerification?: DigitInput;
  selectedPaymentOption?: string;
  isValid?: boolean;
  withValidation?: boolean;
  validationMessage?: string;
  paymentProvider?: PaymentProvider;
}

export default class CreditCardInputs implements IInputValidation {
  paymentProvider: PaymentProvider = PaymentProvider.STRIPE;
  cardholderNameInput: TextInput = new TextInput({
    withValidation: true,
    defaultMessage: 'Enter cardholder name',
    label: 'Cardholder name',
    placeholder: 'Enter cardholder name',
    maxCharacters: 255,
    required: true,
    pattern: /([A-zÀ-ÿ'.,-])/g
  });
  cardNumber: CardNumberInput;
  cardExpiry: CardExpiryInput;
  cardVerification: DigitInput;
  isValid: boolean;
  withValidation: boolean;
  validationMessage: string;

  defaultPaymentOption = StripePaymentMethod.CARD;

  paymentOptions = AppConstants.PAYMENT_OPTIONS.map((option) => ({
    ...option,
    isSelected: false || option.value === this.defaultPaymentOption
  }));

  private previousVerificationCode = 'CVC/CVV';
  private lastCreditNumber = '';

  constructor(data?: ICreditCardInputsArgs) {
    this.paymentProvider = data?.paymentProvider ?? this.paymentProvider;
    this.cardNumber =
      data?.cardNumber ?? new CardNumberInput({ required: true });
    this.cardExpiry =
      data?.cardExpiry ?? new CardExpiryInput({ required: true });
    this.cardVerification =
      data?.cardVerification ??
      new DigitInput({
        label: `CVC`,
        placeholder: `3 digits`,
        withValidation: true,
        defaultMessage: `Please enter a valid CVC/CVV`,
        maxLength: 3,
        minLength: 3,
        required: true
      });
    this.isValid = data?.isValid ?? false;
    this.withValidation = data?.withValidation ?? true;
    this.validationMessage = data?.validationMessage ?? ``;
    this.cardholderNameInput =
      data?.cardholderNameInput ?? this.cardholderNameInput;
  }

  get isCardPayment(): boolean {
    return this.selectedPaymentOption.value === StripePaymentMethod.CARD;
  }

  get isAlipayPayment(): boolean {
    return this.selectedPaymentOption.value === StripePaymentMethod.ALIPAY;
  }

  get isWeChatPayment(): boolean {
    return this.selectedPaymentOption.value === StripePaymentMethod.WECHAT_PAY;
  }

  get isChineseYuanPayment(): boolean {
    return this.isAlipayPayment || this.isWeChatPayment;
  }

  get isGoogleApplePayment(): boolean {
    return (
      this.selectedPaymentOption.value === StripePaymentMethod.GOOGLE_PAY ||
      this.selectedPaymentOption.value === StripePaymentMethod.APPLE_PAY
    );
  }

  get selectedPaymentOption() {
    return this.paymentOptions.filter(({ isSelected }) => !!isSelected)[0];
  }

  emitInputValues(): IPaymentDetails {
    const expiration = this.cardExpiry.expiration;
    return {
      cardNumber: this.cardNumber.value.toString().replace(/ /g, ''),
      cardCvc: this.cardVerification.value.toString(),
      expirationYear: expiration.year,
      expirationMonth: expiration.month
    };
  }

  isPaymentOptionHidden(option: StripePaymentMethod): boolean {
    const isOptionApplePayInAppleEnv =
      option === StripePaymentMethod.APPLE_PAY && !window.ApplePaySession;
    const isOptionGooglePayInAppleEnv =
      option === StripePaymentMethod.GOOGLE_PAY && !!window.ApplePaySession;

    // Hide Apple Pay option even if it is unavailable.
    // Hide Google Pay option if Apple Pay should be used.
    return isOptionApplePayInAppleEnv || isOptionGooglePayInAppleEnv;
  }

  isPaymentOptionDisabled(
    isStripeReady: boolean,
    option: StripePaymentMethod
  ): boolean {
    const isAppleOrGooglePay =
      option === StripePaymentMethod.APPLE_PAY ||
      option === StripePaymentMethod.GOOGLE_PAY;
    return !isStripeReady && isAppleOrGooglePay;
  }

  selectPaymentOption(selectedOption: StripePaymentMethod) {
    this.paymentOptions = this.paymentOptions.map((option) => ({
      ...option,
      isSelected: selectedOption === option.value
    }));
  }

  addGaps() {
    const cardNumber = this.cardNumber.value.toString().replace(/ /g, '');
    const numValidation = validator.number(cardNumber);

    if (
      numValidation.card !== null &&
      cardNumber.length > this.lastCreditNumber.length
    ) {
      this.cardNumber.value = cardNumber;
      numValidation.card.gaps.forEach((gap: number, index: number) => {
        if (this.cardNumber.value.toString().length >= gap + index) {
          this.cardNumber.value = this.cardNumber.value
            .toString()
            .splice(gap + index, 0, ' ');
        }
      });
    }
  }

  validate(): boolean {
    if (!this.withValidation) return (this.isValid = true);

    this.cardholderNameInput.validate();
    this.cardNumber.validate();
    this.cardExpiry.validate();
    this.cardVerification.validate();

    const cardNumber = this.cardNumber.value.toString();
    const numValidation = validator.number(cardNumber);

    this.lastCreditNumber = cardNumber;

    if (!numValidation.isPotentiallyValid)
      return this.cardNumber.setInvalid(`Sorry, card unrecognized.`);
    else this.cardNumber.validationMessage = this.cardNumber.defaultMessage;

    this.cardNumber.isValid = numValidation.isValid;
    this.cardVerification.maxLength =
      numValidation?.card?.code?.size ?? this.cardVerification.maxLength;
    this.cardVerification.minLength =
      numValidation?.card?.code?.size ?? this.cardVerification.minLength;
    this.cardVerification.placeholder =
      `${this.cardVerification.maxLength} digits` ??
      this.cardVerification.placeholder;
    this.cardVerification.label = numValidation?.card?.code?.name ?? 'CVC/CVV';
    this.cardVerification.value =
      this.cardVerification.label != this.previousVerificationCode
        ? ''
        : this.cardVerification.value;
    this.previousVerificationCode = this.cardVerification.label;

    if (!this.cardVerification.isValid)
      this.cardVerification.setInvalid(
        `Please enter a valid ${numValidation?.card?.code?.name ?? 'CVC/CVV'}`
      );
    else
      this.cardVerification.validationMessage =
        this.cardVerification.defaultMessage;

    this.isValid =
      this.cardholderNameInput.isValid &&
      this.cardNumber.isValid &&
      this.cardExpiry.isValid &&
      this.cardVerification.isValid;

    return this.isValid;
  }
}
