
import { defineComponent, reactive, ref } from "@vue/runtime-core";

import HeaderCmp from "./components/standalone/header/header.vue";
import FooterCmp from "./components/standalone/footer/footer.vue";
import Header from "./models/components/standalone/header/header";
import Footer from "./models/components/standalone/footer/footer";
import AppConstants from "./constants/app-constants";
import AppUtils from "./utils/app-utils";

  export default defineComponent({
    name: 'App',
    components: {
      'header-cmp': HeaderCmp,
      'footer-cmp': FooterCmp
    },
    setup() {
      return {
        header: reactive(new Header()),
        footer: reactive(new Footer()),
        isDomainChecked: ref(false),
      }
    },
    head: {
      link() {
        const folderPath = AppUtils.checkDomain() ? 'adv-is-img' : 'adv-com-img';
        return [
          { rel: 'icon', href: require(`../public/${folderPath}/favicon-16x16.png`), sizes: '16x16', type: 'image/png' }, 
          { rel: 'icon', href: require(`../public/${folderPath}/favicon-32x32.png`), sizes: '32x32', type: 'image/png' }, 
          { rel: 'apple-touch-icon', href: require(`../public/${folderPath}/icons/apple-touch-icon.png`), sizes: '180x180', type: 'image/png' }
        ]
      }
    },
    mounted() {
      AppUtils.initThemeSwitch();
      this.updateHeaderFooter();
    },
    methods: {
      updateHeaderFooter() {
        const isAdventuresIs = AppUtils.isAdventuresIS();
        const colorMap = isAdventuresIs ? AppConstants.ADVENTURES_IS_COLOR_MAP : undefined;
        this.isDomainChecked = true;
        
        AppUtils.changeTheme(colorMap);
        this.header.updateInstance(isAdventuresIs);
        this.footer.updateInstance(isAdventuresIs);
      },
    }
  })
