import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "vh-100"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_property = _resolveComponent("property")!
  const _component_loader = _resolveComponent("loader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.property.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.property.propertyFound)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_openBlock(), _createBlock(_component_property, {
                  key: _ctx.property.propertyDetails,
                  "property-model": _ctx.property,
                  onSearchClicked: _ctx.onSearchClicked,
                  onRoomsGuestsChanged: _ctx.onRoomsGuestsChanged,
                  onAddToCartClicked: _cache[0] || (_cache[0] = 
            (proceedToCheckout) => _ctx.addToCart(proceedToCheckout)
          ),
                  onToCheckoutClicked: _ctx.redirectToCheckout
                }, null, 8, ["property-model", "onSearchClicked", "onRoomsGuestsChanged", "onToCheckoutClicked"]))
              ]))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_loader)
        ]))
  ]))
}