import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2553c799"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex align-items-center" }
const _hoisted_2 = { class: "position-relative" }
const _hoisted_3 = { class: "mb-0 me-1 input-label" }
const _hoisted_4 = { class: "g__text--helper text-gray2" }
const _hoisted_5 = {
  key: 0,
  class: "validation-message g__text--helper"
}
const _hoisted_6 = { class: "d-flex align-items-center" }
const _hoisted_7 = { class: "icon-wrapper" }
const _hoisted_8 = { class: "input-wrapper mx-2" }
const _hoisted_9 = ["id", "readOnly", "disabled"]
const _hoisted_10 = ["id"]
const _hoisted_11 = { class: "icon-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      'd-flex align-items-center': true,
      'justify-content-between': _ctx.input.isHorizontal,
      'flex-column gap-3': !_ctx.input.isHorizontal
    })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", {
          class: _normalizeClass({ 'hstack gap-1 mb-0': true, 'pb-3': !_ctx.input.isValid })
        }, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.input?.label), 1),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.input?.helperText), 1)
        ], 2),
        (!_ctx.input.isValid)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.input?.validationMessage), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("i", {
          class: _normalizeClass({
            'bi bi-dash-circle': true,
            'icon-disabled': _ctx.input.minValue === _ctx.input.value || _ctx.input.isDisabled
          }),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (
            _ctx.input.minValue !== _ctx.input.value && !_ctx.input.isDisabled && _ctx.decrement()
          ))
        }, null, 2)
      ]),
      _createElementVNode("div", _hoisted_8, [
        (_ctx.input.withInput)
          ? _withDirectives((_openBlock(), _createElementBlock("input", {
              key: 0,
              id: _ctx.input.isValid ? '' : 'not-valid',
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.input.value) = $event)),
              readOnly: !_ctx.input.withInput,
              type: "number",
              class: "input-wrapper-input",
              disabled: _ctx.input.isDisabled,
              onKeyup: _cache[2] || (_cache[2] = ($event: any) => (_ctx.emitInput()))
            }, null, 40, _hoisted_9)), [
              [_vModelText, _ctx.input.value]
            ])
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              id: _ctx.input.isValid ? '' : 'not-valid'
            }, _toDisplayString(_ctx.input.value), 9, _hoisted_10))
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("i", {
          class: _normalizeClass({
            'bi bi-plus-circle': true,
            'icon-disabled': _ctx.input.maxValue === _ctx.input.value || _ctx.input.isDisabled
          }),
          onClick: _cache[3] || (_cache[3] = ($event: any) => (
            _ctx.input.maxValue !== _ctx.input.value && !_ctx.input.isDisabled && _ctx.increment()
          ))
        }, null, 2)
      ])
    ])
  ], 2))
}