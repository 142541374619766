import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "g__bordered g__shadow-strong text-start pt-3 pb-" }
const _hoisted_2 = { class: "text-black fw-bold ps-3 pb-2 mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.iconsWithTextCard.title), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.iconsWithTextCard.iconsWithText, (icon, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "pb-3 ps-2"
        }, [
          _createVNode(_component_icon, { iconWithTextModel: icon }, null, 8, ["iconWithTextModel"])
        ]))
      }), 128))
    ])
  ]))
}