
import { reactive } from '@vue/reactivity';
import { defineComponent, ref } from 'vue';
import ModalCmp from '../../standalone/modal/modal.vue';

export default defineComponent({
  name: 'TermsAndConditionsModal',
  components: {
    modal: ModalCmp
  },
  props: {
    showModalInput: {
      type: Boolean,
      required: false,
      default: false
    },
    termsTextInput: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: [],
  setup(props: any) {
    props = reactive(props);
    return {
      shouldShowModal: ref(props.showModalInput),
      termsText: ref(props.termsTextInput),
    };
  },
  watch: {
    '$props.showModalInput': {
      handler() {
        this.toggleModal();
      }
    }
  },
  methods: {
    toggleModal() {
      this.shouldShowModal = !this.shouldShowModal;
    }
  }
});
