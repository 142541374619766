import ProductItem from '../standalone/product/product-item';
import PropertyProductItem from '../standalone/product/property-product-item';
import { ISidebarFilters } from './sidebar/sidebar-filters';
import { SelectOption } from '../elements/options/select-option';
import { SortTypeEnum } from '../../enums/sort-type';
import CarProductItem from '../standalone/product/car-product-item';
import { ISidebarCarFilters } from './sidebar/sidebar-filters-car';
import { SidebarConstants } from '../../../components/constants/sidebarConstants';
import Extras from '../../../models/caren/system/extras-dto';

type possibleProductItems = ProductItem | PropertyProductItem | CarProductItem;

export interface ISearchResult {
  productItems?: Array<possibleProductItems>;
  loading?: boolean;
  pickUpLocation?: string;
  dropOffLocation?: string;
}

export default class SearchResult {
  productItems: Array<possibleProductItems>;
  filteredItems: Array<ProductItem>;
  loading?: boolean = true;
  
  pickUpLocation?: string;
  dropOffLocation?: string;

  constructor(data?: ISearchResult) {
    this.productItems = data?.productItems ?? [];
    this.filteredItems = this.productItems;
    this.loading = data?.loading;
    this.pickUpLocation = data?.pickUpLocation;
    this.dropOffLocation = data?.dropOffLocation;
  }

  sort(value: SortTypeEnum) {
    const unavailableProducts = this.productItems.filter((x) => !x.available);
    switch (value) {
      case SortTypeEnum.PriceFromLowest: {
        const sortedArray = this.productItems
          .filter((x) => x.available)
          .sort((a, b) => {
            if ((a.productPrice ?? 0) > (b.productPrice ?? 0)) return 1;
            if ((a.productPrice ?? 0) < (b.productPrice ?? 0)) return -1;
            return 0;
          });
        this.productItems = sortedArray.concat(unavailableProducts);
        break;
      }
      case SortTypeEnum.PriceFromHighest: {
        const sortedArray = this.productItems
          .filter((x) => x.available)
          .sort((a, b) => {
            if ((a.productPrice ?? 0) > (b.productPrice ?? 0)) return -1;
            if ((a.productPrice ?? 0) < (b.productPrice ?? 0)) return 1;
            return 0;
          });
        this.productItems = sortedArray.concat(unavailableProducts);
        break;
      }
      default:
        break;
    }
  }

  filter(filters: ISidebarFilters | ISidebarCarFilters) {
    const selectedPropertyAmenities =
      SidebarConstants.PropertyAmenity in filters
        ? (filters as ISidebarFilters).propertyAmenityOptions?.filter(
            (opt: SelectOption) => opt.selected
          ) ?? []
        : [];
    const selectedPropertyTypes =
      SidebarConstants.PropertyType in filters
        ? (filters as ISidebarFilters).propertyTypeOptions?.filter(
            (opt: SelectOption) => opt.selected
          ) ?? []
        : [];

    const selectedCarCategoryOptions =
      SidebarConstants.CarCategory in filters
        ? (filters as ISidebarCarFilters).carCategoryOptions?.filter(
            (opt: SelectOption) => opt.selected
          ) ?? []
        : [];
    const selectedCarTransmissionTypeOptions =
      SidebarConstants.CarTransmissionType in filters
        ? (filters as ISidebarCarFilters).carTransmissionTypeOptions?.filter(
            (opt: SelectOption) => opt.selected
          ) ?? []
        : [];
    const selectedCarFuelOptions =
      SidebarConstants.CarFuelType in filters
        ? (filters as ISidebarCarFilters).carFuelTypeOptions?.filter(
            (opt: SelectOption) => opt.selected
          ) ?? []
        : [];
    const selectedCarExtrasOptions =
      SidebarConstants.CarExtras in filters
        ? (filters as ISidebarCarFilters).carExtrasOptions?.filter(
            (opt: SelectOption) => opt.selected
          ) ?? []
        : [];

    let minPrice: number;
    let maxPrice: number;

    if ('priceFilter' in filters) {
      minPrice = Number(filters.priceFilter?.minValue);
      maxPrice = Number(filters.priceFilter?.maxValue);
    }

    this.filteredItems = this.productItems.filter(
      (item: possibleProductItems) => {
        switch (item.productItemType) {
          case 'BASE': {
            break;
          }
          case 'PROPERTY': {
            if (
              !selectedPropertyAmenities.some((x) => !!x) &&
              !selectedPropertyTypes.some((x) => !!x)
            )
              return true;

            const property = item as PropertyProductItem;

            // all that are from selected
            if (selectedPropertyTypes.some((x) => !!x))
              if (!property.type) return false;
              else if (
                !selectedPropertyTypes.some(
                  (x: SelectOption) =>
                    property.type.toUpperCase() ===
                    x.value.toString().toUpperCase()
                )
              )
                return false;

            // property must contain all selected amenities
            if (selectedPropertyAmenities.some((x) => !!x))
              if (!property.amenities) return false;
              else if (
                !selectedPropertyAmenities.every((am: SelectOption) =>
                  property.amenities.find(
                    (pAm: string) =>
                      pAm.toUpperCase() === am.value.toString().toUpperCase()
                  )
                )
              ) {
                return false;
              }

            break;
          }
          case 'CAR': {
            if ('priceFilter' in filters) {
              if (
                !(
                  item.productPrice &&
                  item.productPrice >= minPrice &&
                  item.productPrice <= maxPrice
                )
              )
                return false;
            }

            if (
              selectedCarCategoryOptions.length < 1 &&
              selectedCarTransmissionTypeOptions.length < 1 &&
              selectedCarFuelOptions.length < 1 &&
              selectedCarExtrasOptions.length < 1
            ) {
              return true;
            }

            const car = item as CarProductItem;

            if (
              !this.isPassingCarCategoryFilter(car, selectedCarCategoryOptions)
            ) {
              return false;
            }

            if (
              !this.isPassingTransmissionTypeFilter(
                car,
                selectedCarTransmissionTypeOptions
              )
            ) {
              return false;
            }

            if (!this.isPassingFuelTypeFilter(car, selectedCarFuelOptions)) {
              return false;
            }

            if (selectedCarExtrasOptions.length > 0) {
              if (
                !this.isPassingAirConditioningCheck(
                  car,
                  selectedCarExtrasOptions
                )
              ) {
                return false;
              }
              if (
                !this.isPassingDoorCountCheck(car, selectedCarExtrasOptions)
              ) {
                return false;
              }
              if (
                !this.isPassingLargeBagsCheck(car, selectedCarExtrasOptions)
              ) {
                return false;
              }
            }
          }
          // eslint-disable-next-line no-fallthrough
          default: {
            break;
          }
        }

        return true;
      }
    );
  }

  private isPassingCarCategoryFilter(
    car: CarProductItem,
    selectedCarCategoryOptions: SelectOption[]
  ): boolean {
    if (selectedCarCategoryOptions.length <= 0) {
      return true;
    }

    if (!car.groups) {
      return false;
    }

    const selectedFilterIds = selectedCarCategoryOptions.map((x) => x.id);
    const availableCurrentCarGroups = car.groups.map((x) => x.id);

    return availableCurrentCarGroups.some((availableGroupIdFromCar: number) =>
      selectedFilterIds.includes(availableGroupIdFromCar)
    );
  }

  private isPassingFuelTypeFilter(
    car: CarProductItem,
    selectedCarFuelOptions: SelectOption[]
  ): boolean {
    if (selectedCarFuelOptions.length <= 0) {
      return true;
    }

    if (!car.fuelId) {
      return false;
    }

    return selectedCarFuelOptions.some((f: SelectOption) =>
      f.groupedIds?.some((id) => car.fuelId === id)
    );
  }

  private isPassingTransmissionTypeFilter(
    car: CarProductItem,
    selectedCarTransmissionTypeOptions: SelectOption[]
  ): boolean {
    if (selectedCarTransmissionTypeOptions.length <= 0) {
      return true;
    }

    if (!car.transmissionId) {
      return false;
    }

    return selectedCarTransmissionTypeOptions.some((t: SelectOption) =>
      t.groupedIds?.some((id) => car.transmissionId === id)
    );
  }

  private isPassingAirConditioningCheck(
    car: CarProductItem,
    selectedCarExtrasOptions: SelectOption[]
  ): boolean {
    if (
      !selectedCarExtrasOptions
        .map((x) => x.id)
        .includes(Extras.AirConditioningFilterId)
    ) {
      return true;
    }

    return !!car.airCondition;
  }

  private isPassingDoorCountCheck(
    car: CarProductItem,
    selectedCarExtrasOptions: SelectOption[]
  ): boolean {
    if (
      !selectedCarExtrasOptions.map((x) => x.id).includes(Extras.DoorsFilterId)
    ) {
      return true;
    }

    return !!(car?.doors && car.doors >= 4);
  }

  private isPassingLargeBagsCheck(
    car: CarProductItem,
    selectedCarExtrasOptions: SelectOption[]
  ): boolean {
    if (
      !selectedCarExtrasOptions
        .map((x) => x.id)
        .includes(Extras.LargeBagsFilterId)
    ) {
      return true;
    }

    return !!(car?.largeBags && car.largeBags >= 4);
  }

  getHighestPrice(): number {
    let highestPrice = Number.MIN_VALUE;
    this.productItems.map((pi) => {
      highestPrice =
        pi.productPrice && pi.productPrice > highestPrice
          ? pi.productPrice
          : highestPrice;
    });

    if(highestPrice === Number.MIN_VALUE)
      highestPrice = 0;

    return highestPrice;
  }

  getLowestPrice(): number {
    let lowestPrice = Number.MAX_VALUE;
    this.productItems.map((pi) => {
      lowestPrice =
        pi.productPrice && pi.productPrice < lowestPrice
          ? pi.productPrice
          : lowestPrice;
    });

    if(lowestPrice === Number.MAX_VALUE)
    lowestPrice = 0;

    return lowestPrice;
  }
}
