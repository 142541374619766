export enum CurrencyType {
  USD = 'USD',
  ISK = 'ISK',
  EUR = 'EUR',
  SGD = 'SGD',
  HKD = 'HKD',
  TWD = 'TWD',
  GBP = 'GBP',
  CAD = 'CAD',
  CNY = 'CNY',
  AUD = 'AUD',
  SEK = 'SEK',
  NOK = 'NOK',
  DKK = 'DKK'
}
