import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_multiselect = _resolveComponent("multiselect")!
  const _component_dropdown = _resolveComponent("dropdown")!
  const _component_search_dropdown = _resolveComponent("search-dropdown")!
  const _component_checkbox = _resolveComponent("checkbox")!
  const _component_phone_input = _resolveComponent("phone-input")!
  const _component_numeric_input = _resolveComponent("numeric-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_text_area = _resolveComponent("text-area")!
  const _component_text_input = _resolveComponent("text-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (
        _ctx.DynamicInput.isInstanceDropdown(_ctx.dynamicInputModel) &&
        _ctx.dynamicInputModel.selectMultiple
      )
      ? (_openBlock(), _createBlock(_component_multiselect, {
          key: 0,
          inputLabel: _ctx.dynamicInputModel.input.label,
          options: _ctx.dynamicInputModel.input.options,
          selectionRequired: _ctx.dynamicInputModel.input.required,
          shouldShowPills: true
        }, null, 8, ["inputLabel", "options", "selectionRequired"]))
      : _createCommentVNode("", true),
    (
        _ctx.DynamicInput.isInstanceDropdown(_ctx.dynamicInputModel) &&
        !_ctx.DynamicInput.isDataFormatCountry(_ctx.dynamicInputModel.dataFormat) &&
        !_ctx.dynamicInputModel.selectMultiple
      )
      ? (_openBlock(), _createBlock(_component_dropdown, {
          key: 1,
          class: _normalizeClass({ 'pb-3': !_ctx.dynamicInputModel.input.isValid }),
          dropdownSelectModel: _ctx.dynamicInputModel.input
        }, null, 8, ["class", "dropdownSelectModel"]))
      : _createCommentVNode("", true),
    (
        _ctx.DynamicInput.isInstanceSearchDropdown(_ctx.dynamicInputModel) &&
        _ctx.DynamicInput.isDataFormatCountry(_ctx.dynamicInputModel.dataFormat) &&
        !_ctx.dynamicInputModel.selectMultiple
      )
      ? (_openBlock(), _createBlock(_component_search_dropdown, {
          key: 2,
          class: _normalizeClass({ 'pb-3': !_ctx.dynamicInputModel.input.isValid }),
          dropdownModel: _ctx.dynamicInputModel.input
        }, null, 8, ["class", "dropdownModel"]))
      : _createCommentVNode("", true),
    (_ctx.DynamicInput.isInstanceCheckbox(_ctx.dynamicInputModel))
      ? (_openBlock(), _createBlock(_component_checkbox, {
          key: 3,
          class: _normalizeClass({ 'pb-3': !_ctx.dynamicInputModel.input.isValid }),
          checkboxModel: _ctx.dynamicInputModel.input
        }, null, 8, ["class", "checkboxModel"]))
      : _createCommentVNode("", true),
    (_ctx.DynamicInput.isInstancePhoneInput(_ctx.dynamicInputModel))
      ? (_openBlock(), _createBlock(_component_phone_input, {
          key: 4,
          phoneInputModel: _ctx.dynamicInputModel.input
        }, null, 8, ["phoneInputModel"]))
      : _createCommentVNode("", true),
    (_ctx.DynamicInput.isInstanceNumberInput(_ctx.dynamicInputModel))
      ? (_openBlock(), _createBlock(_component_numeric_input, {
          key: 5,
          numericInput: _ctx.dynamicInputModel.input
        }, null, 8, ["numericInput"]))
      : _createCommentVNode("", true),
    (_ctx.DynamicInput.isInstanceDateInput(_ctx.dynamicInputModel))
      ? (_openBlock(), _createBlock(_component_date_input, {
          key: 6,
          dateInputModel: _ctx.dynamicInputModel.input
        }, null, 8, ["dateInputModel"]))
      : _createCommentVNode("", true),
    (_ctx.DynamicInput.isInstanceTextArea(_ctx.dynamicInputModel))
      ? (_openBlock(), _createBlock(_component_text_area, {
          key: 7,
          class: _normalizeClass({ 'pb-3': !_ctx.dynamicInputModel.input.isValid }),
          textAreaInputModel: _ctx.dynamicInputModel.input
        }, null, 8, ["class", "textAreaInputModel"]))
      : _createCommentVNode("", true),
    (_ctx.DynamicInput.isInstanceTextInput(_ctx.dynamicInputModel))
      ? (_openBlock(), _createBlock(_component_text_input, {
          key: 8,
          class: _normalizeClass({ 'pb-3': !_ctx.dynamicInputModel.input.isValid }),
          textInputModel: _ctx.dynamicInputModel.input
        }, null, 8, ["class", "textInputModel"]))
      : _createCommentVNode("", true)
  ]))
}