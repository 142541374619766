import { Nullable } from 'src/models/generics/generics';
import ProductItem, { IProductItem } from './product-item';
import IRoom from '../../../travia/room';
import { CurrencyType } from '../../../internal/currency/currency';
import IPropertyMap from '../../../internal/prop-maps/property-map';
import IconWithText from '../../elements/icon-with-text/icon-with-text';

export interface IRoomProductItem extends IProductItem {
  maxOccupancy: Nullable<number>;
  breakfastAvailable: boolean;
  breakfastIncluded: boolean;
  totalBreakfastPrice?: number;
  totalOriginalBreakfastPrice?: number;
  priceWithBreakfastPerNight?: number;
}

export default class RoomProductItem extends ProductItem {
  maxOccupancy: Nullable<number>;
  breakfastAvailable: boolean;
  breakfastIncluded: boolean;
  totalBreakfastPrice?: number;
  totalOriginalBreakfastPrice?: number;
  priceWithBreakfastPerNight?: number;

  constructor(data: IRoomProductItem) {
    super(data);
    this.productItemType = 'ROOM';
    this.maxOccupancy = data.maxOccupancy;
    this.breakfastAvailable = data.breakfastAvailable;
    this.breakfastIncluded = data.breakfastIncluded;
    this.totalBreakfastPrice = data.totalBreakfastPrice;
    this.totalOriginalBreakfastPrice = data.totalOriginalBreakfastPrice;
    this.priceWithBreakfastPerNight = data.priceWithBreakfastPerNight;
  }

  public static fromIRoom(
    room: IRoom,
    selectedCurrency: CurrencyType,
    propertyMap: IPropertyMap
  ) {
    return new RoomProductItem({
      id: room.id ?? -1,
      breakfastAvailable: room.breakfastAvailable,
      breakfastIncluded: room.breakfastIncluded,
      totalBreakfastPrice: room.totalBreakfastPrice,
      totalOriginalBreakfastPrice: room.totalOriginalBreakfastPrice,
      productName: room.name,
      productTypeName: room.roomTypeName,
      productImages: room.images,
      productPrice: room.pricePerNight ?? null,
      priceWithBreakfastPerNight: room.priceWithBreakfastPerNight ?? 0,
      currencyType: selectedCurrency,
      priceStable: true,
      pricePerUnit: true,
      unitOfMeasure: 'Room',
      available: room.available ?? false,
      maxOccupancy: room.maxOccupancy ?? null,
      iconsWithText: room.roomAmenityCodes
        .slice(0, 4)
        .map((roomAmenity: string) => {
          const codeMap = propertyMap.roomAmenityMap.find(
            (am) => am.code === roomAmenity
          );
          return new IconWithText({
            inline: false,
            code: codeMap?.code ?? roomAmenity,
            title: codeMap?.name ?? roomAmenity,
            content: null
          });
        })
    });
  }
}
