import DualRangeSlider from '../../elements/dual-range-slider/dual-range-slider';
import { SelectOption } from '../../elements/options/select-option';

export type FilterType = 'TRANSMISSION' | 'FUEL';

export interface ISidebarCarFilters {
  carCategoryOptions?: SelectOption[];
  carTransmissionTypeOptions?: SelectOption[];
  carFuelTypeOptions?: SelectOption[];
  carExtrasOptions?: SelectOption[];
  availableCarCategoryOptions?: number[];
  availableCarTransmissionTypeOptions?: number[];
  availableCarFuelTypeOptions?: number[];
  priceFilter?: DualRangeSlider;
  availableCarExtrasOptions?: number[];
}

export default class SidebarCarFilters implements ISidebarCarFilters {
  availableCarCategoryOptions: number[];
  availableCarTransmissionTypeOptions: number[];
  availableCarFuelTypeOptions: number[];
  availableCarExtrasOptions: number[];
  private _carCategoryOptions: SelectOption[] = [];
  private _carTransmissionTypeOptions: SelectOption[] = [];
  private _carFuelTypeOptions: SelectOption[] = [];
  private _carExtrasOptions: SelectOption[] = [];

  priceFilter: DualRangeSlider;

  get carCategoryOptions(): SelectOption[] {
    return this._carCategoryOptions
      .map((opt: SelectOption) => {
        return new SelectOption({
          ...opt,
          disabled: !this.availableCarCategoryOptions.some(
            (id: number) => opt.id === id
          )
        });
      })
      .sort((opt: SelectOption) => (opt.disabled ? 1 : -1));
  }
  set carCategoryOptions(options: SelectOption[]) {
    this._carCategoryOptions = options;
  }

  get carTransmissionTypeOptions(): SelectOption[] {
    return this._carTransmissionTypeOptions
      .map((opt: SelectOption) => {
        return new SelectOption({
          ...opt,
          disabled: !this.availableCarTransmissionTypeOptions.some(
            (id: number) => opt.id === id
          )
        });
      })
      .sort((opt: SelectOption) => (opt.disabled ? 1 : -1));
  }
  set carTransmissionTypeOptions(options: SelectOption[]) {
    this._carTransmissionTypeOptions = options;
  }

  get carFuelTypeOptions(): SelectOption[] {
    return this._carFuelTypeOptions
      .map((opt: SelectOption) => {
        return new SelectOption({
          ...opt,
          disabled: !this.availableCarFuelTypeOptions.some(
            (id: number) => opt.id === id
          )
        });
      })
      .sort((opt: SelectOption) => (opt.disabled ? 1 : -1));
  }
  set carFuelTypeOptions(options: SelectOption[]) {
    this._carFuelTypeOptions = options;
  }

  get carExtrasOptions(): SelectOption[] {
    return this._carExtrasOptions
      .map((opt: SelectOption) => {
        return new SelectOption({
          ...opt,
          disabled: !this.availableCarExtrasOptions.some(
            (id: number) => opt.id === id
          )
        });
      })
      .sort((opt: SelectOption) => (opt.disabled ? 1 : -1));
  }
  set carExtrasOptions(options: SelectOption[]) {
    this._carExtrasOptions = options;
  }

  /*
   *
   * CONSTRUCTOR
   *
   */
  constructor(data?: ISidebarCarFilters) {
    this.availableCarCategoryOptions = data?.availableCarCategoryOptions ?? [];
    this.availableCarTransmissionTypeOptions =
      data?.availableCarTransmissionTypeOptions ?? [];
    this.availableCarFuelTypeOptions = data?.availableCarFuelTypeOptions ?? [];
    this.availableCarExtrasOptions = data?.availableCarExtrasOptions ?? [];
    this.carCategoryOptions = data?.carCategoryOptions ?? [];
    this.carTransmissionTypeOptions = data?.carTransmissionTypeOptions ?? [];
    this.carFuelTypeOptions = data?.carFuelTypeOptions ?? [];
    this.priceFilter = data?.priceFilter ?? new DualRangeSlider({
      minInitialValue: 0,
      maxInitialValue: 0
    });
    this.carExtrasOptions = data?.carExtrasOptions ?? [];
  }

  get sidebarFilters(): ISidebarCarFilters {
    return {
      carCategoryOptions: this._carCategoryOptions.map((op: SelectOption) => {
        return { ...op } as SelectOption;
      }),
      carTransmissionTypeOptions: this._carTransmissionTypeOptions.map(
        (op: SelectOption) => {
          return { ...op } as SelectOption;
        }
      ),
      carFuelTypeOptions: this._carFuelTypeOptions.map((op: SelectOption) => {
        return { ...op } as SelectOption;
      }),
      carExtrasOptions: this._carExtrasOptions.map((op: SelectOption) => {
        return { ...op } as SelectOption;
      }),
      availableCarCategoryOptions: this.availableCarCategoryOptions,
      availableCarTransmissionTypeOptions: this
        .availableCarTransmissionTypeOptions,
      availableCarFuelTypeOptions: this.availableCarFuelTypeOptions,
      availableCarExtrasOptions: this.availableCarExtrasOptions,
      priceFilter: this.priceFilter
    };
  }

  addAvailableFilterOptions(availableOptionGroupedIds: number[], filterType: FilterType){
    const availableList: number[] = [];

    if(filterType === 'TRANSMISSION'){
      this.carTransmissionTypeOptions.forEach(
        t => { 
          //for each group id check if id is in availableOptionGroupedIds push the id to availableList
          t.groupedIds && t.groupedIds.length > 0 && t.groupedIds.forEach(id => {
            if(availableOptionGroupedIds.includes(id)){
              t.id !== undefined && availableList.push(t.id)
            }
          });
      });
      this.availableCarTransmissionTypeOptions = availableList;

    }else if(filterType === 'FUEL'){
      this.carFuelTypeOptions.forEach(
        t => {
          t.groupedIds && t.groupedIds.length > 0 && t.groupedIds.forEach(id => {
            if(availableOptionGroupedIds.includes(id)){
              t.id !== undefined && availableList.push(t.id)
            }
          });
      });
      this.availableCarFuelTypeOptions = availableList;
    }
  }
}
