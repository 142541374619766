import moment from 'moment';
import TravelDetails from './travel-details';
import PropertyDetails from '../standalone/property/property-details';
import { IPropertyInfoDto } from '../../dtos/requests/property-info-dto';
import IBookingRequest from '../../internal/booking/booking-request';
import { IBookingRoomRequest } from '../../internal/booking/booking-room';
import { CurrencyType } from '../../internal/currency/currency';
import CartFabBadge from '../elements/cart-fab-badge/cart-fab-badge';
import { SearchQueryParams } from '../../../models/internal/query-params/search-query';
import { IGuestsInRoomPriceReq } from '../../internal/room-allocation/guests-in-room-price';
import {
  ApiCancellation,
  cancellationTokens,
  cancellationTokensModel
} from '../../internal/api-cancellation/api-cancellation';

export interface IPropertyArgs {
  propertyDetails?: PropertyDetails;
  travelDetails?: TravelDetails;
  loading?: boolean;
  cartFabBadge?: CartFabBadge;
}

export interface IProperty {
  propertyDetails: PropertyDetails;
  travelDetails: TravelDetails;
  loading: boolean;
}

export default class Property implements IProperty {
  propertyDetails: PropertyDetails;
  travelDetails: TravelDetails;
  loading = false;
  propertyFound = true;
  cartFabBadge: CartFabBadge;

  cancellationTokens: Record<cancellationTokens, ApiCancellation>;

  constructor(data?: IPropertyArgs) {
    this.propertyDetails = data?.propertyDetails ?? new PropertyDetails();
    this.travelDetails = data?.travelDetails ?? new TravelDetails();
    this.cartFabBadge = data?.cartFabBadge ?? new CartFabBadge();
    this.loading = data?.loading ?? this.loading;
    this.cancellationTokens = cancellationTokensModel;
  }

  getIPropertyInfoDto(
    productId: number,
    travelDetails: SearchQueryParams
  ): IPropertyInfoDto {
    return {
      propertyIds: [productId],
      numberOfRooms: Number(travelDetails.rooms),
      numberOfGuests:
        Number(travelDetails.guests) + Number(travelDetails.children),
      startDate: moment.utc(travelDetails.startDate).toDate(),
      endDate: moment.utc(travelDetails.endDate).toDate(),
      currencyCode: this.travelDetails.currency
    };
  }

  getBookingRequestModel(
    cartId: number | null,
    currencyCode: CurrencyType,
    travelDetails: SearchQueryParams
  ): IBookingRequest {
    return {
      bookingCartId: cartId,
      currencyCode: currencyCode,
      confirm: false,
      instant: true,
      name: 'Arctic Adventure Bookings',
      countryCode: 'IS',
      propertyId: this.propertyDetails.propertyId,
      bookingRooms: this.travelDetails.guestsPerRoomType.map(
        (guestsPerRoom, idx) => {
          const roomDetails = this.travelDetails.getRoomDetails(
            Number(guestsPerRoom.roomId)
          );
          const startDate = moment
            .utc(this.travelDetails.dateRanges.startDate as Date)
            .toDate();
          const endDate = moment
            .utc(this.travelDetails.dateRanges.endDate as Date)
            .toDate();
          return {
            name: roomDetails?.name,
            breakfast: this.travelDetails.isBreakfastSelected(idx),
            pax: guestsPerRoom.guests,
            startDate,
            endDate,
            roomId: guestsPerRoom.roomId,
            quantity: 1
          } as IBookingRoomRequest;
        }
      )
    };
  }

  getRouterQuery(): SearchQueryParams {
    const dateRanges = this.travelDetails.dateRanges;
    return {
      guests: this.travelDetails.numberOfGuests.toString(),
      children: this.travelDetails.accommodationModal.childrenCount.toString(),
      rooms: this.travelDetails.numberOfRooms.toString(),
      startDate: moment(dateRanges.startDate).format('YYYY-MM-DD'),
      endDate: moment(dateRanges.endDate).format('YYYY-MM-DD'),
      locations: JSON.stringify([]),
      properties: JSON.stringify([this.propertyDetails.propertyId])
    };
  }

  getRoomsPricingModel(): IGuestsInRoomPriceReq {
    const dateRanges = this.travelDetails.dateRanges;
    return {
      propertyId: this.propertyDetails.propertyId,
      startDate: dateRanges.startDate,
      endDate: dateRanges.endDate,
      rooms: this.travelDetails.guestsPerRoomType,
      currencyCode: this.travelDetails.currency
    };
  }
}
