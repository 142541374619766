
import { defineComponent, reactive } from 'vue';
import ResultsPage from '../components/block/results-page/results-page.vue';
import ResultsPageModel, {
  ResultsEnum
} from '../models/components/block/results-page';
import SidebarCar from '../models/components/block/sidebar-car';
import { SelectOption } from '../models/components/elements/options/select-option';
import ProductItem from '../models/components/standalone/product/product-item';
import { ICarSearchProperties } from '../models/internal/global/car-search-properties';
import systemController from '../services/controllers/system-controller-service';
import vehicleController from '../services/controllers/vehicle-controller-service';
import Notification from '../models/internal/notification/notification';
import { SortTypeEnum } from '../models/enums/sort-type';
import IRental from '../models/caren/rental';
import CarSearchResponse from '../models/internal/vehicle/car-search-response';
import CarProductItem from '../models/components/standalone/product/car-product-item';
import CarSearchPanel from '../models/components/block/car-search-panel';
import SidebarCarFilters from '../models/components/block/sidebar/sidebar-filters-car';
import { useRoute } from 'vue-router';
import Groups from '../models/caren/system/groups';
import Transmissions from '../models/caren/system/transmissions';
import Fuels from '../models/caren/system/fuels';
import { CarSearchQueryParams } from '../models/internal/car-query-params/car-search-query';
import { notify } from '@kyvg/vue3-notification';
import SearchResult from '../models/components/block/search-result';
import { useStore } from 'vuex';
import CarSearchResult from '../models/internal/vehicle/car-search-result';
import Extras from '../models/caren/system/extras-dto';
import locationController from '../services/controllers/location-controller-service';

export default defineComponent({
  name: 'CarResults',
  components: {
    'results-page': ResultsPage
  },
  beforeRouteEnter(to, from) {
    const isFromHomeOrRefreshed =
      from.name === 'home' || from.name === undefined;
    /**
     * Only validate query params if page is coming from home to results.
     * Otherwise, allow navigation to proceed.
     */
    if (isFromHomeOrRefreshed && to.name === 'car-results') {
      if (!CarSearchPanel.isCarQueryParamsValid(to.query)) {
        notify(
          Notification.warning(
            'Some details in the URL seems to be invalid. Please try again.',
            '',
            -1,
            'centered'
          )
        );
        return { path: "/car-home" };
      }
    }
    return true;
  },
  setup() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const store = useStore();
    const route = useRoute();
    const params = route.query as CarSearchQueryParams;

    const model = new ResultsPageModel({
      sidebarCar: new SidebarCar({
        carSearchPanel: CarSearchPanel.fromCarSearchProperties({
          rentalId: Number(params.rentalId),
          driverAge: Number(params.driverAge),
          pickupLocationId: Number(params.pickupLocationId),
          dropoffLocationId: Number(params.dropoffLocationId),
          dateFrom: params.dateFrom,
          dateTo: params.dateTo,
          dateFromTime: params.dateFromTime,
          dateToTime: params.dateToTime,
          pickUpPeriod: params.pickUpPeriod,
          dropOffPeriod: params.dropOffPeriod
        }),
        loading: true
      }),
      resultsType: ResultsEnum.Car,
      searchResult: new SearchResult({
        loading: true
      })
    });

    model.searchResult.pickUpLocation = model.sidebarCar.carSearchPanel.pickUpDropdown.selectedName ?? ''; 
    model.searchResult.dropOffLocation = model.sidebarCar.carSearchPanel.dropOffDrodown.selectedName ?? '';

    return {
      carResultsPageModel: reactive(model)
    };
  },
  watch: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'carResultsPageModel.sidebarCar.carSearchPanel.isLoading'(_newValue) {
      if (!this.carResultsPageModel.sidebarCar.carSearchPanel.isLoading) {
        this.getClassListAsync();
        this.getFilters();
      }
    }
  },
  mounted() {
    if (
      this.carResultsPageModel.sidebarCar.carSearchPanel.isLoading &&
      !this.carResultsPageModel.sidebarCar.carSearchPanel.validate()
    ) {
      this.$notify(
        Notification.warning('Please provide proper search parameters')
      );
      this.$router.push({ name: "car-home" });
    } else {
      this.getLocations();
      if (
        this.$store.getters.carQueryUpdated &&
        !this.carResultsPageModel.sidebarCar.carSearchPanel.isLoading
      ) {
        this.carResultsPageModel.sidebarCar.sidebarFilters =
          new SidebarCarFilters();

        this.getClassListAsync();
        this.getFilters();
      } else {
        this.carResultsPageModel.sidebarCar.sidebarFilters =
          new SidebarCarFilters(this.$store.getters.sidebarCarFilters);
        this.carResultsPageModel.searchResult.productItems =
          this.$store.getters.loadedCars;
        this.getPriceFilter();
      }
    }
  },
  methods: {
    getLocations(): void {
        vehicleController.getRentalList()
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .then(([aa, caren]) => {
            this.carResultsPageModel.sidebarCar.carSearchPanel.isLoading = true;
            /**
             * Selecting caren's rentalId from the response.
             * Replace caren to AA when needed.
             */
            const { id } = caren; 
            this.carResultsPageModel.sidebarCar.carSearchPanel.rentalId = id;
            /** Retrieve all locations */
            this.getDropOffLocations();
            this.getPickUpLocations();
        })
        .catch((err) => {
            this.$notify(Notification.error(err.message));
        })
    },
    getDropOffLocations(): void {
        const carPanel = this.carResultsPageModel.sidebarCar.carSearchPanel;
        const { rentalId } = carPanel;
        locationController.getDropOffList(rentalId).then((dropOffLocations) => {
          carPanel.dropOffDrodown.options = SelectOption.fromDropOffPickUpLocations(dropOffLocations, carPanel.selectedDropOffLocationId);
        })
        .catch((err) => {
          this.$notify(Notification.error(err.message));
          carPanel.dropOffDrodown.options = [];
        })
        .finally(() => {
          carPanel.dropOffDrodown.filterOptions();
        })
    },
    getPickUpLocations(): void {
      const carPanel = this.carResultsPageModel.sidebarCar.carSearchPanel;
      const { rentalId } = carPanel;
      locationController.getPickUpList(rentalId).then((pickUpLocations) => {
        carPanel.pickUpDropdown.options = SelectOption.fromDropOffPickUpLocations(pickUpLocations, carPanel.selectedPickUpLocationId);
      })
      .catch((err) => {
        this.$notify(Notification.error(err.message));
        carPanel.pickUpDropdown.options = [];
      })
      .finally(() => {
        carPanel.pickUpDropdown.filterOptions();
        carPanel.isLoading = false;
      })
    },
    onCarClicked(product: ProductItem) {
      this.$router.push({
        name: `car`,
        params: { id: product.id },
        query:
          this.carResultsPageModel.sidebarCar.carSearchPanel.emitCarQueryParams()
      });
    },
    onInputChange(carSearchProperties: ICarSearchProperties) {
      this.$store.commit('updateCarSearchProperties', carSearchProperties);
    },
    onSidebarFiltersChanged() {
      this.$store.commit(
        'sidebarCarFiltersUpdated',
        this.carResultsPageModel.sidebarCar.sidebarFilters.sidebarFilters
      );
      this.filterSearchResults();
    },
    onSortingChanged() {
      this.sortSearchResults();
      this.filterSearchResults();
    },
    onSearchClicked() {
      if (!this.$store.getters.carQueryUpdated) return;

      this.carResultsPageModel.sidebarCar.loading = true;
      this.getClassListAsync();
    },
    sortSearchResults() {
      this.carResultsPageModel.searchResult.sort(
        this.carResultsPageModel.sidebarCar.sorting.selectedValue ??
          SortTypeEnum.PriceFromLowest
      );
    },
    filterSearchResults() {
      this.carResultsPageModel.searchResult.filter(
        this.carResultsPageModel.sidebarCar.sidebarFilters.sidebarFilters
      );
    },
    getRentalListAsync() {
      return vehicleController
        .getRentalList()
        .then(async (res: IRental[]) => {
          this.carResultsPageModel.sidebarCar.carSearchPanel.rentalList = res;
        })
        .catch((err) => {
          this.$notify(Notification.error(err.message));
          this.carResultsPageModel.sidebarCar.carSearchPanel.rentalList = [];
        });
    },
    getGroupListAsync() {
      systemController
        .getGroupList({
          rentalId:
            this.carResultsPageModel.sidebarCar.carSearchPanel.rentalId.toString()
        })
        .then(async (res: Groups) => {
          this.carResultsPageModel.sidebarCar.sidebarFilters.carCategoryOptions =
            SelectOption.fromGroups(
              res.groupsList.sort((x, y) => (x.name > y.name ? 1 : -1))
            );
        })
        .catch((err) => {
          this.$notify(Notification.error(err.message));
          this.carResultsPageModel.sidebarCar.sidebarFilters.carCategoryOptions =
            [];
        })
        .finally(() => {
          this.$store.commit(
            'carSearchCompleted',
            this.carResultsPageModel.searchResult.productItems
          );
          this.$store.commit(
            'sidebarCarFiltersUpdated',
            this.carResultsPageModel.sidebarCar.sidebarFilters.sidebarFilters
          );
        });
    },
    getTransmissionTypesAsync() {
      systemController
        .getTransmissionList()
        .then(async (res: Transmissions) => {
          this.carResultsPageModel.sidebarCar.sidebarFilters.carTransmissionTypeOptions =
            SelectOption.fromTransmissions(
              res.transmissionsList.sort((x, y) => (x.name > y.name ? 1 : -1))
            );
        })
        .catch((err) => {
          this.$notify(Notification.error(err.message));
          this.carResultsPageModel.sidebarCar.sidebarFilters.carTransmissionTypeOptions =
            [];
        })
        .finally(() => {
          this.$store.commit(
            'sidebarCarFiltersUpdated',
            this.carResultsPageModel.sidebarCar.sidebarFilters.sidebarFilters
          );
        });
    },
    getFuelListAsync() {
      systemController
        .getFuelList()
        .then(async (res: Fuels) => {
          this.carResultsPageModel.sidebarCar.sidebarFilters.carFuelTypeOptions =
            SelectOption.fromFuels(
              res.fuelsList.sort((x, y) => (x.name > y.name ? 1 : -1))
            );
        })
        .catch((err) => {
          this.$notify(Notification.error(err.message));
          this.carResultsPageModel.sidebarCar.sidebarFilters.carFuelTypeOptions =
            [];
        })
        .finally(() => {
          this.$store.commit(
            'sidebarCarFiltersUpdated',
            this.carResultsPageModel.sidebarCar.sidebarFilters.sidebarFilters
          );
        });
    },
    getExtrasList() {
      const res = systemController.getExtrasList();

      this.carResultsPageModel.sidebarCar.sidebarFilters.carExtrasOptions =
        SelectOption.fromExtras(
          res.extrasList.sort((x, y) => (x.name > y.name ? 1 : -1))
        );

      this.$store.commit(
        'sidebarCarFiltersUpdated',
        this.carResultsPageModel.sidebarCar.sidebarFilters.sidebarFilters
      );
    },
    getFilters() {
      this.carResultsPageModel.sidebarCar.loading = true;
      this.getGroupListAsync();
      this.getTransmissionTypesAsync();
      this.getFuelListAsync();
      this.getExtrasList();
      this.carResultsPageModel.sidebarCar.loading = false;
    },
    getPriceFilter() {
      const filter = this.carResultsPageModel.sidebarCar.sidebarFilters.priceFilter;
      filter.maxInitialValue =
        this.carResultsPageModel.searchResult.getHighestPrice();
      filter.minInitialValue =
        this.carResultsPageModel.searchResult.getLowestPrice();
      filter.maxValue =
        this.carResultsPageModel.searchResult.getHighestPrice();
      filter.minValue =
        this.carResultsPageModel.searchResult.getLowestPrice();
      filter.currency =
        this.$store.getters.selectedCurrency;

      this.carResultsPageModel.sidebarCar.loading = false;
    },
    getClassListAsync() {
      this.carResultsPageModel.searchResult.loading = true;
      if (!this.carResultsPageModel.sidebarCar.carSearchPanel.validate())
        return;

      vehicleController
        .getClassList(
          this.carResultsPageModel.sidebarCar.carSearchPanel.emitCarSearchProperties()
        )
        .then(async (res: CarSearchResponse) => {
          this.carResultsPageModel.searchResult.productItems =
            CarProductItem.fromClassListResponse(
              res.data,
              this.$store.getters.selectedCurrency
            );

          this.carResultsPageModel.sidebarCar.sidebarFilters.availableCarCategoryOptions =
            res.availableCarCategories;
          this.carResultsPageModel.sidebarCar.sidebarFilters.addAvailableFilterOptions(
            res.availableTransmissionTypes,
            'TRANSMISSION'
          );
          this.carResultsPageModel.sidebarCar.sidebarFilters.addAvailableFilterOptions(
            res.availableFuelTypes,
            'FUEL'
          );
          this.carResultsPageModel.sidebarCar.sidebarFilters.availableCarExtrasOptions =
            this.getAvailableExtras(res.data);
            
          this.carResultsPageModel.searchResult.pickUpLocation = 
            this.carResultsPageModel.sidebarCar.carSearchPanel.pickUpDropdown.selectedName ?? ''; 
          this.carResultsPageModel.searchResult.dropOffLocation = 
            this.carResultsPageModel.sidebarCar.carSearchPanel.dropOffDrodown.selectedName ?? '';
        })
        .catch((err) => {
          if (err.statusCode) {
            if (err.statusCode === 404)
              this.$notify(Notification.warning(err.message));
            else this.$notify(Notification.error(err.message));
          } else {
            this.$notify(Notification.error(err.message));
          }

          this.carResultsPageModel.searchResult.productItems = [];
        })
        .finally(() => {
          this.$store.commit(
            'carSearchCompleted',
            this.carResultsPageModel.searchResult.productItems
          );
          this.$store.commit(
            'sidebarCarFiltersUpdated',
            this.carResultsPageModel.sidebarCar.sidebarFilters.sidebarFilters
          );

          this.sortSearchResults();
          this.getPriceFilter();
          this.filterSearchResults();

          this.carResultsPageModel.searchResult.loading = false;
        });
    },
    getAvailableExtras(data: CarSearchResult[]): number[] {
      const result: number[] = [];
      systemController.getExtrasList().extrasList.map((x) => x.id);
      const isDoorsFilterAvailable = data.some((x) => x.doors >= 4);
      if (isDoorsFilterAvailable) {
        result.push(Extras.DoorsFilterId);
      }

      const isLargeBagsFilterAvailable = data.some((x) => x.largeBags >= 4);
      if (isLargeBagsFilterAvailable) {
        result.push(Extras.LargeBagsFilterId);
      }

      const isAirConditioningFilterAvaiable = data.some((x) => x.airCondition);
      if (isAirConditioningFilterAvaiable) {
        result.push(Extras.AirConditioningFilterId);
      }

      return result;
    }
  }
});
