
import { reactive, defineComponent } from 'vue';
import DayCmp from '../../elements/day/day.vue';
import Calendar from '../../../models/components/standalone/calendar/calendar';

export default defineComponent({
  name: 'CalendarComponent',
  components: {
    day: DayCmp
  },
  props: {
    calendar: {
      type: Object as () => Calendar,
      required: true
    }
  },
  emits: ['day-click'],
  setup(props) {
    return {
      calendarModel: reactive(props.calendar),
      selectedMonth: props.calendar.selectedMonthName
    };
  },
  methods: {
    onDayClick(fullDate: Date) {
      this.calendarModel.onSelectedDay(fullDate);
    },
    onChangeDate(forward: boolean) {
      if (this.calendarModel.onMonthChange(forward)) {
        this.selectedMonth = this.calendarModel.selectedMonthName;
      }
    },
    onChangeMonth() {
      this.calendarModel.selectedMonth = this.selectedMonth;
    }
  }
});
