import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "app-styles aa-bootstrap-utilities" }
const _hoisted_2 = {
  key: 0,
  id: "root-app",
  class: "position-relative"
}
const _hoisted_3 = { class: "root-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_cmp = _resolveComponent("header-cmp")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_footer_cmp = _resolveComponent("footer-cmp")!
  const _component_notifications = _resolveComponent("notifications")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isDomainChecked)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_header_cmp, { headerModel: _ctx.header }, null, 8, ["headerModel"]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_router_view)
          ]),
          _createVNode(_component_footer_cmp, { footerModel: _ctx.footer }, null, 8, ["footerModel"]),
          _createVNode(_component_notifications, { position: "bottom center" }),
          _createVNode(_component_notifications, {
            group: "centered",
            position: "center",
            "close-on-click": ""
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}