import RoomAmenity from '../room-amenity/room-amenity';

interface IRoomArgs {
  id?: number;
  roomName: string;
  availableRooms: number;
  price?: number;
  available?: boolean;
  roomAmenities?: RoomAmenity[];
  minGuests?: number;
  maxGuests?: number;
}

export default class Room implements IRoomArgs {
  id: number;
  roomName: string;
  availableRooms: number;
  price: number;
  available: boolean;
  roomAmenities: RoomAmenity[] = [];
  minGuests: number;
  maxGuests: number;

  constructor(data?: IRoomArgs) {
    this.id = data?.id ?? -1;
    this.roomName = data?.roomName ?? 'property type';
    this.availableRooms = data?.availableRooms ?? 1;
    this.price = data?.price ?? 0;
    this.roomAmenities = data?.roomAmenities ?? this.roomAmenities;
    this.available = data?.available ?? false;
    this.minGuests = data?.minGuests ?? 1;
    this.maxGuests = data?.maxGuests ?? 2;
  }
}
