import DropdownSelect from '../../../models/components/elements/dropdown-select/dropdown-select';
import NumericInput from '../../../models/components/elements/numeric-input/numeric-input';
import { IBookingQuestion } from '../booking/booking-question';
import { IActivityExtra } from './activity-extras';
import { IParticipantType } from '../participants/participants';
import { IActivityItem } from '../activity/activity-item';
import AppConstants from '../../../constants/app-constants';
import { IPriceBase } from '../price-base/price-base';
import { DynamicInput } from '../../../models/components/elements/dynamic-input/dynamic-input';
import { InventorySystem } from '../../../models/enums/inventory-system';

export enum TourExtraCategory {
  TOUR_MISC = 'misc',
  TOUR_ACCOMMODATION = 'accommodation'
}

export interface ITourExtraArgs extends IActivityExtra {
  extraId: number;
  bookedTitle: string;
  unitCount: number;
  pricingCategoryId: number;
  extraConfigs: IActivityExtra['extraConfigs'];
  input: NumericInput;
  participants: IParticipantType[];
  questionFields: DynamicInput[];
  questions?: IBookingQuestion[];
}

export default class TourExtra implements ITourExtraArgs {
  extraId: number;
  bookedTitle: string;
  bookingId: number;
  priceBase: IPriceBase;
  unitCount: number;
  pricingCategoryId: number;
  extraConfigs: IActivityExtra['extraConfigs'];
  input: NumericInput;
  participants: IParticipantType[];
  questionFields: DynamicInput[];
  description?: string;
  questions?: IBookingQuestion[];

  constructor(data: ITourExtraArgs) {
    this.extraId = data.extraId;
    this.bookedTitle = data.bookedTitle;
    this.bookingId = data.bookingId;
    this.priceBase = data.priceBase;
    this.unitCount = data.unitCount;
    this.pricingCategoryId = data.pricingCategoryId;
    this.extraConfigs = data.extraConfigs;
    this.input = data.input;
    this.participants = data.participants;
    this.questionFields = data.questionFields;
    this.questions = data.questions;
  }

  get isHidden(): boolean {
    if (!this.extraConfigs.flags) return false;
    return this.extraConfigs.flags.includes(AppConstants.FLAGS.NO_RENDER);
  }

  /**
   * An extra should be selectable if it has no pricing category id
   * and if it has a pricing category id where the participants of the category
   * are more than zero.
   */
  get isSelectableExtra(): boolean {
    const isNonParticipant =
      this.pricingCategoryId === 0 || this.pricingCategoryId === null;
    const hasParticipants =
      !!this.pricingCategoryId &&
      !!this.participants.find(
        (participant) => participant.id === this.pricingCategoryId
      )?.quantity;
    return isNonParticipant || hasParticipants;
  }

  get category(): TourExtraCategory {
    if (
      this.extraConfigs.flags?.some((flag) =>
        AppConstants.FLAGS.ACCOMMODATION.includes(flag)
      )
    ) {
      return TourExtraCategory.TOUR_ACCOMMODATION;
    }
    return TourExtraCategory.TOUR_MISC;
  }

  updateDropdownMapping(quantity) {
    if (!this.questions) return null;
    const questionFields = this.questions.map(DynamicInput.fromQuestion);

    if (this.questionFields.length <= 0) {
      this.questionFields = questionFields;
      return;
    }

    const diff = this.questionFields.length - this.questions.length * quantity;

    if (diff <= 0) {
      this.questionFields = [...this.questionFields, ...questionFields];
    } else {
      if (quantity === 0) {
        this.questionFields = [];
      } else {
        this.questionFields.splice(diff, this.questions.length);
      }
    }
  }

  transformDropdownToInput(dropdown: DropdownSelect): NumericInput {
    const inputValues = dropdown.options.map(({ value }) => Number(value));
    const maxValueFromOptions = Math.max(...inputValues);
    const totalParticipants = this.participants.reduce(
      (total, pax) => pax.quantity + total,
      0
    );

    /**
     * If guests exceed the max value from options and
     * single room fee is applied, choose whichever is smaller.
     * Otherwise, max input value is the max value from the options.
     */
    const maxInput = this.isSingleRoomFeeApplied(dropdown.id)
      ? Math.min(maxValueFromOptions, totalParticipants)
      : maxValueFromOptions;

    return new NumericInput(
      {
        label: '',
        value: Number(dropdown.selectedValue),
        withInput: false
      },
      {
        withValidation: false,
        minValue: Math.min(...inputValues),
        maxValue: maxInput
      }
    );
  }

  isIncludedAccommodation(): boolean {
    return (
      this.extraConfigs.includedInPrice &&
      this.category === TourExtraCategory.TOUR_ACCOMMODATION
    );
  }

  isSingleRoomFeeApplied(optionId: string): boolean {
    const option = this.questions?.find((question) => question.id === optionId);

    if (!option) return false;
    if (!option.flags) return false;

    return option.flags.some((flag) =>
      AppConstants.FLAGS.SINGLE_ROOM.includes(flag)
    );
  }

  /**
   * Returns max item depending on given condition
   * Documentation: https://bokun.dev/booking-api-restful/vU6sCfxwYdJWd1QAcLt12i/introduction-to-the-data-model-of-products/mGtiogVmyzywvDaZFK29b5
   * @param participants
   * @param extra
   * @returns
   */
  static getMaxInput(
    participants: IParticipantType[],
    extra: IActivityExtra
  ): number {
    // if limitByPax is true, return total participant count
    if (extra.extraConfigs.limitByPax) {
      if (extra.pricingCategoryId) {
        const participant = participants.find(
          ({ id }) => extra.pricingCategoryId === id
        ) as IParticipantType;

        return participant.quantity;
      }
      return participants.reduce((total, { quantity }) => quantity + total, 0);
    }

    // if maxPerBooking > 0, return maxPerBooking
    if (extra.extraConfigs.maxPerBooking > 0) {
      return extra.extraConfigs.maxPerBooking;
    }

    // if all condition fail, return max possible count
    return AppConstants.MAX_EXTRA_COUNT;
  }

  static fromActivityItem(activityItem: IActivityItem) {
    const {
      activity: { bookingQuestions, ratePrices, bookableExtras, bundleItems },
      extras,
      participantTypes
    } = activityItem;

    return extras.map((extra) => {
      const questions = bookingQuestions.filter(
        ({ extraTriggers, context }) => {
          // Select respective question from the extra
          return extraTriggers?.some(
            ({ value }) =>
              Number(value) === extra.extraId &&
              context === AppConstants.CONTEXTS.EXTRA
          );
        }
      );

      const prices = [
        ...ratePrices.extraPricePerCategoryUnit,
        ...ratePrices.extraPricePerUnit
      ];

      const extraPrices = prices.find(({ id, categoryId }) => {
        const isMatchingId = extra.extraId === id;
        if (extra.pricingCategoryId) {
          return isMatchingId && categoryId === extra.pricingCategoryId;
        }
        return isMatchingId;
      });

      const input = new NumericInput(
        {
          label: '',
          value: 0,
          withInput: false,
          isDisabled: extra.extraConfigs.preselected
        },
        {
          withValidation: false,
          minValue: 0,
          maxValue: this.getMaxInput(participantTypes, extra)
        }
      );

      const isFromBundle =
        activityItem.inventorySystem === InventorySystem.BUNDLE;
      const bookableExtraDetails = bookableExtras.filter(
        ({ id }) => id === extra.extraId
      )[0];
      const bundleItem = bundleItems.find(
        (bundleItem) => bundleItem.id === bookableExtraDetails.bundleItemId
      );

      const bookedTitle =
        isFromBundle && bundleItem
          ? `Day ${bundleItem.dayNo} (${bundleItem.title}) - ${extra.bookedTitle}`
          : extra.bookedTitle;

      return new TourExtra({
        ...extra,
        bookedTitle: bookedTitle,
        participants: participantTypes,
        priceBase: extraPrices
          ? { base: extraPrices.amount, discounted: extraPrices.amount }
          : extra.priceBase,
        questionFields: [],
        questions: questions,
        input: input
      });
    });
  }
}
