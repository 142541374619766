import BaseHttpClientService from '../base-http-client-service';
import ILocationInfo from '../../models/caren/location-info';

interface ILocationControllerService {
    getDropOffList(rentalId: number): Promise<ILocationInfo[]>;
    getPickUpList(rentalId: number): Promise<ILocationInfo[]>;
    getLocationinfo(rentalId: number, id: number): Promise<ILocationInfo>;
}

class LocationControllerService
  extends BaseHttpClientService
  implements ILocationControllerService {
    getDropOffList(rentalId: number): Promise<ILocationInfo[]> {
      return this.httpClient
        .post('api/location/caren/get-dropoff-list', { rentalId })
        .then((resp) => this.prepareResponse(resp).content);
    }

    getPickUpList(rentalId: number): Promise<ILocationInfo[]> {
        return this.httpClient
            .post('api/location/caren/get-pickup-list', { rentalId })
            .then(resp => this.prepareResponse(resp).content)
    }

    getLocationinfo(rentalId: number, id: number): Promise<ILocationInfo> {
        return this.httpClient
            .post('api/location/caren/get-location-info', { rentalId, id })
            .then(resp => this.prepareResponse(resp).content)
    }
}

export default new LocationControllerService() as ILocationControllerService;
