
import { defineComponent, reactive } from '@vue/runtime-core';
import Header from '../../../models/components/standalone/header/header';

export default defineComponent({
  name: 'HeaderCmp',
  props: {
    headerModel: {
      type: Object as () => Header,
      required: true
    }
  },
  setup(props) {
    return {
      header: reactive(props.headerModel)
    };
  },
  watch: {
    '$store.getters.bookedRoomsCount': {
      handler() {
        this.getBookedRoomsCount();
      },
      deep: true
    }
  },
  mounted() {
    this.getBookedRoomsCount();
    document.addEventListener('visibilitychange', this.getBookedRoomsCount);
  },
  unmounted() {
    document.removeEventListener('visibilitychange', this.getBookedRoomsCount);
  },
  methods: {
    getBookedRoomsCount(): void {
      if (!document.hidden) {
        this.header.cartItemCount = this.$store.getters.bookedRoomsCount;
      }
    },
    setActiveSubNav(idx) {
      this.header.selectedSubNav = idx;
    }
  }
});
