import { NotificationsOptions } from '@kyvg/vue3-notification';

export default class Notification {
  public static error(
    message: string,
    title?: string,
    duration?: number,
    group?: string
  ): NotificationsOptions {
    return {
      type: 'error',
      title: title,
      text: message,
      duration: duration ?? 6000,
      group: group ?? ''
    } as NotificationsOptions;
  }

  public static warning(
    message: string,
    title?: string,
    duration?: number,
    group?: string
  ): NotificationsOptions {
    return {
      type: 'warn',
      title: title,
      text: message,
      duration: duration ?? 6000,
      group: group ?? ''
    } as NotificationsOptions;
  }

  public static success(
    message: string,
    title?: string,
    duration?: number,
    group?: string
  ): NotificationsOptions {
    return {
      type: 'success',
      title: title,
      text: message,
      duration: duration ?? 6000,
      group: group ?? ''
    } as NotificationsOptions;
  }
}
