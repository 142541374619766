
import { reactive, defineComponent, computed, ref } from 'vue';
import PropertySearchPanel from '../../../models/components/block/property-search-panel';
import LocationMultiselect from '../../elements/dropdown-multiselect/dropdown-multiselect.vue';
import Loader from '../../elements/loader/loader.vue';
import CalendarModal from '../../standalone/calendar-modal/calendar-modal.vue';
import AccommodationModal from '../../standalone/accommodation-modal/accommodation-modal.vue';
import AppUtils from '../../../utils/app-utils';

export default defineComponent({
  name: 'PropertySearchPanel',
  components: {
    'location-multiselect': LocationMultiselect,
    loader: Loader,
    'calendar-modal': CalendarModal,
    'accommodation-modal': AccommodationModal
  },
  props: {
    isSidebar: {
      type: Boolean,
      required: false
    },
    bgColor: {
      type: String,
      required: false,
      default: '#FFFFFF'
    },
    validationColor: {
      type: String,
      required: false,
      default: 'red'
    },
    propertySearchPanelInputsModel: {
      type: Object as () => PropertySearchPanel,
      required: true
    }
  },
  emits: ['inputs-changed', 'search-clicked'],
  setup(props: any) {
    return {
      propertySearchPanelInputs: reactive(
        props.propertySearchPanelInputsModel
      ) as PropertySearchPanel,
      themePrefix: ref(
        AppUtils.shouldShowThemeManually() ? 'is' : AppUtils.getDomain()
      ),
      headStyles: computed(() => ({
        'flex-lg-row flex-column': !props.isSidebar,
        'flex-column': props.isSidebar,
        disabled: props.propertySearchPanelInputsModel.loading,
        'd-flex g__shadow-strong position-relative rounded p-3 gap-3': true
      }))
    };
  },
  computed: {
    styleObject(): {
      validationColor: { color: string | undefined };
      backgroundColor: { backgroundColor: string | undefined };
    } {
      return {
        validationColor: { color: this.$props.validationColor },
        backgroundColor: { backgroundColor: this.$props.bgColor }
      };
    }
  },
  watch: {
    propertySearchPanelInputs: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      handler(_old: any, _newVal: any): void {
        this.propertySearchPanelInputs?.validate();
      },
      deep: true
    }
  },
  methods: {
    onCalendarClicked(e: any): void {
      e.preventDefault();
    },
    submitForm(event: any): void {
      event.preventDefault();
      event.stopPropagation();
      if (this.propertySearchPanelInputs?.validate()) {
        this.$emit(
          'inputs-changed',
          this.propertySearchPanelInputs.emitValues()
        );
        this.$emit(
          'search-clicked',
          this.propertySearchPanelInputs.emitValues()
        );
      }
    },
    setDatesButtonLabel(): string {
      let dates =
        this.propertySearchPanelInputs.calendarModal.calendar.dateRange.asDates;

      let innerHtml = 'Select dates';
      if (dates.startDate && dates.endDate) {
        innerHtml = `
        <div>
            <span class="px-1">
                ${dates.startDate?.toISOString().slice(0, 10)} | 
                ${dates.endDate?.toISOString().slice(0, 10)}
            </span>
        </div>`;
      } else if (dates.startDate) {
        innerHtml = `
        <div>
            <span class="px-1">
                ${dates.startDate?.toISOString().slice(0, 10)}
            </span>
        </div>`;
      }

      return innerHtml;
    }
  }
});
