import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-339d761a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col-xl-4 col-md-8 col-12 p-3 mx-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_panel = _resolveComponent("search-panel", true)!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_search_panel, {
      "is-sidebar": true,
      "bg-color": '#FFFFFF',
      "validation-color": 'red',
      "property-search-panel-inputs-model": _ctx.propertySearchPanelInputs,
      onSearchClicked: _ctx.onSearchClicked
    }, null, 8, ["property-search-panel-inputs-model", "onSearchClicked"])
  ]))
}