interface IFooter {
  isAdventuresIs: boolean;
  endText: string;
}
export default class Footer implements IFooter {
  isAdventuresIs = false;
  endText: string;

  private AdvComEndText =
    '© Copyright 2022. Adventures.com. All rights reserved.';
  private AdvIsEndText =
    '© Copyright 2022. Arctic Adventures. All rights reserved.';

  constructor(data?: IFooter) {
    this.isAdventuresIs = data?.isAdventuresIs ?? this.isAdventuresIs;
    this.endText = data?.endText ?? '';
  }

  updateInstance(isAdventuresIs) {
    this.isAdventuresIs = isAdventuresIs;
    this.endText = isAdventuresIs ? this.AdvIsEndText : this.AdvComEndText;
  }
}
