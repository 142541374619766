import NavItem from './nav-item';

interface IHeader {
  isAdventuresIs?: boolean;
  navItems?: NavItem[];
  logo?: NavItem;
  baseURL?: string;
  userURL?: string;
}

export default class Header implements IHeader {
  isAdventuresIs = false;
  navItems: NavItem[] = [];
  logo: NavItem;
  baseURL: string;
  cartItemCount = 0;
  selectedSubNav = 0;
  userURL: string;

  AdvComLinks: NavItem[] = [
    new NavItem({
      name: 'Iceland',
      link: '/iceland/',
      hasSubNav: true,
      subNav: [
        new NavItem({
          name: 'Day tours',
          link: '/iceland/tours/one-day/'
        }),
        new NavItem({
          name: 'Multi-Day Tours',
          link: '/iceland/tours/packages/'
        }),
        new NavItem({
          name: 'Self Drive Tours',
          link: '/iceland/tours/self-drive/'
        }),
        new NavItem({
          name: 'Private Tours',
          link: '/iceland/tours/private/'
        }),
        new NavItem({
          name: 'Activities',
          link: '/iceland/tours/activities/'
        }),
        new NavItem({
          name: 'Regions',
          link: '/iceland/regions/'
        }),
        new NavItem({
          name: 'Attractions',
          link: '/iceland/attractions/'
        }),
        new NavItem({
          name: 'Summer Tours',
          link: '/iceland/tours/summer/'
        }),
        new NavItem({
          name: 'Winter Tours',
          link: '/iceland/tours/winter/'
        }),
        new NavItem({
          name: 'Destinations',
          link: '/iceland/destinations/'
        }),
        new NavItem({
          name: 'Guided Tours',
          link: '/iceland/tours/guided/'
        }),
        new NavItem({
          name: 'Weekend Tours',
          link: '/iceland/tours/weekend-tours/'
        }),
        new NavItem({
          name: 'Stopover Packages',
          link: '/iceland/tours/stopover-packages/'
        }),
        new NavItem({
          name: 'New Year in Iceland',
          link: '/iceland/tours/new-year/'
        }),
        new NavItem({
          name: 'Honeymoon Tours',
          link: '/iceland/tours/honeymoon-tours/'
        })
      ]
    }),
    new NavItem({
      name: 'Canada',
      link: '/canada/'
    }),
    new NavItem({
      name: 'Nordic Countries',
      link: '/scandinavia/'
    }),
    new NavItem({
      name: 'Baltic Countries',
      link: '/baltic-countries/'
    }),
    new NavItem({
      name: 'Hotels',
      link: '/iceland/hotels/'
    })
  ];

  AdvIsLinks: NavItem[] = [
    new NavItem({
      name: 'Day tours',
      link: '/iceland/day-tours/'
    }),
    new NavItem({
      name: 'Multi-Day Tours',
      link: '/iceland/multiday-tours/'
    }),
    new NavItem({
      name: 'Self Drive Tours',
      link: '/iceland/self-drive-tours/'
    }),
    new NavItem({
      name: 'Private Tours',
      link: '/iceland/private-tours/'
    }),
    new NavItem({
      name: 'Activities',
      link: '/iceland/day-tours/popular-activities/'
    }),
    new NavItem({
      name: 'Attractions',
      link: '/iceland/attractions/'
    })
  ];

  AdvIsBaseURL = 'https://adventures.is';
  AdvComBaseURL = 'https://adventures.com';

  AdvIsUserURL = 'https://my.adventures.is';
  AdvComUserURL = 'https://my.adventures.com';

  AdvComLogo = new NavItem({
    name: this.AdvComBaseURL,
    link: '/assets/images/brand/adv_logo.svg'
  });

  AdvIsLogo = new NavItem({
    name: this.AdvIsBaseURL,
    link: '/assets/images/brand/aa_logo.svg'
  });

  constructor(data?: IHeader) {
    this.navItems = data?.navItems ?? this.navItems;
    this.logo = data?.logo ?? this.AdvComLogo;
    this.baseURL = data?.baseURL ?? this.AdvComBaseURL;
    this.userURL = data?.userURL ?? this.AdvComUserURL;
  }

  updateInstance(isAdventuresIs: boolean) {
    this.isAdventuresIs = isAdventuresIs;
    this.logo = isAdventuresIs ? this.AdvIsLogo : this.AdvComLogo;
    this.baseURL = isAdventuresIs ? this.AdvIsBaseURL : this.AdvComBaseURL;
    this.navItems = isAdventuresIs ? this.AdvIsLinks : this.AdvComLinks;
    this.userURL = isAdventuresIs ? this.AdvIsUserURL : this.AdvComUserURL;
  }
}
