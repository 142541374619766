import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container my-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_results_page = _resolveComponent("results-page")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_results_page, {
      "results-page-model": _ctx.carResultsPageModel,
      onPropertyClicked: _ctx.onCarClicked,
      onInputsChanged: _ctx.onInputChange,
      onSidebarFiltersChanged: _ctx.onSidebarFiltersChanged,
      onSortingChanged: _ctx.onSortingChanged,
      onSearchClicked: _ctx.onSearchClicked,
      onPriceFiltersChanged: _ctx.onSidebarFiltersChanged
    }, null, 8, ["results-page-model", "onPropertyClicked", "onInputsChanged", "onSidebarFiltersChanged", "onSortingChanged", "onSearchClicked", "onPriceFiltersChanged"])
  ]))
}