
import { reactive, defineComponent } from 'vue';
import Modal from '../../elements/modal-with-button/modal-with-button.vue';
import NumericInput from '../../elements/numeric-input/numeric-input.vue';
import AccommodationModal from '../../../models/components/standalone/modals/accommodation-modal/accommodation-modal';

export default defineComponent({
  name: 'AccommodationModal',
  components: {
    modal: Modal,
    'numeric-input': NumericInput
  },
  props: {
    accommodationModalModel: {
      type: Object as () => AccommodationModal,
      required: true
    }
  },
  emits: ['input-changed'],
  setup(props) {
    return {
      accommodationModal: reactive(props.accommodationModalModel)
    };
  },
  data() {
    return {};
  },
  methods: {
    emitChange() {
      this.$emit('input-changed');
    }
  }
});
