export interface IBadge {
  color?: string;
  content?: number;
}

export default class CartFabBadge implements IBadge {
  color?: string = 'secondary';
  content?: number = 0;

  constructor(data?: IBadge) {
    this.color = data?.color ?? this.color;
    this.content = data?.content ?? this.content;
  }
}
