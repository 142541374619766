import LocationOrProperty from '../../models/travia/locations-and-properties';
import IPropertySearch from '../../models/travia/property-search';
import { PropertySearchResults } from '../../models/travia/property-search-results';
import BaseHttpClientService from '../base-http-client-service';
import { CurrencyType } from '../../models/internal/currency/currency';
import IAvailabilityFilters from '../../models/dtos/responses/availability-filters';
import {CancelTokenSource} from "axios";

interface ISearchControllerService {
  getAvailableFiltersAsync(searchModel: any): Promise<IAvailabilityFilters>;
  getLocationsAsync(): Promise<LocationOrProperty[]>;
  searchForPropertiesAsync(
    searchModel: IPropertySearch,
    cancelToken?: CancelTokenSource
  ): Promise<PropertySearchResults>;
}

class SearchControllerService
  extends BaseHttpClientService
  implements ISearchControllerService {
  async getAvailableFiltersAsync(
    searchModel: any
  ): Promise<IAvailabilityFilters> {
    // todo: change to dynamic according to page's selected one in the future
    searchModel.currencyCode = CurrencyType.ISK;
    return this.httpClient
      .post('api/search/v1/search-properties', searchModel)
      .then((res) => {
        const response = this.prepareResponse(res);
        return (
          response.content?.availabilityFilters ?? {
            propertyTypes: []
          }
        );
      });
  }

  async getLocationsAsync(): Promise<LocationOrProperty[]> {
    return this.httpClient
      .get('api/search/v1/get-selection-list')
      .then((res) => {
        const response = this.prepareResponse(res);
        if (response.statusCode === 200) {
          let propertyArray = [];
          if (response.content?.availableProperties)
            propertyArray = response.content?.availableProperties.map(
              (property: any) => ({
                ...property,
                id: property.id,
                name: property.name,
                value: property.name,
                type: 'property'
              })
            );

          let locationArray = [];
          if (response.content?.locations)
            locationArray = response.content.locations.map((location: any) => ({
              id: location.id,
              name: location.name,
              value: location.name,
              type: 'location'
            }));

          const jointArray: LocationOrProperty[] = [
            ...locationArray,
            ...propertyArray
          ];
          return jointArray;
        } else {
          return [];
        }
      });
  }

  async searchForPropertiesAsync(
    searchModel: IPropertySearch,
    cancelToken?: CancelTokenSource
  ): Promise<PropertySearchResults> {
    return this.httpClient
      .post('api/search/v1/search-properties', searchModel, {
          cancelToken: cancelToken?.token
      })
      .then((res) => {
        return new PropertySearchResults(this.prepareResponse(res).content);
      });
  }
}

export default new SearchControllerService() as ISearchControllerService;
