
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ModalWBtn',
  props: {
    buttonLabel: {
      type: String,
      required: false,
      default: ''
    },
    buttonName: {
      type: String,
      required: false,
      default: 'Open modal'
    }
  }
});
