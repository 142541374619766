import { SelectOption } from '../../../models/components/elements/options/select-option';

export interface IDriversAge {
  driversAgeList?: SelectOption[];
  startAge?: number;
  endAge?: number;
  uniqueAgeList?: string[];
}

export default class DriversAge {
  driversAgeList: SelectOption[];
  startAge = 18;
  endAge = 29;
  uniqueAgeList: string[] = ['65+'];
  defaultAge = 30;

  constructor(data?: IDriversAge) {
    this.driversAgeList =
      data?.driversAgeList ?? SelectOption.fromStringList(this.getRawList());
    this.startAge = data?.startAge ?? this.startAge;
    this.endAge = data?.endAge ?? this.endAge;
    this.uniqueAgeList = data?.uniqueAgeList ?? this.uniqueAgeList;
  }

  private getRawList(): string[] {
    /** Generates a list of numbers starting from 18-29 by default */
    const diff = this.endAge - this.startAge + 1; // adding one to normalize list
    const initial = this.startAge;
    const ageList = Array.from({ length: diff }, (_, i) => {
      const sum = i + initial;
      return sum.toString();
    });

    /** Combines the unique list of ages with the normal list  */
    const rawList = ageList.length
      ? ageList.concat(this.uniqueAgeList)
      : ageList;
    return rawList;
  }
}
