import { CurrencyType } from './../models/internal/currency/currency';
import qrcode from 'qrcode-generator';
import i18next from 'i18next';
import AppConstants from '../constants/app-constants';
import TranslationControllerService from '../services/controllers/translation-controller-service';

export default class AppUtils {
  public static changeTheme(colorMap?: Record<string, string>): void {
    if (!colorMap) return;
    const doc = document.documentElement.style;
    Object.keys(colorMap).forEach((key) => doc.setProperty(key, colorMap[key]));
  }

  public static checkDomain(): boolean {
    return this.getDomain() === 'is';
  }

  /**
   * Returns the domain from the URL.
   * If the domain is not included in the list,
   * `com` is returned as the fallback domain.
   *
   */
  public static getDomain(): string {
    const splitHost = location.hostname.split('.');
    const domain = splitHost[splitHost.length - 1];
    // restricting possibilities of return localhost or other unincluded domains
    // to default return 'com' for now.
    return AppConstants.DOMAIN_LIST.includes(domain) ? domain : 'com';
  }

  /**
   * Used to check if styles should be changed for specific domains.
   * Returns true or false if domain is Adventures.IS.
   *
   * If `AppConstants.APP_THEME_SWITCH_KEY` is manually set to `1`,
   * this function is overriden and returns true.
   */
  public static isAdventuresIS(): boolean {
    return this.checkDomain() || this.shouldShowThemeManually();
  }

  /**
   * Returns `is` or `com` as prefix or postfix styling.
   *
   * It is limited to `is` and `com`, as as fallback domain, for now
   * but if use-case needs dynamic domain, use `AppUtils.getDomain()`.
   */
  public static getFallbackDomain(): typeof AppConstants.DOMAIN_LIST[number] {
    return this.isAdventuresIS() ? 'is' : 'com';
  }

  public static initThemeSwitch(): void {
    const isValueSet = localStorage.getItem(AppConstants.APP_THEME_SWITCH_KEY);
    // Manually checking value if it's a string equivalent of the boolean
    // since parsing it to boolean will always be true as long as there is a value.
    if (
      !isValueSet &&
      process.env.VUE_APP_ALLOW_THEME_CHANGE?.toString() === 'true'
    ) {
      localStorage.setItem(
        AppConstants.APP_THEME_SWITCH_KEY,
        Number(false).toString()
      );
    }
  }

  public static shouldShowThemeManually(): boolean {
    const shouldShow = localStorage.getItem(AppConstants.APP_THEME_SWITCH_KEY);
    return (
      Boolean(Number(shouldShow)) &&
      // Manually checking value if it's a string equivalent of the boolean
      // since parsing it to boolean will always be true as long as there is a value.
      process.env.VUE_APP_ALLOW_THEME_CHANGE?.toString() === 'true'
    );
  }

  public static encodeTextToQrCode(text: string) {
    const qrgen = qrcode(0, 'L');
    qrgen.addData(text);
    qrgen.make();
    return qrgen.createDataURL();
  }

  public static isValidCurrency(currencyCode: CurrencyType) {
    return Object.values(CurrencyType).includes(currencyCode);
  }

  public static initTranslation(lang) {
    const namespace = 'translations';
    i18next.init({
      fallbackLng: ['en-US'],
      lng: lang,
      nsSeparator: false,
      keySeparator: false,
      defaultNS: namespace,
      ns: [namespace],
      interpolation: {
        escapeValue: false
      },
      returnEmptyString: false
    });

    if (lang !== 'en') {
      TranslationControllerService.getTranslations('en-US').then(
        (translations) =>
          i18next.addResourceBundle('en-US', namespace, translations)
      );
    }

    TranslationControllerService.getTranslations(lang).then((translations) =>
      i18next.addResourceBundle(lang, namespace, translations)
    );

    if (i18next.language !== lang) i18next.changeLanguage(lang);

    return {
      i18next
    };
  }

  public static debounce = <T, K extends any[]>(
    callback: (args: K) => T,
    wait: number
  ) => {
    let timerId;
    return (...args: K) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        callback(args);
      }, wait);
    };
  };
}
