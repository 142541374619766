export const TRACKING_TYPES = {
  TRACK: 'track',
  TRACK_CUSTOM: 'trackCustom'
};

export const STANDARD_ACTIONS = {
  INITIATE_CHECKOUT: 'InitiateCheckout'
};

export const CUSTOM_ACTIONS = {
  REMOVE_FROM_CART: 'RemoveFromCart',
  ADD_PROMOTION: 'AddPromotion'
};

export const CONTENT_TYPES = {
  PRODUCT: 'product',
  PRODUCT_GROUP: 'product_group'
};
