import LabelStyle from '../../../internal/label-style/label-style';
import IInputValidation from '../../../validation/input-validation';
import { SelectOption } from '../options/select-option';

export interface IDropdownArgs {
  id?: string;
  isValid?: boolean;
  label?: string;
  placeholder?: string;
  withValidation?: boolean;
  validationMessage?: string;
  labelStyle?: LabelStyle;
  options?: SelectOption[];
  value?: string;
  dropdownActive?: boolean;
  withInput?: boolean;
  required?: boolean;
  disabled?: boolean;
  showPlaceholder?: boolean;
}

export default class Dropdown implements IInputValidation {
  options: SelectOption[] = [];
  filteredList: SelectOption[];

  withInput = true;
  value = '';

  id = '';
  placeholder = 'Search';
  label = '';
  labelStyle?: LabelStyle;

  required = false;
  disabled = false;
  withValidation = false;
  isValid = true;
  validationMessage = '';

  dropdownActive = false;
  showPlaceholder = true;

  constructor(data?: IDropdownArgs) {
    this.id = data?.id ?? this.id;
    this.label = data?.label ?? this.label;
    this.placeholder = data?.placeholder ?? this.placeholder;
    this.withValidation = data?.withValidation ?? this.withValidation;
    this.validationMessage = data?.validationMessage ?? this.validationMessage;
    this.labelStyle = data?.labelStyle;
    this.options = data?.options ?? this.options;
    this.filteredList = this.options;
    this.isValid = data?.isValid ?? this.withValidation ? this.isValid : true;
    this.value = data?.value ?? this.value;
    this.dropdownActive = data?.dropdownActive ?? this.dropdownActive;
    this.withInput = data?.withInput ?? this.withInput;
    this.required = data?.required ?? this.required;
    this.disabled = data?.disabled ?? this.disabled;
    this.showPlaceholder = data?.showPlaceholder ?? this.showPlaceholder;

    if (data?.value) this.select(data?.value);
  }

  validate() {
    if (!this.withValidation) return (this.isValid = true);
    else return (this.isValid = !!this.selectedValue);
  }

  select(value: string): void {
    this.clearSelection();
    const selected = this.options.find((option) => option.value === value);
    selected?.toggle();
    this.value = '';
    this.dropdownActive = false;
    this.filterOptions();
    this.validate();
  }

  filterOptions(): void {
    this.filteredList = this.options.filter((item: SelectOption) =>
      item.includesText(this.value.trim())
    );
  }

  sortOptions(): void {
    this.filteredList = this.options.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
  }

  checkInList(): void {
    if (this.value === '') return;
    const isInList = this.options.find(
      (option) => option.value === this.value || option.name === this.value
    );
    if (isInList) this.select(isInList.value);
    this.filterOptions();
    this.isValid = !!isInList;
  }

  clearSelection() {
    this.options.forEach((option) => (option.selected = false));
  }

  get selectedValue(): any {
    const selectedValue = this.options.find((x) => x.selected);
    if (!selectedValue) return null;

    return selectedValue.value;
  }

  get selectedName(): string | null {
    const selectedValue = this.options.find((x) => x.selected);
    if (!selectedValue) return null;

    return selectedValue.name;
  }
}
