import { CurrencyType } from '../../internal/currency/currency';

export interface IPaymentSuccessful {
  name: string;
  lastName: string;
  amountPayed: number;
  currency: CurrencyType;
  email: string;
  bookingRefNumbers?: string[];
  textContent?: string;
  titleColor?: string;
  textColor?: string;
  textBold?: boolean;
}

export default class PaymentSuccessful {
  name: string;
  lastName: string;
  amountPayed: number;
  bookingRefNumbers: string[];
  textContent: string;
  currency: CurrencyType;
  email: string;
  titleColor: string;
  textColor: string;
  textBold: boolean;

  constructor(data: IPaymentSuccessful) {
    this.name = data.name;
    this.lastName = data.lastName;
    this.amountPayed = data.amountPayed;
    this.currency = data.currency;
    this.email = data.email;
    this.bookingRefNumbers = data.bookingRefNumbers ?? [];
    this.textContent = data.textContent ?? '';
    this.textColor = data.textColor ?? '#38649c';
    this.titleColor = data.titleColor ?? '#198754';
    this.textBold = data.textBold ?? false;
  }
}
