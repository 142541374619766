import { StripePaymentMethod } from './../models/enums/payment-methods';

export default class AppConstants {
  public static APP_STORAGE_KEY = 'vuex';
  public static APP_THEME_SWITCH_KEY = 'AH-ShowISTheme';
  public static APP_CURRENCY_RATES = 'AH-CurrencyRates';
  public static APP_TRANSLATION = 'AH-Translation';
  public static APP_CURRENCY = 'AH-Currency';
  public static APP_CURRENCY_RATES_RETRY = 'AH-CurrencyRatesRetry';
  public static APP_CART_ID = 'arcticAdventuresCartId';

  public static DOMAIN_LIST = ['com', 'is'];

  public static ADVENTURES_IS_COLOR_MAP = {
    '--bs-primary': '#000000',
    '--bs-primary-rgb': '0, 0, 0',
    '--bs-primary-light': '#367aa7',
    '--bs-primary-light-rgb': '54, 122, 167',
    '--bs-secondary': '#1c1f4a',
    '--bs-secondary-rgb': '28, 31, 74',
    '--bs-secondary-lighter': '#ebecf7',
    '--bs-secondary-lighter-rgb': '235, 236, 247',
    '--bs-gray2': '#717171',
    '--bs-gray2-rgb': '113, 113, 113'
  };

  public static ADVENTURES_COM_COLOR_MAP = {
    '--bs-primary': '#003D67',
    '--bs-primary-rgb': '0, 61, 103',
    '--bs-primary-light': '#406e8d',
    '--bs-primary-light-rgb': '64, 110, 141',
    '--bs-secondary': '#F89810',
    '--bs-secondary-rgb': '248, 152, 16',
    '--bs-secondary-lighter': '#fff7ee',
    '--bs-secondary-lighter-rgb': '255, 247, 238',
    '--bs-light': '#FFFFFF',
    '--bs-light-rgb': '255, 255, 255',
    '--bs-gray2': '#757575',
    '--bs-gray2-rgb': '117, 117, 117'
  };

  public static ADVENTURES_COMMON_COLOR_MAP = {
    '--bs-success': '#6AB17A',
    '--bs-success-rgb': '106, 177, 122',
    '--bs-warning': '#FAD659',
    '--bs-danger': '#dc3545',
    '--bs-danger-rgb': '220, 53, 69',
    '--bs-white': '#ffff',
    '--bs-white-rgb': '255, 255, 255'
  };

  public static FLAGS = {
    ACCOMMODATION: ['Accommodation', 'group:Accommodation'],
    SINGLE_ROOM: ['SingleRoomFeeIsApplied', 'SingleRoom'],
    SINGLE_ROOM_FEE: 'SingleRoomFee',
    NO_RENDER: 'NoRender'
  };

  public static CONTEXTS = {
    BOOKING: 'BOOKING',
    EXTRA: 'EXTRA',
    PASSENGER: 'PASSENGER',
    CONTACT: 'CONTACT'
  };

  public static PAYMENT_OPTIONS = [
    {
      name: 'Credit Card',
      value: StripePaymentMethod.CARD,
      icon: 'fas fa-credit-card'
    },
    {
      name: 'WeChat Pay',
      value: StripePaymentMethod.WECHAT_PAY,
      icon: 'fab fa-weixin'
    },
    {
      name: 'Alipay',
      value: StripePaymentMethod.ALIPAY,
      icon: 'fab fa-alipay'
    },
    {
      name: 'Google Pay',
      value: StripePaymentMethod.GOOGLE_PAY,
      icon: 'fab fa-google-pay'
    },
    {
      name: 'Apple Pay',
      value: StripePaymentMethod.APPLE_PAY,
      icon: 'fab fa-apple-pay'
    }
  ];

  public static MAX_EXTRA_COUNT = 99;

  public static LOCATION_TYPE_IDS = {
    OPTIONAL: -1,
    CUSTOM: -2
  };
}
