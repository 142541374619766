import { ISummaryDetails } from './../models/internal/summary-details/summary-details';
import AccommodationProductItem from '../models/components/standalone/detailed-product-item/accommodation-product-item';
import TourProductItem from '../models/components/standalone/detailed-product-item/tour-product-item';
import NumberUtils from '../utils/number-utils';

import GoogleTracker from './google/tracker';
import FacebookTracker from './facebook/tracker';
import BingTracker from './bing/tracker';
import GoogleAnalyticsV4 from './googleAnalyticsV4/tracker';
import Rudderstack from './rudderstack';

import * as GoogleUtils from './google/utils';
import * as FacebookUtils from './facebook/utils';
import * as BingUtils from './bing/utils';
import * as GA4Utils from './googleAnalyticsV4/utils';

class Tracker {
  config: any;
  googleTracker: GoogleTracker;
  googleAnalyticsV4: GoogleAnalyticsV4;
  facebookTracker: FacebookTracker;
  bingTracker: BingTracker;
  rudderstack: Rudderstack;

  constructor() {
    this.googleTracker = new GoogleTracker();
    this.facebookTracker = new FacebookTracker();
    this.bingTracker = new BingTracker();
    this.googleAnalyticsV4 = new GoogleAnalyticsV4();
    this.rudderstack = new Rudderstack();
  }

  init(config) {
    this.config = config;
    this.bingTracker.init();
    this.googleTracker.init(config);
    this.googleAnalyticsV4.init(config);
    this.facebookTracker.init(config);
  }

  removeProduct(
    product: TourProductItem | AccommodationProductItem,
    totalPrice: number,
    currency: string,
    promoCode: string
  ) {
    const { analyticsCurrency, facebookPixelCurrency, bingTrackingCurrency } =
      this.config;

    const convertedGooglePrice = NumberUtils.convertCurrency({
      from: currency,
      to: analyticsCurrency,
      amount: totalPrice
    });

    const convertedFacebookPrice = NumberUtils.convertCurrency({
      from: currency,
      to: facebookPixelCurrency,
      amount: totalPrice
    });

    const convertedBingPrice = NumberUtils.convertCurrency({
      from: currency,
      to: bingTrackingCurrency,
      amount: totalPrice
    });

    const googleExtractedData = GoogleUtils.extractRemoveBookingData(
      [product],
      convertedGooglePrice
    );

    const facebookExtractedData = FacebookUtils.extractRemoveBookingData(
      product,
      convertedFacebookPrice,
      facebookPixelCurrency
    );

    const bingExtractedData = BingUtils.extractRemoveBookingData(
      product,
      convertedBingPrice,
      bingTrackingCurrency
    );
    const googleParams = GA4Utils.extractRemoveFromCartParams(
      product,
      promoCode,
      currency,
      analyticsCurrency
    );

    this.rudderstack.removeFromCart(googleParams);
    this.facebookTracker.removeFromCart(facebookExtractedData);
    this.bingTracker.removeFromCart(bingExtractedData);
  }

  checkout(
    cartId: number,
    products: (TourProductItem | AccommodationProductItem)[],
    step: number,
    totalPrice: number,
    currency: string,
    promoCode: string,
    productsSummary: ISummaryDetails[]
  ) {
    const { analyticsCurrency, facebookPixelCurrency, bingTrackingCurrency } =
      this.config;

    const convertedGooglePrice = NumberUtils.convertCurrency({
      from: currency,
      to: analyticsCurrency,
      amount: totalPrice
    });

    const convertedFacebookPrice = NumberUtils.convertCurrency({
      from: currency,
      to: facebookPixelCurrency,
      amount: totalPrice
    });

    const convertedBingPrice = NumberUtils.convertCurrency({
      from: currency,
      to: bingTrackingCurrency,
      amount: totalPrice
    });

    const facebookExtractedData = FacebookUtils.extractInitiateCheckoutData(
      products,
      convertedFacebookPrice,
      facebookPixelCurrency
    );

    const bingExtractedData = BingUtils.extractInitiateCheckoutData(
      cartId,
      products,
      convertedBingPrice,
      bingTrackingCurrency
    );

    const googleParams = GA4Utils.convertActivityToEventParams(
      productsSummary,
      convertedGooglePrice,
      currency,
      analyticsCurrency,
      promoCode
    );
    this.rudderstack.beginCheckout(googleParams);
    this.facebookTracker.initiateCheckout(facebookExtractedData);
    this.bingTracker.beginCheckout(bingExtractedData);
  }

  addPromoCode(
    products: (TourProductItem | AccommodationProductItem)[],
    promoCode: string,
    totalPrice: number,
    currency: string
  ) {
    const { facebookPixelCurrency } = this.config;
    const convertedFacebookPrice = NumberUtils.convertCurrency({
      from: currency,
      to: facebookPixelCurrency,
      amount: totalPrice
    });
    const facebookExtractedData = FacebookUtils.extractAddPromoCodeData(
      products,
      promoCode,
      convertedFacebookPrice,
      facebookPixelCurrency
    );

    this.facebookTracker.addPromoCode(facebookExtractedData);
  }

  purchase(
    products: ISummaryDetails[],
    totalPrice: number,
    currency: string,
    promoCode: string
  ) {
    const { analyticsCurrency } = this.config;
    const convertedGooglePrice = NumberUtils.convertCurrency({
      from: currency,
      to: analyticsCurrency,
      amount: totalPrice
    });
    const googleParams = GA4Utils.convertActivityToEventParams(
      products,
      convertedGooglePrice,
      currency,
      analyticsCurrency,
      promoCode
    );
    this.rudderstack.purchase(googleParams);
  }

  viewTermsAndConditions(bookingId: number) {
    if (!bookingId) return;
    this.rudderstack.viewTermsConditions(bookingId);
  }
}

const tracker = new Tracker();
export default tracker;
