import { ref, onMounted, onBeforeMount } from 'vue';
import { notify } from '@kyvg/vue3-notification';
import moment from 'moment';
import cartControllerService from '../services/controllers/cart-controller-service';
import AppConstants from '../constants/app-constants';
import NumberUtils from '../utils/number-utils';
import StringFormatUtils from '../utils/stringFormatUtils';
import Notification from '../models/internal/notification/notification';
import { ICurrencyRate } from '../models/internal/currency/currency-rate';
import { CurrencyType } from '../models/internal/currency/currency';
import { Nullable } from 'src/models/generics/generics';

export default function useCurrencies(cartId?: number) {
  const localRates = localStorage.getItem(AppConstants.APP_CURRENCY_RATES);
  const toCurrency = localStorage.getItem(AppConstants.APP_CURRENCY) ?? 'ISK';
  const currencyUpdateRetry = localStorage.getItem(
    AppConstants.APP_CURRENCY_RATES_RETRY
  );
  const isLoading = ref(true);

  async function getCurrencyRates() {
    try {
      const ratesRes = await cartControllerService.getCurrencies();
      return ratesRes;
    } catch (error) {
      localStorage.setItem(
        AppConstants.APP_CURRENCY_RATES_RETRY,
        moment().valueOf().toString()
      );
      notify(Notification.error('Failed to get currency rates'));
      return [] as ICurrencyRate[];
    }
  }

  function getConvertedAmount(fromCurrency: string, amount: number) {
    return NumberUtils.convertCurrency({
      from: fromCurrency,
      to: toCurrency,
      amount
    });
  }

  function getAmountWithCurrency(amount: number) {
    const convertedAmount = getConvertedAmount('ISK', amount);
    return StringFormatUtils.formatCurrency(
      convertedAmount,
      toCurrency as CurrencyType
    );
  }

  async function setCartCurrency(
    cartId: number,
    currency: string = toCurrency
  ) {
    try {
      isLoading.value = true;
      await cartControllerService.setCartCurrency(
        cartId,
        currency as CurrencyType
      );
    } catch (err) {
      notify(
        Notification.warning('Failed to set cart currency', 'Currency', 5000)
      );
      console.warn(err);
      return null;
    } finally {
      isLoading.value = false;
    }
  }

  /**
   * Checking if there is a cached copy of rates locally
   * and if there is, check if it was stored an hour ago.
   * If it's not updated, get a new copy of rates for an hour and
   * update local copy.
   */
  function updateLocalRates() {
    isLoading.value = true;

    let hasHourPassed = false;
    let isLocalRatesAvailable = false;
    let parsedLocalRates;

    // check if localRates are available by default
    if (localRates) {
      try {
        parsedLocalRates = JSON.parse(localRates);
      } catch (error) {
        notify(
          Notification.error(
            'Invalid syntax. Failed to parse rates.',
            'Currency Rates',
            5000
          )
        );
      }
    }

    // if localRates are available by default
    // verify values
    if (
      !!parsedLocalRates &&
      'storedOn' in parsedLocalRates &&
      'rates' in parsedLocalRates
    ) {
      const now = moment(new Date());
      const lastSaved = moment(Number(parsedLocalRates.storedOn));
      const duration = moment.duration(now.diff(lastSaved));
      const hours = duration.asHours();

      hasHourPassed = hours >= 1;
      isLocalRatesAvailable = parsedLocalRates.rates.length > 0;
    }

    if (isLocalRatesAvailable && !hasHourPassed) {
      isLoading.value = false;
      return;
    }

    const lastRetry = moment(Number(currencyUpdateRetry));
    const now = moment(new Date());
    const retryDuration = moment.duration(now.diff(lastRetry));
    const retrySeconds = retryDuration.asSeconds();
    const has5secsPassed = retrySeconds >= 5;

    // BE has a tendency not to return any
    // currency rates and since a lot of components
    // are using this hook, a lot of error messages are showing.
    // We should only try to call the BE every 5 seconds and check
    // if currency rates are available.
    if (!has5secsPassed) return;

    // after verifying values whether
    // an hour has passed
    // or local rates are not available
    // update cache of currency rates
    getCurrencyRates()
      .then((ratesRes) => {
        const storedKey = AppConstants.APP_CURRENCY_RATES;
        const storedValue = JSON.stringify({
          storedOn: moment().valueOf(),
          rates: ratesRes
        });

        localStorage.setItem(storedKey, storedValue);
      })
      .catch((err) =>
        notify(Notification.warning(err.message, 'Currency Rates', 5000))
      )
      .finally(() => {
        isLoading.value = false;
      });
  }

  onBeforeMount(() => {
    if (cartId) setCartCurrency(cartId);
  });

  onMounted(() => {
    updateLocalRates();
  });

  return {
    isLoading,
    getConvertedAmount,
    getAmountWithCurrency,
    setCartCurrency,
    currencyCode: toCurrency as CurrencyType
  };
}
