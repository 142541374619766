import SearchFilters from '../../models/travia/search-filters';
import BaseHttpClientService from '../base-http-client-service';

interface IFilterControllerService {
  getSearchFiltersAsync(): Promise<SearchFilters>;
}

class FilterControllerService
  extends BaseHttpClientService
  implements IFilterControllerService {
  async getSearchFiltersAsync(): Promise<SearchFilters> {
    return this.httpClient
      .get('api/filters/travia/get-search-filters')
      .then((res) => {
        const genericResp = this.prepareResponse(res);
        return new SearchFilters(genericResp.content);
      });
  }
}

export default new FilterControllerService() as IFilterControllerService;
