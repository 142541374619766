
import { defineComponent, reactive } from "vue"
import CarSearchPanel from "../../../models/components/block/car-search-panel";
import Dropdown from "../../elements/dropdown/dropdown.vue";
import CalendarTimeModal from "../../standalone/calendar-time-modal/calendar-time-modal.vue"
import Loader from "../../elements/loader/loader.vue";

export default defineComponent({
  name: 'CarSearchPanel',
  components: {
    'dropoff-locations-dropdown': Dropdown,
    'pickup-locations-dropdown': Dropdown,
    'drivers-age-dropdown': Dropdown,
    'calendar-time-modal': CalendarTimeModal,
    'loader': Loader
  },
  props: {
    isSidebar: {
      type: Boolean,
      required: false
    },
    carSearchPanelInputs: {
      type: Object as () => CarSearchPanel,
      required: true,
    }
  },
  emits: [
    'inputs-changed',
    'search-clicked'
  ],
  setup(props) {
    return {
      isSidebarMode: props.isSidebar,
      carSearchPanel: reactive(props.carSearchPanelInputs) as CarSearchPanel
    }
  },
  methods: {
    submit () {
      this.carSearchPanel.validate();
      
      if (this.carSearchPanel.isValid) {
        this.$emit('inputs-changed', this.carSearchPanel.emitCarSearchProperties());
        this.$emit('search-clicked');
        this.$router.push({ name: 'car-results', query: this.carSearchPanel.emitCarQueryParams()})
      }
    }
  },
})
