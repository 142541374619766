import Dropdown from '../elements/dropdown/dropdown';
import { SelectOption } from '../elements/options/select-option';
import PropertySearchPanel from './property-search-panel';
import SidebarFilters from './sidebar/sidebar-filters';
import { SortTypeEnum } from '../../enums/sort-type';

export interface ISidebar {
  propertySearchPanel?: PropertySearchPanel;
  sidebarFilters?: SidebarFilters;
}

export default class Sidebar implements ISidebar {
  propertySearchPanel: PropertySearchPanel;
  sidebarFilters: SidebarFilters;
  sorting: Dropdown;

  loading = false;

  constructor(data?: ISidebar) {
    this.propertySearchPanel =
      data?.propertySearchPanel ?? new PropertySearchPanel();
    this.sidebarFilters = data?.sidebarFilters ?? new SidebarFilters();
    this.sorting = new Dropdown({
      options: [
        new SelectOption({
          name: 'Lowest price',
          value: SortTypeEnum.PriceFromLowest,
          selected: true
        }),
        new SelectOption({
          name: 'Highest price',
          value: SortTypeEnum.PriceFromHighest
        })
      ],
      value: 'Lowest price',
      withValidation: false,
      withInput: false
    });
  }
}
