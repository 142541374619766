import { CurrencyType } from '../../internal/currency/currency';
import LabelStyle from '../../internal/label-style/label-style';
import RoomDropdown from '../../internal/room-dropdown/room-dropdown';
import IRoomAllocation from '../../internal/room-allocation/room-allocation';
import AccommodationModal from '../standalone/modals/accommodation-modal/accommodation-modal';
import CalendarModal from '../standalone/modals/calendar-modal/calendar-modal';
import {
  IGuestsInRoom,
  IGuestsInRoomPrice
} from '../../internal/room-allocation/guests-in-room-price';
import DateRange from '../../internal/date-range/date-range';
import {
  ApiCancellation,
  cancellationTokens,
  cancellationTokensModel
} from '../../internal/api-cancellation/api-cancellation';
import DropdownSelect from '../elements/dropdown-select/dropdown-select';
import IRoom from '../../../models/travia/room';
import { SelectOption } from '../elements/options/select-option';
import Switch from '../elements/switch/switch';
import { IProductItem } from '../standalone/product/product-item';
interface ITravelDetailsArgs {
  justify?: string;
  calendarModal?: CalendarModal;
  accommodationModal?: AccommodationModal;
  roomList?: IRoom[];
  roomOccupancies?: IRoomOccupancy[];
  roomDropdowns: RoomDropdown[];
  currency?: CurrencyType;
  roomLabelStyle?: LabelStyle;
  justifyForMobile?: boolean;
  showAvailableRooms?: boolean;
  isHorizontal?: boolean;
}

interface IBreakfast {
  switch: Switch;
  price: number;
  isAvailable: boolean;
}

interface IRoomOccupancy {
  id: string;
  pricePerNight: number;
  priceWithBreakfastPerNight: number;
  totalPrice: number;
  totalPriceWithBreakfast: number;
  loading: boolean;
  occupancy: AccommodationModal;
  dropdown: DropdownSelect;
  breakfast: IBreakfast;
}

interface ITravelDetails {
  calendarModal?: CalendarModal;
  accommodationModal?: AccommodationModal;
  roomList?: IRoom[];
  roomOccupancies: IRoomOccupancy[];
  roomDetails?: IProductItem[];
  roomDropdowns: RoomDropdown[];
  currency?: CurrencyType;
  roomLabelStyle?: LabelStyle;
  justifyForMobile: boolean;
  showAvailableRooms: boolean;
  isHorizontal?: boolean;
}

export default class TravelDetails implements ITravelDetails {
  calendarModal: CalendarModal;
  accommodationModal: AccommodationModal;
  guestsInRoomsPrices: IGuestsInRoomPrice[];
  cancellationTokens: Record<cancellationTokens, ApiCancellation>;
  justifyForMobile: boolean;
  showAvailableRooms: boolean;
  isHorizontal: boolean;

  isLoading = false;
  roomsLoading = false;
  possibleToFitGuests = false;
  roomList?: IRoom[];
  roomOccupancies: IRoomOccupancy[] = [];
  roomDropdowns: RoomDropdown[] = [];
  roomAllocation: IRoomAllocation[] = [];
  currency: CurrencyType;

  itemAddedDuringSession = false;

  constructor(data?: ITravelDetailsArgs) {
    this.justifyForMobile = data?.justifyForMobile ?? true;

    this.cancellationTokens = cancellationTokensModel;
    this.calendarModal = data?.calendarModal ?? new CalendarModal();

    this.accommodationModal =
      data?.accommodationModal ?? new AccommodationModal();

    this.currency = data?.currency ?? CurrencyType.ISK;
    this.roomDropdowns = data?.roomDropdowns ?? this.roomDropdowns;
    this.roomList = data?.roomList ?? this.roomList;

    this.guestsInRoomsPrices = [];
    this.showAvailableRooms = data?.showAvailableRooms ?? false;
    this.isHorizontal = data?.isHorizontal ?? false;
  }

  get numberOfGuests(): number {
    return this.accommodationModal.adultsInput.value ?? 0;
  }

  get numberOfRooms(): number {
    return this.accommodationModal.roomsInput.value ?? 0;
  }

  get numberOfNights(): number {
    return this.calendarModal.calendar.dateRange.numberOfNights;
  }

  get dateRanges(): Record<'startDate' | 'endDate', Date> {
    return (<DateRange>this.calendarModal.calendar.dateRange).asDatesDefinite;
  }

  get guestsPerRoomType(): IGuestsInRoom[] {
    return this.roomOccupancies.map((roomOccupancy) => ({
      guests: roomOccupancy.occupancy.guestsCount,
      roomId: roomOccupancy.dropdown.selectedOption?.id ?? 0
    }));
  }

  get maxRoomCount(): number {
    if (!this.roomList?.length) return 0;

    return this.roomList.reduce((total, room) => {
      if (!room.quantity) return total;
      return room.quantity + total;
    }, 0);
  }

  get canAddMoreRoom(): boolean {
    return this.roomOccupancies.length !== this.maxRoomCount;
  }

  get totalRoomsSelected(): number {
    return this.roomOccupancies.length;
  }

  get totalPrice(): number {
    // roomPrices with breakfast prices
    return this.roomOccupancies.reduce((total, roomOccupancy) => {
      const isBreakfastSelected = roomOccupancy.breakfast.switch.selected;
      const price = isBreakfastSelected
        ? roomOccupancy.totalPriceWithBreakfast
        : roomOccupancy.totalPrice;
      return price + total;
    }, 0);
  }

  get totalGuestsAllocated(): number {
    return this.guestsPerRoomType.reduce(
      (total, { guests }) => total + guests,
      0
    );
  }

  get totalBreakfastIncluded(): number {
    return this.roomOccupancies.reduce(
      (total, roomOccupancy) =>
        total +
        Number(roomOccupancy.breakfast.switch.selected) *
          roomOccupancy.occupancy.guestsCount,
      0
    );
  }

  get isRoomAllocationValid(): boolean {
    // check if each occupancy has a selected room
    // check if each occupancy has an allocated guest
    return (
      this.roomOccupancies.every((room) => !!room.dropdown.selectedOption) &&
      this.roomOccupancies.every((room) => !!room.occupancy.adultsCount)
    );
  }

  get isValid(): boolean {
    return this.calendarModal.calendar.dateRange.validate();
  }

  get isRoomOccupancyLoading(): boolean {
    return this.roomOccupancies.some(({ loading }) => loading);
  }

  isBreakfastSelected(occupancyIdx): boolean {
    return this.roomOccupancies[occupancyIdx].breakfast.switch.selected;
  }

  getRoomDetails(roomId: number) {
    if (!this.roomList?.length) return null;

    return this.roomList.filter((room) => room.id === roomId)[0];
  }

  getPricePerNight(occupancyIdx) {
    const roomSelected = this.roomOccupancies[occupancyIdx];
    const roomPrice = this.isBreakfastSelected(occupancyIdx)
      ? roomSelected.priceWithBreakfastPerNight
      : roomSelected.pricePerNight;
    return roomPrice;
  }

  /**
   * The property `guestsInRoomsPrices` contains a list
   * of mapped objects with guests count, price, and roomId from
   * the `get-property-room-prices` endpoint.
   * It is updated every time one of the search parameters is changed,
   * through the function `onRoomGuestsChanged` in `property-page`
   * and `hotel-calendar`.
   */
  updatedRoomPricing() {
    this.roomOccupancies.forEach((roomOccupancy) => {
      const roomDetails = this.getRoomDetails(
        roomOccupancy.dropdown.selectedOption?.id ?? 0
      );
      if (!roomDetails) return;

      const roomPricing = this.guestsInRoomsPrices.find(
        ({ roomId, guests }) =>
          roomId === roomDetails.id &&
          guests === roomOccupancy.occupancy.guestsCount
      );

      if (!roomPricing) return;
      roomOccupancy.totalPrice = roomPricing.price;
      roomOccupancy.totalPriceWithBreakfast = roomPricing.priceWithBreakfast;

      // To get the breakfast price, `priceWithBreakfast` is subtracted to room price.
      const breakfastPrice = roomPricing.priceWithBreakfast - roomPricing.price;
      roomOccupancy.breakfast.isAvailable = roomDetails.breakfastAvailable;
      // To get the breakfast price "per guest", the breakfast price is divided to
      // the number of guests and number of nights.
      roomOccupancy.breakfast.price =
        breakfastPrice / roomPricing.guests / this.numberOfNights;
    });
  }

  /**
   * When dropdown values in the room occupancies are changed,
   * each option value is checked whether it is enabled or disabled.
   * This depends whether the room has available quantity left.
   */
  updateRoomAvailability() {
    this.roomOccupancies.forEach(({ dropdown }) => {
      dropdown.options.forEach((option) => {
        const isUnavailable =
          this.getRemainingRoomQuantity(Number(option?.id)) <= 0;
        option.disabled = isUnavailable && !option.selected;
      });
    });
  }

  /**
   * Function is called everytime dropdown
   * or accommodation inputs are changed.
   * In this function, maxValue per guests type (adult or children)
   * is set since it is varies and it depends on the selected room type.
   * @param roomOccupancyId
   * @returns
   */
  updateRoomOccupancyLimits(roomOccupancyId) {
    const roomOccupancy = this.roomOccupancies.filter(
      ({ id }) => id === roomOccupancyId
    )[0];
    const roomDetails = this.getRoomDetails(
      roomOccupancy.dropdown.selectedOption?.id ?? 0
    );

    if (!roomDetails || !roomDetails.maxOccupancy) return;

    roomOccupancy.breakfast.isAvailable = roomDetails.breakfastAvailable;
    roomOccupancy.pricePerNight = roomDetails.pricePerNight ?? 0;
    roomOccupancy.priceWithBreakfastPerNight =
      roomDetails.priceWithBreakfastPerNight ?? 0;

    const allowableGuestsCount = roomDetails.maxOccupancy;
    const guestsAllocated = roomOccupancy.occupancy.guestsCount;
    const isMaxedOut = allowableGuestsCount === guestsAllocated;

    // if user has selected the max possible guest per room
    // the value is set to the selected value to immediately disable it.
    // otherwise, if max possible value is not yet reached,
    // either set the maxValue to max possible value from roomDetails.maxOccupancy
    // or set the maxValue to the remaining guests that can be allocated from
    // subtracting current guest type value to roomDetails.maxOccupany
    roomOccupancy.occupancy.adultsInput.maxValue = isMaxedOut
      ? roomOccupancy.occupancy.adultsCount
      : Math.max(
          allowableGuestsCount - roomOccupancy.occupancy.adultsCount,
          allowableGuestsCount
        );
    roomOccupancy.occupancy.childrenInput.maxValue = isMaxedOut
      ? roomOccupancy.occupancy.childrenCount
      : Math.max(
          allowableGuestsCount - roomOccupancy.occupancy.childrenCount,
          allowableGuestsCount
        );
  }

  getRemainingRoomQuantity(id: number): number {
    const roomDetails = this.getRoomDetails(id);
    const roomQuantity = roomDetails?.quantity || 0;
    const roomTypeSelectedCount = this.guestsPerRoomType.filter(
      ({ roomId }) => roomId === id
    ).length;

    return roomQuantity - roomTypeSelectedCount;
  }

  setRoomOccupancyLoadingOff() {
    this.roomOccupancies.forEach(
      (roomOccupancy) => (roomOccupancy.loading = false)
    );
  }

  addRoomOccupancy() {
    if (!this.roomList) return;
    // Disable rooms that have reached possible bookable count
    const rooms = SelectOption.fromRoom(this.roomList);
    rooms.forEach((room) => {
      room.disabled = this.getRemainingRoomQuantity(room?.id || 0) <= 0;
    });

    // Hiding rooms input in accommodation modal as it is not needed
    const accommodation = new AccommodationModal({
      isHorizontal: true,
      showRoomsInput: false
    });

    // Overriding isHorizontal property as it is true by default
    accommodation.adultsInput.isHorizontal = false;
    accommodation.childrenInput.isHorizontal = false;

    return this.roomOccupancies.push({
      id: new Date().getTime().toString(),
      pricePerNight: 0,
      priceWithBreakfastPerNight: 0,
      totalPrice: 0,
      totalPriceWithBreakfast: 0,
      loading: false,
      occupancy: accommodation,
      breakfast: {
        isAvailable: false,
        price: 0,
        switch: new Switch({ label: 'Include breakfast' })
      },
      dropdown: new DropdownSelect({
        label: '',
        placeholder: 'Select preferred room',
        options: rooms
      })
    });
  }

  removeRoomOccupancy(itemId: string) {
    if (!this.roomList || this.roomList.length === 0) return;
    const roomIdx = this.roomOccupancies.findIndex(({ id }) => id === itemId);
    this.roomOccupancies.splice(roomIdx, 1);
  }
}
