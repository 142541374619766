import { createRouter, createWebHistory } from 'vue-router';
import searchPanel from '../views/search-panel.vue';
import resultsPage from '../views/results-page.vue';
import propertyPage from '../views/property-page.vue';
import carPage from '../views/car-page.vue';
import checkout from '../views/checkout-page.vue';
import successPayment from '../views/successful-payment-page.vue';
import notFoundPage from '../views/not-found-page.vue';
import carResultsPage from '../views/car-results-page.vue';
import carSearchPanel from '../views/car-search-panel.vue';

export const routes = [
  { path: '/', name: 'home', component: searchPanel },
  { path: '/results', name: 'results', component: resultsPage },
  { path: '/car-results', name: 'car-results', component: carResultsPage },
  { path: '/car-home', name: 'car-home', component: carSearchPanel },
  {
    path: '/property/:id',
    name: 'property',
    component: propertyPage,
    props: true
  },
  { path: '/car/:id', name: 'car', component: carPage, props: true },
  { path: '/checkout', name: 'checkout', component: checkout },
  { path: '/success', name: 'successful-payment', component: successPayment },
  { path: '/:pathMatch(.*)*', name: 'not-found', component: notFoundPage }
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes
});

export default router;

export const openInNewTab = (
  name: string | null = null,
  query: any | null = null,
  redirectUrl: string | null = null,
  params: Record<any, any> | null
): void => {
  if (!name) return;

  const route = router.resolve({
    name: name,
    query: query,
    params: params ?? {}
  });
  if (!route) return;

  window.open(
    (redirectUrl ?? process.env.VUE_APP_URL?.replace(/\/$/, '')) + route.fullPath,
    '_blank'
  );
};

export const toNotFound = (): void => {
  router.push({ name: 'not-found' });
};

export const toHome = (): void => {
  router.push({ name: 'home' });
};

export const toCheckout = (): void => {
  router.push({ name: 'checkout' });
};
