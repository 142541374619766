import CartFabBadge from '../elements/cart-fab-badge/cart-fab-badge';
import SearchResult from './search-result';
import Sidebar from './sidebar';
import SidebarCar from './sidebar-car';
import {
  ApiCancellation, cancellationTokens, cancellationTokensModel
} from "../../internal/api-cancellation/api-cancellation";

export enum ResultsEnum {
  Car = 'CAR',
  Property = 'PROPERTY'
}

export interface IResultsPage {
  searchResult?: SearchResult;
  sidebar?: Sidebar;
  sidebarCar?: SidebarCar;
  cartFabBadge?: CartFabBadge;
  resultsType?: ResultsEnum;
}

export default class ResultsPage implements IResultsPage {
  searchResult: SearchResult;
  sidebar: Sidebar;
  sidebarCar: SidebarCar;
  cartFabBadge: CartFabBadge;
  resultsType?: ResultsEnum;

  cancellationTokens: Record<cancellationTokens, ApiCancellation>;

  constructor(data?: IResultsPage) {
    this.searchResult = data?.searchResult ?? new SearchResult();
    this.sidebar = data?.sidebar ?? new Sidebar();
    this.sidebarCar = data?.sidebarCar ?? new SidebarCar();
    this.cartFabBadge = data?.cartFabBadge ?? new CartFabBadge();
    this.resultsType = data?.resultsType ?? ResultsEnum.Property;
    this.cancellationTokens = cancellationTokensModel;
  }
}
