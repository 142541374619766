
import { defineComponent, reactive } from 'vue';
import { useStore } from 'vuex';
import { toHome } from '../router';
import { PaymentRequestPaymentMethodEvent } from '@stripe/stripe-js';

import AppConstants from '../constants/app-constants';
import CheckoutCmp from '../components/block/checkout/checkout.vue';
import Loader from '../components/elements/loader/loader.vue';

import cartControllerService from '../services/controllers/cart-controller-service';
import searchController from '../services/controllers/search-controller-service';
import tracker from '../analytics/tracker';
import Checkout from '../models/components/block/checkout';
import Notification from '../models/internal/notification/notification';
import { PropertySearch } from '../models/travia/property-search';
import { PropertySearchResults } from '../models/travia/property-search-results';
import AccommodationProductItem from '../models/components/standalone/detailed-product-item/accommodation-product-item';
import AvailableProperty from '../models/travia/available-property';
import { IBookingRoomResponse } from '../models/internal/booking/booking-room';
import EmptyCart from '../components/block/empty-cart/empty-cart.vue';
import countriesControllerService from '../services/controllers/countries-controller-service';
import { SelectOption } from '../models/components/elements/options/select-option';
import { DataFormat } from '../models/enums/data-format';
import Countries from '../models/travia/countries';
import paymentControllerService from '../services/controllers/payment-controller-service';
import GenericResponse from '../models/response';
import ISuccessfulPaymentInformation from '../models/internal/payment/successful-payment-information';
import TourProductItem from '../models/components/standalone/detailed-product-item/tour-product-item';
import PromoCode from '../models/components/block/promo-code';
import Success from '../components/block/payment-successful/payment-successful.vue';
import IUnifiedCart, {
  cartIsEmpty,
  getAccommodationItems,
  getActivityItems,
  IAccommodationItem
} from '../models/internal/cart/unified-cart';
import IBooking from '../models/internal/booking/booking';
import { IActivityItem } from '../models/internal/activity/activity-item';
import { DynamicInput } from '../models/components/elements/dynamic-input/dynamic-input';
import { t as translate } from 'i18next';
import { IAnalyticsConfig } from '../models/internal/analytics/analytics-config';
import useCurrencies from '../composables/useCurrencies';
import useTranslation from '../composables/useTranslation';
import PaymentWebhook from '../utils/payment-webhook';
import AppUtils from '../utils/app-utils';
import PaymentSuccessful from '../models/components/block/paymentSuccessful';
import { CurrencyType } from '../models/internal/currency/currency';

export default defineComponent({
  name: 'CheckoutPage',
  components: {
    'empty-cart': EmptyCart,
    loader: Loader,
    checkout: CheckoutCmp,
    success: Success
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  beforeRouteEnter(_to, _from) {
    document.body.style.overflow = '';
    document.body.style.paddingRight = '';
  },
  props: {
    isRedirectDisabled: {
      type: Boolean,
      required: false
    },
    cartAnalytics: {
      type: Object as () => IAnalyticsConfig,
      required: true
    },
    stripeKey: {
      type: Object as () => string | null,
      required: false,
      default: null
    }
  },
  setup() {
    const store = useStore();

    const paymentInfo = store.getters.successfulPaymentInformation;
    const localId = localStorage.getItem(AppConstants.APP_CART_ID);
    const cartId = Number(localId);
    const currency = useCurrencies(cartId);
    const currencyType = CurrencyType;
    const translation = useTranslation();

    return {
      cartId,
      currency,
      currencyType,
      translation,
      translate,
      paymentSuccessfulInfo: reactive(
        new PaymentSuccessful({ ...paymentInfo, textBold: true })
      ),
      checkout: reactive(
        new Checkout({
          loading: true
        })
      ) as Checkout
    };
  },
  computed: {
    isRedirectDisabledCalc(): boolean {
      // `this` points to the component instance
      return !!this.isRedirectDisabled;
    },
    cartTotalAmount(): number {
      return this.checkout.productSummaries.getSummaryTotal({
        isDiscounted: this.checkout.promoCode.isApplied
      });
    },
    isChineseCurrencyAndPaymentMethod(): boolean {
      const isCNYCurrencySelected =
        this.currency.currencyCode === this.currencyType.CNY;
      const isChineseCurrencyAndPaymentMethod =
        this.checkout.creditCardInputs.isChineseYuanPayment &&
        !isCNYCurrencySelected;
      return isChineseCurrencyAndPaymentMethod;
    }
  },
  watch: {
    'checkout.countries': {
      handler(tourSummaries) {
        if (tourSummaries.length > 0) {
          this.initializeCountries();
          this.initializeLanguages();
        }
      }
    },
    'checkout.productSummaries': {
      handler(productSummaries) {
        // if cart is empty, remove promo code if there's any
        const cart = [
          ...productSummaries.accommodationSummaries,
          ...productSummaries.tourSummaries
        ];
        if (cart.length === 0 && !this.checkout.loading) {
          this.onPromoCodeRemoved();
        }
      },
      deep: true
    },
    'checkout.promoCode': {
      handler(promoCode) {
        if (!promoCode.isLoading && promoCode.isApplied) {
          this.applyDiscounts();
        }
      },
      deep: true
    },
    'checkout.creditCardInputs.isCardPayment': {
      async handler(isCardPayment) {
        this.checkout.validate();
        if (!isCardPayment) {
          await this.checkout.stripeCheckout.initializePaymentRequest(
            this.cartTotalAmount,
            this.currency.currencyCode,
            this.payWithAppleGooglePay,
            this.onApplePayButtonClick
          );
        }
      },
      deep: true
    }
  },
  mounted(): void {
    this.initializeApp();
  },
  methods: {
    toHomeClick(): void {
      if (this.isRedirectDisabledCalc) {
        return;
      }

      toHome();
    },
    async initializeApp(): Promise<void> {
      if (this.cartId === null || this.cartId === 0) {
        this.$store.commit('updateBookedRoomsCount', 0);
        this.checkout.loading = false;
        return;
      }
      this.initializeTrackers();
      this.initializeWebhook();
      await this.initializeStripe();
      await this.getUnifiedCheckout();
      await this.getContactQuestions();
      await this.getCountriesLanguagesAsync();
    },
    async initializeStripe() {
      const paymentSettings = await this.getPaymentSettings();
      const key =
        typeof this.stripeKey === 'string' &&
        this.stripeKey.toLowerCase() === 'aa'
          ? 'stripeAAPublicKey'
          : 'stripePublicKey';
      await this.checkout.stripeCheckout.initializeStripe(paymentSettings[key]);
      await this.checkout.stripeCheckout.initializePaymentRequest(
        this.cartTotalAmount,
        this.currency.currencyCode,
        this.payWithAppleGooglePay,
        this.onApplePayButtonClick
      );
    },
    initializeWebhook() {
      const confirmCallback = AppUtils.debounce(() => {
        window.scrollTo({ top: 0 });
        this.$notify(
          Notification.success(
            'Payment received. Finalizing payment process...',
            'Payment',
            5000
          )
        );
        this.confirmStripePayment();
      }, 250);
      PaymentWebhook(this.cartId.toString(), confirmCallback);
    },
    async createPaymentIntent() {
      if (!this.checkout.cart.cartId) throw Error('No cart id.');

      /**
       * For payments that are made through
       * Alipay or wechat pay,
       * we convert the cart currency to CNY
       * if it's not in CNY. Initially, we have to update
       * the list of conversion rates to make sure
       * rates are up to date.
       */
      if (this.isChineseCurrencyAndPaymentMethod) {
        await this.currency.setCartCurrency(this.cartId, this.currencyType.CNY);
      }

      return paymentControllerService
        .createStripePaymentIntent(this.checkout.cart.cartId, {
          token: '',
          currencyCode: this.isChineseCurrencyAndPaymentMethod
            ? this.currencyType.CNY
            : this.currency.currencyCode,
          paymentMethod:
            this.checkout.creditCardInputs.selectedPaymentOption.value,
          activityBookingAnswers: this.checkout.getTourDetailsForPayment(),
          contactQuestionAnswers: this.checkout.getContactDetailsForPayment(),
          language: this.translation.lang
        })
        .then((paymentIntent) => paymentIntent)
        .catch(async (resp: GenericResponse) => {
          this.checkout.stripeCheckout.loading = false;
          /**
           * If an error occurs and the cart currency was previously converted
           * to CNY, we go back to the original cart currency
           */
          if (this.isChineseCurrencyAndPaymentMethod) {
            await this.currency.setCartCurrency(
              this.cartId,
              this.currency.currencyCode
            );
          }
          this.$notify(
            Notification.error(
              resp.message,
              'Failed to create payment intent',
              5000
            )
          );
          return null;
        })
        .finally(() => {
          this.checkout.loading = false;
        });
    },
    confirmStripePayment() {
      if (!this.cartId) return;
      this.checkout.loading = true;

      return paymentControllerService
        .makeStripePayment(this.cartId, {
          token: '',
          currencyCode: this.isChineseCurrencyAndPaymentMethod
            ? this.currencyType.CNY
            : this.currency.currencyCode,
          activityBookingAnswers: this.checkout.getTourDetailsForPayment(),
          contactQuestionAnswers: this.checkout.getContactDetailsForPayment(),
          language: this.translation.lang
        })
        .then(this.redirectToSuccessPage)
        .catch((error) => {
          if(error.statusCode === 201){
            this.redirectToSuccessPage({"text": error.message})
          }
          else{
            this.$notify(Notification.error(error.message, 'Payment', 5000)); // 5 seconds
          }
        })
        .finally(() => {
          this.checkout.loading = false;
          this.checkout.stripeCheckout.loading = false;
        });
    },
    payWithAlipay(clientSecret: string) {
      if (!this.checkout.stripeCheckout.stripeInstance) return;
      return this.checkout.stripeCheckout.stripeInstance
        .confirmAlipayPayment(
          clientSecret,
          { return_url: window.location.origin },
          {
            handleActions: false
          }
        )
        .then((result) => {
          if (result.error) {
            this.checkout.stripeCheckout.loading = false;
            const errMessage = result.error.message ?? 'Alipay payment failed';
            this.$notify(Notification.error(errMessage, 'Payment', 5000));
          }

          this.checkout.stripeCheckout.paymentQR = AppUtils.encodeTextToQrCode(
            result.paymentIntent?.next_action?.['alipay_handle_redirect']['url']
          );

          this.focusOnQrCode();
        })
        .catch((err) => this.$notify(Notification.error(err, 'Payment', 5000)))
        .finally(() => {
          this.checkout.loading = false;
        });
    },
    payWithWeChat(clientSecret: string) {
      if (!this.checkout.stripeCheckout.stripeInstance) return;
      this.checkout.stripeCheckout.stripeInstance
        .confirmWechatPayPayment(
          clientSecret,
          {
            payment_method_options: {
              wechat_pay: {
                client: 'web'
              }
            }
          },
          {
            handleActions: false // show qr manually
          }
        )
        .then((result) => {
          if (result.error) {
            this.checkout.stripeCheckout.loading = false;
            const errMessage = result.error.message ?? 'WeChat payment failed';
            this.$notify(Notification.error(errMessage, 'Payment', 5000));
          }

          this.checkout.stripeCheckout.paymentQR =
            result.paymentIntent?.next_action?.wechat_pay_display_qr_code
              ?.image_data_url ?? null;

          this.focusOnQrCode();
        })
        .catch((err) => this.$notify(Notification.error(err, 'Payment', 5000)))
        .finally(() => {
          this.checkout.loading = false;
        });
    },
    payWithCard(): void {
      if (!this.checkout.cart.cartId) return;
      paymentControllerService
        .makeStripePaymentWithoutToken(this.checkout.cart.cartId, {
          token: '',
          currencyCode: this.currency.currencyCode,
          paymentDetails: this.checkout.creditCardInputs.emitInputValues(),
          activityBookingAnswers: this.checkout.getTourDetailsForPayment(),
          contactQuestionAnswers: this.checkout.getContactDetailsForPayment(),
          language: this.translation.lang
        })
        .then(this.redirectToSuccessPage)
        .catch((resp: GenericResponse) => {
          this.$notify(Notification.error(resp.message, 'Payment', 5000)); // 5 seconds
          this.checkout.loading = false;
        })
        .finally(() => {
          this.checkout.loading = false;
        });
    },
    payWithStripeCard(clientSecret: string) {
      if (
        !this.checkout.stripeCheckout.stripeInstance ||
        !this.checkout.stripeCheckout.cardInstance
      ) {
        return;
      }

      this.checkout.stripeCheckout.stripeInstance
        .confirmCardPayment(clientSecret, {
          payment_method: { card: this.checkout.stripeCheckout.cardInstance }
        })
        .then((result) => {
          if (result.error) {
            this.checkout.stripeCheckout.loading = false;
            const errMessage =
              result.error.message ?? 'Stripe card payment failed';
            this.$notify(Notification.error(errMessage, 'Payment', 5000));
          }
        })
        .catch((err) => this.$notify(Notification.error(err, 'Payment', 5000)));
    },
    /**
     * This function is passed to `StripeCheckout.initializePaymentRequest`
     * as a callback function that is called in
     * `paymentRequest.on('paymentMethod')` event.
     * This function handles payment when browser interface
     * is closed after a payment is done.
     */
    async payWithAppleGooglePay(ev: PaymentRequestPaymentMethodEvent) {
      if (!this.checkout.validate()) {
        ev.complete('success');
        this.$notify(
          Notification.error(
            'Payment not possible. Please fill all required fields.'
          )
        );
        return;
      }

      const stripeCheckout = this.checkout.stripeCheckout;
      if (!stripeCheckout) return;

      // Create payment intent for Apple or google pay
      const paymentIntentRes = await this.createPaymentIntent();
      if (!paymentIntentRes || stripeCheckout.stripeInstance === null) return;

      const { paymentIntent, error: confirmError } =
        await stripeCheckout.stripeInstance.confirmCardPayment(
          paymentIntentRes.clientSecret,
          { payment_method: ev.paymentMethod.id },
          { handleActions: false }
        );

      if (confirmError) {
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        ev.complete('fail');
      } else {
        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.
        ev.complete('success');
        // Check if the PaymentIntent requires any actions and if so let Stripe.js
        // handle the flow. If using an API version older than "2019-02-11"
        // instead check for: `paymentIntent.status === "requires_source_action"`.
        if (paymentIntent?.status === 'requires_action') {
          // Let Stripe.js handle the rest of the payment flow.
          const { error } =
            await stripeCheckout.stripeInstance.confirmCardPayment(
              paymentIntentRes.clientSecret
            );
          if (error) {
            // The payment failed -- ask your customer for a new payment method.
            this.$notify(
              Notification.error(
                error.message ?? 'Error in payment',
                'Failed to pay using Google/Apple pay.',
                5000
              )
            );
          }
        }
      }
    },
    onApplePayButtonClick() {
      if (!this.checkout.stripeCheckout.paymentRequest) return;

      this.checkout.stripeCheckout.paymentRequest.update({
        currency: this.currency.currencyCode.toLowerCase(),
        total: {
          label: 'Total',
          amount: Math.round(this.cartTotalAmount * 100)
        }
      });
    },
    async onPayClicked() {
      try {
        const payment = this.checkout.creditCardInputs;
        // If it's card payment and
        // default card option is selected
        if (payment.isCardPayment && this.checkout.isPaymentProviderBorgun) {
          this.checkout.loading = true;
          return this.payWithCard();
        }

        this.checkout.stripeCheckout.loading = true;
        const paymentIntent = await this.createPaymentIntent();
        if (!paymentIntent) return;

        if (payment.isCardPayment && this.checkout.isPaymentProviderStripe) {
          return this.payWithStripeCard(paymentIntent.clientSecret);
        }

        if (payment.isAlipayPayment) {
          return this.payWithAlipay(paymentIntent.clientSecret);
        }

        if (payment.isWeChatPayment) {
          return this.payWithWeChat(paymentIntent.clientSecret);
        }
      } catch (error) {
        this.$notify(
          Notification.error('Failed to pay using wallet.', 'Payment', 5000)
        );
        this.checkout.stripeCheckout.loading = false;
        this.checkout.loading = false;
        console.error(error);
      }
    },
    focusOnQrCode() {
      // focus screen to qrcode
      const qrcodeContainer =
        document.getElementById('#qrcode-container')?.offsetTop;
      if (qrcodeContainer)
        window.scrollTo({ top: qrcodeContainer - 70, behavior: 'smooth' });
    },
    onNextStepClicked() {
      if (!this.checkout.productSummaries.isValid) return;
      window.scrollTo({ top: 0 });
      this.checkout.stepper.nextStep();
      tracker.checkout(
        this.cartId,
        this.checkout.productSummaries.combinedOrderSummary(),
        this.checkout.stepper.activeStep,
        this.cartTotalAmount,
        this.currency.currencyCode,
        this.checkout.promoCode.code,
        this.checkout.productSummaries.summaryDetails
      );
    },
    redirectToSuccessPage(res: Record<'text', string>) {
      this.$store.commit('updateCartMap', { cartId: null, bookings: [] });
      this.$store.commit('updateBookedRoomsCount', 0);
      this.$notify(Notification.success('Payment successful', 'Payment'));
      window.scrollTo({ top: 0 });

      const contactDetails = this.checkout.getContactDetailsForPayment();
      const successfulPaymentInfo = {
        name: contactDetails['firstName'].values[0],
        lastName: contactDetails['lastName'].values[0],
        email: contactDetails['email'].values[0],
        amountPayed: this.cartTotalAmount,
        currency: this.currency.currencyCode,
        textContent: res.text
      } as ISuccessfulPaymentInformation;

      // remove id on successful payment
      localStorage.removeItem(AppConstants.APP_CART_ID);

      if (!this.isRedirectDisabledCalc) {
        // redirects to page and force reloads to update component
        this.$store.commit(
          'uploadSuccessfulPaymentInformation',
          successfulPaymentInfo
        );
        location.href = '/success';
      } else {
        // if redirect is disabled, show the successful payment info on the widget itself
        // and dont commit anything to the store, instead update the payment object right away
        this.checkout.showSuccessSection = true;
        this.paymentSuccessfulInfo = new PaymentSuccessful({
          ...successfulPaymentInfo,
          textBold: true
        });
      }

      tracker.checkout(
        this.cartId,
        this.checkout.productSummaries.combinedOrderSummary(),
        this.checkout.stepper.activeStep,
        this.cartTotalAmount,
        this.currency.currencyCode,
        this.checkout.promoCode.code,
        this.checkout.productSummaries.summaryDetails
      );

      tracker.purchase(
        this.checkout.productSummaries.summaryDetails,
        this.cartTotalAmount,
        this.currency.currencyCode,
        this.checkout.promoCode.code
      );
    },
    onTermsConditionsOpened() {
      tracker.viewTermsAndConditions(Number(this.checkout.cart.cartId));
    },
    onProductClicked(product: AccommodationProductItem): void {
      if (!this.isRedirectDisabledCalc) {
        // TODO: test this
        return;
      }
      this.$router.push({
        name: `property`,
        params: { id: product.propertyId },
        query: product.getRouterQuery()
      });
    },
    removeUnusedPromoCode(product: TourProductItem) {
      // get list of remaining ids after product is going to be removed
      const remainingIds = this.checkout.productSummaries.tourSummaries
        .filter(({ activityId }) => activityId !== product.activityId)
        .map(({ activityId }) => activityId);
      const promoCode = this.checkout.promoCode;
      // check if the promo code applies to some tours in the cart
      // if it's not going to be used, remove promo code
      const isUsedByIncludedTours =
        remainingIds.some((id) => promoCode.isTourInDiscounts(id)) &&
        promoCode.isTypeForIncluded;

      if (!isUsedByIncludedTours || promoCode.isTypeForAll) {
        this.onPromoCodeRemoved();
      }
    },
    onRemoveProduct(product: AccommodationProductItem | TourProductItem): void {
      if (TourProductItem.isTourProduct(product)) {
        this.removeUnusedPromoCode(product);
      }

      if (TourProductItem.isTourProduct(product)) {
        this.removeTourFromCart(product);
      }

      if (AccommodationProductItem.isAccommodationProduct(product)) {
        this.removeRoomFromCart(product);
      }
      const totalPrice = TourProductItem.isTourProduct(product)
        ? product.getTotalPrice({
            isDiscounted: this.checkout.isProductDiscounted(product.id)
          })
        : product.productPrice ?? 0;
      tracker.removeProduct(
        product,
        totalPrice,
        this.currency.currencyCode,
        this.checkout.promoCode.code
      );
      this.$store.commit(
        'updateBookedRoomsCount',
        this.checkout.cartItemsCount
      );
    },
    removeTourFromCart(tour: TourProductItem) {
      this.checkout.loading = true;
      if (this.checkout.cart.cartId && tour.bookingId) {
        cartControllerService
          .deleteItemFromCart(this.checkout.cart.cartId, tour.id)
          .catch((err) => {
            if (err.statusCode >= 300 && err.statusCode <= 500) {
              this.$notify(Notification.error(err.message));
              this.checkout.loading = false;
            }
          })
          .finally(() => {
            this.checkout.productSummaries.removeTour(tour.id);

            this.$store.commit(
              'updateBookedRoomsCount',
              this.checkout.cartItemsCount
            );
            this.$notify(
              Notification.success(translate('Tour was removed from cart'))
            );
            this.checkout.loading = false;
          });
      }
    },
    removeRoomFromCart(room: AccommodationProductItem) {
      this.checkout.loading = true;
      if (this.checkout.cart.cartId) {
        return cartControllerService
          .deleteRoomFromBooking(
            this.checkout.cart.cartId,
            room.bookingId,
            room.bookingRoomId,
            this.checkout.cart.currency
          )
          .catch((err) => {
            if (err.statusCode >= 300 && err.statusCode <= 500) {
              this.$notify(Notification.error(err.message));
              this.checkout.loading = false;
            }
          })
          .finally(() => {
            this.checkout.productSummaries.removeAccommodation(
              room.id,
              room.bookingId
            );
            this.$store.commit(
              'updateBookedRoomsCount',
              this.checkout.cartItemsCount
            );
            this.$notify(
              Notification.success(this.translate('Room was removed from cart'))
            );
            this.checkout.loading = false;
          });
      }
    },
    getContactQuestions() {
      return cartControllerService
        .getContactQuestions(this.cartId)
        .then((response) => {
          if (response.length) {
            this.checkout.contactQuestions = response.map((question) => ({
              question,
              dynamicInput: DynamicInput.fromQuestion(question)
            }));
          }
        })
    },
    getUnifiedCheckout() {
      this.checkout.loading = true;
      this.checkout.productSummaries.tourSummaries = [];
      this.checkout.productSummaries.accommodationSummaries = [];
      cartControllerService
        .getUnifiedCart(this.cartId)
        .then((unifiedCart: IUnifiedCart) => {
          if (!unifiedCart.cartItems?.length) {
            throw new Error("Cart is empty");
          }

          this.checkout.cart = unifiedCart;
          this.$store.commit('updateCartMap', unifiedCart);
        })
        .catch((err) => {
          console.error(err);
          this.$notify(Notification.error('Unable to get cart information'));
          // remove id on error
          localStorage.removeItem(AppConstants.APP_CART_ID);
          this.checkout.loading = false;
        })
        .finally(() => {
          try {
            this.getPropertiesFromCart();
            this.getActivitiesFromCart();
            this.getPromoCode();
            this.$store.commit('updateBookedRoomsCount');
          } catch (err) {
            this.$store.commit('updateBookedRoomsCount');
            this.checkout.productSummaries.tourSummaries = [];
            this.checkout.productSummaries.accommodationSummaries = [];
            this.checkout.loading = false;
          }
        });
    },
    onPromoCodeApplied() {
      this.checkout.promoCode.isLoading = true;
      return cartControllerService
        .addPromoCode(
          this.$store.getters.cartMap.cartId,
          this.checkout.promoCode.code
        )
        .catch((err) => {
          // Promise is not fulfilling due to null message so it goes in catch.
          if (err.statusCode >= 200 && err.statusCode < 300) {
            this.checkout.promoCode.isApplied = true;
            this.checkout.promoCode.errorMessage = null;
          } else {
            console.warn(err);
            this.checkout.promoCode.code = '';
            this.checkout.promoCode.isValid = false;
            this.checkout.promoCode.errorMessage = err.message;
          }
        })
        .finally(() => {
          this.checkout.promoCode.isLoading = false;
        });
    },
    onPromoCodeRemoved() {
      this.checkout.promoCode.isLoading = true;
      return cartControllerService
        .removePromoCode(this.$store.getters.cartMap.cartId)
        .then(() => {
          this.checkout.promoCode.isApplied = false;
          this.checkout.promoCode.code = '';
          this.checkout.productSummaries.tourSummaries.forEach(
            (tour) => (tour.isDiscountApplied = false)
          );
        })
        .catch((err) => {
          if (err.statusCode >= 200 && err.statusCode < 300) {
            this.checkout.promoCode.isApplied = true;
          } else {
            console.warn(err);
          }
        })
        .finally(() => {
          this.checkout.promoCode.isLoading = false;
        });
    },
    getPromoCode() {
      const cartMap = this.$store.getters.cartMap;
      const promoCodeArgs = cartMap.promoCode
        ? {
            ...cartMap.promoCode,
            isApplied: !!cartMap.promoCode.code
          }
        : null;
      this.checkout.promoCode = new PromoCode(promoCodeArgs);
    },
    applyDiscounts() {
      cartControllerService
        .getUnifiedCart(this.$store.getters.cartMap.cartId)
        .then((unifiedCart: IUnifiedCart) => {
          if (unifiedCart.promoCode) {
            const newCode = new PromoCode(unifiedCart.promoCode);
            const activityItemsFromResponse = getActivityItems(
              unifiedCart
            ) as IActivityItem[];

            this.checkout.productSummaries.tourSummaries.forEach((tour) => {
              const itemFromResponse = activityItemsFromResponse.find(
                (activity) => Number(activity.id) === tour.id
              );
              if (!itemFromResponse) return;

              if (itemFromResponse.isDiscountApplied) {
                tour.isDiscountApplied = itemFromResponse.isDiscountApplied;
                tour.ratePrices = itemFromResponse.activity.ratePrices;
                tour.participants = itemFromResponse.participantTypes;
              }
            });
            tracker.addPromoCode(
              this.checkout.productSummaries.combinedOrderSummary(),
              newCode.code,
              this.checkout.productSummaries.getSummaryTotal({
                isDiscounted: true
              }),
              this.$store.getters.cartMap.currency
            );
          }
        });
    },
    getActivitiesFromCart() {
      const cartMap = this.$store.getters.cartMap;
      if (cartIsEmpty(cartMap)) {
        throw 'Cart is empty';
      }

      const { cartItems } = this.checkout.cart;
      if (cartItems === null) return;

      const activityItems = getActivityItems(cartMap) as IActivityItem[];
      const tourItems = activityItems.map((activity) =>
        TourProductItem.fromCart(activity)
      );

      this.checkout.productSummaries.tourSummaries = tourItems;
    },
    async getPropertiesFromCart(): Promise<void> {
      const cartMap = this.$store.getters.cartMap;
      if (cartIsEmpty(cartMap)) {
        throw 'Cart is empty';
      }

      const accommodationItems = getAccommodationItems(
        cartMap
      ) as IAccommodationItem[];
      if (accommodationItems.length === 0) {
        this.checkout.loading = false;
        this.checkout.productSummaries.accommodationSummaries = [];
        return;
      }

      accommodationItems.forEach((item: IAccommodationItem) => {
        if (!item.bookings) return;

        item.bookings.forEach((booking: IBooking) => {
          const searchDto = PropertySearch.fromCart(
            booking,
            this.$store.getters.inputsModel,
            this.$store.getters.selectedCurrency
          );

          if (!searchDto) {
            this.$notify(
              Notification.warning(this.translate('Something went wrong'))
            );
            return;
          }
          searchController
            .searchForPropertiesAsync(searchDto)
            .then(async (res: PropertySearchResults) => {
              const availableProperty = (res.availableProperties.find(
                (x) => x.id == booking.propertyId
              ) as AvailableProperty) || { rooms: [] };

              booking.bookingRooms.forEach((br: IBookingRoomResponse) => {
                const room = availableProperty.rooms.find(
                  (r) => r.id === br.roomId
                );
                if (!room) {
                  this.$notify(
                    Notification.error(
                      `Room ${br.roomTypeCode} is no longer available.`
                    )
                  );
                  return;
                }

                if (!room.images.some((x) => !!x)) {
                  room.images = availableProperty.images;
                }

                this.checkout.productSummaries.accommodationSummaries.push(
                  AccommodationProductItem.fromIRoom(
                    booking.id,
                    room,
                    this.$store.getters.selectedCurrency,
                    this.$store.getters.propertyMap,
                    br,
                    availableProperty.name
                  )
                );
              });
            })
            .catch((err) => {
              console.warn(err);

              if (err.statusCode) {
                if (err.statusCode === 404) {
                  this.$notify(Notification.warning(err.message));
                } else {
                  this.$notify(Notification.error(err.message));
                }
              } else {
                this.$notify(Notification.error(err.message));
              }

              this.checkout.productSummaries.accommodationSummaries.length = 0; // clear array
            })
            .finally(() => {
              this.checkout.loading = false;
            });
        });
      });
    },
    initializeTrackers() {
      const config = this.cartAnalytics ?? {
        analyticsCurrency: 'USD',
        bingTrackingCurrency: 'USD',
        facebookPixelCurrency: 'USD',
        facebookDisabled: false,
        googleAnalyticsDisabled: false
      };
      tracker.init(config);
    },
    setCountryOptions(question: DynamicInput) {
      if (
        DynamicInput.isInstanceDropdown(question) &&
        question.dataFormat === 'COUNTRY'
      ) {
        question.input.options = SelectOption.fromCountries(
          this.checkout.countries
        );
        question.input.filterOptions();
        question.input.sortOptions();
      }
      return question;
    },
    setLanguageOptions(question: DynamicInput) {
      if (
        DynamicInput.isInstanceDropdown(question) &&
        question.dataFormat === 'LANGUAGE'
      ) {
        question.input.options = SelectOption.fromLanguages(
          this.checkout.languages
        );
        question.input.filterOptions();
        question.input.sortOptions();
      }
      return question;
    },
    initializeCountries() {
      // init in passengerQuestions
      this.checkout.productSummaries.tourSummaries.forEach((tour) => {
        const countriesQuestions = tour.passengerQuestions.filter((item) =>
          item.questionInputList.some(
            ({ dynamicInput }) => dynamicInput.dataFormat === DataFormat.COUNTRY
          )
        );
        if (countriesQuestions.length > 0) {
          tour.passengerQuestions.forEach(({ questionInputList }) =>
            questionInputList.forEach(({ dynamicInput }) =>
              this.setCountryOptions(dynamicInput)
            )
          );
        }
      });
      this.checkout.contactQuestions
        .filter(
          (question) => question.dynamicInput.dataFormat === DataFormat.COUNTRY
        )
        .forEach(({ dynamicInput }) => this.setCountryOptions(dynamicInput));
    },
    initializeLanguages() {
      // init in passengerQuestions
      this.checkout.productSummaries.tourSummaries.forEach((tour) => {
        const languagesQuestions = tour.passengerQuestions.filter((item) =>
          item.questionInputList.some(
            ({ dynamicInput }) =>
              dynamicInput.dataFormat === DataFormat.LANGUAGE
          )
        );
        if (languagesQuestions.length > 0) {
          tour.passengerQuestions.forEach(({ questionInputList }) =>
            questionInputList.forEach(({ dynamicInput }) =>
              this.setLanguageOptions(dynamicInput)
            )
          );
        }
      });
      this.checkout.contactQuestions
        .filter(
          (question) => question.dynamicInput.dataFormat === DataFormat.LANGUAGE
        )
        .forEach(({ dynamicInput }) => this.setLanguageOptions(dynamicInput));
    },
    getPaymentSettings() {
      return paymentControllerService
        .getPaymentSettings()
        .then((res: Record<string, string>) => res)
        .catch((err) => this.$notify(Notification.error(err.message)));
    },
    getCountriesLanguagesAsync(): Promise<void> {
      return countriesControllerService
        .getCountriesLanguagesAsync()
        .then((res) => {
          this.checkout.countries = res.countries.countryList;
          this.checkout.languages = res.languages.languageList;
        })
        .catch((err) => this.$notify(Notification.error(err.message)));
    }
  }
});
