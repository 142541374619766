import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-36a617e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_location_multiselect = _resolveComponent("location-multiselect")!
  const _component_calendar_modal = _resolveComponent("calendar-modal")!
  const _component_accommodation_modal = _resolveComponent("accommodation-modal")!
  const _component_loader = _resolveComponent("loader")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.headStyles),
      style: _normalizeStyle(_ctx.styleObject.backgroundColor)
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_location_multiselect, {
          inputLabelColor: 'white',
          placeholder: 'Find a place',
          options: _ctx.propertySearchPanelInputs.locationInput.options,
          optionColor: '#336699',
          "selection-required": true,
          onOptionClick: _cache[0] || (_cache[0] = 
            (_id, _selected, _type) =>
              _ctx.propertySearchPanelInputs.locationInput.toggleSelection(
                _id,
                _selected,
                _type
              )
          )
        }, null, 8, ["options"])
      ]),
      _createVNode(_component_calendar_modal, {
        class: "col",
        calendarModal: _ctx.propertySearchPanelInputs.calendarModal,
        onClick: _ctx.onCalendarClicked
      }, null, 8, ["calendarModal", "onClick"]),
      _createVNode(_component_accommodation_modal, {
        class: "col",
        accommodationModalModel: _ctx.propertySearchPanelInputs.accommodationModal
      }, null, 8, ["accommodationModalModel"]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          id: "submit-button",
          class: _normalizeClass([
            `
              btn 
              g__adventures-btn--${_ctx.themePrefix}
              property-search-button--${_ctx.themePrefix}
              w-100`
          ]),
          type: "submit",
          disabled: !_ctx.propertySearchPanelInputs.formValid,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.submitForm($event)))
        }, " Search ", 10, _hoisted_3)
      ]),
      (_ctx.propertySearchPanelInputs.loading)
        ? (_openBlock(), _createBlock(_component_loader, {
            key: 0,
            overlayProp: true
          }))
        : _createCommentVNode("", true)
    ], 6)
  ]))
}