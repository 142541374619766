export enum QuestionDataType {
  SHORT_TEXT = 'SHORT_TEXT',
  LONG_TEXT = 'LONG_TEXT',
  PHONE_NUMBER = 'PHONE_NUMBER',
  COUNTRY = 'COUNTRY',
  OPTIONS = 'OPTIONS',
  DATE = 'DATE',
  DATE_AND_TIME = 'DATE_AND_TIME',
  BOOLEAN = 'BOOLEAN',
  INT = 'INT',
  DOUBLE = 'DOUBLE'
}
