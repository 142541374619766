import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-219032ef"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "hstack gap-3" }
const _hoisted_2 = { class: "g__text--helper" }
const _hoisted_3 = { class: "form-check form-switch" }
const _hoisted_4 = ["checked"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.switchModel.label), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("input", {
        id: "switchCmp",
        class: "form-check-input custom px-0",
        type: "checkbox",
        role: "switch",
        checked: _ctx.switchModel.selected,
        onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.switchModel.toggle()))
      }, null, 40, _hoisted_4)
    ])
  ]))
}