import BaseHttpClientService from '../base-http-client-service';
import ICreatePaymentIntentDto from '../../models/internal/payment/create-payment-intent-dto';
import IMakePaymentDto from '../../models/internal/payment/make-payment-dto';
import { ICreatePaymentIntentResponse } from '../../models/stripe/create-payment-intent-response';

interface IPaymentControllerService {
  createStripePaymentIntent(
    cartId: number,
    createPaymentIntentDto: ICreatePaymentIntentDto &
      Omit<IMakePaymentDto, 'paymentDetails'>
  ): Promise<ICreatePaymentIntentResponse>;
  makeStripePayment(
    cartId: number,
    makePaymentDto: Omit<IMakePaymentDto, 'paymentDetails'>
  ): Promise<Record<'text', string>>;
  makeStripePaymentWithoutToken(
    cartId: number,
    makePaymentDto: IMakePaymentDto
  ): Promise<Record<'text', string>>;
  getPaymentSettings(): Promise<Record<string, string>>;
}

class PaymentControllerService
  extends BaseHttpClientService
  implements IPaymentControllerService
{
  getPaymentSettings(): Promise<any> {
    return this.httpClient
      .get('api/payment/v1/get-payment-settings')
      .then((resp) => this.prepareResponse(resp).content);
  }

  createStripePaymentIntent(
    cartId: number,
    createPaymentIntentDto: ICreatePaymentIntentDto & IMakePaymentDto
  ): Promise<ICreatePaymentIntentResponse> {
    return this.httpClient
      .post(
        `api/payment/v1/stripe/create-payment-intent/${cartId}`,
        createPaymentIntentDto
      )
      .then((resp) => this.prepareResponse(resp).content);
  }

  makeStripePayment(
    cartId: number,
    makePaymentDto: IMakePaymentDto
  ): Promise<Record<'text', string>> {
    return this.httpClient
      .post(`api/payment/v1/stripe/make-payment/${cartId}`, makePaymentDto)
      .then((resp) => this.prepareResponse(resp).content);
  }

  makeStripePaymentWithoutToken(
    cartId: number,
    makePaymentDto: IMakePaymentDto
  ): Promise<Record<'text', string>> {
    return this.httpClient
      .post(
        `api/payment/v1/stripe/make-payment-without-token/${cartId}`,
        makePaymentDto
      )
      .then((resp) => this.prepareResponse(resp).content);
  }
}

export default new PaymentControllerService() as IPaymentControllerService;
