
import { IDynamicBookingQuestion } from '../../../models/internal/dynamic-booking-question/dynamic-booking-question';
import { reactive, defineComponent } from 'vue';
import DynamicInputCmp from '../../../components/elements/dynamic-input/dynamic-input.vue';
export default defineComponent({
  components: {
    'dynamic-input': DynamicInputCmp
  },
  props: {
    customerDetailsModel: {
      type: Object as () => IDynamicBookingQuestion[],
      required: true
    }
  },
  emits: ['inputs-changed'],
  setup(props) {
    return {
      customerDetails: reactive(props.customerDetailsModel)
    };
  }
});
