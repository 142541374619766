import { IPromoCode } from '../../../models/internal/promo-code/promo-code';
import { PromoCodeType } from '../../../models/internal/promo-code/promo-code-types';
import { Nullable } from '../../../models/generics/generics';

interface IPromoCodeArgs extends IPromoCode {
  errorMessage?: Nullable<string>;
  isLoading?: boolean;
  isApplied?: boolean;
  isValid?: boolean;
}

export default class PromoCode implements IPromoCodeArgs {
  code: string;
  applyForExtras: boolean;
  applyForPickups: boolean;
  bookingDateFrom: string;
  bookingDateTo: string;
  discounts: IPromoCode['discounts'];
  promoCodeType: PromoCodeType;

  errorMessage: Nullable<string> = null;
  isLoading = false;
  isApplied = false;
  isValid = true;

  constructor(data?: IPromoCodeArgs) {
    this.code = data?.code ?? '';
    this.applyForExtras = data?.applyForExtras ?? false;
    this.applyForPickups = data?.applyForPickups ?? false;
    this.bookingDateFrom = data?.bookingDateFrom ?? '';
    this.bookingDateTo = data?.bookingDateTo ?? '';
    this.discounts = data?.discounts ?? [];
    this.promoCodeType = data?.promoCodeType ?? PromoCodeType.ALL;

    this.isLoading = data?.isLoading ?? this.isLoading;
    this.errorMessage = data?.errorMessage ?? this.errorMessage;
    this.isApplied = data?.isApplied ?? this.isApplied;
    this.isValid = data?.isValid ?? this.isValid;
  }

  get isTypeForAll(): boolean {
    return this.promoCodeType === PromoCodeType.ALL;
  }

  get isTypeForIncluded(): boolean {
    return this.promoCodeType === PromoCodeType.INCLUDE;
  }

  get isTypeForExcluded(): boolean {
    return this.promoCodeType === PromoCodeType.EXCLUDE;
  }

  isTourInDiscounts(tourId: number): boolean {
    const tourIds =
      this.discounts.length > 0
        ? this.discounts[0].tours.map(({ id }) => id)
        : [];

    return tourIds.includes(tourId);
  }
}
