import DropdownSelect from '../dropdown-select/dropdown-select';
import TextInput from '../text-input/text-input';
import Checkbox from '../checkbox/checkbox';
import PhoneInput from '../phone-input/phone-input';
import NumericInput from '../numeric-input/numeric-input';
import DateInput from '../date-input/date-input';
import TextArea from '../text-area/text-area';
import Dropdown from '../dropdown/dropdown';

import {
  DynamicInputAssertType,
  Nullable
} from '../../../../models/generics/generics';
import { IBookingQuestion } from '../../../../models/internal/booking/booking-question';
import { QuestionDataType } from '../../../../models/internal/question-data-type/question-data-type';
import { DynamicInputClasses } from '../../../../models/internal/dynamic-input-classes/dynamic-input-classes';
import { SelectOption } from '../options/select-option';
import { DataFormat } from '../../../../models/enums/data-format';

type DynamicInputValue = Nullable<
  string | number | string[] | boolean | undefined
>;

export interface IDynamicInput {
  id: string;
  group: Nullable<string>;
  input: DynamicInputClasses;
  dataType: Nullable<QuestionDataType>;
  dataFormat: Nullable<string>;
  selectMultiple: boolean;
  isTriggerDependent: boolean;
  triggerIds: Record<'extra' | 'rate' | 'category', number[]>;
  dynamicValue: DynamicInputValue;
}

export class DynamicInput implements IDynamicInput {
  id: string;
  input: DynamicInputClasses;
  group: Nullable<string>;
  dataType: Nullable<QuestionDataType>;
  dataFormat: Nullable<string>;
  selectMultiple: boolean;
  isTriggerDependent: boolean;
  triggerIds: Record<'extra' | 'rate' | 'category', number[]>;

  constructor(data: IDynamicInput) {
    this.id = data.id;
    this.input = data.input;
    this.group = data.group;
    this.dataType = data.dataType;
    this.dataFormat = data.dataFormat;
    this.selectMultiple = data.selectMultiple;
    this.isTriggerDependent = data.isTriggerDependent;
    this.triggerIds = data.triggerIds;
  }

  get dynamicValue(): DynamicInputValue {
    const input = this.input;
    if (DynamicInput.isDataTypeBoolean(this.dataType)) {
      return (input as Checkbox).selected.toString();
    }

    if (DynamicInput.isDataTypeDate(this.dataType)) {
      if (this.dataType === QuestionDataType.DATE_AND_TIME) {
        return (input as DateInput).dateTimeValue;
      }
      return (input as DateInput).dateValue;
    }

    if (DynamicInput.isDataTypeOptions(this.dataType)) {
      if (this.selectMultiple) {
        const selectedOptions = (input as DropdownSelect).options
          .filter(({ selected }) => selected)
          .map((option) => option.value);

        if (selectedOptions.length) return selectedOptions;
        return null;
      }
      return (input as DropdownSelect).selectedValue;
    }

    if (
      DynamicInput.isDataTypeTextArea(this.dataType) ||
      DynamicInput.isDataTypeTextInput(this.dataType) ||
      DynamicInput.isDataTypeNumberInput(this.dataType) ||
      DynamicInput.isDataTypePhone(this.dataType)
    ) {
      return (input as TextArea | TextInput | NumericInput | PhoneInput).value;
    }
  }

  /** dataFormat booleans */

  static isDataFormatCountry(dataFormat: Nullable<string>): boolean {
    if (!dataFormat) return false;
    return dataFormat === DataFormat.COUNTRY;
  }

  /** dataType booleans */

  static isDataTypeTextInput(dataType: DynamicInput['dataType']) {
    if (!dataType) return false;
    return dataType === QuestionDataType.SHORT_TEXT;
  }

  static isDataTypeTextArea(dataType: DynamicInput['dataType']) {
    if (!dataType) return false;
    return dataType === QuestionDataType.LONG_TEXT;
  }

  static isDataTypeNumberInput(dataType: DynamicInput['dataType']) {
    if (!dataType) return false;
    const numberDataTypes = [QuestionDataType.INT, QuestionDataType.DOUBLE];

    return numberDataTypes.includes(dataType);
  }

  static isDataTypePhone(dataType: DynamicInput['dataType']) {
    if (!dataType) return false;
    return dataType === QuestionDataType.PHONE_NUMBER;
  }

  static isDataTypeDate(dataType: DynamicInput['dataType']) {
    if (!dataType) return false;
    const datesDataTypes = [
      QuestionDataType.DATE,
      QuestionDataType.DATE_AND_TIME
    ];
    return datesDataTypes.includes(dataType);
  }

  static isDataTypeBoolean(dataType: DynamicInput['dataType']) {
    return dataType === QuestionDataType.BOOLEAN;
  }

  static isDataTypeOptions(dataType: DynamicInput['dataType']) {
    return dataType === QuestionDataType.OPTIONS;
  }

  /** instance booleans */

  static isInstanceTextInput(
    input: DynamicInput
  ): input is DynamicInputAssertType<TextInput> {
    return this.isDataTypeTextInput(input.dataType);
  }

  static isInstanceTextArea(
    input: DynamicInput
  ): input is DynamicInputAssertType<TextArea> {
    return this.isDataTypeTextArea(input.dataType);
  }

  static isInstanceNumberInput(
    input: DynamicInput
  ): input is DynamicInputAssertType<NumericInput> {
    return this.isDataTypeNumberInput(input.dataType);
  }

  static isInstanceDateInput(
    input: DynamicInput
  ): input is DynamicInputAssertType<DateInput> {
    return this.isDataTypeDate(input.dataType);
  }

  static isInstanceCheckbox(
    input: DynamicInput
  ): input is DynamicInputAssertType<Checkbox> {
    return this.isDataTypeBoolean(input.dataType);
  }

  static isInstancePhoneInput(
    input: DynamicInput
  ): input is DynamicInputAssertType<PhoneInput> {
    return this.isDataTypePhone(input.dataType);
  }

  static isInstanceDropdown(
    input: DynamicInput
  ): input is DynamicInputAssertType<DropdownSelect> {
    return this.isDataTypeOptions(input.dataType);
  }

  static isInstanceSearchDropdown(
    input: DynamicInput
  ): input is DynamicInputAssertType<Dropdown> {
    return this.isDataTypeOptions(input.dataType);
  }

  static fromQuestion(question: IBookingQuestion): DynamicInput {
    let input;
    const { dataType } = question;

    if (DynamicInput.isDataTypeTextInput(dataType)) {
      input = new TextInput({
        withValidation: question.required,
        defaultMessage: 'This field is required',
        label: question.label,
        placeholder: question.placeholder ?? '',
        required: question.required,
        value: question.defaultValue ?? '',
        maxCharacters: 100
      });
    }

    if (DynamicInput.isDataTypeTextArea(dataType)) {
      input = new TextArea({
        withValidation: question.required,
        defaultMessage: 'This field is required',
        label: question.label,
        placeholder: question.placeholder ?? '',
        required: question.required,
        value: question.defaultValue ?? '',
        maxCharacters: 200
      });
    }

    if (DynamicInput.isDataTypeNumberInput(dataType)) {
      input = new NumericInput(
        {
          label: question.label,
          allowDecimal: dataType === QuestionDataType.DOUBLE,
          withInput: false
        },
        {
          withValidation: question.required,
          validationMessage: 'This field is required',
          minValue: 0,
          maxValue: 99,
          allowOnlyValid: true
        }
      );
    }

    if (DynamicInput.isDataTypePhone(dataType)) {
      input = new PhoneInput({
        label: question.label,
        withValidation: true,
        required: question.required,
        placeholder: question.placeholder ?? '',
        maxCharacters: 255
      });
    }

    if (DynamicInput.isDataTypeDate(dataType)) {
      const isDateTime = dataType === QuestionDataType.DATE_AND_TIME;
      input = new DateInput({
        isDateTime,
        label: question.label,
        withValidation: true,
        required: question.required,
        placeholder: question.placeholder || 'YYYY/MM/DD',
        validationMessage: 'This field is required'
      });
    }

    if (DynamicInput.isDataTypeBoolean(dataType)) {
      input = new Checkbox({
        label: question.label
      });
    }

    if (DynamicInput.isDataTypeOptions(dataType)) {
      const base = {
        id: question.id,
        label: question.label,
        options: question.options?.map(
          (option) =>
            new SelectOption({
              ...option
            })
        ),
        showPlaceholder: !question.defaultValue,
        placeholder: '',
        withValidation: question.required,
        withInput: false,
        validationMessage: 'Select at least one',
        value: question.defaultValue ?? '',
        required: question.required
      };

      if (question.dataFormat === DataFormat.COUNTRY) {
        input = new DropdownSelect({
          ...base,
          placeholder: 'Select country',
          withInput: true
        });
      } else {
        input = new DropdownSelect(base);
      }
    }

    const extraIds = question.extraTriggers?.length
      ? question.extraTriggers
          ?.map(({ value }) => value)
          .filter((value): value is number => !!value)
      : [];

    const categoryIds = question.pricingCategoryTriggers?.length
      ? question.pricingCategoryTriggers
          ?.map(({ value }) => value)
          .filter((value): value is number => !!value)
      : [];

    const rateIds = question.rateTriggers?.length
      ? question.rateTriggers
          ?.map(({ value }) => value)
          .filter((value): value is number => !!value)
      : [];

    return new DynamicInput({
      id: question.id,
      input: input,
      dynamicValue: null,
      group: question.group,
      dataType: question.dataType,
      dataFormat: question.dataFormat,
      selectMultiple: question.selectMultiple,
      isTriggerDependent:
        question.pricingCategoryTriggerSelection === 'SELECTED_ONLY' ||
        question.extraTriggerSelection === 'SELECTED_ONLY' ||
        question.rateTriggerSelection === 'SELECTED_ONLY',
      triggerIds: {
        extra: extraIds,
        category: categoryIds,
        rate: rateIds
      }
    });
  }
}
