
import { defineComponent, reactive } from 'vue';
import SearchPanel from '../search-panels/property-search-panel.vue';
import DropdownMultiselect from '../../elements/dropdown-multiselect/dropdown-multiselect.vue';
import Loader from '../../elements/loader/loader.vue';
import Sidebar from '../../../models/components/block/sidebar';
import SearchProperties from '../../../models/internal/global/search-properties';
import Dropdown from '../../elements/dropdown/dropdown.vue';
import { SidebarConstants } from '../../constants/sidebarConstants';
import { SelectOption } from '../../../models/components/elements/options/select-option';

export default defineComponent({
  name: 'SidebarCmp',
  components: {
    multiselect: DropdownMultiselect,
    loader: Loader,
    'search-panel': SearchPanel,
    dropdown: Dropdown
  },
  props: {
    sidebar: {
      type: Object as () => Sidebar,
      required: true
    }
  },
  emits: [
    'inputs-changed',
    'search-clicked',
    'sidebar-filters-changed',
    'sorting-changed'
  ],
  setup(props) {
    return {
      sidebarModel: reactive(props.sidebar) as Sidebar
    };
  },
  methods: {
    onInputChange(searchProperties: SearchProperties) {
      this.$emit('inputs-changed', searchProperties);
    },
    updateSelected(objectKey: string, id: number, selected: boolean) {
      const options = this.sidebarModel.sidebarFilters[objectKey];
      this.sidebarModel.sidebarFilters[objectKey] = options.map((option) =>
        option.id !== id ? option : ({ ...option, selected } as SelectOption)
      );
      this.onSidebarFiltersChanged();
    },
    onSidebarFiltersChanged() {
      this.$emit('sidebar-filters-changed');
    },
    onSearchCLicked() {
      this.$emit('search-clicked');
    },
    onSortingChanged() {
      this.$emit('sorting-changed');
    },
    getPropertyTypeConst() {
      return SidebarConstants.PropertyType;
    },
    getPropertyAmenityConst() {
      return SidebarConstants.PropertyAmenity;
    }
  }
});
