import NumberUtils from '../../../utils/number-utils';
import {
  ISummaryDetails,
  ISummaryDetailsExtra
} from '../../../models/internal/summary-details/summary-details';
import AccommodationProductItem from '../standalone/detailed-product-item/accommodation-product-item';
import TourProductItem from '../standalone/detailed-product-item/tour-product-item';

interface IProductSummaryArgs {
  accommodationSummaries?: AccommodationProductItem[];
  tourSummaries?: TourProductItem[];
}
export default class ProductSummary {
  accommodationSummaries: AccommodationProductItem[] = [];
  tourSummaries: TourProductItem[] = [];

  constructor(data?: IProductSummaryArgs) {
    this.accommodationSummaries =
      data?.accommodationSummaries ?? this.accommodationSummaries;
    this.tourSummaries = data?.tourSummaries ?? this.tourSummaries;
  }

  get isValid() {
    if (!this.tourSummaries.length) return true;
    const tourValidations = this.tourSummaries.map(
      (tour) => tour.areSectionsValid
    );
    return tourValidations.every(Boolean);
  }

  get summaryDetails(): ISummaryDetails[] {
    return this.combinedOrderSummary().map((product) => {
      let extras: ISummaryDetailsExtra[] = [];
      let locations: ISummaryDetailsExtra[] = [];
      let participants: ISummaryDetailsExtra[] = [];

      if (TourProductItem.isTourProduct(product)) {
        extras =
          product.selectedExtras?.map((selected) => ({
            id: selected.extra.extraId,
            name: `${selected.extra.bookedTitle} ${
              selected.extra.pricingCategoryId
                ? `(${
                    product.getParticipant(selected.extra.pricingCategoryId)
                      ?.title
                  })`
                : ''
            }`,
            unitPrice: selected.extra.priceBase.base.amount,
            quantity: selected.quantity,
            totalPrice:
              selected.extra.priceBase.base.amount * selected.quantity,
            isDiscounted: false, // extras and locations cannot be discounted for now.
            discountedUnitPrice: selected.extra.priceBase.discounted.amount,
            discountedTotalPrice:
              selected.extra.priceBase.discounted.amount * selected.quantity
          })) ?? [];

        participants = product.participants
          .filter(({ quantity }) => !!quantity)
          .map((participant) => ({
            id: product.activityId,
            name: participant.title,
            unitPrice: participant.priceBase.base.amount,
            quantity: participant.quantity,
            totalPrice:
              participant.priceBase.base.amount * participant.quantity,
            isDiscounted: product.isDiscountApplied,
            discountedUnitPrice: participant.priceBase.discounted.amount,
            discountedTotalPrice:
              participant.priceBase.discounted.amount * participant.quantity
          }));

        if (product.hasSelectedPickupLocation) {
          const pickupFees = this.getPickupFeeSummary(product);
          locations = [...locations, ...pickupFees];
        }

        if (product.hasSelectedDropoffLocation) {
          const dropoffFees = this.getDropoffFeeSummary(product);
          locations = [...locations, ...dropoffFees];
        }
      }
      return {
        id: product.id,
        name: product.productName,
        date: TourProductItem.isTourProduct(product)
          ? product.startMomentFormatted
          : product.formattedDateRange,
        currencyType: product.currencyType,
        unitPrice: TourProductItem.isTourProduct(product)
          ? product.getTotalPrice()
          : product.productPrice ?? 0,
        currency: product.currencyType,
        details: [...participants, ...extras, ...locations],
        isDiscounted:
          TourProductItem.isTourProduct(product) && product.isDiscountApplied,
        discountedUnitPrice: TourProductItem.isTourProduct(product)
          ? product.getTotalPrice({ isDiscounted: product.isDiscountApplied })
          : product.productPrice ?? 0
      };
    });
  }

  combinedOrderSummary(): Array<AccommodationProductItem | TourProductItem> {
    return [...this.accommodationSummaries, ...this.tourSummaries];
  }

  getDropoffFeeSummary(product: TourProductItem) {
    const prices = product.getDropoffLocationPrices() ?? [];

    // Add instance manually since free locations
    // do not have items inside prices.
    if (!prices.length && product.hasSelectedDropoffLocation) {
      prices.push([
        {
          amount: { amount: 0, amountISK: 0 },
          categoryId: null,
          id: -1
        }
      ]);
    }

    return prices.flatMap<ISummaryDetailsExtra>((price) =>
      price.map((priceItem) => {
        const participantCategory = product.getParticipant(
          priceItem.categoryId
        );
        const categoryTitle = participantCategory?.title ?? 'All guests';
        const name = `Drop-off fee (${categoryTitle})`;
        const quantity = participantCategory?.quantity ?? 1;

        return {
          id: priceItem.categoryId
            ? Number(priceItem.categoryId)
            : product.activityId,
          name: name,
          quantity: quantity,
          unitPrice: priceItem.amount.amount,
          totalPrice: priceItem.amount.amount * quantity
        };
      })
    );
  }

  getPickupFeeSummary(product: TourProductItem) {
    const prices = product.getPickupLocationPrices() ?? [];

    // Add instance manually since free locations
    // do not have items inside prices.
    if (!prices.length && product.hasSelectedPickupLocation) {
      prices.push([
        {
          amount: { amount: 0, amountISK: 0 },
          categoryId: null,
          id: -1
        }
      ]);
    }

    return prices.flatMap<ISummaryDetailsExtra>((price) =>
      price.map((priceItem) => {
        const participantCategory = product.getParticipant(
          priceItem.categoryId
        );
        const categoryTitle = participantCategory?.title ?? 'All guests';
        const tourName = `Pick-up fee - (${categoryTitle})`;
        const bundleName = `Day ${
          product.getBundleDetails(priceItem.id)?.dayNo
        } ${tourName}`;
        const name = product.isFromBundle ? bundleName : tourName;
        const quantity = participantCategory?.quantity ?? 1;

        return {
          id: priceItem.categoryId
            ? Number(priceItem.categoryId)
            : product.activityId,
          name: name,
          quantity: quantity,
          unitPrice: priceItem.amount.amount,
          totalPrice: priceItem.amount.amount * quantity
        };
      })
    );
  }

  getSummaryTotal({ isDiscounted } = { isDiscounted: false }) {
    const isCartDiscounted = isDiscounted;
    const total = this.combinedOrderSummary().reduce((total, product) => {
      const defaultProductPrice = product.productPrice ?? 0;
      const price = TourProductItem.isTourProduct(product)
        ? product.getTotalPrice({
            isDiscounted: isCartDiscounted && Boolean(product.isDiscountApplied)
          })
        : defaultProductPrice;

      return total + price;
    }, 0);
    return NumberUtils.round(total);
  }

  areTourSectionsValid() {
    return this.tourSummaries.every((tour) =>
      Object.values(tour.isValid).every(Boolean)
    );
  }

  removeAccommodation(id: number, bookingId: number): void {
    const accommodationIdx = this.accommodationSummaries.findIndex(
      (item) => item.id === id && item.bookingId === bookingId
    );
    this.accommodationSummaries.splice(accommodationIdx, 1);
  }

  removeTour(id: number): void {
    this.tourSummaries = this.tourSummaries.filter((tour) => tour.id !== id);
  }
}
