import {CancelTokenSource} from "axios";

export type cancellationTokens = 'getPropertyRoomPrices' | 'getPropertiesResult'


export class ApiCancellation {
    private _cancellationToken: CancelTokenSource | null;

    constructor() {
        this._cancellationToken = null;
    }

    tokenIsSet(): boolean {
        return this._cancellationToken !== null;
    }

    setRunning(token: CancelTokenSource): CancelTokenSource {
        this._cancellationToken = token;
        
        return this._cancellationToken;
    }

    cancel(): void {
        if (this._cancellationToken != null)
            this._cancellationToken.cancel();
    }
}

export const cancellationTokensModel: Record<cancellationTokens, ApiCancellation> = {
    getPropertyRoomPrices: new ApiCancellation(),
    getPropertiesResult: new ApiCancellation(),
}