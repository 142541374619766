
import AppUtils from "../../../utils/app-utils";
import {reactive, defineComponent, ref } from "vue";
import IconWithText from '../../../models/components/elements/icon-with-text/icon-with-text'

export default defineComponent({
  name: 'IconWithText',
  props: {
    iconWithTextModel: {
      type: Object as () => IconWithText,
      required: true
    }
  },
  setup(props) {
    return {
      iconWithText: reactive(props.iconWithTextModel),
      isDomainIS: ref(AppUtils.isAdventuresIS())
    }
  }
})
