import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "modal-container position-relative vstack gap-2" }
const _hoisted_2 = {
  key: 0,
  class: "g__text--label"
}
const _hoisted_3 = {
  class: "w-100 position-relative d-flex justify-content-start btn border",
  "data-bs-toggle": "dropdown",
  "data-bs-auto-close": "outside"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  class: "text-center m-0"
}
const _hoisted_6 = { class: "dropdown-menu custom p-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!!_ctx.buttonLabel)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.buttonLabel), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (!!_ctx.$slots.buttonContent)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "buttonContent")
          ]))
        : (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.buttonName), 1))
    ]),
    _createElementVNode("div", _hoisted_6, [
      _renderSlot(_ctx.$slots, "modalContent")
    ])
  ]))
}