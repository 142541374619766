import { Language } from './../../travia/language';
import { PopupStatus } from '../../enums/popup-status';
import { CurrencyType } from '../../internal/currency/currency';
import IInputValidation from '../../validation/input-validation';
import PopupModel from '../standalone/popup/popup';
import CreditCardInputs from './credit-card-inputs';
import CustomerDetails from './customer-details';
import ProductSummary from './product-summary';
import EmptyCartDetails from './empty-cart';
import Stepper from './stepper';
import PromoCode from './promo-code';
import IconsWithTextCard from '../standalone/icons-with-text-card/icons-with-text-card';
import IconWithText from '../elements/icon-with-text/icon-with-text';
import UserConsent from './user-consent';
import Country from '../../../models/travia/country';
import IUnifiedCart, {
  UnifiedCartItems
} from '../../../models/internal/cart/unified-cart';
import TourProductItem from '../standalone/detailed-product-item/tour-product-item';
import StripeCheckout from '../../../models/internal/stripe-checkout/stripe-checkout';
import { IDynamicBookingQuestion } from '../../../models/internal/dynamic-booking-question/dynamic-booking-question';
import {
  DynamicInput,
  IDynamicInput
} from '../elements/dynamic-input/dynamic-input';
import { IQuestionAnswer } from '../../../models/internal/payment/question-answer';
import { IActivityBookingDetail } from '../../../models/internal/payment/activity-booking-detail';
import { PaymentProvider } from '../../../models/enums/payment-provider';

export interface ICheckoutArgs {
  emptyCartDetails?: EmptyCartDetails;
  contactQuestions?: IDynamicBookingQuestion[];
  customerDetails?: CustomerDetails;
  creditCardInputs?: CreditCardInputs;
  productSummaries?: ProductSummary;
  promoCode?: PromoCode;
  popup?: PopupModel;
  isValid?: boolean;
  withValidation?: boolean;
  validationMessage?: string;
  loading: boolean;
  reassuranceCard?: IconsWithTextCard;
  stripeCheckout?: StripeCheckout;
  countries?: Country[];
  languages?: Language[];
  paymentProvider?: PaymentProvider;
}
export default class Checkout implements IInputValidation {
  emptyCartDetails: EmptyCartDetails;

  stepper: Stepper;
  promoCode: PromoCode;
  contactQuestions: IDynamicBookingQuestion[];
  customerDetails: CustomerDetails;
  creditCardInputs: CreditCardInputs;
  productSummaries: ProductSummary;
  popup: PopupModel;
  cart: IUnifiedCart;
  reassuranceCard: IconsWithTextCard;
  userConsent: UserConsent;
  stripeCheckout: StripeCheckout;
  countries: Country[];
  languages: Language[];
  paymentProvider: PaymentProvider;

  showTermsAndConditionsModal: boolean;
  showCancellationPolicyModal: boolean;

  showSuccessSection = false;
  paymentFailed = false;
  isValid: boolean;
  withValidation: boolean;
  validationMessage: string;

  loading = false;

  constructor(data?: ICheckoutArgs) {
    this.loading = data?.loading ?? this.loading;

    this.stepper = new Stepper({
      activeStep: 1,
      steps: [
        {
          number: 1,
          label: 'View Cart Summary',
          isDone: false
        },
        {
          number: 2,
          label: 'Confirm Payment',
          isDone: false
        }
      ]
    });
    this.promoCode = data?.promoCode ?? new PromoCode();
    this.emptyCartDetails =
      data?.emptyCartDetails ??
      new EmptyCartDetails({
        buttonTitle: 'Continue shopping',
        message:
          'You may only proceed to checkout if you have added something to your cart.',
        title: 'Shopping cart is empty',
        onButtonClick: () => {
          location.href = `${location.protocol}//${location.host}`;
        }
      });

    this.cart = {
      cartId: null,
      currency: CurrencyType.ISK,
      totalAmount: 0,
      cartItems: [] as UnifiedCartItems,
      promoCode: null,
      createDate: null,
      lastUpdateDate: null
    };
    this.contactQuestions = data?.contactQuestions ?? [];
    this.customerDetails = data?.customerDetails ?? new CustomerDetails();
    this.creditCardInputs = data?.creditCardInputs ?? new CreditCardInputs();
    this.productSummaries = data?.productSummaries ?? new ProductSummary();
    this.userConsent = new UserConsent();
    this.countries = data?.countries ?? [];
    this.languages = data?.languages ?? [];
    this.showTermsAndConditionsModal = false;
    this.showCancellationPolicyModal = false;

    this.stripeCheckout = new StripeCheckout();
    this.paymentProvider = data?.paymentProvider ?? PaymentProvider.STRIPE;

    this.popup =
      data?.popup ??
      new PopupModel({
        text: 'payment unsuccessful',
        status: PopupStatus.Error,
        isVisible: false
      });
    this.isValid = data?.isValid ?? true;
    this.withValidation = data?.withValidation ?? true;
    this.validationMessage =
      data?.validationMessage ?? 'All fields must be filled out correctly';

    this.reassuranceCard =
      data?.reassuranceCard ??
      new IconsWithTextCard({
        title: 'Book with confidence',
        iconsWithText: [
          new IconWithText({
            title: 'Lowest price guarantee',
            content: "Find it cheaper? We'll refund the difference",
            code: 'WALLET',
            regular: false
          }),
          new IconWithText({
            title: '24/7 Global Support',
            content: 'Get the answers you need, when you need them',
            code: 'HEADSET',
            regular: false
          }),
          new IconWithText({
            title: 'Book securely',
            content: 'We use SSL encryption to keep your data secure',
            code: 'LOCK',
            regular: false
          }),
          new IconWithText({
            title: 'Call to book',
            content: 'Book here or call us at +1234567890',
            code: 'TELEPHONE',
            regular: false
          })
        ]
      });
  }

  get cartItemsCount() {
    return this.productSummaries.combinedOrderSummary().length;
  }

  get hasAcceptedTermsAndPolicies() {
    return (
      this.userConsent.hasTermsConsent &&
      this.userConsent.hasCancellationPolicyConsent
    );
  }

  get hasItemsFromBundle() {
    return this.productSummaries.tourSummaries.some(
      (tour) => tour.isFromBundle
    );
  }

  get isPaymentProviderBorgun() {
    return this.paymentProvider === PaymentProvider.BORGUN;
  }

  get isPaymentProviderStripe() {
    return this.paymentProvider === PaymentProvider.STRIPE;
  }

  /**
   * Checks if product is generally discounted by
   * checking if a promo code is applied
   * and the specific product is applicable to the discount.
   * @param productId
   */
  isProductDiscounted(productId: number): boolean {
    const product = this.productSummaries
      .combinedOrderSummary()
      .find((product) => product.id === productId);

    if (!product) return false;
    // only check discounted products that are tours for now
    return (
      this.promoCode.isApplied &&
      TourProductItem.isTourProduct(product) &&
      Boolean(product.isDiscountApplied)
    );
  }

  validateCustomerDetails() {
    const validations = this.contactQuestions.map(({ dynamicInput }) =>
      dynamicInput.input.validate()
    );
    return validations.every(Boolean);
  }

  validateBookingDetails() {
    const validations = this.productSummaries.tourSummaries.flatMap((tour) =>
      tour.bookingQuestions.map(({ dynamicInput }) =>
        dynamicInput.input.validate()
      )
    );
    return validations.every(Boolean);
  }

  validate(): boolean {
    if (!this.withValidation) {
      this.isValid = true;
      return this.isValid;
    }

    // if we are not using card for payments,
    // disregard validation for credit card inputs.
    const isUsingCard = this.creditCardInputs.isCardPayment;

    const isValidWalletPayment = !isUsingCard;
    const isBorgunPayment = isUsingCard && this.isPaymentProviderBorgun;
    const isStripeCardPayment = isUsingCard && this.isPaymentProviderStripe;

    const isValidBorgunPayment =
      isBorgunPayment && this.creditCardInputs.isValid;
    const isValidStripePayment =
      isStripeCardPayment &&
      !this.stripeCheckout.isCardEmpty &&
      this.stripeCheckout.isCardValid;

    // If payment provider is borgun
    // form feilds are visible and are used for input.
    // Otherwise, Stripe payment method using stripe
    // card element which handles validation automatically.
    if (isBorgunPayment) {
      this.creditCardInputs.validate();
    }

    if (isStripeCardPayment) {
      this.stripeCheckout.validateCard();
    }

    const isPaymentValid =
      isValidWalletPayment || isValidStripePayment || isValidBorgunPayment;

    this.isValid =
      this.validateBookingDetails() &&
      this.validateCustomerDetails() &&
      isPaymentValid;

    return this.isValid;
  }

  getTourDetailsForPayment(): IActivityBookingDetail[] {
    return this.productSummaries.tourSummaries.map((tour) =>
      tour.getDetailsForPayment()
    );
  }

  getContactDetailsForPayment(): { [key: string]: IQuestionAnswer } {
    return this.contactQuestions
      .filter(({ dynamicInput }) => Checkout.isInputValueValid(dynamicInput))
      .reduce((prev, { question, dynamicInput }) => {
        const detail = {
          [question.id]: {
            questionId: null,
            group: dynamicInput.group,
            values: [String(dynamicInput.dynamicValue)]
          }
        };
        return { ...prev, ...detail };
      }, {});
  }

  static isInputValueValid(dynamicInput: IDynamicInput): boolean {
    const isCheckbox = DynamicInput.isDataTypeBoolean(dynamicInput.dataType);
    const isEmpty =
      dynamicInput.dynamicValue === '' || dynamicInput.dynamicValue === null;

    return (isCheckbox || (!isCheckbox && !isEmpty));
  }

  static extractDetailsForPayment(dynamicInput: DynamicInput): IQuestionAnswer {
    let values;
    // specifying null as some value can
    // be of type boolean, so we want to accommodate
    // true or false values
    if (dynamicInput.dynamicValue === null) {
      values = [];
    } else {
      // dynamic value can be array of string
      // when the dynamic is multiselect
      values = Array.isArray(dynamicInput.dynamicValue)
        ? dynamicInput.dynamicValue
        : [dynamicInput.dynamicValue];
    }

    return {
      questionId: dynamicInput.id,
      group: dynamicInput.group,
      values: values.map(x => String(x))
    };
  }
}
