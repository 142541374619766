import { CurrencyType } from '../models/internal/currency/currency';

export default class StringFormatUtils {
  public static startWordsWithUpperCase(text: string) {
    //change every words first letter to upper case if it's not undefined
    const convertedWords = text.split(' ').map((word) => {
      if (!word) {
        return '';
      }

      return word[0].toUpperCase() + word.slice(1);
    });

    return convertedWords.join(' ');
  }

  public static formatCurrency(amount: number, currency: CurrencyType) {
    const comma = ',';
    const period = '.';

    /**
     * If amount is in ISK, add commas to number,
     * otherwise, parse amount to it's dedicated
     * currency.
     */
    const parsedAmount =
      currency === CurrencyType.ISK
        ? Intl.NumberFormat('en-US')
        : Intl.NumberFormat(navigator.language, {
            style: 'currency',
            currency: currency,
            currencyDisplay: 'code',
            useGrouping: true
          });

    const formattedAmount = parsedAmount
      .format(amount)
      .replace(currency, '')
      .trim();

    /**
     * If number is not an integer,
     * retain decimal separators and replace
     * thousand separators with spaces.
     */
    if (!Number.isInteger(amount) || Number(amount) === 0) {
      const decimalSeparator =
        formattedAmount.split('')[formattedAmount.length - 3];

      return formattedAmount
        .replace(decimalSeparator === period ? comma : period, ' ')
        .concat(` ${currency}`);
    }

    /**
     * If number is an integer,
     * replace thousand separators, which can be commas or periods,
     * with spaces.
     */
    return formattedAmount
      .replace(/,/g, ' ')
      .replace(/\./g, ' ')
      .concat(` ${currency}`);
  }
}
