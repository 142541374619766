
import { defineComponent, reactive, ref } from '@vue/runtime-core';
import AppUtils from '../../../utils/app-utils';
import EmptyCart from '../../../models/components/block/empty-cart';

export default defineComponent({
  name: 'EmptyCart',
  props: {
    emptyCartDetails: {
      type: Object as () => EmptyCart,
      required: true
    }
  },
  setup(props) {
    return {
      details: reactive(props.emptyCartDetails),
      themePrefix: ref(AppUtils.getFallbackDomain())
    };
  }
});
