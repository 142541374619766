interface String {
  removeDiacriticToLower(): string;
  splice(ind: number, rem: number, str: string): string;
}

String.prototype.splice = function (idx, rem, str) {
  return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem));
};

String.prototype.removeDiacriticToLower = function (): string {
  const customLetters = [
    {
      letters: /(&#100;|&#9427;|&#65348;|&#7691;|&#271;|&#7693;|&#7697;|&#7699;|&#7695;|&#273;|&#396;|&#598;|&#599;|&#42874;|&#240;|[\u0064\u24D3\uFF44\u1E0B\u010F\u1E0D\u1E11\u1E13\u1E0F\u0111\u018C\u0256\u0257\uA77A\u00F0])/g,
      base: 'd'
    },
    {
      letters: /(&#68;|&#9401;|&#65316;|&#7690;|&#270;|&#7692;|&#7696;|&#7698;|&#7694;|&#272;|&#395;|&#394;|&#393;|&#42873;|&#208;|[\u0044\u24B9\uFF24\u1E0A\u010E\u1E0C\u1E10\u1E12\u1E0E\u0110\u018B\u018A\u0189\uA779\u00D0])/g,
      base: 'D'
    }
  ];

  let normalized = String(this)
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '');
  customLetters.forEach(
    (ltr) => (normalized = normalized.replace(ltr.letters, ltr.base))
  );
  return normalized.toLowerCase();
};
