import { ILocationConfig } from './../../../internal/activity/activity-rates';
import { IBundleAvailabilityItem } from './../../../internal/bundle/bundle-availability-item';
import moment from 'moment';
import { t as $translate } from 'i18next';
import ProductItem, { IProductItem } from '../product/product-item';
import TourExtra, {
  TourExtraCategory
} from '../../../internal/extras/tour-extras';
import { BundleLocationSelectionType } from './../../../enums/bundle-location-selection-type';
import DropdownSelect from '../../elements/dropdown-select/dropdown-select';
import { SelectOption } from '../../elements/options/select-option';
import { Nullable } from '../../../../models/generics/generics';
import { CurrencyType } from '../../../../models/internal/currency/currency';
import { IParticipantType } from '../../../../models/internal/participants/participants';
import { IActivityItem } from '../../../../models/internal/activity/activity-item';
import { IActivityLocation } from '../../../../models/internal/activity/activity-location';
import {
  IActivityRatePrices,
  RatePrice
} from '../../../../models/internal/activity/activity-rate-prices';
import { LocationType } from '../../../../models/internal/location-type/location-type';
import AppConstants from '../../../../constants/app-constants';
import TextInput from '../../elements/text-input/text-input';
import {
  BookingType,
  IActivity,
  MeetingType
} from '../../../../models/internal/activity/activity';
import { DynamicInput } from '../../elements/dynamic-input/dynamic-input';
import NumberUtils from '../../../../utils/number-utils';
import {
  IActivityRate,
  PricingType,
  SelectionType
} from '../../../../models/internal/activity/activity-rates';
import { DynamicInputClasses } from '../../../../models/internal/dynamic-input-classes/dynamic-input-classes';
import { IDynamicBookingQuestion } from '../../../../models/internal/dynamic-booking-question/dynamic-booking-question';
import Checkout from '../../block/checkout';
import { IPassengerQuestionsAnswer } from '../../../../models/internal/payment/passenger-question-answer';
import { IQuestionAnswer } from '../../../../models/internal/payment/question-answer';
import { IExtrasAnswer } from '../../../../models/internal/payment/extras-answer';
import { IActivityBookingDetail } from '../../../../models/internal/payment/activity-booking-detail';
import { InventorySystem } from '../../../../models/enums/inventory-system';
import { IBundleAvailability } from '../../../../models/internal/bundle/bundle-availability';
import { IBundleItem } from '../../../../models/internal/bundle/bundle-item';
import { ILocationSelection } from '../../../../models/internal/payment/location-selection';

interface ISelectedTourExtra {
  extra: TourExtra;
  quantity: number;
}

export interface IPassengerQuestion {
  participant: IParticipantType;
  questionInputList: IDynamicBookingQuestion[];
  isValid: boolean;
}

export interface ILocationInput {
  tourIds: Nullable<number>[];
  dropdownInput: Nullable<DropdownSelect>;
  customInput: TextInput;
  isIncludedInPrice: boolean;
}

interface ITourProductItemArgs extends IProductItem {
  activityId: number;
  inventorySystem: string;
  bookingId: number;
  bookingType: BookingType;
  bundleAvailability: Nullable<IBundleAvailability>;
  startDate: Date;
  endDate: Date;
  participants: IParticipantType[];
  dropoffLocation: ILocationInput[];
  pickupLocation: ILocationInput[];
  rates: IActivityRate[];
  ratePrices: IActivityRatePrices;
  passengerQuestions: IPassengerQuestion[];
  bookingQuestions: IDynamicBookingQuestion[];
  productDiscountedPrice: number;
  isDiscountApplied: boolean;
  customPickupValue?: string;
  customDropoffValue?: string;
  selectedExtras?: ISelectedTourExtra[];
  extras?: TourExtra[];
}

export default class TourProductItem extends ProductItem {
  activityId: number;
  bookingId: number;
  bookingType: BookingType;
  bundleAvailability: Nullable<IBundleAvailability>;
  startDate: Date;
  endDate: Date;
  participants: IParticipantType[];
  inventorySystem: string;
  isDiscountApplied: boolean;
  dropoffLocation: ILocationInput[];
  pickupLocation: ILocationInput[];
  rates: IActivityRate[];
  ratePrices: IActivityRatePrices;
  passengerQuestions: IPassengerQuestion[];
  bookingQuestions: IDynamicBookingQuestion[];
  productDiscountedPrice: number;
  customPickupValue?: string;
  customDropoffValue?: string;
  extras?: TourExtra[];
  selectedExtras?: ISelectedTourExtra[];

  isValid: Record<
    'extras' | 'accommodationExtras' | 'locations' | 'passengerDetails',
    boolean
  > = {
    extras: true,
    accommodationExtras: true,
    locations: true,
    passengerDetails: true
  };

  private dateFormat: string;

  constructor(data: ITourProductItemArgs) {
    super(data);
    this.activityId = data.activityId;
    this.inventorySystem = data.inventorySystem;
    this.bundleAvailability = data.bundleAvailability;
    this.startDate = data.startDate;
    this.endDate = data.endDate;
    this.bookingId = data.bookingId;
    this.bookingType = data.bookingType;
    this.endDate = data.endDate;
    this.participants = data.participants;
    this.productDiscountedPrice = data.productDiscountedPrice;
    this.isDiscountApplied = data.isDiscountApplied;
    this.dropoffLocation = data.dropoffLocation;
    this.pickupLocation = data.pickupLocation;
    this.rates = data.rates;
    this.ratePrices = data.ratePrices;
    this.extras = data.extras;
    this.passengerQuestions = data.passengerQuestions;
    this.bookingQuestions = data.bookingQuestions;

    this.dateFormat =
      this.bookingType === BookingType.DATE
        ? 'MMM DD YYYY'
        : BookingType.DATE_AND_TIME
        ? 'MMM DD YYYY h:mm A'
        : '';

    this.selectedExtras =
      (data.selectedExtras && data.selectedExtras.length > 0) || !this.extras
        ? []
        : this.extras
            .filter(
              (extra) => extra.extraConfigs.preselected && extra.unitCount > 0
            )
            .map((extra) => ({ quantity: extra.unitCount, extra }));

    if (this.extras) {
      this.extras.forEach((extra) => {
        if (
          this.selectedExtras?.some(
            (selected) => selected.extra.extraId === extra.extraId
          )
        ) {
          extra.input.value = extra.unitCount;
          Array.from({ length: extra.unitCount }).map((_, idx) =>
            extra.updateDropdownMapping(idx + 1)
          );
        }
      });
    }
  }

  static isTourProduct(product): product is TourProductItem {
    return product instanceof TourProductItem;
  }

  private isTriggeredByExtra(question: DynamicInput) {
    if (!this.selectedExtras) return false;
    const selectedExtrasIds = this.selectedExtras.map(
      ({ extra }) => extra.extraId
    );

    return question.triggerIds.extra.some((id) =>
      selectedExtrasIds.includes(Number(id))
    );
  }

  private isTriggeredByCategory(question: DynamicInput, categoryId?: number) {
    // specifying categoryId when coming from specific passenger related questions
    if (categoryId) {
      return question.triggerIds.category.includes(categoryId);
    }

    // if it's coming from booking questions, categoryId is optional and can be acquired
    // generally from the participants.
    return this.participants
      .filter(({ quantity }) => !!quantity)
      .some(({ id }) => question.triggerIds.category.includes(id));
  }

  private isTriggeredByRate(question: DynamicInput) {
    return question.triggerIds.rate.includes(this.ratePrices.activityRateId);
  }

  get isFromBundle(): boolean {
    return this.inventorySystem === InventorySystem.BUNDLE;
  }

  get startMomentFormatted(): string {
    return moment.utc(this.startDate).format(this.dateFormat);
  }

  get endMomentFormatted(): string {
    return moment.utc(this.endDate).format(this.dateFormat);
  }

  get numberOfGuests(): number {
    return this.participants.reduce(
      (total, participant) => participant.quantity + total,
      0
    );
  }

  get activityRate(): Nullable<IActivityRate> {
    return (
      this.rates.find(({ id }) => this.ratePrices.activityRateId === id) ?? null
    );
  }

  get areSectionsValid() {
    this.validateAccommodationExtras();
    this.validateExtras();
    this.validateLocations();
    this.validatePassengerDetails();

    return Object.values(this.isValid).every(Boolean);
  }

  get shouldShowPickups(): boolean {
    return (
      !!this.pickupLocation &&
      !!this.pickupLocation.length &&
      !!this.activityRate &&
      this.activityRate.pickupConfigs[0].selectionType !==
        SelectionType.UNAVAILABLE
    );
  }

  get shouldShowDropoffs(): boolean {
    return (
      !!this.dropoffLocation &&
      !!this.dropoffLocation.length &&
      !!this.activityRate &&
      this.activityRate.dropoffConfigs[0].selectionType !==
        SelectionType.UNAVAILABLE
    );
  }

  get isPricedPerBooking(): boolean {
    return !!this.ratePrices.pricePerBooking.amount;
  }

  get hasSelectedPickupLocation(): boolean {
    return this.pickupLocation.some(
      ({ dropdownInput }) => !!dropdownInput?.selectedOption
    );
  }

  get hasSelectedDropoffLocation(): boolean {
    return this.dropoffLocation.some(
      ({ dropdownInput }) => !!dropdownInput?.selectedOption
    );
  }

  isCustomLocation(locationDropdown: Nullable<DropdownSelect>) {
    return locationDropdown?.selectedValue === LocationType.CUSTOM;
  }

  isBookingQuestionTriggered(bookingQuestion: DynamicInput) {
    if (!bookingQuestion.isTriggerDependent) return true;
    return (
      this.isTriggeredByExtra(bookingQuestion) ||
      this.isTriggeredByCategory(bookingQuestion) ||
      this.isTriggeredByRate(bookingQuestion)
    );
  }

  isPassengerQuestionTriggered(
    passengerQuestion: DynamicInput,
    categoryId: number
  ) {
    if (!passengerQuestion.isTriggerDependent) return true;

    return (
      this.isTriggeredByExtra(passengerQuestion) ||
      this.isTriggeredByCategory(passengerQuestion, categoryId) ||
      this.isTriggeredByRate(passengerQuestion)
    );
  }

  getBundleDetails(tourId: number): Nullable<IBundleAvailabilityItem> {
    if (!this.isFromBundle) return null;
    const bundleItem =
      this.bundleAvailability?.bundle?.items.find(
        (bundleItem) => bundleItem.id === tourId
      ) ?? null;

    return bundleItem;
  }

  getAccommodationExtras(): TourExtra[] | null {
    if (!this.extras) return null;
    return this.extras?.filter(
      (data) => data.category === TourExtraCategory.TOUR_ACCOMMODATION
    );
  }

  getIncludedExtras(): TourExtra[] | null {
    if (!this.extras) return null;
    return this.extras
      .filter(
        ({ extraConfigs: { includedInPrice }, isHidden }) =>
          includedInPrice && !isHidden
      )
      .sort((extraA) =>
        extraA.category === TourExtraCategory.TOUR_ACCOMMODATION ? -1 : 1
      );
  }

  getOtherExtras(): TourExtra[] | null {
    if (!this.extras) return null;
    return this.extras.filter(
      ({ extraConfigs: { includedInPrice }, isHidden }) =>
        !includedInPrice && !isHidden
    );
  }

  getSingleRoomFeeExtra(): TourExtra | null {
    if (!this.extras) return null;
    return (
      this.extras.find(
        (extra) =>
          extra.extraConfigs.flags !== null &&
          extra.extraConfigs.flags.includes(AppConstants.FLAGS.SINGLE_ROOM_FEE)
      ) ?? null
    );
  }

  getParticipant(categoryId: Nullable<number>): Nullable<IParticipantType> {
    const category = this.participants.find(({ id }) => categoryId === id);
    if (!category) return null;

    return category;
  }

  getDropoffLocationPrices(): Nullable<RatePrice[][]> {
    const { dropoffPricePerCategoryUnit, dropoffPrice } = this.ratePrices;
    const dropoffLocationPrices = [
      ...dropoffPrice,
      ...dropoffPricePerCategoryUnit
    ];

    // When locations are coming from bundles,
    // IDs of the locations are based on the bundleId.
    // But when locations are coming from non-bundle items (regular tours),
    // IDs of the locations are based on the placeId of the location.
    const prices = this.dropoffLocation.map((location) => {
      return dropoffLocationPrices.filter(
        ({ id }) => id === location.dropdownInput?.selectedOption?.id
      );
    });

    const tourPrice = this.hasSelectedDropoffLocation
      ? dropoffLocationPrices
      : [];

    if (!prices.length) return null;
    return this.isFromBundle ? prices : [tourPrice];
  }

  getPickupLocationPrices(): Nullable<RatePrice[][]> {
    const { pickupPrice, pickupPricePerCategoryUnit } = this.ratePrices;
    const pickupLocationPrices = [
      ...pickupPrice,
      ...pickupPricePerCategoryUnit
    ];

    // When locations are coming from bundles,
    // IDs of the locations are based on the bundleId.
    // But when locations are coming from non-bundle items (regular tours),
    // IDs of the locations are based on the placeId of the location.
    const prices = this.pickupLocation.map((location) => {
      return pickupLocationPrices.filter(({ id }) =>
        location.tourIds.includes(id)
      );
    });

    if (!prices.length) return null;

    const tourPrice = this.hasSelectedPickupLocation
      ? pickupLocationPrices
      : [];

    return this.isFromBundle ? prices : [tourPrice];
  }

  getLocationTotalFee(locationType: LocationType): number {
    const prices =
      locationType === LocationType.PICK_UP
        ? this.getPickupLocationPrices()
        : this.getDropoffLocationPrices();
    if (!prices) return 0;

    return prices.reduce((total, price) => {
      return (
        total +
        price.reduce((subTotal, priceItem) => {
          const quantity = this.getParticipant(priceItem.categoryId)?.quantity;
          if (typeof quantity === 'number') {
            return quantity * priceItem.amount.amount + subTotal;
          } else {
            return priceItem.amount.amount + subTotal;
          }
        }, 0)
      );
    }, 0);
  }

  getTotalPrice({ isDiscounted } = { isDiscounted: false }) {
    const priceSource = !isDiscounted ? 'base' : 'discounted';
    // pick up and drop off location fees
    const locationFee =
      this.getLocationTotalFee(LocationType.PICK_UP) +
      this.getLocationTotalFee(LocationType.DROP_OFF);

    const participantFee = this.participants.reduce((total, participant) => {
      return participant.quantity > 0
        ? participant.quantity * participant.priceBase[priceSource].amount +
            total
        : total;
    }, 0);

    // included and optiona extras total price
    const extrasFee =
      this.selectedExtras && this.selectedExtras.length
        ? this.selectedExtras.reduce(
            (total, extra) =>
              extra.quantity > 0
                ? extra.quantity * extra.extra.priceBase[priceSource].amount +
                  total
                : total,
            0
          )
        : 0;

    const basePrice = this.isPricedPerBooking
      ? this.ratePrices.pricePerBooking.amount
      : locationFee + participantFee;

    const rawTotal = basePrice + extrasFee;
    return NumberUtils.round(rawTotal);
  }

  selectExtra(selectedExtra: ISelectedTourExtra) {
    if (!this.selectedExtras) return null;

    const selectedExtraIdx = this.selectedExtras.findIndex(
      (extra) =>
        extra.extra.extraId === selectedExtra.extra.extraId &&
        extra.extra.pricingCategoryId === selectedExtra.extra.pricingCategoryId
    );

    /** If not existing yet, add to list */
    if (selectedExtraIdx === -1 && selectedExtra.quantity > 0) {
      return this.selectedExtras.push(selectedExtra);
    }
    /** Remove from list if quantity is zero */
    if (selectedExtra.quantity === 0) {
      return this.selectedExtras.splice(selectedExtraIdx, 1);
    }
    /** Replace existing object if id exists. */
    return this.selectedExtras.splice(selectedExtraIdx, 1, selectedExtra);
  }

  validateAccommodationExtras() {
    if (!this.selectedExtras) return;
    if (!this.getAccommodationExtras()?.length) return;
    // select all accommodation extras
    const selectedAccommodationExtras = this.selectedExtras.filter(
      (data) => data.extra.category === TourExtraCategory.TOUR_ACCOMMODATION
    );
    // select all rooms and check if there is at least one room selected
    const rooms = selectedAccommodationExtras.flatMap(({ extra }) =>
      extra.questionFields.map(({ input }) => input as DropdownSelect)
    );
    this.isValid.accommodationExtras = rooms.some(
      (room) => Number(room.selectedValue) > 0
    );
  }

  validateExtras() {
    if (!this.selectedExtras) return;
    // select all non-accommodation extras
    const extras = this.selectedExtras.filter(
      ({ extra }) => extra.category === TourExtraCategory.TOUR_MISC
    );
    // retrieve the input fields of non-accommodation extras
    const inputs = extras.flatMap(({ extra }) =>
      extra.questionFields.map((field) => field.input)
    );

    this.isValid.extras = inputs
      .map((input) => input.validate())
      .every(Boolean);
  }

  getExtraDetailsForPayment(): Array<IExtrasAnswer> {
    if (!this.selectedExtras) return [];
    return this.selectedExtras.reduce((extrasAnswer, { extra, quantity }) => {
      // check if questionFields has dedicated questions
      // as some extras do not trigger a question
      const hasQuestions = extra.questionFields && extra.questionFields.length;
      // Only get the fields with valid input
      const validFields = extra.questionFields.filter((input) => {
        const isAccommodation =
          extra.category === TourExtraCategory.TOUR_ACCOMMODATION;
        const isNonAccommodationAndValid =
          extra.category === TourExtraCategory.TOUR_MISC &&
          Checkout.isInputValueValid(input);

        // accept invalid inputs for accommodation extras
        // BE requires all question field values even if it's zero
        if (isAccommodation || isNonAccommodationAndValid) return input;
      });
      // extras answer obj
      extrasAnswer.push({
        bookingId: extra.bookingId,
        extraId: extra.extraId,
        unitCount: quantity,
        pricingCategoryId: extra.pricingCategoryId,
        bookingAnswersGroups: hasQuestions
          ? validFields.map((field) => [
              Checkout.extractDetailsForPayment(field)
            ])
          : []
      });

      return extrasAnswer;
    }, [] as Array<IExtrasAnswer>);
  }

  getBookingQuestionsDetailsForPayment(): Array<IQuestionAnswer> {
    return this.bookingQuestions
      .filter(({ dynamicInput }) => {
        const isValid = Checkout.isInputValueValid(dynamicInput);
        const isTriggered = this.isBookingQuestionTriggered(dynamicInput);

        if (isValid && isTriggered) {
          return dynamicInput;
        }
      })
      .map(({ dynamicInput }) =>
        Checkout.extractDetailsForPayment(dynamicInput)
      );
  }

  getLocationDetailsForPayment(): Array<ILocationSelection> {
    const pickupLocationSelections = this.pickupLocation
      .filter((loc) => !!loc.dropdownInput?.selectedOption?.id)
      .flatMap((location) => {
        const baseDetails = {
          locationType: LocationType.PICK_UP,
          locationDescription: this.isCustomLocation(location.dropdownInput)
            ? location.customInput.value.toString()
            : null,
          locationId: Number(location.dropdownInput?.selectedOption?.id),
          bundleItemId: null
        };
        // if location is not from a bundle
        // return the base location details right away

        if (!this.isFromBundle) return [baseDetails];

        // otherwise, loop through the list of bundleItemIds
        // which are inside the tourIds array.
        // when the selectionType of the location for the bundle
        // is independent, include the id on the details otherwise
        // let it be null.

        return location.tourIds.flatMap((tourId) => {
          if (!tourId) return [];
          const details = this.getBundleDetails(tourId);
          const isIndependent =
            details?.pickupSelectionType ===
            BundleLocationSelectionType.INDEPENDENT;

          return {
            ...baseDetails,
            bundleItemId: isIndependent ? tourId : null
          };
        });
      });

    const dropoffLocationSelections = this.dropoffLocation
      .filter((loc) => !!loc.dropdownInput?.selectedOption?.id)
      .flatMap((location) => {
        const baseDetails = {
          locationType: LocationType.DROP_OFF,
          locationDescription: this.isCustomLocation(location.dropdownInput)
            ? location.customInput.value.toString()
            : null,
          locationId: Number(location.dropdownInput?.selectedOption?.id),
          bundleItemId: null
        };

        // if location is not from a bundle
        // return the base location details right away

        if (!this.isFromBundle) return [baseDetails];

        // otherwise, loop through the list of bundleItemIds
        // which are inside the tourIds array.
        // when the selectionType of the location for the bundle
        // is independent, include the id on the details otherwise
        // let it be null.

        return location.tourIds.flatMap((tourId) => {
          if (!tourId) return [];
          const details = this.getBundleDetails(tourId);
          const isIndependent =
            details?.dropoffSelectionType ===
            BundleLocationSelectionType.INDEPENDENT;

          return {
            ...baseDetails,
            bundleItemId: isIndependent ? tourId : null
          };
        });
      });
    return [...pickupLocationSelections, ...dropoffLocationSelections];
  }

  getPassengerQuestionsDetailsForPayment(): Array<IPassengerQuestionsAnswer> {
    return this.passengerQuestions
      .filter(({ questionInputList }) =>
        questionInputList.some(
          ({ dynamicInput }) =>
            Checkout.isInputValueValid(dynamicInput) ||
            dynamicInput.input.withValidation
        )
      )
      .reduce((passengerQuestionAnswer, { questionInputList, participant }) => {
        // As per structure of the passengerQuestionAnswer payload for payment,
        // answers are divided per participant category id.

        // With the list of answers, check if paticipant id
        // is already in list
        const categoryIdx = passengerQuestionAnswer.findIndex(
          (prev) => prev.bookingId === participant.id
        );
        const answers = {
          bookingAnswers: questionInputList
            .filter(({ dynamicInput }) => {
              const isValid = Checkout.isInputValueValid(dynamicInput);
              const isTriggered = this.isPassengerQuestionTriggered(
                dynamicInput,
                participant.id
              );

              if (isValid && isTriggered) return dynamicInput;
            })
            .map(({ dynamicInput }) =>
              Checkout.extractDetailsForPayment(dynamicInput)
            )
        };
        if (categoryIdx > -1) {
          // If it is already in the list,
          // just answer to the `passengerBookingAnswers` array.
          passengerQuestionAnswer[categoryIdx].passengerBookingAnswers.push(
            answers
          );
        } else {
          // If not, create a new object with the participant id.
          passengerQuestionAnswer.push({
            bookingId: participant.id,
            passengerBookingAnswers: [answers]
          });
        }
        return passengerQuestionAnswer;
      }, [] as Array<IPassengerQuestionsAnswer>);
  }

  getDetailsForPayment(): IActivityBookingDetail {
    return {
      cartItemID: this.id,
      bookingId: this.bookingId,
      bookingAnswers: this.getBookingQuestionsDetailsForPayment(),
      locationSelections: this.getLocationDetailsForPayment(),
      extraAnswers: this.getExtraDetailsForPayment(),
      pricingCategoryBookingAnswers:
        this.getPassengerQuestionsDetailsForPayment()
    };
  }

  validateLocations() {
    let isPickupValid = true;
    let isDropoffValid = true;

    isPickupValid = this.pickupLocation.every((location) => {
      if (
        location.dropdownInput?.selectedOption &&
        this.isCustomLocation(location.dropdownInput)
      ) {
        return location.customInput.validate();
      } else {
        return true;
      }
    });

    isDropoffValid = this.dropoffLocation.every((location) => {
      if (
        location.dropdownInput?.selectedOption &&
        this.isCustomLocation(location.dropdownInput)
      ) {
        return location.customInput.validate();
      } else {
        return true;
      }
    });

    this.isValid.locations = isPickupValid && isDropoffValid;
  }

  validatePassengerDetails() {
    // from the list of questions, retrieve inputs that are triggered or shown to the user
    // from the list of triggered inputs, find and validate required ones
    const requiredInputs: {
      passengerIdx: number;
      input: DynamicInputClasses[];
    }[] = [];
    this.passengerQuestions.forEach(
      ({ questionInputList, participant }, idx) => {
        const inputList = questionInputList.flatMap(({ dynamicInput }) => {
          const isTriggered = this.isPassengerQuestionTriggered(
            dynamicInput,
            participant.id
          );
          const required =
            dynamicInput.input.withValidation ||
            ('required' in dynamicInput.input && dynamicInput.input.required);

          if (isTriggered && required) return dynamicInput.input;
          return [];
        });

        requiredInputs.push({ passengerIdx: idx, input: inputList });
      }
    );

    const validations = requiredInputs.map(({ passengerIdx, input }) => {
      const isValid = input.map((input) => input.validate()).every(Boolean);
      this.passengerQuestions[passengerIdx].isValid = isValid;
      return isValid;
    });
    this.isValid.passengerDetails = validations.every(Boolean);
  }

  static getCustomLocation(locations: IActivityLocation[]) {
    return locations.find(
      ({ placeId, id }) =>
        placeId === AppConstants.LOCATION_TYPE_IDS.CUSTOM ||
        id === AppConstants.LOCATION_TYPE_IDS.CUSTOM
    );
  }

  static isBundleSelectionTypeEqual(
    activity: IActivity,
    isPickup: boolean,
    bundleId: Nullable<number>
  ) {
    return (selectionType: BundleLocationSelectionType) => {
      const bundle = activity.bundleAvailability?.bundle.items.find(
        ({ id }) => id === bundleId
      );
      if (!bundle) return false;
      const locationSelectionType = isPickup
        ? 'pickupSelectionType'
        : 'dropoffSelectionType';
      return bundle[locationSelectionType] === selectionType;
    };
  }

  static initPickupDropoffLocations(config: {
    locations: IActivityLocation[];
    activity: IActivity;
    locationType: LocationType;
  }): ILocationInput[] {
    const { locations, activity, locationType } = config;
    const isPickUp = locationType === LocationType.PICK_UP;

    const locationsGroups = locations.reduce((group, location) => {
      let groupIdx = 0;

      // if activity is not a bundle
      const isSelectionType = TourProductItem.isBundleSelectionTypeEqual(
        activity,
        isPickUp,
        location.bundleItemId
      );
      // if bundle selection type for locations is "INDEPENDENT",
      // group the locations by bundleItemId
      if (isSelectionType(BundleLocationSelectionType.INDEPENDENT)) {
        groupIdx = group.findIndex((locs) =>
          locs.some(
            ({ bundleItemId }) => location.bundleItemId === bundleItemId
          )
        );
        // We base groups of location on the bundle id.
        // Ensure that the list of location doesnt get
        // mixed up with locations from other tours
        // based on the bundleId.
        // We push to the list where the bundleItemId
        // is the key of the object.
        if (groupIdx != -1) {
          group[groupIdx].push(location);
        } else {
          group.push([location]);
        }
      }

      // if activity is not a bundle or
      // if bundle selection type for locations is "MASTER",
      // group the locations by id
      if (
        !activity.bundleAvailability ||
        isSelectionType(BundleLocationSelectionType.MASTER)
      ) {
        groupIdx = group.findIndex((locs) => !!locs);
        // We base groups of locations on the placeId.
        // It doesnt matter if there are locations for different
        // tours based on the bundleItemId as long as
        // there's one location list based on the placeId.
        if (group.length > 0) {
          group[groupIdx].push(location);
        } else {
          group.push([location]);
        }
      }

      return group;
    }, [] as IActivityLocation[][]);

    const couldAllowPickup =
      activity.meetingType !== MeetingType.MEET_ON_LOCATION;

    const rate = activity.rates.find(
      ({ id }) => activity.ratePrices.activityRateId === id
    ) as IActivityRate;

    const label = isPickUp ? 'Pickup Location' : 'Dropoff Location';
    const locationConfigs = isPickUp ? rate.pickupConfigs : rate.dropoffConfigs;
    const isActivityAllowingCustomLocation = isPickUp
      ? activity.customPickupAllowed
      : activity.customDropoffAllowed;
    const isAvailable = isPickUp
      ? activity.pickupService
      : activity.dropoffService;

    return locationsGroups.flatMap((locations) => {
      const locIdx = locations.findIndex((loc) => !!loc);

      console.log({ locIdx });
      const bundleDetails = activity.bundleAvailability?.bundle.items.find(
        ({ id }) => id === locations[locIdx].bundleItemId
      ) as IBundleAvailabilityItem;

      const selectionTypeCategory = isPickUp
        ? 'pickupSelectionType'
        : 'dropoffSelectionType';

      const masterSelectionTypeItems =
        activity.bundleAvailability?.bundle.items.filter(
          (item) =>
            item[selectionTypeCategory] === BundleLocationSelectionType.MASTER
        );
      const masterSelectionTypeIds = masterSelectionTypeItems
        ? masterSelectionTypeItems.map(({ id }) => id)
        : [];

      const selectionType =
        bundleDetails && bundleDetails[selectionTypeCategory];
      const isMaster = selectionType === BundleLocationSelectionType.MASTER;

      // For bundle tours with selectionType as "Master",
      // we have to group the ids together as they use one master list of locations.
      // Otherwise, tours with selectionType as "Independent"
      // there would only be one id as each tour use an independent list of locations.
      const validLocIdx = locations.findIndex((loc) => !!loc);
      const tourIds = isMaster
        ? masterSelectionTypeIds
        : [locations[validLocIdx].bundleItemId];

      // Retrieve config for location
      // Id can be -1 and null for optional
      const locTourIdx = tourIds.findIndex(
        (id) => typeof id === 'number' || id === null
      );
      const locationConfigItem = locationConfigs.find(
        ({ bundleItemId }) => bundleItemId === tourIds[locTourIdx]
      ) as ILocationConfig;

      const isPreselected =
        locationConfigItem.selectionType === SelectionType.PRESELECTED;
      const isUnavailable =
        locationConfigItem.selectionType === SelectionType.UNAVAILABLE;

      if (!isAvailable || isUnavailable) return [];

      const shouldAllowOptional = !isPreselected && couldAllowPickup;
      const isIncludedInPrice =
        locationConfigItem.pricingType === PricingType.INCLUDED_IN_PRICE;
      const isCustomAllowed =
        isActivityAllowingCustomLocation || locationConfigItem.customAllowed;

      const customLocation = this.getCustomLocation(locations)?.title;
      const customLocationValue =
        typeof customLocation === 'string' ? customLocation : undefined;

      const dropdownInput = this.mapLocationToDropdown({
        locations,
        isCustomAllowed,
        isPreselected,
        shouldAllowOptional,
        locationType
      });

      const customInput = new TextInput({
        withValidation: true,
        defaultMessage: 'Please enter location',
        label: label,
        placeholder: 'Enter the address',
        maxCharacters: 255,
        required: true,
        value: customLocationValue
      });

      // We check if there are selected values.
      // If we dont find any and the `SelectionType` is `PRESELECTED`,
      // we select the "one and only" location from the backend.

      if (!dropdownInput?.selectedValue && isPreselected) {
        dropdownInput?.select(locations[validLocIdx].title as string);
      }

      return {
        tourIds,
        locations,
        isIncludedInPrice,
        dropdownInput,
        customInput
      };
    });
  }

  static mapLocationToDropdown(dropdownConfig: {
    locations: IActivityLocation[];
    isCustomAllowed: boolean;
    isPreselected: boolean;
    shouldAllowOptional: boolean;
    locationType: LocationType;
  }): Nullable<DropdownSelect> {
    const { locationType, locations, isCustomAllowed, shouldAllowOptional } =
      dropdownConfig;

    const placeholder =
      locationType === LocationType.PICK_UP
        ? $translate("I don't need pickup")
        : $translate("I don't need drop off");

    const customLocationOption = new SelectOption({
      id: AppConstants.LOCATION_TYPE_IDS.CUSTOM,
      name: $translate('I will write down the address'),
      value: LocationType.CUSTOM
    });

    const hasValidLocations = locations.some(
      (location) => !!location.title || !!location.placeDescription
    );

    const customLocationIdx = locations.findIndex(
      ({ placeId, id }) =>
        placeId === AppConstants.LOCATION_TYPE_IDS.CUSTOM ||
        id === AppConstants.LOCATION_TYPE_IDS.CUSTOM
    );

    // We first search for a custom location from the locations
    // from the backend. If we find one, we remove custom input in locations.
    // This is because the value of custom location from the backend is set
    // to 'I will write down the address' and the actual value is in IActivityLocation['title'].
    // We will retrieve it by using `getCustomLocation` function.
    // Thus, we will overwrite by pushing customLocationOption.

    if (customLocationIdx > 0) {
      locations.splice(customLocationIdx, 1);
      customLocationOption.selected = true;
    }

    const locationOptions = SelectOption.fromActivityLocations(locations);
    if (isCustomAllowed) locationOptions.push(customLocationOption);

    return new DropdownSelect({
      options: hasValidLocations ? locationOptions : [],
      label: '',
      placeholder: placeholder,
      showPlaceholder: shouldAllowOptional,
      withInput: false,
      withValidation: false
    });
  }

  static fromCart(item: IActivityItem) {
    const pickupLocations = this.initPickupDropoffLocations({
      locations: item.pickups,
      activity: item.activity,
      locationType: LocationType.PICK_UP
    });

    const dropoffLocations = this.initPickupDropoffLocations({
      locations: item.dropoffs,
      activity: item.activity,
      locationType: LocationType.DROP_OFF
    });

    const bookingQuestions = item.activity.bookingQuestions
      .filter(({ context }) => context === AppConstants.CONTEXTS.BOOKING)
      .map((question) => ({
        dynamicInput: DynamicInput.fromQuestion(question),
        question: question
      }));

    const passengerQuestions = item.activity.bookingQuestions.filter(
      ({ context }) => context === AppConstants.CONTEXTS.PASSENGER
    );

    const passengerQuestionsMap: IPassengerQuestion[] = [];
    item.participantTypes
      .filter(({ quantity }) => !!quantity)
      .flatMap((participant) =>
        Array.from({ length: participant.quantity }).forEach(() => {
          if (passengerQuestions.length) {
            passengerQuestionsMap.push({
              participant: participant,
              questionInputList: passengerQuestions.map((question) => ({
                dynamicInput: DynamicInput.fromQuestion(question),
                question
              })),
              isValid: true
            });
          }
        })
      );

    return new TourProductItem({
      id: Number(item.id),
      inventorySystem: item.inventorySystem,
      activityId: Number(item.activity.id),
      bookingId: item.bookingId,
      bookingType: item.activity.bookingType,
      bundleAvailability: item.activity.bundleAvailability,
      startDate: new Date(item.startDateTime),
      endDate: new Date(item.endDate),
      productName: item.activity.title,
      participants: item.participantTypes,
      productTypeName: 'TOUR_PRODUCT_ITEM',
      currencyType: CurrencyType.ISK,
      productPrice: item.totalPrice,
      productDiscountedPrice: item.totalPriceDiscounted,
      rates: item.activity.rates,
      ratePrices: item.activity.ratePrices,
      isDiscountApplied: item.isDiscountApplied,
      pickupLocation: pickupLocations,
      dropoffLocation: dropoffLocations,
      passengerQuestions: passengerQuestionsMap,
      bookingQuestions: bookingQuestions,
      available: true,
      iconsWithText: [],
      selectedExtras: [],
      extras: TourExtra.fromActivityItem(item)
    });
  }
}
