import { TRACKING_TYPES, CUSTOM_ACTIONS, STANDARD_ACTIONS } from './constants';

export default class FacebookTracker {
  isEnabled = false;

  init(config) {
    this.isEnabled = window.fbq && !config.facebookDisabled;
  }

  removeFromCart(params) {
    if (!this.isEnabled) return;
    window.fbq(
      TRACKING_TYPES.TRACK_CUSTOM,
      CUSTOM_ACTIONS.REMOVE_FROM_CART,
      params
    );
  }

  initiateCheckout(params) {
    if (!this.isEnabled) return;
    window.fbq(
      TRACKING_TYPES.TRACK,
      STANDARD_ACTIONS.INITIATE_CHECKOUT,
      params
    );
  }

  addPromoCode(params) {
    if (!this.isEnabled) return;
    window.fbq(
      TRACKING_TYPES.TRACK_CUSTOM,
      CUSTOM_ACTIONS.ADD_PROMOTION,
      params
    );
  }
}
