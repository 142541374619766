import RibbonItem from './ribbon-item';

export interface IRibbon {
  items: RibbonItem[];
}

export default class Ribbon implements IRibbon {
  items: RibbonItem[] = [];

  constructor(data?: IRibbon) {
    this.items = data?.items ?? [];
  }
}
