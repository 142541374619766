import { NavLink } from './nav-link';

export interface INavItem extends NavLink{
  subNav?: NavLink[];
  hasSubNav?: boolean;
} 

export default class NavItem implements INavItem{
  name: string;
  link: string;
  subNav: NavLink[] = [];
  hasSubNav = false;

  constructor(data: INavItem){
    this.name = data.name;
    this.link = data.link;
    this.subNav = data.subNav ?? this.subNav;
    this.hasSubNav = data.hasSubNav ?? this.hasSubNav;
  }
}