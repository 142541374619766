import PropertyType from './property-type';
import PropertyAmenity from './property-amenity';
import RoomAmenity from './room-amenity';
import IPropertyMap from '../internal/prop-maps/property-map';

export interface ISearchFilters {
  propertyTypes?: PropertyType[];
  propertyAmenities?: PropertyAmenity[];
  roomAmenities?: RoomAmenity[];
}

export default class SearchFilters implements ISearchFilters {
  propertyTypes: PropertyType[];
  propertyAmenities: PropertyAmenity[];
  roomAmenities: RoomAmenity[];

  constructor(data: ISearchFilters) {
    this.propertyTypes = data.propertyTypes ?? [];
    this.propertyAmenities = data.propertyAmenities ?? [];
    this.roomAmenities = data.roomAmenities ?? [];
  }

  getAsMap(): IPropertyMap {
    return {
      propertyTypeMap: this.propertyTypes.map((t: any) => {
        return {
          code: t.code,
          name: t.name,
          description: t.description ?? null
        };
      }),
      propertyAmenityMap: this.propertyAmenities.map((t: any) => {
        return {
          code: t.code,
          name: t.name,
          description: t.description ?? null
        };
      }),
      roomAmenityMap: this.roomAmenities.map((t: any) => {
        return {
          code: t.code,
          name: t.name,
          description: t.description ?? null
        };
      })
    } as IPropertyMap;
  }
}
