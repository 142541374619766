import moment from 'moment';
import DropdownSelect, {
  IDropdownSelectArgs
} from '../../components/elements/dropdown-select/dropdown-select';
import { SelectOption } from '../../components/elements/options/select-option';
interface ITimeDropdown extends IDropdownSelectArgs {
  interval?: number;
  timeFormat?: string;
  dayList?: DropdownSelect;
  pickUpTime?: string;
  dropOffTime?: string;
  period?: string;
}

export default class TimeDropdown extends DropdownSelect {
  interval = 30; // setting 30 mins as time interval in the list;
  timeFormat = 'h:mm';
  dayList: DropdownSelect = new DropdownSelect({
    options: SelectOption.fromStringList(['AM', 'PM'])
  });

  constructor(data?: ITimeDropdown) {
    super({ options: [] });

    this.interval = data?.interval ?? this.interval;
    this.timeFormat = data?.timeFormat ?? this.timeFormat;
    this.dayList = data?.dayList ?? this.dayList;

    this.options = SelectOption.fromStringList(this.timeList);

    data?.pickUpTime && this.select(data.pickUpTime);
    data?.dropOffTime && this.select(data.dropOffTime);
    data?.period && this.dayList.select(data.period);
  }

  get selectedTime(): string | undefined {
    if (this.selectedValue && this.dayList.selectedValue) {
      return `${this.selectedValue} ${this.dayList.selectedValue}`;
    }
  }

  get timeList(): string[] {
    return Array.from({ length: 12 }).flatMap((_, i) => [
      moment({ hour: i }).format(this.timeFormat),
      moment({ hour: i }).minute(this.interval).format(this.timeFormat)
    ]);
  }
}
