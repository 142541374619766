import { CONTENT_CATEGORIES } from './../constants';
import AccommodationProductItem from '../../models/components/standalone/detailed-product-item/accommodation-product-item';
import TourProductItem from '../../models/components/standalone/detailed-product-item/tour-product-item';
import { IAnalyticsProduct } from '../../models/internal/analytics/analytics-product';

export const extractRemoveBookingData = (
  products: (TourProductItem | AccommodationProductItem)[],
  totalPrice: number
): IAnalyticsProduct[] => {
  return products.map((product) => ({
    id: TourProductItem.isTourProduct(product)
      ? product.activityId
      : product.propertyId,
    price: totalPrice,
    name: product.productName,
    category: TourProductItem.isTourProduct(product)
      ? CONTENT_CATEGORIES.ACTIVITIES
      : CONTENT_CATEGORIES.BOOKING,
    quantity: 1
  }));
};
