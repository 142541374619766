import AppConstants from './constants/app-constants';

const updateAppVersion = () => {
  const storage = localStorage;
  const versionStorageKey = 'AH-WidgetAppVersion';

  const currentVersion = process.env.VUE_APP_VERSION || '';
  const oldVersion = storage.getItem(versionStorageKey);
  if (oldVersion !== currentVersion) {
    storage.removeItem(AppConstants.APP_STORAGE_KEY);
  }

  storage.setItem(versionStorageKey, currentVersion);
};

export default updateAppVersion;
