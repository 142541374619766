
import { reactive, defineComponent } from 'vue';
import Modal from 'bootstrap/js/dist/modal';

export default defineComponent({
  name: 'ModalCmp',
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    modalId: {
      type: String,
      required: true
    },
    withFooter: {
      type: Boolean,
      required: false,
      default: false
    },
    showModal: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props) {
    props = reactive(props);

    return {
      id: props.modalId,
      show: props.showModal
    };
  },
  watch: {
    '$props.showModal': {
      handler() {
        this.toggleModal();
      }
    }
  },
  methods: {
    getModal() {
      return new Modal(document.getElementById(this.modalId), {
        keyboard: false
      });
    },
    toggleModal() {
      const modal = this.getModal();
      modal.toggle();
    }
  }
});
