
import { reactive, defineComponent } from '@vue/runtime-core';
import DigitInput from '../../../models/components/elements/digit-input/digit-input';

export default defineComponent({
  name: 'DigitInput',
  props: {
    digitInputModel: {
      type: Object as () => DigitInput,
      required: true
    }
  },
  emits: ['key-up', 'on-blur'],
  setup(props) {
    return {
      lastValue: props.digitInputModel?.value,
      digitInput: reactive(props.digitInputModel)
    };
  },
  methods: {
    onKeyUp() {
      this.digitInput.formatInput();
      this.$emit('key-up');
    },
    onBlur() {
      this.digitInput.validate();
      this.$emit('on-blur');
    }
  }
});
