
import { defineComponent, reactive } from 'vue';
import CarSearchPanel from '../search-panels/car-search-panel.vue';
import DropdownMultiselect from '../../elements/dropdown-multiselect/dropdown-multiselect.vue';
import Loader from '../../elements/loader/loader.vue';
import SidebarCar from '../../../models/components/block/sidebar-car';
import Dropdown from '../../elements/dropdown/dropdown.vue';
import { ICarSearchProperties } from '../../../models/internal/global/car-search-properties';
import { SidebarConstants } from '../../constants/sidebarConstants';
import DualRangeSlider from '../../elements/dual-range-slider/dual-range-slider.vue';
import { SelectOption } from '../../../models/components/elements/options/select-option';

export default defineComponent({
  name: 'SidebarCar',
  components: {
    multiselect: DropdownMultiselect,
    loader: Loader,
    'car-search-panel': CarSearchPanel,
    dropdown: Dropdown,
    'dual-range-slider': DualRangeSlider
  },
  props: {
    sidebar: {
      type: Object as () => SidebarCar,
      required: true
    }
  },
  emits: [
    'inputs-changed',
    'search-clicked',
    'sidebar-filters-changed',
    'sorting-changed',
    'price-filters-changed'
  ],
  setup(props) {
    return {
      sidebarModel: reactive(props.sidebar) as SidebarCar
    };
  },
  methods: {
    onInputChange(carSearchProperties: ICarSearchProperties) {
      this.$emit('inputs-changed', carSearchProperties);
    },
    updateSelected(objectKey: string, id: number, selected: boolean) {
      const options = this.sidebarModel.sidebarFilters[objectKey];
      this.sidebarModel.sidebarFilters[objectKey] = options.map((option) =>
        option.id !== id ? option : ({ ...option, selected } as SelectOption)
      );
      this.onSidebarFiltersChanged();
    },
    onSidebarFiltersChanged() {
      this.$emit('sidebar-filters-changed');
    },
    onSearchClicked() {
      this.$emit('search-clicked');
    },
    onSortingChanged() {
      this.$emit('sorting-changed');
    },
    getCarCategoryConst() {
      return SidebarConstants.CarCategory;
    },
    getCarTransmissionTypeConst() {
      return SidebarConstants.CarTransmissionType;
    },
    getCarFuelTypeConst() {
      return SidebarConstants.CarFuelType;
    },
    getCarExtrasConst() {
      return SidebarConstants.CarExtras;
    }
  }
});
