import { CurrencyType } from '../internal/currency/currency';
import ILocation from './location';
import PropertySearchPanel from '../components/block/property-search-panel';
import ISearchProperties from '../internal/global/search-properties';
import IBooking from '../internal/booking/booking';

export default interface IPropertySearch {
  startDate: Date;
  endDate: Date;
  numberOfGuests: number;
  numberOfRooms: number;
  propertyIds?: number[];
  locations: ILocation[];
  currencyCode?: CurrencyType;
  includeRooms: boolean;
}

export class PropertySearch {
  public static fromCart(
    booking: IBooking,
    searchProperties: ISearchProperties,
    currencyCode: CurrencyType
  ): IPropertySearch | null {
    if (!booking) {
      return null;
    }

    return {
      includeRooms: true,
      currencyCode: currencyCode ?? null,
      numberOfGuests: searchProperties.accommodation.guests,
      numberOfRooms: searchProperties.accommodation.rooms,
      propertyIds: [booking.propertyId],
      locations: [],
      startDate: booking.startDate,
      endDate: booking.endDate
    };
  }

  public static getPropertySearchModel(
    searchPanel: PropertySearchPanel,
    currency: CurrencyType
  ): IPropertySearch {
    const dates = searchPanel.selectedDates;
    return {
      propertyIds: searchPanel.selectedProperties,
      currencyCode: currency,
      includeRooms: false,
      locations: searchPanel.selectedLocations,
      startDate: dates.startDate,
      endDate: dates.endDate,
      numberOfGuests: searchPanel.numberOfGuests,
      numberOfRooms: searchPanel.numberOfRooms
    };
  }
}
