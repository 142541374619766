
import {reactive, defineComponent} from 'vue'
import Modal from '../../elements/modal-with-button/modal-with-button.vue'
import Calendar from '../../standalone/calendar/calendar.vue'
import CalendarTimeModal from '../../../models/components/standalone/modals/calendar-time-modal/calendar-time-modal'
import DropdownSelect from '../../elements/dropdown-select/dropdown-select.vue'

export default defineComponent({
  name: 'CalendarTimeModal',
  components: {
    'modal': Modal,
    'calendar': Calendar,
    'time-dropdown': DropdownSelect,
    'day-dropdown': DropdownSelect
  },
  props: {
    calendarModalModel: {
      type: Object as () => CalendarTimeModal,
      required: true
    }
  },
  setup(props) {
    return {
      calendarModal: reactive(props.calendarModalModel) as CalendarTimeModal,
    }
  },
  methods: {
    setDatesButtonLabel(): string {
      const { startDate, endDate } = this.calendarModal.calendar.dateRange.asDates;
      const formattedStartDate = startDate?.toISOString().slice(0, 10);
      const formattedEndDate = endDate?.toISOString().slice(0, 10);

      const pickupTime = this.calendarModal.pickUpTimeDropdown.selectedTime ?? '';
      const dropoffTime = this.calendarModal.dropOffTimeDropdown.selectedTime ?? '';

      if (formattedStartDate && formattedEndDate) {
        return `${formattedStartDate} ${pickupTime}  |  ${formattedEndDate} ${dropoffTime}`;
      }

      if (formattedStartDate) return formattedStartDate;
      return 'Select a date'
    },
  },
})
