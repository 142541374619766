export interface IExtrasDtoArgs {
  id: number;
  name: string;
}

export class ExtrasDto {
  id: number;
  name: string;

  constructor(data: IExtrasDtoArgs) {
    this.id = data.id;
    this.name = data.name;
  }
}

export interface IFuelsArgs {
  extrasList: ExtrasDto[];
}

export default class Extras {
  extrasList: ExtrasDto[];

  constructor(data: ExtrasDto[]) {
    this.extrasList = data;
  }

  static getDefaultExtras(): Extras {
    return {
      extrasList: [
        {
          id: 3,
          name: 'Air-conditioning'
        },
        {
          id: 1,
          name: '4+ doors'
        },
        {
          id: 2,
          name: '4+ large bags'
        }
      ]
    };
  }

  public static AirConditioningFilterId = 3;
  public static DoorsFilterId = 1;
  public static LargeBagsFilterId = 2;
}
