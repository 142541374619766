import ProductItem, { IProductItem } from './product-item';
import AvailableProperty from '../../../travia/available-property';
import { CurrencyType } from '../../../internal/currency/currency';
import IconWithText from '../../elements/icon-with-text/icon-with-text';

export interface IPropertyProductItem extends IProductItem {
  amenities: string[];
  type: string;
}

export default class PropertyProductItem extends ProductItem {
  amenities: string[];
  type: string;

  constructor(data: IPropertyProductItem) {
    super(data);

    this.amenities = data.amenities;
    this.type = data.type;
    this.productItemType = 'PROPERTY';
  }

  public static fromAvailableProperties(
    data: AvailableProperty[],
    selectedCurrency: CurrencyType
  ): PropertyProductItem[] {
    return data.map((property: AvailableProperty) => {
      const iconsWithText: IconWithText[] = [];
      property.location.city &&
        iconsWithText.push(
          new IconWithText({
            title: 'Location',
            code: 'c_LOC',
            content: property.location.city
          })
        );
      property.propertyTypeName &&
        iconsWithText.push(
          new IconWithText({
            title: 'Property type',
            code: 'c_PTN',
            content: property.propertyTypeName
          })
        );

      return new PropertyProductItem({
        productImages:
          property.images.length > 0
            ? [property.images[0]]
            : property.coverImage
            ? [property.coverImage]
            : property.logo
            ? [property.logo]
            : [],
        productName: property.name,
        productTypeName: property.propertyTypeName,
        productPrice:
          (property.fromPrice ?? -1) >= 0 ? property.fromPrice ?? -1 : null,
        available: (property.fromPrice ?? -1) >= 0,
        pricePerUnit: false,
        currencyType: selectedCurrency,
        iconsWithText: iconsWithText,
        id: property.id ?? -1,
        type: property.propertyTypeCode,
        amenities: property.propertyAmenityCodes
      });
    });
  }
}
