
import { reactive, defineComponent } from '@vue/runtime-core';
import Checkbox from '../../elements/checkbox/checkbox.vue';
import UserConsent from 'src/models/components/block/user-consent';

export default defineComponent({
  name: 'TermsAndConditions',
  components: {
    checkbox: Checkbox
  },
  props: {
    userConsentInputsModel: {
      type: Object as () => UserConsent,
      required: true
    }
  },
  emits: ['onCancellationPolicyClick', 'onTermsAndConditionsClick'],
  setup(props) {
    return {
      userConsent: reactive(props.userConsentInputsModel)
    };
  },
  methods: {}
});
