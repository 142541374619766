import BaseHttpClientService from '../base-http-client-service';
import IRentalInfo from '../../models/caren/rental-info';
import IRental from '../../models/caren/rental';
import CarSearchResult from '../../models/internal/vehicle/car-search-result';
import CarSearchProperties, {
  ICarSearchProperties
} from '../../models/internal/global/car-search-properties';
import { CarRequestDto } from '../../models/internal/vehicle/car-request-dto';
import CarSearchResponse from 'src/models/internal/vehicle/car-search-response';

interface IVehicleControllerService {
  getRentalList(): Promise<IRental[]>;
  getRentalInfo(rentalId: number): Promise<IRentalInfo>;
  getClass(vehicleRequestDto: CarRequestDto): Promise<CarSearchResult>;
  getClassList(
    carSearchPropertiesModel: ICarSearchProperties
  ): Promise<CarSearchResponse>;
}

class VehicleControllerService
  extends BaseHttpClientService
  implements IVehicleControllerService {
  getRentalList(): Promise<IRental[]> {
    return this.httpClient
      .get('api/vehicle/caren/get-rental-list')
      .then((resp) => this.prepareResponse(resp).content);
  }

  getRentalInfo(rentalId: number): Promise<IRentalInfo> {
    return this.httpClient
      .get(`api/vehicle/caren/get-rental-info/${rentalId}`)
      .then((resp) => this.prepareResponse(resp).content);
  }

  getClass(carRequestDto: CarRequestDto): Promise<CarSearchResult> {
    return this.httpClient
      .post('api/vehicle/caren/get-class', carRequestDto)
      .then((resp) => this.prepareResponse(resp).content);
  }

  getClassList(
    carSearchPropertiesModel: CarSearchProperties
  ): Promise<CarSearchResponse> {
    return this.httpClient
      .post(`api/vehicle/caren/get-class-list/`, carSearchPropertiesModel)
      .then((resp) => this.prepareResponse(resp).content);
  }
}

export default new VehicleControllerService() as IVehicleControllerService;
