import axios from 'axios';

interface ITranslationControllerService {
  getTranslations(culture: string): Promise<{ [key: string]: string }>;
}

class TranslationControllerService implements ITranslationControllerService {
  getTranslations(culture: string) {
    return axios
      .get('https://aawidgetapi-dev.azurewebsites.net/v1/translations', {
        params: { culture }
      })
      .then((response) => response.data);
  }
}

export default new TranslationControllerService() as ITranslationControllerService;
