
import { defineComponent, reactive } from 'vue';
import NumericInput from '../../../models/components/elements/numeric-input/numeric-input';

export default defineComponent({
  name: 'NumericInput',
  props: {
    numericInput: {
      type: Object as () => NumericInput,
      required: true
    }
  },
  emits: ['input-changed'],
  setup(props) {
    return {
      lastValue: props.numericInput?.value,
      input: reactive(props.numericInput as NumericInput)
    };
  },
  methods: {
    decrement() {
      if (this.input?.value || this.input.value === 0)
        this.numericInput.allowDecimal
          ? (this.input.value -= 0.1)
          : (this.input.value -= 1);

      this.emitInput();
    },
    increment() {
      if (this.input?.value || this.input.value === 0)
        this.numericInput.allowDecimal
          ? (this.input.value += 0.1)
          : (this.input.value += 1);

      this.emitInput();
    },
    emitInput() {
      this.input?.validate(this.lastValue);
      this.$emit('input-changed', this.lastValue, this.input.value);
      this.lastValue = !this.input.value ? this.input.value : this.lastValue;
    }
  }
});
