
import { defineComponent, reactive } from 'vue';

import Product from '../../../components/standalone/product/product.vue';
import Loader from '../../../components/elements/loader/loader.vue';
import CarTravelDetails from '../../../models/components/block/car-travel-details';
import CarSearchPanel from '../../../models/components/block/car-search-panel';
import CarSearchPanelCmp from '../../../components/block/search-panels/car-search-panel.vue';
import CarTravelDetailsCmp from '../../../components/block/car-travel-details/car-travel-details.vue';

export default defineComponent({
  name: 'CarCmp',
  components: {
    'product': Product,
    'car-travel-details': CarTravelDetailsCmp,
    'car-search-panel': CarSearchPanelCmp,
    'loader': Loader,
  },
  props: {
    travelDetailsModel: {
      type: Object as () => CarTravelDetails,
      required: true
    },
    searchPanelInputs: {
      type: Object as () => CarSearchPanel,
      required: true
    }
  },
  emits: ['addToCartClicked'],
  setup(props) {
    return {
      carTravelDetails: reactive(props.travelDetailsModel),
      carSearchPanelInputs: reactive(props.searchPanelInputs)
    };
  },
  methods: {
    addToCart(): void {
      this.$emit('addToCartClicked');
    }
  }
});
