
import { defineComponent, reactive } from "vue";

import IExtraDetailedDto from "../../../models/caren/extras/extra-detailed-dto";
import IInsuranceDetailedDto from "../../../models/caren/extras/insurance-detailed-dto";

export default defineComponent({
  props: {
    detailsProp: {
      type: Object as () => IExtraDetailedDto | IInsuranceDetailedDto,
      required: true
    },
    numberOfDaysProp: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    return {
      details: reactive(props.detailsProp),
      numberOfDays: props.numberOfDaysProp
    }
  },
})
