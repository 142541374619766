import IAvailabilityFilters from '../dtos/responses/availability-filters';
import AvailableProperty from './available-property';

export interface IPropertySearchResults {
  availableProperties: AvailableProperty[];
  availabilityFilters: IAvailabilityFilters;
}

export class PropertySearchResults {
  availableProperties: AvailableProperty[];
  availabilityFilters: IAvailabilityFilters;

  constructor(data: IPropertySearchResults) {
    this.availableProperties = data.availableProperties;
    this.availabilityFilters = data.availabilityFilters;
  }
}
