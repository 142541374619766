class RudderstackTracker {
  isReady = false;

  constructor() {
    if (!window.rudderanalytics) return;

    window.rudderanalytics.ready(() => {
      this.isReady = true;
      console.log('Rudderstack -- Ready');
    });
  }

  viewProduct(product) {
    if (!this.isReady) return;

    window.rudderanalytics.track('Product Viewed', product);
  }

  viewTermsConditions(bookingId) {
    if (!this.isReady) return;

    window.rudderanalytics.track('terms_and_conditions', { bookingId });
  }

  addToCart(product) {
    if (!this.isReady) return;

    window.rudderanalytics.track('Product added', product);
  }

  removeFromCart(product) {
    if (!this.isReady) return;

    window.rudderanalytics.track('Product Removed', product);
  }

  trackCheckoutStep(step) {
    if (!this.isReady) return;

    window.rudderanalytics.track('Checkout Step Viewed', { step });
  }

  purchase(products) {
    if (!this.isReady) return;

    window.rudderanalytics.track('Order Completed', products);
  }

  beginCheckout(products) {
    if (!this.isReady) return;

    window.rudderanalytics.track('Checkout Started', products);
  }
}

export default RudderstackTracker;
