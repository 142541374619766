import { ISummaryDetails } from './../../models/internal/summary-details/summary-details';
import { DEFAULT_AFFILIATION, CONTENT_CATEGORIES } from './../constants';
import NumberUtils from '../../utils/number-utils';
import TourProductItem from '../../models/components/standalone/detailed-product-item/tour-product-item';
import AccommodationProductItem from '../../models/components/standalone/detailed-product-item/accommodation-product-item';

export const extractRemoveFromCartParams = (
  product: TourProductItem | AccommodationProductItem,
  promoCode,
  fromCurrency,
  toCurrency
) => {
  const id = TourProductItem.isTourProduct(product)
    ? product.activityId
    : product.propertyId;
  const isDiscounted = TourProductItem.isTourProduct(product) && !!promoCode;
  const unitPrice = TourProductItem.isTourProduct(product)
    ? product.getTotalPrice({ isDiscounted })
    : product.productPrice;

  return {
    product_id: id,
    category: CONTENT_CATEGORIES.ACTIVITIES,
    name: product.productName,
    price: NumberUtils.convertCurrency({
      from: fromCurrency,
      to: toCurrency,
      amount: Number(unitPrice)
    }),
    quantity: 1,
    coupon: promoCode
  };
};

export const convertProductToItems = (
  item: ISummaryDetails,
  promoCode,
  fromCurrency: string,
  toCurrency: string
) =>
  item.details.map((detail) => ({
    product_id: detail.id,
    name: `${item.name} - ${detail.name}`,
    quantity: detail.quantity,
    item_category: CONTENT_CATEGORIES.ACTIVITIES,
    price: NumberUtils.convertCurrency({
      from: fromCurrency,
      to: toCurrency,
      amount: detail.unitPrice
    })
  }));

export const convertActivityToEventParams = (
  items: ISummaryDetails[],
  totalPrice: number,
  fromCurrency: string,
  toCurrency: string,
  promoCode: string
) => {
  const price = items.reduce((price, item) => item.unitPrice + price, 0);
  const totalDiscount = items.reduce(
    (totalDiscount, item) => item.discountedUnitPrice + totalDiscount,
    0
  );
  return {
    value: totalPrice,
    revenue: totalPrice,
    discount: Number(
      NumberUtils.convertCurrency({
        from: fromCurrency,
        to: toCurrency,
        amount: price - totalDiscount
      }).toFixed(2)
    ),
    affiliation: DEFAULT_AFFILIATION,
    currency: toCurrency,
    coupon: promoCode,
    products: items.flatMap((item) =>
      convertProductToItems(item, promoCode, fromCurrency, toCurrency)
    )
  };
};
