import DateRange from '../../../internal/date-range/date-range';
import DayModel from '../../../internal/day/day';
import moment, { Moment } from 'moment';

export interface IAvailableMonth {
  name: string;
  value: string;
}

export class AvailableMonth {
  name: string;
  value: string;

  constructor(data: IAvailableMonth) {
    this.name = data.name;
    this.value = data.value;
  }
}

export interface ICalendar {
  isRange: boolean;
  dateRange?: DateRange;
}

export default class Calendar {
  isRange: boolean;
  dateRange: DateRange;
  visibleDays: DayModel[] = [];
  loading = false;

  private _selectedMonth: Moment = moment().startOf('month').utc();
  private _availableMonths: AvailableMonth[] = [];
  private _loadedDates: DayModel[] = [];
  private _selectedMonthName: string = this.selectedMonthName;

  set selectedMonth(date: string) {
    this._selectedMonth = moment(date).startOf('month');
    this.loadSurroundingAndSelectedMonth();
  }

  get selectedMonth() {
    return this._selectedMonth.startOf('month').format('yyyy-MM-DD');
  }

  get selectedMonthName(): string {
    return this._selectedMonth.startOf('month').format('yyyy-MM-DD');
  }

  set selectedMonthName(month: string) {
    this._selectedMonthName = month;
  }

  get availableMonths(): AvailableMonth[] {
    return this._availableMonths;
  }

  get isEarliestPossibleMonth(): boolean {
    return this.selectedMonthName === moment().startOf('month').format('yyyy-MM-DD');
  }

  get isLatestPossibleMonth(): boolean {
    return this.selectedMonth === 
      this.availableMonths[this.availableMonths.length - 1].value;
  }

  constructor(data: ICalendar) {
    this.isRange = data.isRange;
    this.dateRange = data.dateRange ?? new DateRange();
    if (this.dateRange.startDate)
      this._selectedMonth = moment(this.dateRange.startDate);
    else
      this._selectedMonth = moment();

    this.loadSurroundingAndSelectedMonth();
    this.getAvailableMonths();
  }

  public onMonthChange(forward: boolean): boolean {
    const copy = moment(this._selectedMonth);
    const changed = copy.add(forward ? 1 : -1, 'M').format('yyyy-MM-DD');
    if (this._availableMonths.find((month) => changed === month.value)) {
      this.selectedMonth = changed;
      return true;
    } else {
      return false;
    }
  }

  public onSelectedDay(date: Moment | Date): void {
    if (this._loadedDates.find((lDate) => 
        moment(lDate.fullDate).isSame(moment(date)))?.isDisabled ?? false)
      return;

    this.dateRange.onSelectedDay(date);
    this.loadSurroundingAndSelectedMonth();
  }

  public weeksInMonth(): number[] {
    return this.visibleDays
      .map((x) => x.week)
      .filter((value, index, self) => self.indexOf(value) === index);
  }

  public daysInWeek(week: number): DayModel[] {
    return this.visibleDays.filter((x) => x.week === week);
  }

  private getAvailableMonths() {
    for (let i = 0; i < 24; i++) {
      this._availableMonths.push(
        new AvailableMonth({
          name: moment().startOf('month').add(i, 'month').format('MMMM yyyy'),
          value: moment().startOf('month').add(i, 'month').format('yyyy-MM-DD')
        })
      );
    }
  }

  private loadSurroundingAndSelectedMonth() {
    const loadedDates: DayModel[] = [];
    let start = moment(this._selectedMonth).startOf('month').utc().day(0);
    const end = moment(this._selectedMonth).endOf('month').utc().day(6);

    while (start <= end) {
      loadedDates.push(
        new DayModel({
          day: start.date(),
          week: start.week(),
          month: start.month(),
          fullDate: start.utc().startOf('day').toDate(),
          isSelected:
            (this.dateRange.startDate &&
              moment(this.dateRange.startDate)
                .startOf('day')
                .isSame(start.startOf('day'))) ||
            (this.dateRange.endDate &&
              moment(this.dateRange.endDate)
                .startOf('day')
                .isSame(start.startOf('day'))),
          isIncluded: this.dateRange.isInRange(start),
          isDisabled: start < moment().startOf('day')
        })
      );
      start = start.add(1, 'day');
    }

    this._loadedDates = loadedDates;
    this.loadVisibleDays();
  }

  private loadVisibleDays() {
    this.visibleDays = this._loadedDates.filter(
      (x) =>
        x.month === moment(this._selectedMonth).month() ||
        x.week === moment(this._selectedMonth).startOf('month').week() ||
        x.week === moment(this._selectedMonth).endOf('month').week()
    );
  }
}
