import Dropdown from '../elements/dropdown/dropdown';
import DriversAge from '../../../models/internal/drivers-age/drivers-age';
import CalendarTimeModal from '../standalone/modals/calendar-time-modal/calendar-time-modal';
import moment from 'moment';
import { ICarSearchProperties } from '../../internal/global/car-search-properties';
import IRental from '../../caren/rental';
import TimeDropdown from '../../internal/time-dropdown/time-dropdown';
import { CarSearchQueryParams } from '../../internal/car-query-params/car-search-query';
import { LocationQuery } from 'vue-router';

export interface ICarSearchPanel {
  rentalId?: number;
  rentalList?: IRental[];
  isLoading: boolean;
  isValid?: boolean;
  isPickUpDropOffLocationSame?: boolean;
  isCustomAge?: boolean;

  dropOffDrodown?: Dropdown;
  pickUpDropdown?: Dropdown;
  calendarTimeModal?: CalendarTimeModal;
  ageDropdown?: Dropdown;
  countriesDropdown?: Dropdown;
  selectedDropOffLocationId?: number;
  selectedPickUpLocationId?: number;
}

export default class CarSearchPanel implements ICarSearchPanel {
  rentalId = 0;
  rentalList: IRental[] = [];
  isLoading: boolean;
  isValid = false;
  isPickUpDropOffLocationSame = true;
  isCustomAge = false;
  selectedDropOffLocationId?: number;
  selectedPickUpLocationId?: number;

  private driversAge = new DriversAge();

  dropOffDrodown: Dropdown = new Dropdown({
    withValidation: false,
    placeholder: 'Select a drop-off location'
  });

  pickUpDropdown: Dropdown = new Dropdown({
    withValidation: true,
    placeholder: 'Select a pick-up location'
  });

  calendarTimeModal: CalendarTimeModal;

  ageDropdown: Dropdown = new Dropdown({
    withValidation: true,
    placeholder: 'Select age',
    withInput: false,
    options: this.driversAge.driversAgeList
  });

  constructor(data?: ICarSearchPanel) {
    this.isLoading = data?.isLoading ?? false;
    this.isValid = data?.isValid ?? false;
    this.isPickUpDropOffLocationSame =
      data?.isPickUpDropOffLocationSame ?? this.isPickUpDropOffLocationSame;
    this.isCustomAge = data?.isCustomAge ?? this.isCustomAge;

    this.pickUpDropdown = data?.pickUpDropdown ?? this.pickUpDropdown;
    this.dropOffDrodown = data?.dropOffDrodown ?? this.dropOffDrodown;

    this.calendarTimeModal = data?.calendarTimeModal ?? new CalendarTimeModal();
    this.ageDropdown = data?.ageDropdown ?? this.ageDropdown;

    // set validation to false if drop off and pick up locations are the same.
    this.dropOffDrodown.withValidation = !this.isPickUpDropOffLocationSame;
    // set validation to false if age is not custom.
    this.ageDropdown.withValidation = this.isCustomAge;

    this.selectedDropOffLocationId = data?.selectedDropOffLocationId;
    this.selectedPickUpLocationId = data?.selectedPickUpLocationId;
  }

  togglePickUpDropOffSimilarity() {
    this.isPickUpDropOffLocationSame = !this.isPickUpDropOffLocationSame;
    this.dropOffDrodown.withValidation = !this.isPickUpDropOffLocationSame;
  }

  toggleCustomAge() {
    this.isCustomAge = !this.isCustomAge;
    this.ageDropdown.withValidation = this.isCustomAge;
    !this.isCustomAge && this.ageDropdown.clearSelection();
  }

  validate() {
    return (this.isValid =
      this.pickUpDropdown.validate() &&
      this.dropOffDrodown.validate() &&
      this.ageDropdown.validate() &&
      this.calendarTimeModal.calendar.dateRange.validate());
  }

  emitCarSearchProperties(): ICarSearchProperties {
    const formattedStartDate = moment(
      this.calendarTimeModal.calendar.dateRange.startDate
    )
      .format('YYYY-MM-DD')
      .toString();
    const formattedEndDate = moment(
      this.calendarTimeModal.calendar.dateRange.endDate
    )
      .format('YYYY-MM-DD')
      .toString();

    return {
      rentalId: this.rentalId,
      passengers: 1,
      driverAge: this.isCustomAge
        ? this.ageDropdown.selectedValue
        : this.driversAge.defaultAge,
      pickupLocationId: this.pickUpDropdown.selectedValue,
      dropoffLocationId: this.isPickUpDropOffLocationSame
        ? this.pickUpDropdown.selectedValue
        : this.dropOffDrodown.selectedValue,
      dateFrom: formattedStartDate,
      dateTo: formattedEndDate,
      showGroupNames: true
    };
  }

  emitCarQueryParams(): CarSearchQueryParams {
    const formattedStartDate = moment(
      this.calendarTimeModal.calendar.dateRange.startDate
    )
      .format('YYYY-MM-DD')
      .toString();
    const formattedEndDate = moment(
      this.calendarTimeModal.calendar.dateRange.endDate
    )
      .format('YYYY-MM-DD')
      .toString();

    return {
      rentalId: this.rentalId.toString(),
      passengers: '1',
      driverAge: this.isCustomAge
        ? this.ageDropdown.selectedValue.toString()
        : this.driversAge.defaultAge.toString(),
      pickupLocationId: this.pickUpDropdown.selectedValue.toString(),
      dropoffLocationId: this.isPickUpDropOffLocationSame
        ? this.pickUpDropdown.selectedValue.toString()
        : this.dropOffDrodown.selectedValue.toString(),
      dateFrom: formattedStartDate,
      dateTo: formattedEndDate,
      dateFromTime: this.calendarTimeModal.pickUpTimeDropdown.selectedValue,
      dateToTime: this.calendarTimeModal.dropOffTimeDropdown.selectedValue,
      pickUpPeriod: this.calendarTimeModal.pickUpTimeDropdown.dayList
        .selectedValue,
      dropOffPeriod: this.calendarTimeModal.dropOffTimeDropdown.dayList
        .selectedValue
    };
  }

  static fromCarSearchProperties(
    carSearchProperties: ICarSearchProperties
  ): CarSearchPanel {
    const panel = new CarSearchPanel({
      selectedDropOffLocationId: carSearchProperties.dropoffLocationId,
      selectedPickUpLocationId: carSearchProperties.pickupLocationId,
      calendarTimeModal: new CalendarTimeModal({
        pickUpTimeDropdown: new TimeDropdown({
          pickUpTime: carSearchProperties.dateFromTime,
          period: carSearchProperties.pickUpPeriod
        }),
        dropOffTimeDropdown: new TimeDropdown({
          dropOffTime: carSearchProperties.dateToTime,
          period: carSearchProperties.dropOffPeriod
        }),
        startDate: carSearchProperties.dateFrom,
        endDate: carSearchProperties.dateTo
      }),
      isLoading: false,
      rentalId: carSearchProperties.rentalId,
      isPickUpDropOffLocationSame:
        carSearchProperties.pickupLocationId ===
        carSearchProperties.dropoffLocationId,
      isCustomAge: carSearchProperties.isCustomAge
    });
    panel.isCustomAge = panel.ageDropdown.options.some(
      (o) => o.value === carSearchProperties.driverAge.toString()
    );
    panel.isCustomAge &&
      panel.ageDropdown.select(carSearchProperties.driverAge.toString());
    return panel;
  }

  static isCarQueryParamsValid(params: LocationQuery) {
    try {
      const query = params as CarSearchQueryParams;

      const isRentalIdValid = Number(query.rentalId) > 0;
      const isPassengersValid = Number(query.passengers) > 0;
      const isDriverAgeValid = Number(query.driverAge) > 17;
      const isPickupLocationIdValid = Number(query.pickupLocationId) > 0;
      const isDropoffLocationIdValid = Number(query.dropoffLocationId) > 0;
      const dateFrom = moment.utc(query.dateFrom);
      const dateTo = moment.utc(query.dateTo);
      const isdateFromValid =
        dateFrom.isValid() && dateFrom.isSameOrAfter(moment.now(), 'day');
      const isdateToValid = dateTo.isValid() && dateTo.isAfter(dateFrom);
      const isDatesValid = isdateFromValid && isdateToValid;

      return (
        isRentalIdValid &&
        isPassengersValid &&
        isDriverAgeValid &&
        isPickupLocationIdValid &&
        isDropoffLocationIdValid &&
        isDatesValid
      );
    } catch (error) {
      console.error(error);
      return false;
    }
  }
}
