import moment from 'moment';
import DateRange from '../../../../../models/internal/date-range/date-range';
import TimeDropdown from '../../../../../models/internal/time-dropdown/time-dropdown';
import Calendar from '../../calendar/calendar';
import CalendarModal from '../calendar-modal/calendar-modal';

interface ICalendarTimeModal {
  pickUpTimeDropdown?: TimeDropdown;
  dropOffTimeDropdown?: TimeDropdown;
  startDate?: string;
  endDate?: string;
}

export default class CalendarTimeModal extends CalendarModal {
  pickUpTimeDropdown: TimeDropdown;
  dropOffTimeDropdown: TimeDropdown;

  constructor(data?: ICalendarTimeModal) {
    const dateRange =
      data?.startDate && data.endDate
        ? {
            startDate: moment.utc(data.startDate),
            endDate: moment.utc(data.endDate)
          }
        : {};

    super({
      btnColorType: 'btn-light',
      calendar: new Calendar({
        dateRange: new DateRange(dateRange, {
          withValidation: true,
          isValid: true,
          earliestDate: new Date()
        }),
        isRange: true
      })
    });

    this.pickUpTimeDropdown = data?.pickUpTimeDropdown ?? new TimeDropdown();
    this.dropOffTimeDropdown = data?.dropOffTimeDropdown ?? new TimeDropdown();

    this.pickUpTimeDropdown.placeholder = 'Time';
    this.dropOffTimeDropdown.placeholder = 'Time';
    this.pickUpTimeDropdown.dayList.placeholder = 'Day';
    this.dropOffTimeDropdown.dayList.placeholder = 'Day';
  }
}
