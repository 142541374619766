
import { defineComponent, reactive } from "vue";

import NumericInput from "../../elements/numeric-input/numeric-input.vue";
import Checkbox from '../../elements/checkbox/checkbox.vue';
import CarExtras from "../../../models/components/block/car-extras";
import CarPriceDetails from '../car-price-details/car-price-details.vue';

export default defineComponent({
  components: {
    "numeric-input": NumericInput,
    "checkbox": Checkbox,
    "car-price-details": CarPriceDetails
  },
  props: {
    carExtrasModel: {
      type: Object as () => CarExtras,
      required: true
    }
  },
  setup(props) {
    return {
      carExtras: reactive(props.carExtrasModel),
    }
  },
})
