
import { defineComponent, reactive, ref } from '@vue/runtime-core';
import AppUtils from '../../../utils/app-utils';
import PaymentSuccessful from '../../../models/components/block/paymentSuccessful';
import WidgetSettingsSuccessPageDto from 'src/models/internal/widget-settings/widget-settings-success-page-dto';
import widgetSettingsControllerService from '../../../services/controllers/widget-settings-controller-service';

export default defineComponent({
  name: 'PaymentSuccessful',
  props: {
    paymentSuccessfulModel: {
      type: Object as () => PaymentSuccessful,
      required: true
    }
  },
  emits: ['continue-click'],
  setup(props) {
    return {
      paymentSuccessful: reactive(props.paymentSuccessfulModel),
      themePrefix: ref(AppUtils.getFallbackDomain())
    };
  },
  data() {
    let successPage: WidgetSettingsSuccessPageDto = null as any;
    let facebookText = '';
    return {
      successPage,
      facebookText
    };
  },
  computed: {
    styleObject() {
      return {
        titleColor: { color: this.$props.paymentSuccessfulModel.titleColor },
        textColorAndWeight: {
          color: this.$props.paymentSuccessfulModel.textColor,
          'font-weight': this.$props.paymentSuccessfulModel.textBold && 'bold'
        },
        buttonBackground: {
          background: this.$props.paymentSuccessfulModel.titleColor
        }
      };
    }
  },
  mounted() {
    this.getSuccessInfo().then((data) => {
      this.successPage = data;

      if (data.facebookAppName) {
        const facebookScript = document.createElement('script');
        facebookScript.setAttribute('crossorigin', 'anonymous');
        facebookScript.setAttribute('nonce', 'oOYfCVjk');
        facebookScript.setAttribute(
          'src',
          `https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v14.0&appId=${data.facebookAppName}`
        );
        document.head.appendChild(facebookScript);

        // TODO: test well before prod
        this.facebookText = `
<div
  class="fb-page"
    data-href="https://www.facebook.com/ArcticAdventures"
    data-tabs="timeline"
    data-width="400"
    data-height="600"
    data-small-header="false"
    data-adapt-container-width="true"
    data-hide-cover="false"
    data-show-facepile="true">
  <blockquote
    cite="https://www.facebook.com/ArcticAdventures"
    class="fb-xfbml-parse-ignore">
  <a href="https://www.facebook.com/ArcticAdventures">
    Arctic Adventures
  </a></blockquote></div>
`;
      }
    });
  },
  methods: {
    onContinueClick() {
      this.$store.commit('uploadSuccessfulPaymentInformation', null);
      this.$emit('continue-click');
    },
    getCurrentTranslation(): string {
      return (localStorage.getItem('AH-Translation') ?? 'en-US').split('-')[0];
    },
    getSuccessInfo(): Promise<WidgetSettingsSuccessPageDto> {
      return widgetSettingsControllerService.getWidgetSettingsSuccessPage(
        this.getCurrentTranslation()
      );
    }
  }
});
