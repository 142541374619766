export enum SidebarConstants {
  // Should be equal to getter names in sidebar-filters-car.ts
  CarCategory = 'carCategoryOptions',
  CarTransmissionType = 'carTransmissionTypeOptions',
  CarFuelType = 'carFuelTypeOptions',
  CarExtras = 'carExtrasOptions',
  // Should be equal to getter names in sidebar-filters.ts
  PropertyType = 'propertyTypeOptions',
  PropertyAmenity = 'propertyAmenityOptions'
}
