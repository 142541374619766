import ProductItem, { IProductItem } from '../product/product-item';
import IRoom from '../../../travia/room';
import { CurrencyType } from '../../../internal/currency/currency';
import IPropertyMap from '../../../internal/prop-maps/property-map';
import IconWithText from '../../elements/icon-with-text/icon-with-text';
import { IBookingRoomResponse } from '../../../internal/booking/booking-room';
import moment from 'moment';
import { SearchQueryParams } from 'src/models/internal/query-params/search-query';

interface IAccommodationProductItemArgs extends IProductItem {
  numberOfNights: number;
  numberOfGuests: number;
  propertyId: number;
  propertyName: string;
  bookingRoomId: number;
  bookingId: number;
  quantity: number;
  editable?: boolean;
  breakfast?: boolean;

  startDate: Date;
  endDate: Date;
}

export default class AccommodationProductItem extends ProductItem {
  numberOfNights: number;
  numberOfGuests: number;
  propertyId: number;
  propertyName: string;
  bookingRoomId: number;
  bookingId: number;
  quantity: number;
  editable = false;
  breakfast = false;

  startDate: Date;
  endDate: Date;

  get startMomentFormatted(): string {
    return moment(this.startDate).format('MMM DD YYYY');
  }

  get endMomentFormatted(): string {
    return moment(this.endDate).format('MMM DD YYYY');
  }

  get formattedDateRange(): string {
    return `${this.startMomentFormatted} - ${this.endMomentFormatted}`;
  }

  constructor(data: IAccommodationProductItemArgs) {
    super(data);

    this.productItemType = 'ACCOMMODATION_PRODUCT_ITEM';
    this.bookingRoomId = data.bookingRoomId;
    this.propertyId = data.propertyId;
    this.bookingId = data.bookingId;
    this.propertyName = data.propertyName;
    this.startDate = data.startDate;
    this.endDate = data.endDate;
    this.numberOfNights = data.numberOfNights;
    this.numberOfGuests = data.numberOfGuests;
    this.quantity = data.quantity;
    this.editable = data.editable ?? this.editable;
    this.breakfast = data.breakfast ?? this.breakfast;
  }

  getRouterQuery(): SearchQueryParams {
    return {
      guests: this.numberOfGuests.toString(),
      // temporarily setting children params to zero as the property is not passed from BE yet.
      children: '0',
      rooms: this.quantity.toString(),
      startDate: moment(this.startDate).format('YYYY-MM-DD'),
      endDate: moment(this.endDate).format('YYYY-MM-DD'),
      locations: JSON.stringify([]),
      properties: JSON.stringify([this.propertyId])
    };
  }

  static isAccommodationProduct(product): product is AccommodationProductItem {
    return product instanceof AccommodationProductItem;
  }

  public static fromIRoom(
    bookingId: number,
    room: IRoom,
    selectedCurrency: CurrencyType,
    propertyMap: IPropertyMap,
    bookingRoom: IBookingRoomResponse,
    propertyName: string
  ): AccommodationProductItem {
    return new AccommodationProductItem({
      propertyId: room.propertyId ?? -1,
      bookingId: bookingId,
      breakfast: bookingRoom.breakfast,
      propertyName: propertyName,
      bookingRoomId: bookingRoom.id,
      id: bookingRoom.roomId,
      startDate: bookingRoom.startDate,
      endDate: bookingRoom.endDate,
      productName: room.name,
      productTypeName: room.roomTypeName,
      productImages: room.images,
      productPrice: bookingRoom.totalPriceWithMargin,
      currencyType: selectedCurrency,
      priceStable: true,
      pricePerUnit: false,
      editable: false,
      numberOfGuests: bookingRoom.pax,
      numberOfNights: bookingRoom.numberOfNights,
      quantity: bookingRoom.quantity,
      available: true,
      iconsWithText: room.roomAmenityCodes
        .slice(0, 4)
        .map((roomAmenity: string) => {
          const codeMap = propertyMap.roomAmenityMap.find(
            (am) => am.code === roomAmenity
          );
          return new IconWithText({
            inline: false,
            code: codeMap?.code ?? roomAmenity,
            title: codeMap?.name ?? roomAmenity,
            content: null
          });
        })
    });
  }
}
