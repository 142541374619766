import { ICarouselItem } from './carousel-item';

export enum CarouselSizes {
  xl = '60vh',
  lg = '40vh',
  md = '30vh',
  sm = '25vh',
  xs = '20vh',
  xxs = '180px'
}

export interface ICarousel {
  maxItemsForIndicators?: number;
  carouselItems: ICarouselItem[];
  activeIndex?: number;
  carouselSize?: CarouselSizes;
  rounded?: boolean;
}

export default class Carousel implements ICarousel {
  carouselSize: CarouselSizes = CarouselSizes.lg;
  rounded = true;

  carouselItems: ICarouselItem[] = [];
  maxItemsForIndicators = 0;
  activeIndex = 0;

  constructor(data?: ICarousel) {
    this.carouselItems = data?.carouselItems ?? [];
    this.activeIndex = data?.activeIndex ?? 0;
    this.carouselSize = data?.carouselSize ?? this.carouselSize;
    this.maxItemsForIndicators =
      data?.maxItemsForIndicators ?? this.maxItemsForIndicators;
  }

  public setActiveIndex(index: number) {
    this.activeIndex = index;
  }

  public previous() {
    if (this.activeIndex === 0) {
      this.activeIndex = (this.carouselItems?.length ?? 1) - 1;
    } else {
      --this.activeIndex;
    }
  }

  public next() {
    if (this.activeIndex === (this.carouselItems?.length ?? 1) - 1) {
      this.activeIndex = 0;
    } else {
      ++this.activeIndex;
    }
  }
}
