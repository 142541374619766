
import { reactive, defineComponent } from 'vue';
import TextInput from '../../../models/components/elements/text-input/text-input';

export default defineComponent({
  name: 'TextInput',
  props: {
    textInputModel: {
      type: Object as () => TextInput,
      required: true
    }
  },
  setup(props) {
    return {
      textInput: reactive(props.textInputModel)
    };
  }
});
