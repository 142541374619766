
import { reactive, defineComponent } from 'vue';
import DropdownSelect from '../../../models/components/elements/dropdown-select/dropdown-select';

export default defineComponent({
  name: 'DropdownSelect',
  props: {
    dropdownSelectModel: {
      type: Object as () => DropdownSelect,
      required: true
    }
  },
  emits: ['input-changed'],
  setup(props) {
    return {
      dropdownSelect: reactive(props.dropdownSelectModel)
    };
  },
  computed: {
    styleObject() {
      return {
        labelStyle: this.dropdownSelect.labelStyle
      };
    }
  },
  methods: {
    select(value: string | number) {
      this.dropdownSelect.select(value);
      this.dropdownSelect.validate();
      this.$emit('input-changed', value);
    }
  }
});
