
import { reactive, defineComponent } from 'vue';
import Checkbox from '../../../models/components/elements/checkbox/checkbox';

export default defineComponent({
  name: 'CheckboxCmp',
  props: {
    checkboxModel: {
      type: Object as () => Checkbox,
      required: true
    }
  },
  emits: ['click'],
  setup(props) {
    return {
      checkbox: reactive(props.checkboxModel)
    };
  },
  methods: {
    toggleCheckbox() {
      this.checkbox.toggle();
      this.$emit('click', this.checkbox.selected);
    },
    getInputClass() {
      return `form-check-input ${this.checkbox.isValid ? '' : 'is-invalid'}`;
    }
  }
});
