import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "container-fluid my-3"
}
const _hoisted_2 = {
  key: 0,
  class: "container my-3"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 1,
  class: "vh-100 position-relative"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_success = _resolveComponent("success")!
  const _component_checkout = _resolveComponent("checkout")!
  const _component_fab = _resolveComponent("fab")!
  const _component_empty_cart = _resolveComponent("empty-cart")!
  const _component_loader = _resolveComponent("loader")!

  return (!_ctx.checkout.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.checkout.showSuccessSection)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_success, { "payment-successful-model": _ctx.paymentSuccessfulInfo }, null, 8, ["payment-successful-model"])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (
          !!_ctx.checkout.productSummaries.combinedOrderSummary().length &&
          _ctx.checkout.cart.cartId !== null
        )
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_checkout, {
                      checkoutModel: _ctx.checkout,
                      onPayClicked: _ctx.onPayClicked,
                      onRemoveProduct: _ctx.onRemoveProduct,
                      onProductClicked: _ctx.onProductClicked,
                      onNextStepClicked: _ctx.onNextStepClicked,
                      onPromoCodeApplied: _ctx.onPromoCodeApplied,
                      onPromoCodeRemoved: _ctx.onPromoCodeRemoved,
                      onTermsConditionsOpened: _ctx.onTermsConditionsOpened
                    }, null, 8, ["checkoutModel", "onPayClicked", "onRemoveProduct", "onProductClicked", "onNextStepClicked", "onPromoCodeApplied", "onPromoCodeRemoved", "onTermsConditionsOpened"]),
                    _createVNode(_component_fab, {
                      iconProp: 'house',
                      action: _ctx.toHomeClick,
                      hasMobileFooterProp: true
                    }, null, 8, ["action"])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_empty_cart, {
                      "empty-cart-details": _ctx.checkout.emptyCartDetails
                    }, null, 8, ["empty-cart-details"])
                  ]))
            ]))
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_6, [
        _createVNode(_component_loader)
      ]))
}