
import { defineComponent, reactive } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import Property from '../components/block/property/property.vue';
import Loader from '../components/elements/loader/loader.vue';

import propertyControllerService from '../services/controllers/property-controller-service';
import cartControllerService from '../services/controllers/cart-controller-service';
import filterController from '../services/controllers/filter-controller-service';

import PropertyModel from '../models/components/block/property';
import PropertyDetails from '../models/components/standalone/property/property-details';
import TravelDetails from '../models/components/block/travel-details';
import PropertySearchPanel from '../models/components/block/property-search-panel';
import RoomDropdown from '../models/internal/room-dropdown/room-dropdown';
import Room from '../models/internal/room/room';
import Notification from '../models/internal/notification/notification';
import AvailableProperty from '../models/travia/available-property';
import IRoom from '../models/travia/room';
import SearchFilters from '../models/travia/search-filters';
import ProductItem from '../models/components/standalone/product/product-item';
import { toCheckout, toNotFound } from '../router';
import { SearchQueryParams } from '../models/internal/query-params/search-query';
import { notify } from '@kyvg/vue3-notification';
import axios from 'axios';
import IUnifiedCart from '../models/internal/cart/unified-cart';
import useCurrencies from '../composables/useCurrencies';
import useTranslation from '../composables/useTranslation';
import AccommodationModal from '../models/components/standalone/modals/accommodation-modal/accommodation-modal';
import AppConstants from '../constants/app-constants';

export default defineComponent({
  name: 'PropertyPage',
  components: {
    loader: Loader,
    property: Property
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  beforeRouteLeave(_to, _from) {
    document.body.style.overflow = '';
    document.body.style.paddingRight = '';
  },
  beforeRouteEnter(to, from) {
    const isFromResultsOrRefreshed =
      from.name === 'results' ||
      from.name === 'checkout' ||
      from.name === undefined; // specifiying undefined since it can be null
    /**
     * Only validate query params if page is coming from results or checkout to property.
     * Otherwise, allow navigation to proceed.
     */
    if (
      isFromResultsOrRefreshed &&
      to.name === 'property' &&
      !PropertySearchPanel.isQueryParamsValid(to.query)
    ) {
      notify(
        Notification.warning(
          'Some details in the URL seems to be invalid. Please try again.',
          '',
          -1,
          'centered'
        )
      );
      return { path: '/' };
    }
    return true;
  },
  setup() {
    const route = useRoute();
    const store = useStore();

    const currency = useCurrencies();
    useTranslation();

    const { params } = route;
    const query = route.query as SearchQueryParams;

    let inputModels = PropertySearchPanel.fromSearchProperties({
      accommodation: {
        guests: Number(query?.guests),
        rooms: Number(query?.rooms),
        children: Number(query?.children)
      },
      locationList: JSON.parse(query?.locations ?? '') as number[],
      properties: JSON.parse(query?.properties ?? '') as number[],
      startDate: query?.startDate,
      endDate: query?.endDate
    });
    return {
      productId: Number.parseInt(params.id as string) as number,
      property: reactive(
        new PropertyModel({
          loading: true,
          travelDetails: new TravelDetails({
            calendarModal: inputModels.calendarModal,
            accommodationModal: AccommodationModal.fromIAccommodationBasicArgs({
              guests: Number(query?.guests),
              rooms: Number(query?.rooms),
              children: Number(query?.children)
            }),
            roomDropdowns: [],
            currency: currency.currencyCode
          })
        })
      ) as PropertyModel
    };
  },
  async created() {
    if (this.$store.getters.propertyMap.propertyAmenityMap.length <= 0)
      await this.getPropertyTypesAsync();

    this.getPropertyInfoAsync(false);
    this.getBookedRoomsCount();
  },
  mounted() {
    document.addEventListener('visibilitychange', this.getBookedRoomsCount);
  },
  unmounted() {
    document.removeEventListener('visibilitychange', this.getBookedRoomsCount);
  },
  methods: {
    toCheckoutClick() {
      this.property.cancellationTokens.getPropertyRoomPrices.cancel();
      toCheckout();
    },
    onRoomsGuestsChanged(): void {
      const reqModel = this.property.getRoomsPricingModel();
      if (reqModel.rooms.some((room) => isNaN(room.guests))) return;

      this.property.travelDetails.cancellationTokens.getPropertyRoomPrices.cancel();

      propertyControllerService
        .getPropertyRoomPricesAsync(
          reqModel,
          this.property.travelDetails.cancellationTokens.getPropertyRoomPrices.setRunning(
            axios.CancelToken.source()
          )
        )
        .then((guestRoomPricesResp) => {
          const newItems = guestRoomPricesResp.filter((item) => {
            const isInList =
              this.property.travelDetails.guestsInRoomsPrices.some(
                (obj) =>
                  obj.guests === item.guests && obj.roomId === item.roomId
              );
            return !isInList;
          });
          this.property.travelDetails.guestsInRoomsPrices.push(...newItems);
          this.property.travelDetails.updatedRoomPricing();
          this.property.travelDetails.setRoomOccupancyLoadingOff();
        })
        .catch((err) => {
          if (!axios.isCancel(err))
            this.property.travelDetails.setRoomOccupancyLoadingOff();
        });
    },
    callAddToCartApi(proceedToCheckout: boolean): Promise<void> {
      this.property.travelDetails.isLoading = true;
      return cartControllerService
        .addBookingToCart(
          this.property.getBookingRequestModel(
            this.$store.getters.cartMap.cartId,
            this.$store.getters.selectedCurrency,
            this.$route.query as SearchQueryParams
          )
        )
        .then((cart: IUnifiedCart) => {
          this.property.travelDetails.isLoading = false;

          const cartId = localStorage.getItem(AppConstants.APP_CART_ID);
          if (!cartId && cart.cartId) {
            localStorage.setItem(
              AppConstants.APP_CART_ID,
              cart.cartId.toString()
            );
          }

          this.$store.commit('updateCartMap', cart);
          this.updateBookedRoomsCount();
          this.getBookedRoomsCount();
          this.$notify(
            Notification.success('Booking added to cart', 'Cart updated')
          );
          this.property.travelDetails.itemAddedDuringSession = true;

          if (proceedToCheckout) this.redirectToCheckout();
          else this.getPropertyInfoAsync();
        });
    },
    addToCart(proceedToCheckout: boolean): Promise<void> {
      return this.callAddToCartApi(proceedToCheckout)
        .catch((err) => {
          if (err.statusCode === 404) {
            // handle expired cart case
            this.$store.commit('updateCartMap', { cartId: null, bookings: [] });
            this.updateBookedRoomsCount();
            this.getBookedRoomsCount();
            this.callAddToCartApi(proceedToCheckout).catch((err) => {
              this.$notify(Notification.error(err.message));
            });
          } else {
            this.$notify(Notification.error(err.message));
          }
        })
        .finally(() => {
          this.property.travelDetails.isLoading = false;
        });
    },
    updateBookedRoomsCount() {
      this.$store.commit('updateBookedRoomsCount');
    },
    getBookedRoomsCount(): void {
      if (!document.hidden) {
        this.property.cartFabBadge.content =
          this.$store.getters.bookedRoomsCount;
      }
    },
    onSearchClicked(): void {
      this.$router.push({
        name: 'property',
        params: { id: this.productId },
        query: this.property.getRouterQuery()
      });
      this.getPropertyInfoAsync(true);
    },
    getPropertyInfoAsync(propertyAlreadyFound = false) {
      this.property.travelDetails.roomOccupancies = [];
      this.property.travelDetails.roomsLoading = true;

      propertyControllerService
        .getPropertyInfoAsync(
          this.property.getIPropertyInfoDto(
            this.productId,
            this.property.getRouterQuery() as SearchQueryParams
          )
        )
        .then((res: AvailableProperty) => {
          this.property.travelDetails.roomList = res.rooms.filter(
            ({ available }) => !!available
          );
          this.property.propertyDetails = PropertyDetails.fromAvailableProperty(
            res,
            this.$store.getters.selectedCurrency,
            this.$store.getters.propertyMap
          );
          this.property.travelDetails.possibleToFitGuests =
            res.possibleToFitGuests;

          this.property.travelDetails.roomDropdowns = res.rooms
            .map((room: IRoom) => {
              if (!room.available) return undefined;

              return new RoomDropdown({
                room: new Room({
                  id: room.id,
                  roomName: room.name,
                  available: room.available,
                  price: room.pricePerNight,
                  availableRooms: room.availableQuantity ?? 0,
                  minGuests: room.minOccupancy,
                  maxGuests: room.maxOccupancy
                })
              });
            })
            .filter(
              (room?: RoomDropdown) => room !== undefined
            ) as RoomDropdown[];
          this.property.travelDetails.addRoomOccupancy();

          if (!res.possibleToFitGuests)
            this.$notify(
              Notification.warning(
                'Not possible to fit guests in the chosen amount of rooms. Please change room / guests parameters.'
              )
            );
        })
        .catch((err) => {
          this.property.propertyFound = propertyAlreadyFound;
          this.property.propertyDetails.rooms.forEach((room: ProductItem) => {
            room.available = false;
            room.productPrice = null;
          });
          this.$notify(Notification.error(err.message));
        })
        .finally(() => {
          this.property.travelDetails.roomsLoading = false;
          this.property.loading = false;
          if (!this.property.propertyFound) toNotFound();
        });
    },
    async getPropertyTypesAsync() {
      filterController
        .getSearchFiltersAsync()
        .then(async (res: SearchFilters) => {
          this.$store.commit('updatePropertyMap', res.getAsMap());
        })
        .catch((err) => {
          this.$notify(Notification.error(err.message));
        });
    },
    redirectToCheckout() {
      this.$router.push('/checkout');
    }
  }
});
