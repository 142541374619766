import moment from 'moment';
import PriceRequestDto from '../../../models/caren/reservation/price-request-dto';
import ILocationInfo from '../../../models/caren/location-info';
import CarProductItem from '../standalone/product/car-product-item';
import CarExtras from './car-extras';
import CarInsurance from './car-insurance';

interface ILocationBase {
  dateTime?: string;
  location?: ILocationInfo;
  mapLink?: string;
}

interface ICarTravelDetails {
  isLoading?: boolean;

  rentalId: number;
  classId: number;

  dropOff?: ILocationBase;
  pickUp?: ILocationBase;
  car?: CarProductItem;
  carInsurance?: CarInsurance;
  carExtras?: CarExtras;
}

export default class CarTravelDetails implements ICarTravelDetails {
  isLoading: boolean;

  rentalId: number;
  classId: number;

  dropOff?: ILocationBase;
  pickUp?: ILocationBase;
  car: CarProductItem;
  carInsurance: CarInsurance;
  carExtras: CarExtras;

  private static searchUrl = 'https://www.google.com/maps/search/';

  constructor(data: ICarTravelDetails) {
    this.isLoading = data?.isLoading ?? false;
    this.rentalId = data?.rentalId;
    this.classId = data?.classId;
    this.pickUp = data?.pickUp;
    this.dropOff = data?.dropOff;
    this.car = data?.car ?? ({} as CarProductItem);
    this.carInsurance = data?.carInsurance ?? new CarInsurance();
    this.carExtras = data?.carExtras ?? new CarExtras();
  }

  static generateMapLink(locationName: string) {
    const parsedName = locationName.split(' ').join('+');
    return `${this.searchUrl}${parsedName}`;
  }

  get formattedDates() {
    return {
      pickUp: moment(this.pickUp?.dateTime).format('llll'),
      dropOff: moment(this.dropOff?.dateTime).format('llll')
    };
  }

  get totalPrice(): number {
    const carPrice = this.car.productPrice ?? 0;
    return carPrice + this.carInsurance.totalPrice + this.carExtras.totalPrice;
  }

  emitValues(): Pick<PriceRequestDto, 'extras' | 'insurances'> {
    return {
      extras: this.carExtras.selectedList,
      insurances: this.carInsurance.selectedIds
    };
  }
}
