import LocationOrProperty from '../../travia/locations-and-properties';
import { SelectOption } from '../../components/elements/options/select-option';
import ILocation from '../../travia/location';

interface ILocationInput {
  models?: LocationOrProperty[];
  selectedModels?: LocationOrProperty[];
  selectedLocationIds?: number[];
  selectedPropertyIds?: number[];
}

export default class LocationInput implements ILocationInput {
  models: LocationOrProperty[];
  selectedModels: LocationOrProperty[];
  selectedLocationIds: number[];
  selectedPropertyIds: number[];

  private preSelectedLocations = [0]; // Where Reykjavik is 0.

  constructor(data?: ILocationInput) {
    this.models = data?.models ?? [];
    this.selectedModels = data?.selectedModels ?? [];
    this.selectedLocationIds = data?.selectedLocationIds ?? [];
    this.selectedPropertyIds = data?.selectedPropertyIds ?? [];

    if (
      this.selectedLocationIds.length > 0 ||
      this.selectedPropertyIds.length > 0
    ) {
      this.mapIdsToLocationOrProperty();
    }
  }

  get options(): SelectOption[] {
    if (!this.models) return [];

    return this.models
      .map((item) => {
        const isSelected = this.selectedModels?.find(
          (selected) => selected.value === item.value
        );
        return new SelectOption({
          ...item,
          selected: !!isSelected
        });
      })
      .sort(this.sortBySelected);
  }

  get selectedLocations(): ILocation[] {
    return this.selectedModels
      .map((sModels) => sModels.location)
      .filter((loc) => loc !== undefined) as ILocation[];
  }

  mapIdsToLocationOrProperty() {
    const selectedLocations = this.models.filter(
      (model) =>
        this.selectedLocationIds.includes(model.id) && model.type === 'location'
    );
    const selectedProperties = this.models.filter(
      (model) =>
        this.selectedPropertyIds.includes(model.id) && model.type === 'property'
    );

    this.selectedModels = [...selectedLocations, ...selectedProperties];
  }

  sortBySelected(optionA: SelectOption, optionB: SelectOption) {
    if (optionA.selected === optionB.selected) return 0;
    return optionB.selected ? -1 : 1;
  }

  initSelections() {
    const allSelectedModels = JSON.parse(
      JSON.stringify(this.selectedModels)
    ) as LocationOrProperty[];

    allSelectedModels.forEach((sm) => {
      if (this.models.find((m) => sm.value === m.value)) return;

      this.selectedModels = this.selectedModels.filter(
        (x) => x.value !== sm.value
      );
    });
  }

  /** Pre-selects a set of location or property */
  setDefaultSelected() {
    const model = this.models.find(
      (model) =>
        this.preSelectedLocations.includes(model.id) &&
        model.type === 'location'
    );
    if (model) {
      this.selectedModels.push(model);
    }
  }

  toggleSelection(id: number, selected: boolean, type: string) {
    if (!this.selectedModels) this.selectedModels = [];

    if (!this.selectedModels?.some((x) => x.id === id)) {
      if (selected) {
        const model = this.models?.find((x) => x.id === id && x.type === type);
        if (model) this.selectedModels.push(model);
      }
    } else
      this.selectedModels = this.selectedModels?.filter((x) => x.id !== id);
  }

  validate(): boolean {
    return this.selectedModels.length > 0;
  }
}
