export interface IEmptyCart {
  title: string;
  message: string;
  buttonTitle?: string;
  onButtonClick: () => void;
}

export default class EmptyCartDetails implements IEmptyCart {
  title: string;
  message: string;
  buttonTitle?: string;
  onButtonClick: () => void;

  constructor(data: IEmptyCart) {
    this.title = data.title;
    this.message = data.message;
    this.buttonTitle = data?.buttonTitle ?? 'Shop properties';
    this.onButtonClick = data.onButtonClick;
  }
}
