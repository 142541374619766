import { SelectOption } from '../../elements/options/select-option';
import PropertyProductItem from '../../standalone/product/property-product-item';

export interface ISidebarFilters {
  propertyAmenityOptions?: SelectOption[];
  propertyTypeOptions?: SelectOption[];
  availablePropertyAmenityOptions?: string[];
  availablePropertyTypeOptions?: string[];
}

export default class SidebarFilters implements ISidebarFilters {
  availablePropertyAmenityOptions: string[];
  availablePropertyTypeOptions: string[];
  private _propertyAmenityOptions: SelectOption[] = [];
  private _propertyTypeOptions: SelectOption[] = [];

  get propertyAmenityOptions(): SelectOption[] {
    return this._propertyAmenityOptions
      .map((opt: SelectOption) => {
        return new SelectOption({
          ...opt,
          disabled: !this.availablePropertyAmenityOptions.find(
            (val: string) => opt.value.toUpperCase() === val.toUpperCase()
          )
        });
      })
      .sort((opt: SelectOption) => (opt.disabled ? 1 : -1));
  }
  set propertyAmenityOptions(options: SelectOption[]) {
    this._propertyAmenityOptions = options;
  }

  get propertyTypeOptions(): SelectOption[] {
    return this._propertyTypeOptions
      .map((opt: SelectOption) => {
        return new SelectOption({
          ...opt,
          disabled: !this.availablePropertyTypeOptions.find(
            (val: string) => opt.value.toUpperCase() === val.toUpperCase()
          )
        });
      })
      .sort((opt: SelectOption) => (opt.disabled ? 1 : -1));
  }
  set propertyTypeOptions(options: SelectOption[]) {
    this._propertyTypeOptions = options;
  }

  /*
   *
   * CONSTRUCTOR
   *
   */
  constructor(data?: ISidebarFilters) {
    this.availablePropertyAmenityOptions =
      data?.availablePropertyAmenityOptions ?? [];
    this.availablePropertyTypeOptions =
      data?.availablePropertyTypeOptions ?? [];
    this.propertyAmenityOptions = data?.propertyAmenityOptions ?? [];
    this.propertyTypeOptions = data?.propertyTypeOptions ?? [];
  }

  get hasPropertyTypes(): boolean {
    return this.propertyTypeOptions
      ? this.propertyTypeOptions.length > 0
      : false;
  }

  get hasPropertyAmenities(): boolean {
    return this.propertyAmenityOptions
      ? this.propertyAmenityOptions.length > 0
      : false;
  }

  get sidebarFilters(): ISidebarFilters {
    return {
      propertyTypeOptions: this._propertyTypeOptions.map((op: SelectOption) => {
        return { ...op } as SelectOption;
      }),
      propertyAmenityOptions: this._propertyAmenityOptions.map(
        (op: SelectOption) => {
          return { ...op } as SelectOption;
        }
      ),
      availablePropertyTypeOptions: this.availablePropertyTypeOptions,
      availablePropertyAmenityOptions: this.availablePropertyAmenityOptions
    };
  }

  filterOptions(filteredResults: PropertyProductItem[]) {
    const amenityCodes: string[] = [];
    /** Retrieves the productTypeName and amenity code from the filtered result and updates  */
    filteredResults.forEach((result) => {
      result.amenities.forEach((code) =>
        amenityCodes.includes(code) ? code : amenityCodes.push(code)
      );
    });
    this.availablePropertyAmenityOptions = amenityCodes;
  }
}
