import { notify } from '@kyvg/vue3-notification';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import Notification from '../models/internal/notification/notification';

export default function PaymentWebhook<T>(
  cartId: string,
  successCallback: () => T
) {
  const connection = new HubConnectionBuilder()
    .withUrl(`${process.env.VUE_APP_API_URL}hub?cartId=${cartId}`)
    .withAutomaticReconnect()
    .configureLogging(LogLevel.Information)
    .build();

  connection.on('ReceivePaymentConfirmation', () => {
    successCallback();
  });

  connection
    .start()
    .then(() => {
      console.log(
        `Signalr - Connection: ${connection?.state} with ${connection?.connectionId}`
      );
    })
    .catch((err) => {
      notify(Notification.error(err.message, 'Signalr', 5000));
      setTimeout(() => {
        connection?.start();
      }, 5000); // Restart connection after 5 seconds.
    });

  return {
    connection
  };
}
