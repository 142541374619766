
import { reactive, defineComponent } from 'vue';
import IconWithTextCmp from '../../elements/icon-with-text/icon-with-text.vue';
import Carousel from '../../standalone/carousel/carousel.vue';
import ProductItem from '../../../models/components/standalone/product/product-item';
import RoomProductItem from '../../../models/components/standalone/product/room-product-item';
import CarProductItem from '../../../models/components/standalone/product/car-product-item';
import IconWithText from '../../../models/components/elements/icon-with-text/icon-with-text';
import useCurrencies from '../../../composables/useCurrencies';

export default defineComponent({
  name: 'ProductCmp',
  components: {
    icon: IconWithTextCmp,
    carousel: Carousel
  },
  props: {
    productModel: {
      type: Object as () => ProductItem | RoomProductItem | CarProductItem,
      required: true
    },
    buttonTextProp: {
      type: String,
      required: false,
      default: ''
    },
    detailsOnlyProp: {
      type: Boolean,
      required: false
    }
  },
  emits: ['property-clicked'],
  setup(props) {
    const currency = useCurrencies();
    return {
      product: reactive(props.productModel),
      buttonText: props.buttonTextProp,
      detailsOnly: props.detailsOnlyProp,
      currency
    };
  },
  methods: {
    onProductClicked() {
      this.$emit('property-clicked', this.product);
    },
    getAmenitiesContainerClass(field: IconWithText) {
      if (this.product.isHorizontal) {
        return 'col-lg-4 col-6';
      }
      if (field.inline || !field.content) {
        return 'col-12';
      }

      return 'col-6';
    }
  }
});
