
import { reactive, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import CalendarModal from '../../standalone/calendar-modal/calendar-modal.vue';
import AccommodationModal from '../../standalone/accommodation-modal/accommodation-modal.vue';
import TravelDetails from '../../../models/components/block/travel-details';
import DropdownSelect from '../../elements/dropdown-select/dropdown-select.vue';
import Loader from '../../elements/loader/loader.vue';
import RoomDropdown from '../../../models/internal/room-dropdown/room-dropdown';
import useCurrencies from '../../../composables/useCurrencies';
import Switch from '../../elements/switch/switch.vue';
import StringFormatUtils from '../../../utils/stringFormatUtils';

export default defineComponent({
  name: 'TravelDetails',
  components: {
    loader: Loader,
    'calendar-modal': CalendarModal,
    'accommodation-modal': AccommodationModal,
    'dropdown-select': DropdownSelect,
    'switch-checkbox': Switch
  },
  props: {
    travelDetailsModel: {
      type: Object as () => TravelDetails,
      required: true
    }
  },
  emits: [
    'search-clicked',
    'add-to-cart-clicked',
    'rooms-guests-changed',
    'to-checkout-clicked'
  ],
  setup(props) {
    const store = useStore();
    const currency = useCurrencies();

    return {
      StringFormatUtils,
      travelDetails: reactive(props.travelDetailsModel),
      searchParametersChanged: ref(false),
      themePrefix: ref(store.getters.theme),
      currency: currency
    };
  },
  data() {
    return {
      invalidGuestsRooms: [] as string[]
    };
  },
  computed: {
    selectionIsValid(): boolean {
      const isAnOccupancyLoading = this.travelDetails.roomOccupancies.some(
        ({ loading }) => loading
      );
      return (
        this.travelDetails.totalRoomsSelected !== 0 &&
        this.travelDetails.isRoomAllocationValid &&
        !this.travelDetails.roomsLoading &&
        !this.searchParametersChanged &&
        !isAnOccupancyLoading
      );
    },
    isAllowedToCheckout(): boolean {
      const hasAddedAndNotLoading =
        this.travelDetails.itemAddedDuringSession &&
        !this.travelDetails.roomsLoading;
      return hasAddedAndNotLoading || this.selectionIsValid;
    },
    areSearchParametersValid(): boolean {
      return (
        this.travelDetails.isValid &&
        this.travelDetails.accommodationModal.isValid
      );
    }
  },
  watch: {
    'travelDetails.calendarModal': {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      handler(_o, _n) {
        this.searchParametersChanged = true;
        this.travelDetails.roomOccupancies = [];
        this.travelDetails.roomList = [];
      },
      deep: true
    }
  },
  methods: {
    handleInputUpdates(roomOccupancyIdx) {
      const roomOccupancy =
        this.travelDetails.roomOccupancies[roomOccupancyIdx];

      roomOccupancy.loading = true;
      this.travelDetails.updateRoomOccupancyLimits(roomOccupancy.id);
      this.travelDetails.updateRoomAvailability();
      return this.$emit('rooms-guests-changed');
    },
    onHasInvalidGuests(roomDropdown: RoomDropdown, allocationId: number): void {
      const key = `${roomDropdown.room.id}-${allocationId}`;
      if (this.invalidGuestsRooms.find((x) => x === key)) return;

      this.invalidGuestsRooms.push(key);
    },
    redoSearch(): void {
      this.$emit('search-clicked');
      this.searchParametersChanged = false;
    },
    addToCart(proceedToCheckout: boolean): void {
      this.$emit('add-to-cart-clicked', proceedToCheckout);
    },
    onToCheckoutClicked(): void {
      this.$emit('to-checkout-clicked');
    }
  }
});
