
import { defineComponent, reactive } from "vue";

import Checkbox from '../../elements/checkbox/checkbox.vue';
import CarInsurance from "../../../models/components/block/car-insurance";
import CarPriceDetails from '../car-price-details/car-price-details.vue';

export default defineComponent({
  components: {
    "checkbox": Checkbox,
    "car-price-details": CarPriceDetails
  },
  props: {
    carInsuranceModel: {
      type: Object as () => CarInsurance,
      required: true
    }
  },
  setup(props) {
    return {
      carInsurance: reactive(props.carInsuranceModel),
    }
  },
})
