import IInsuranceDetailedDto from '../../caren/extras/insurance-detailed-dto';
import Checkbox from '../elements/checkbox/checkbox';

interface ICarInsurance {
  isLoading?: boolean;
  numberOfDays?: number;
  list?: ICarInsuranceOption[];
  selectedIds?: number[];
}

interface ICarInsuranceOption {
  details: IInsuranceDetailedDto;
  checkbox: Checkbox;
}

export default class CarInsurance implements ICarInsurance {
  isLoading: boolean;
  list: ICarInsuranceOption[];
  selectedIds: number[];
  numberOfDays: number;

  constructor(data?: ICarInsurance) {
    this.isLoading = data?.isLoading ?? false;
    this.list = data?.list ?? [];
    this.selectedIds = data?.selectedIds ?? [];
    this.numberOfDays = data?.numberOfDays ?? 0;
  }

  /** Sorts the list where included insurances are placed first. */
  get sortedList(): ICarInsuranceOption[] {
    return this.list.sort((insurance) => (insurance.details.included ? -1 : 1));
  }

  /**
   * If insurance policy selected is priced per day, multiply unit price to number of days.
   * If it's a package which is not priced per day, return the unit price as the final price.
   */
  get totalPrice(): number {
    return this.selectedObjects().reduce((prev, curr) => {
      const initialPrice = curr.details.pricePerDay
        ? curr.details.price * this.numberOfDays
        : curr.details.price;
      return initialPrice + prev;
    }, 0);
  }

  selectedObjects(): ICarInsuranceOption[] {
    return this.list.filter((insurance) =>
      this.selectedIds.includes(Number(insurance.details.id))
    );
  }

  selectInsurance(isChecked: boolean, id: number) {
    if (!isChecked) {
      const insuranceIdx = this.selectedIds?.indexOf(id);
      return this.selectedIds.splice(insuranceIdx, 1);
    }

    return this.selectedIds.push(id);
  }
}
