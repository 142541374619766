import BaseHttpClientService from '../base-http-client-service';
import IInsuranceDetailedDto from '../../models/caren/extras/insurance-detailed-dto';
import IExtraDetailedDto from 'src/models/caren/extras/extra-detailed-dto';

interface ICarExtrasControllerService {
  getInsuranceList(
    rentalId: number,
    classId: number
  ): Promise<IInsuranceDetailedDto[]>;
}

class CarExtrasControllerService
  extends BaseHttpClientService
  implements ICarExtrasControllerService {
  getInsuranceList(
    rentalId: number,
    classId: number
  ): Promise<IInsuranceDetailedDto[]> {
    return this.httpClient
      .post('api/extra/caren/get-insurance-list', { rentalId, classId })
      .then((resp) => this.prepareResponse(resp).content);
  }

  getExtrasList(
    rentalId: number,
    classId: number
  ): Promise<IExtraDetailedDto[]> {
    return this.httpClient
      .post('api/extra/caren/get-extra-list', { rentalId, classId })
      .then((resp) => this.prepareResponse(resp).content);
  }
}

export default new CarExtrasControllerService() as CarExtrasControllerService;
