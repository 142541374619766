
import { defineComponent } from '@vue/runtime-core';
import DropdownMultiselectCmp from '../../../components/elements/dropdown-multiselect/dropdown-multiselect.vue';
import SearchDropdown from '../../../components/elements/dropdown/dropdown.vue';
import TextInputCmp from '../../../components/elements/text-input/text-input.vue';
import DropdownSelectCmp from '../../../components/elements/dropdown-select/dropdown-select.vue';
import CheckboxCmp from '../checkbox/checkbox.vue';
import PhoneInput from '../phone-input/phone-input.vue';
import { DynamicInput } from '../../../models/components/elements/dynamic-input/dynamic-input';
import NumericInputCmp from '../numeric-input/numeric-input.vue';
import DateInputCmp from '../date-input/date-input.vue';
import TextAreaCmp from '../text-area/text-area.vue';
export default defineComponent({
  name: 'DynamicInput',
  components: {
    multiselect: DropdownMultiselectCmp,
    dropdown: DropdownSelectCmp,
    checkbox: CheckboxCmp,
    'search-dropdown': SearchDropdown,
    'text-input': TextInputCmp,
    'phone-input': PhoneInput,
    'numeric-input': NumericInputCmp,
    'date-input': DateInputCmp,
    'text-area': TextAreaCmp
  },
  props: {
    dynamicInputModel: {
      type: Object as () => DynamicInput,
      required: true
    }
  },
  setup() {
    return {
      DynamicInput
    };
  }
});
