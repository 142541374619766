
import moment from 'moment';
import { defineComponent, reactive } from 'vue';
import { useRoute } from 'vue-router';

import Car from '../components/block/car/car.vue';
import Checkbox from '../models/components/elements/checkbox/checkbox';
import CarSearchPanel from '../models/components/block/car-search-panel';
import CarProductItem from '../models/components/standalone/product/car-product-item';
import locationControllerService from '../services/controllers/location-controller-service';
import carExtrasControllerService from '../services/controllers/car-extras-controller-service';
import vehicleControllerService from '../services/controllers/vehicle-controller-service';
import ILocationInfo from '../models/caren/location-info';
import Notification from '../models/internal/notification/notification';
import ILogo from '../models/travia/logo';
import CarInsurance from '../models/components/block/car-insurance';
import CarTravelDetails from '../models/components/block/car-travel-details';
import NumericInput from '../models/components/elements/numeric-input/numeric-input';
import IExtraDetailedDto from '../models/caren/extras/extra-detailed-dto';
import IInsuranceDetailedDto from '../models/caren/extras/insurance-detailed-dto';
import CarSearchResult from '../models/internal/vehicle/car-search-result';
import CarExtras from '../models/components/block/car-extras';
import { CurrencyType } from '../models/internal/currency/currency';
import { CarSearchQueryParams } from '../models/internal/car-query-params/car-search-query';
import { SelectOption } from '../models/components/elements/options/select-option';

export default defineComponent({
  components: {
    'car': Car
  },
  setup() {
    const { query, params } = useRoute();
    const pickUpDate = `${query?.dateFrom} ${query?.dateFromTime} ${query?.pickUpPeriod}`;
    const dropOffDate = `${query?.dateTo} ${query?.dateToTime} ${query?.dropOffPeriod}`;

    const numberOfDays = moment(dropOffDate)
      .utc()
      .diff(moment(pickUpDate).utc(), 'days');

    return {
      pickUpDate: pickUpDate,
      dropOffDate: dropOffDate,
      carSearchPanelInputs: reactive(
        new CarSearchPanel({ isLoading: true, isValid: true })
      ),
      carTravelDetails: reactive(
        new CarTravelDetails({
          car: { isLoading: true } as CarProductItem,
          rentalId: Number(query?.rentalId),
          classId: Number(params?.id),
          pickUp: { dateTime: pickUpDate },
          dropOff: { dateTime: dropOffDate },
          isLoading: true,
          carInsurance: new CarInsurance({ numberOfDays: numberOfDays }),
          carExtras: new CarExtras({ numberOfDays: numberOfDays })
        })
      )
    };
  },
  mounted() {
    this.getLocations();
    this.initCarDetails();
    this.initPage();
    this.initExtras();
    this.initInsurance();
  },
  methods: {
    getLocations(): void {
        vehicleControllerService.getRentalList()
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .then(([aa, caren]) => {
            this.carSearchPanelInputs.isLoading = true;
            /**
             * Selecting caren's rentalId from the response.
             * Replace caren to AA when needed.
             */
            const { id } = caren; 
            this.carSearchPanelInputs.rentalId = id;
            /** Retrieve all locations */
            this.getDropOffLocations();
            this.getPickUpLocations();
        })
        .catch((err) => {
            this.$notify(Notification.error(err.message));
        })
    },
    getDropOffLocations(): void {
        const carPanel = this.carSearchPanelInputs;
        const { rentalId } = carPanel;
        locationControllerService.getDropOffList(rentalId).then((dropOffLocations) => {
          carPanel.dropOffDrodown.options = SelectOption.fromDropOffPickUpLocations(dropOffLocations, carPanel.selectedDropOffLocationId);
        })
        .catch((err) => {
          this.$notify(Notification.error(err.message));
          carPanel.dropOffDrodown.options = [];
        })
        .finally(() => {
          carPanel.dropOffDrodown.filterOptions();
        })
    },
    getPickUpLocations(): void {
      const carPanel = this.carSearchPanelInputs;
      const { rentalId } = carPanel;
      locationControllerService.getPickUpList(rentalId).then((pickUpLocations) => {
        carPanel.pickUpDropdown.options = SelectOption.fromDropOffPickUpLocations(pickUpLocations, carPanel.selectedPickUpLocationId);
      })
      .catch((err) => {
        this.$notify(Notification.error(err.message));
        carPanel.pickUpDropdown.options = [];
      })
      .finally(() => {
        carPanel.pickUpDropdown.filterOptions();
        carPanel.isLoading = false;
      })
    },
    getInsuranceList(): Promise<IInsuranceDetailedDto[]> {
      this.carTravelDetails.carInsurance.isLoading = true;
      const { rentalId, classId } = this.carTravelDetails;
      let insuranceList: IInsuranceDetailedDto[] = [];
      return carExtrasControllerService
        .getInsuranceList(rentalId, classId)
        .then((res) => {
          insuranceList = res;
          return insuranceList;
        })
        .catch((err) => {
          insuranceList = [];
          this.$notify(Notification.error(err.message));
          return insuranceList;
        })
        .finally(() => {
          this.carTravelDetails.carInsurance.isLoading = false;
          return insuranceList;
        });
    },
    getExtrasList(): Promise<IExtraDetailedDto[]> {
      this.carTravelDetails.carExtras.isLoading = true;
      const { rentalId, classId } = this.carTravelDetails;
      let extrasList: IExtraDetailedDto[] = [];
      return carExtrasControllerService
        .getExtrasList(rentalId, classId)
        .then((res) => {
          extrasList = res;
          return extrasList;
        })
        .catch((err) => {
          extrasList = [];
          this.$notify(Notification.error(err.message));
          return extrasList;
        })
        .finally(() => {
          this.carTravelDetails.carExtras.isLoading = false;
          return extrasList;
        });
    },
    getVehicleInfo(): Promise<CarSearchResult | undefined> {
      this.carTravelDetails.car.isLoading = true;
      const vehicleRequestDto = {
        Id: this.carTravelDetails.classId,
        RentalId: this.carTravelDetails.rentalId
      };
      let carDetails = {} as CarSearchResult | undefined;
      return vehicleControllerService
        .getClass(vehicleRequestDto)
        .then((res) => {
          carDetails = res;
          return carDetails;
        })
        .catch((err) => {
          carDetails = undefined;
          this.$notify(Notification.error(err.message));
          return carDetails;
        })
        .finally(() => {
          this.carTravelDetails.car.isLoading = false;
          return carDetails;
        });
    },
    getLocationDetails(locationId: number): Promise<ILocationInfo | undefined> {
      this.carTravelDetails.isLoading = true;
      const rentalId = this.carTravelDetails.rentalId;
      const id = locationId;
      let location: ILocationInfo | undefined = undefined;
      return locationControllerService
        .getLocationinfo(rentalId, id)
        .then((res) => {
          location = res;
          return location;
        })
        .catch((err) => {
          this.$notify(Notification.error(err.message));
          return location;
        })
        .finally(() => {
          this.carTravelDetails.isLoading = false;
          return location;
        });
    },
    addToCart(): void {
      const values = this.carTravelDetails.emitValues();
      console.log({ values });
    },
    async initCarDetails() {
      const carDetails = await this.getVehicleInfo();
      if (carDetails) {
        const carProduct = new CarProductItem({
          id: carDetails?.id,
          seats: carDetails?.seats,
          doors: carDetails?.doors,
          model: carDetails?.model,
          productName: carDetails?.name,
          productTypeName: 'Car',
          available: carDetails?.available,
          productPrice: carDetails?.unitPrice,
          currencyType: 'ISK' as CurrencyType,
          isHorizontal: true,
          smallBags: carDetails?.smallBags,
          largeBags: carDetails?.largeBags,
          transmissionType: carDetails?.transmissionName,
          forHighland: carDetails?.forHighland,
          fuelType: carDetails?.fuelName,
          iconsWithText: CarProductItem.getIconMappings(carDetails),
          productImages: [
            { thumbnailPath: CarProductItem.getImageURL(carDetails.image) }
          ] as ILogo[],
          transmissionId: carDetails.transmissionId,
          fuelId: carDetails.fuelId,
          groups: carDetails.groups
        });
        this.carTravelDetails.car = carProduct;
      }
    },
    async initPage() {
      const query = this.$route.query as CarSearchQueryParams;
      this.carTravelDetails.isLoading = true;
      const pickUpLocation = await this.getLocationDetails(
        Number(query?.pickupLocationId)
      );
      const dropOffLocation = await this.getLocationDetails(
        Number(query?.dropoffLocationId)
      );

      this.carTravelDetails.pickUp = {
        location: pickUpLocation,
        mapLink: CarTravelDetails.generateMapLink(pickUpLocation?.name ?? '')
      };

      this.carTravelDetails.dropOff = {
        location: dropOffLocation,
        mapLink: CarTravelDetails.generateMapLink(dropOffLocation?.name ?? '')
      };
    },
    async initInsurance() {
      const insuranceList = await this.getInsuranceList();
      const insuranceOptions = insuranceList.map((insurance) => ({
        details: insurance,
        checkbox: new Checkbox({
          withValidation: false,
          label: '',
          selected: insurance.included,
          isDisabled: insurance.included
        })
      }));
      this.carTravelDetails.carInsurance.list = insuranceOptions;
    },
    async initExtras() {
      const extras = await this.getExtrasList();
      const extrasOptions = extras.map((extra) => {
        /** Some extras are checkboxes, some are numeric inputs. */
        const isSingle = extra.maximumQuantity === 1;
        const checkbox = new Checkbox({
          withValidation: false,
          label: '',
          selected: false
        });
        const numericInput = new NumericInput(
          {
            label: '',
            value: 0
          },
          {
            withValidation: true,
            minValue: 0,
            maxValue: Number(extra.maximumQuantity),
            validationMessage: 'At least one quantity'
          }
        );

        return {
          isCheckbox: isSingle,
          details: extra,
          input: isSingle ? checkbox : numericInput
        };
      });
      this.carTravelDetails.carExtras.list = extrasOptions;
    }
  }
});
