import Transmissions from '../../models/caren/system/transmissions';
import GroupRequestDtoModel from '../../models/caren/system/group-request-dto';
import Groups from '../../models/caren/system/groups';
import BaseHttpClientService from '../base-http-client-service';
import Fuels from '../../models/caren/system/fuels';
import Extras from '../../models/caren/system/extras-dto';

interface ISystemControllerService {
  getTransmissionList(): Promise<Transmissions>;
  getGroupList(groupRequestDto: GroupRequestDtoModel): Promise<Groups>;
  getFuelList(): Promise<Fuels>;
  getExtrasList(): Extras;
}

class SystemControllerService
  extends BaseHttpClientService
  implements ISystemControllerService
{
  async getTransmissionList(): Promise<Transmissions> {
    return this.httpClient
      .get('api/system/caren/get-transmission-list')
      .then((res) => {
        const genericResp = this.prepareResponse(res);
        return new Transmissions(genericResp.content);
      });
  }

  async getGroupList(groupRequestDto: GroupRequestDtoModel): Promise<Groups> {
    return this.httpClient
      .post('api/system/caren/get-group-list', groupRequestDto)
      .then((res) => {
        const genericResp = this.prepareResponse(res);
        return new Groups(genericResp.content);
      });
  }

  async getFuelList(): Promise<Fuels> {
    return this.httpClient.get('api/system/caren/get-fuel-list').then((res) => {
      const genericResp = this.prepareResponse(res);
      return new Fuels(genericResp.content);
    });
  }

  getExtrasList(): Extras {
    return Extras.getDefaultExtras();
  }
}

export default new SystemControllerService() as ISystemControllerService;
