import INumericInputValidation from '../../../validation/numeric-input-validation';

export interface INumericInput {
  label?: string;
  value?: number;
  withInput?: boolean;
  isDisabled?: boolean;
  allowDecimal?: boolean;
  isHorizontal?: boolean;
  helperText?: string;
}

export default class NumericInput implements INumericInputValidation {
  label = '';
  helperText = '';
  value = 0;

  withValidation = false;
  allowOnlyValid = true;
  validationMessage?: string | undefined;
  minValue?: number | undefined;
  maxValue?: number | undefined;
  isValid = true;
  isDisabled = false;
  withInput = true;
  allowDecimal = false;
  isHorizontal = true;

  constructor(data: INumericInput, validation?: INumericInputValidation) {
    this.label = data?.label ?? this.label;
    this.value = data?.value ?? this.value;
    this.withInput = data?.withInput ?? this.withInput;
    this.isDisabled = data?.isDisabled ?? this.isDisabled;
    this.allowDecimal = data?.allowDecimal ?? this.allowDecimal;
    this.isHorizontal = data?.isHorizontal ?? this.isHorizontal;
    this.helperText = data?.helperText ?? this.helperText;

    if (validation !== undefined) {
      this.withValidation = validation.withValidation ?? false;
      this.minValue = validation.minValue;
      this.maxValue = validation.maxValue;
      this.validationMessage = validation.validationMessage;
      this.allowOnlyValid = validation.allowOnlyValid ?? this.allowOnlyValid;
    } else {
      this.withValidation = false;
    }
  }

  get displayValue(): number {
    const isInvalidValue = isNaN(this.value ?? Number.NaN) || this.value <= 0;
    return isInvalidValue ? 0 : this.value;
  }

  isFloat(value: number) {
    return typeof value === 'number' && !!(value % 1);
  }

  validate(lastValue?: number): boolean {
    let wasEmpty = false;
    if ((this.value as any) === '') wasEmpty = true;

    if (this.allowDecimal) {
      this.value = this.isFloat(this.value)
        ? Number(this.value.toFixed(2))
        : Number(parseFloat(String(this.value)).toFixed(2));
    } else {
      this.value = Number.isInteger(this.value)
        ? this.value
        : parseInt(String(this.value).replace(/[^0-9]+/g, ''));
    }

    this.value = isNaN(this.value) ? 0 : this.value;

    if (!this.withValidation) {
      this.isValid = true;
      return this.isValid;
    }

    if (
      this.value !== undefined &&
      this.minValue !== undefined &&
      this.minValue !== null &&
      this.maxValue !== undefined &&
      this.maxValue !== null
    ) {
      this.isValid = this.withValidation
        ? this.value >= this.minValue && this.value <= this.maxValue
        : true;
    } else if (
      this.value !== undefined &&
      ((this.minValue !== undefined && this.minValue !== null) ||
        (this.maxValue !== undefined && this.maxValue !== null))
    ) {
      if (this.minValue !== undefined && this.minValue !== null) {
        this.isValid = this.value >= this.minValue;
      } else if (this.maxValue !== undefined && this.maxValue !== null) {
        this.isValid = this.value <= this.maxValue;
      }
    } else {
      this.isValid = false;
    }

    if (wasEmpty) {
      (this.value as any) = '';
      return this.isValid;
    }

    return this.allowOnlyValid ? this.setValidValue(lastValue) : this.isValid;
  }

  private setValidValue(lastValue?: number): boolean {
    if (!this.value && this.value !== 0) return this.isValid;

    if ((this.maxValue || this.maxValue === 0) && this.value > this.maxValue)
      this.value = this.isValid ? lastValue ?? this.value : this.maxValue;

    if (
      (this.minValue || this.minValue === 0) &&
      Number.parseInt(this.value.toString()) <
        Number.parseInt(this.minValue.toString())
    )
      this.value = this.isValid ? lastValue ?? this.value : this.minValue;

    this.isValid = true;
    return this.isValid;
  }
}
