
import { reactive, defineComponent } from '@vue/runtime-core';
import CreditCardInputs from '../../../models/components/block/credit-card-inputs';
import CardNumberInput from '../../elements/card-number-input/card-number-input.vue';
import DigitInput from '../../elements/digit-input/digit-input.vue';
import CardExpiryInput from '../../elements/card-expiry-input/card-expiry-input.vue';
import textInputVue from '../../elements/text-input/text-input.vue';
import supportedPaymentIconsVue from '../../standalone/supported-payment-icons/supported-payment-icons.vue';
import AppConstants from '../../../constants/app-constants';
import StripeCheckout from '../../../models/internal/stripe-checkout/stripe-checkout';
import { PaymentProvider } from '../../../models/enums/payment-provider';

export default defineComponent({
  name: 'CreditCardInputs',
  components: {
    'card-number-input': CardNumberInput,
    'digit-input': DigitInput,
    'card-expiry-input': CardExpiryInput,
    'text-input': textInputVue,
    'supported-payment-icons': supportedPaymentIconsVue
  },
  props: {
    creditCardInputsModel: {
      type: Object as () => CreditCardInputs,
      required: true
    },
    isCardPayment: {
      type: Boolean,
      required: true
    },
    paymentProvider: {
      type: String as () => PaymentProvider,
      required: true
    },
    stripeCheckout: {
      type: Object as () => StripeCheckout,
      required: true
    }
  },
  emits: ['inputs-changed'],
  setup(props) {
    return {
      creditCardInputs: reactive(props.creditCardInputsModel),
      paymentOptions: AppConstants.PAYMENT_OPTIONS
    };
  },
  computed: {
    isStripeReady() {
      return (
        this.stripeCheckout.isGoogleApplePayAvailable &&
        !this.stripeCheckout.loading
      );
    },
    shouldShowStripeCard() {
      return (
        this.creditCardInputs.isCardPayment &&
        this.paymentProvider === PaymentProvider.STRIPE
      );
    },
    shouldShowPaymentForm() {
      return (
        this.creditCardInputs.isCardPayment &&
        this.paymentProvider === PaymentProvider.BORGUN
      );
    }
  },
  mounted() {
    const cardElem = this.$refs['card-elem'] as HTMLElement;
    this.stripeCheckout.listenAndMountCard(cardElem);
  },
  methods: {
    onBlur() {
      if (this.creditCardInputs.validate()) {
        this.$emit('inputs-changed', this.creditCardInputs.emitInputValues());
      }
    }
  }
});
