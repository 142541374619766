interface IDay {
  month: number;
  week: number;
  day: number;
  isDisabled: boolean;
  isSelected: boolean | null;
  fullDate: Date;
  isIncluded?: boolean;
}

export default class DayModel implements IDay {
  month: number;
  week: number;
  day: number;
  isDisabled: boolean;
  isSelected: boolean;
  fullDate: Date;
  isIncluded: boolean;

  constructor(data: IDay) {
    this.day = data.day;
    this.week = data.week;
    this.month = data.month;
    this.isDisabled = data.isDisabled;
    this.isSelected = data.isSelected ?? false;
    this.fullDate = data.fullDate;
    this.isIncluded = data.isIncluded ?? false;
  }
}
