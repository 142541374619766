
import { reactive } from '@vue/reactivity';
import { defineComponent, ref } from 'vue';
import ModalCmp from '../../standalone/modal/modal.vue';
import ICancellationPolicy from '../../../models/internal/cancellation-policy/cancellation-policy';

export default defineComponent({
  name: 'PickUpModal',
  components: {
    modal: ModalCmp
  },
  props: {
    cancellationPolicies: {
      type: Object as () => ICancellationPolicy[],
      required: true
    },
    showModal: {
      type: Boolean,
      required: false,
      default: false
    },
    cancelTextInput: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: ['input-changed'],
  setup(props) {
    props = reactive(props);
    return {
      policyModal: props.cancellationPolicies,
      cancelText: ref(props.cancelTextInput),
      shouldShowModal: ref(props.showModal)
    };
  },
  watch: {
    '$props.showModal': {
      handler() {
        this.toggleModal();
      }
    }
  },
  methods: {
    toggleModal() {
      this.shouldShowModal = !this.shouldShowModal;
    }
  }
});
