import DropdownSelect from '../../components/elements/dropdown-select/dropdown-select';
import { SelectOption } from '../../components/elements/options/select-option';
import LabelStyle from '../label-style/label-style';
import RoomAmenity from '../room-amenity/room-amenity';
import Room from '../room/room';

interface IRoomDropdown {
  room: Room;
  labelStyle?: LabelStyle;
}

export interface IRoomDropdownDetails {
  selectedValue: number;
  room: Room;
}

export default class RoomDropdown extends DropdownSelect {
  room: Room;
  roomAmenities: RoomAmenity[];

  constructor(data: IRoomDropdown) {
    super({
      placeholder: 'Select room',
      labelStyle: data.labelStyle,
      options: []
    });

    this.room = data.room;
    this.labelStyle = data.labelStyle;
    this.roomAmenities = data.room.roomAmenities;

    if (this.room.availableRooms > 0) {
      for (let i = 1; i <= this.room.availableRooms; i++) {
        this.options.push(new SelectOption({ name: `${i} Rooms`, value: i }));
      }
      this.options[0].name = this.options[0].name && '1 Room';
    } else {
      this.options.push(
        new SelectOption({ name: `No rooms available`, value: 0 })
      );
    }
  }

  get roomDropdownDetails(): IRoomDropdownDetails {
    return {
      room: this.room,
      selectedValue: this.selectedValue
    };
  }
}
