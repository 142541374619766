import { Nullable } from '../../../models/generics/generics';
import { CurrencyType } from '../currency/currency';
import { UnifiedCartItemType } from '../cart-item-type/cart-item-type';
import { IActivityItem } from '../activity/activity-item';
import ICart from './cart';
import IBooking from '../booking/booking';
import { IPromoCode } from '../promo-code/promo-code';

export interface IAccommodationItem extends ICart {
  itemType: UnifiedCartItemType;
}

export type UnifiedCartItems = Array<IActivityItem | IAccommodationItem>;

export default interface IUnifiedCart {
  cartId: Nullable<number>;
  currency: CurrencyType;
  createDate: Nullable<string>;
  lastUpdateDate: Nullable<string>;
  promoCode: Nullable<IPromoCode>;
  cartItems: Nullable<UnifiedCartItems>;
  totalAmount: number; // To be changed
}

export const getTotalAmount = (unifiedCart: IUnifiedCart): number => {
  return (
    unifiedCart.cartItems?.reduce((total, ci) => {
      const amount =
        ci.itemType === UnifiedCartItemType.ACTIVITY
          ? (<IActivityItem>ci).totalPrice
          : (<IAccommodationItem>ci).totalAmount;
      // totalAmount referes to totalPrice of the accommodationItems

      return amount + total;
    }, 0) || 0
  );
};

export const cartIsEmpty = (unifiedCart: IUnifiedCart): boolean => {
  return getCartItemCount(unifiedCart) === Number(0);
};

export const getCartItemCount = (unifiedCart: IUnifiedCart): number => {
  return (
    getActivityItems(unifiedCart).length +
    getAccommodationItems(unifiedCart).reduce(
      (total: number, ai: IAccommodationItem) =>
        ai.bookings
          ? ai.bookings?.reduce(
              (tot: number, b: IBooking) =>
                (b ? b.bookingRooms?.length : 0) + tot,
              0
            ) + total
          : total,
      0
    )
  );
};

export const getAccommodationItems = (
  unifiedCart: IUnifiedCart
): IAccommodationItem[] => {
  return (
    (unifiedCart.cartItems?.filter(
      (x) => x.itemType === UnifiedCartItemType.ACCOMMODATION
    ) as IAccommodationItem[]) || []
  );
};

export const getActivityItems = (
  unifiedCart: IUnifiedCart
): IActivityItem[] => {
  return (
    (unifiedCart.cartItems?.filter(
      (x) => x.itemType === UnifiedCartItemType.ACTIVITY
    ) as IActivityItem[]) || []
  );
};
