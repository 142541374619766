
import { reactive, defineComponent } from 'vue';
import PhoneInput from '../../../models/components/elements/phone-input/phone-input';

export default defineComponent({
  name: 'PhoneInput',
  props: {
    phoneInputModel: {
      type: Object as () => PhoneInput,
      required: true
    }
  },
  setup(props) {
    return {
      phoneInput: reactive(props.phoneInputModel)
    };
  },
  computed: {
    preferredCountries() {
      return this.phoneInput.countries.filter(({ preferred }) => !!preferred);
    },
    countriesList() {
      if (
        !!this.phoneInput.searchQuery &&
        !!this.phoneInput.searchMatches.length
      ) {
        return this.phoneInput.searchMatches;
      }

      return this.phoneInput.countries;
    }
  },
  methods: {
    getFlagSrc(country) {
      return `https://flagcdn.com/${country.id.toLowerCase()}.svg`;
    }
  }
});
