
import {reactive, defineComponent} from 'vue'
import Loader from '../../elements/loader/loader.vue'
import ProductCmp from '../../standalone/product/product.vue'
import SearchResult from "../../../models/components/block/search-result";
import ProductItem from "../../../models/components/standalone/product/product-item";

export default defineComponent({
  name: 'SearchResult',
  components: {
    'product': ProductCmp,
    'loader': Loader
  },
  props: {
      searchResultModel: {
      type: Object as () => SearchResult,
      required: true,
    }
  },
  emits: ['property-clicked'],
  setup(props, {emit}) {
    return {
      openResult(product: ProductItem) {
        emit('property-clicked', product);
      },
      searchResult: reactive(props.searchResultModel)
    }
  }
})
