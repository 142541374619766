
import { reactive, defineComponent } from 'vue';
import CardExpiryInput from '../../../models/components/elements/card-expiry-input/card-expiry-input';

export default defineComponent({
  name: 'CardExpiryInput',
  props: {
    cardExpiryInputModel: {
      type: Object as () => CardExpiryInput,
      required: true
    }
  },
  emits: ['key-up', 'on-blur'],
  setup(props) {
    return {
      cardExpiryInput: reactive(props.cardExpiryInputModel)
    };
  },
  methods: {
    onKeyUp() {
      this.cardExpiryInput.formatInput();
      this.$emit('key-up');
    },
    onBlur() {
      this.cardExpiryInput.validate();
      this.$emit('on-blur');
    }
  }
});
