export const GA_PLUGINS = {
  ECOMMERCE: 'ec'
};

export const EVENT_ACTIONS = {
  PAGE_VIEW: 'pageview',
  VIEW_TERMS_AND_CONDITIONS: 'view_terms_and_conditions'
};

export const ECOMMERCE_COMMANDS = {
  ADD_PRODUCT: 'addProduct',
  SET_ACTION: 'setAction'
};

export const PRODUCT_ACTIONS = {
  BUY_TOUR: 'buy-tour',
  CHECKOUT: 'checkout',
  CLICK: 'click',
  PURCHASE: 'purchase',
  REMOVE: 'remove',
  REMOVE_FROM_CART: 'removeFromCart',
  VIEW_TERMS: 'view-terms'
};
