
import { reactive, defineComponent } from 'vue';
import Modal from '../../elements/modal-with-button/modal-with-button.vue';
import Calendar from '../../standalone/calendar/calendar.vue';
import CalendarModal from '../../../models/components/standalone/modals/calendar-modal/calendar-modal';
import moment from 'moment';

export default defineComponent({
  name: 'AccommodationModal',
  components: {
    modal: Modal,
    calendar: Calendar
  },
  props: {
    calendarModal: {
      type: Object as () => CalendarModal,
      required: true
    }
  },
  setup(props) {
    return {
      calendarModalModel: reactive(props.calendarModal) as CalendarModal
    };
  },
  data() {
    return {};
  },
  computed: {
    startDate() {
      let date = this.calendarModalModel.calendar.dateRange.asDates.startDate;
      return date;
    },
    endDate() {
      const date = this.calendarModalModel.calendar.dateRange.asDates.endDate;
      return date;
    }
  },
  methods: {
    formatDate(date: Date): string {
      return moment(date).format('DD/MM/YYYY');
    }
  }
});
