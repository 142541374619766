<template>
  <div v-if="!asContent" class="h-100">
    <div
      v-if="overlay"
      class="d-flex justify-content-center align-items-center h-100"
    >
      <div class="custom-spinner" />
    </div>
    <div v-else class="g__absolute-center">
      <div class="custom-spinner" />
    </div>
  </div>
  <div v-else>
    <div class="custom-spinner content"></div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LoaderCmp',
  props: {
    overlayProp: {
      type: Boolean,
      required: false,
      default: false
    },
    asContent: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props) {
    return {
      overlay: props.overlayProp
    };
  }
});
</script>

<style scoped>
.custom-spinner {
  width: 2rem;
  height: 2rem;
  border: var(--bs-primary) solid 0.2em;
  animation: spinTransform 2500ms infinite ease-in-out;
  z-index: 9999;
}

.custom-spinner.content {
  z-index: 0;
  width: 16px;
  height: 16px;
  border: gray solid 0.2em;
  animation: spin 2500ms infinite ease-in-out;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinTransform {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(90deg) scale(1.5);
  }
  50% {
    transform: rotate(180deg) scale(2);
    border-color: var(--bs-secondary);
  }
  75% {
    transform: rotate(270deg) scale(1.5);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>