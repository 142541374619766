/* eslint-disable @typescript-eslint/no-unused-vars */
import ISearchProperties from '../models/internal/global/search-properties';
import ProductItem from '../models/components/standalone/product/product-item';
import { createStore } from 'vuex';
import IStore from '../models/internal/global/store/store';
import createPersistedState from 'vuex-persistedstate';
import { ISidebarFilters } from '../models/components/block/sidebar/sidebar-filters';
import IPropertyMap from '../models/internal/prop-maps/property-map';
import { CurrencyType } from '../models/internal/currency/currency';
import ISuccessfulPaymentInformation from '../models/internal/payment/successful-payment-information';
import IInputOptions from 'src/models/internal/global/input-options';
import { ISidebarCarFilters } from '../models/components/block/sidebar/sidebar-filters-car';
import { ICarSearchProperties } from '../models/internal/global/car-search-properties';
import CarProductItem from '../models/components/standalone/product/car-product-item';
import AppConstants from '../constants/app-constants';
import { Nullable } from '../models/generics/generics';
import IUnifiedCart, {
  getCartItemCount
} from '../models/internal/cart/unified-cart';
import AppUtils from '../utils/app-utils';

const getFromApplicationStorage = (key: string) => {
  const storeFromApplicationStorage = JSON.parse(
    window.localStorage[AppConstants.APP_STORAGE_KEY] || '{}'
  );
  return storeFromApplicationStorage[key];
};

const store = createStore<IStore>({
  state: {
    theme: AppUtils.getFallbackDomain(),
    searchProperties: {
      accommodation: {
        rooms: 1,
        guests: 1,
        children: 0
      },
      locations: []
    } as ISearchProperties,
    carSearchProperties: null,
    sidebarFilters: {
      availablePropertyAmenityOptions: [],
      availablePropertyTypeOptions: [],
      propertyAmenityOptions: [],
      propertyTypeOptions: []
    },
    sidebarCarFilters: {
      availableCarCategoryOptions: [],
      availableCarTransmissionTypeOptions: [],
      availableCarFuelTypeOptions: [],
      availableCarExtrasOptions: [],
      carCategoryOptions: [],
      carTransmissionTypeOptions: [],
      carFuelTypeOptions: [],
      carExtrasOptions: []
    },
    propertyMap: {
      propertyAmenityMap: [],
      propertyTypeMap: [],
      roomAmenityMap: []
    },
    cartMap: {
      cartId: null,
      currency: CurrencyType.ISK,
      totalAmount: 0,
      promoCode: null,
      createDate: null,
      lastUpdateDate: null,
      cartItems: []
    },
    loadedProperties: [],
    loadedCars: [],
    queryUpdated: false,
    carQueryUpdated: false,
    selectedCurrency: CurrencyType.ISK,
    successfulPaymentInformation: null,
    bookedRoomsCount: 0,
    inputOptions: null
  },
  mutations: {
    updateUsedTheme(state, theme: string) {
      state.theme = theme;
    },
    updateCartMap(state, cartMap: IUnifiedCart) {
      state.cartMap = cartMap;
    },
    updateSearchProperties(state, searchProperties: ISearchProperties) {
      state.searchProperties = searchProperties;
      state.queryUpdated = true;
      state.loadedProperties = [];
    },
    updateCarSearchProperties(
      state,
      carSearchProperties: ICarSearchProperties
    ) {
      state.carSearchProperties = carSearchProperties;
      state.carQueryUpdated = true;
      state.loadedCars = [];
    },
    searchCompleted(state, properties: ProductItem[]) {
      state.queryUpdated = false;
      state.loadedProperties = properties;
    },
    carSearchCompleted(state, cars: CarProductItem[]) {
      state.carQueryUpdated = false;
      state.loadedCars = cars;
    },
    sidebarFiltersUpdated(state, sidebarFilters: ISidebarFilters) {
      state.sidebarFilters = sidebarFilters;
    },
    sidebarCarFiltersUpdated(state, sidebarCarFilters: ISidebarCarFilters) {
      state.sidebarCarFilters = sidebarCarFilters;
    },
    updatePropertyMap(state, propertyMap: IPropertyMap) {
      state.propertyMap = propertyMap;
    },
    uploadSuccessfulPaymentInformation(
      state,
      spi: ISuccessfulPaymentInformation
    ) {
      state.successfulPaymentInformation = spi;
    },
    updateBookedRoomsCount(state, count?: number) {
      if (!count) {
        state.bookedRoomsCount = getCartItemCount(state.cartMap);
      } else {
        state.bookedRoomsCount = count;
      }
    },
    updateInputOptions(state, inputOptions: IInputOptions) {
      state.inputOptions = inputOptions;
    }
  },
  getters: {
    theme(state): string {
      return getFromApplicationStorage('theme') ?? state.theme;
    },
    selectedCurrency(state): CurrencyType {
      return (
        getFromApplicationStorage('selectedCurrency') ?? state.selectedCurrency
      );
    },
    queryUpdated(state): boolean {
      return getFromApplicationStorage('queryUpdated') ?? state.queryUpdated;
    },
    carQueryUpdated(state): boolean {
      return (
        getFromApplicationStorage('carQueryUpdated') ?? state.carQueryUpdated
      );
    },
    searchPropertiesUpdated(state): boolean {
      return getFromApplicationStorage('queryUpdated') ?? state.queryUpdated;
    },
    carSearchUpdated(state): boolean {
      return (
        getFromApplicationStorage('carQueryUpdated') ?? state.carQueryUpdated
      );
    },
    loadedProperties(state): ProductItem[] {
      return (
        getFromApplicationStorage('loadedProperties') ?? state.loadedProperties
      );
    },
    loadedCars(state): CarProductItem[] {
      return getFromApplicationStorage('loadedCars') ?? state.loadedCars;
    },
    inputsModel(state): ISearchProperties {
      return (
        getFromApplicationStorage('searchProperties') ?? state.searchProperties
      );
    },
    sidebarFilters(state): ISidebarFilters {
      return (
        getFromApplicationStorage('sidebarFilters') ?? state.sidebarFilters
      );
    },
    propertyMap(state): IPropertyMap {
      return getFromApplicationStorage('propertyMap') ?? state.propertyMap;
    },
    cartMap(state): IUnifiedCart {
      return getFromApplicationStorage('cartMap') ?? state.cartMap;
    },
    successfulPaymentInformation(state): ISuccessfulPaymentInformation | null {
      return (
        getFromApplicationStorage('successfulPaymentInformation') ??
        state.successfulPaymentInformation
      );
    },
    bookedRoomsCount(state): number {
      return (
        getFromApplicationStorage('bookedRoomsCount') ?? state.bookedRoomsCount
      );
    },
    inputOptions(state): Nullable<IInputOptions> {
      return getFromApplicationStorage('inputOptions') ?? state.inputOptions;
    },
    carSearchProperties(state): Nullable<ICarSearchProperties> {
      return (
        getFromApplicationStorage('carSearchProperties') ??
        state.carSearchProperties
      );
    }
  },
  plugins: [
    createPersistedState({
      key: AppConstants.APP_STORAGE_KEY,
      storage: window.localStorage
    })
  ]
});

export default store;
