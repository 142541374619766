import EmailInput from '../email-input/email-input';
import IInputValidation from '../../../validation/input-validation';

export interface IEmailWithConfirmationArgs {
  isValid?: boolean;
  withValidation?: boolean;
  validationMessage?: string;
  email?: EmailInput;
  confirmationEmail?: EmailInput;
}

export default class EmailWithConfirmation implements IInputValidation {
  isValid = false;
  withValidation = true;
  validationMessage = '';

  email: EmailInput = new EmailInput({
    withValidation: true,
    defaultMessage: 'Enter a valid email',
    label: 'Email',
    withAutocomplete: true,
    maxCharacters: 254,
    required: true
  });
  confirmationEmail: EmailInput = new EmailInput({
    withValidation: true,
    defaultMessage: 'Enter your email adress again',
    label: 'Confirm Email',
    placeholder: 'Enter your email address again',
    maxCharacters: 254,
    required: true
  });

  constructor(data?: IEmailWithConfirmationArgs) {
    this.isValid = data?.isValid ?? this.isValid;
    this.withValidation = data?.withValidation ?? this.withValidation;
    this.validationMessage = data?.validationMessage ?? this.validationMessage;
    this.email = data?.email ?? this.email;
    this.confirmationEmail = data?.confirmationEmail ?? this.confirmationEmail;
  }

  validate(): boolean {
    if (!this.withValidation) {
      if (this.email.withValidation) {
        this.email.withValidation = false;
        this.email.isValid = true;
      }
      if (this.confirmationEmail.withValidation) {
        this.confirmationEmail.withValidation = false;
        this.confirmationEmail.isValid = true;
      }
      return (this.isValid = true);
    }

    if (!this.confirmationEmail.isValid || !this.email.isValid) {
      if (this.confirmationEmail.isValid) this.confirmationEmail.setInvalid();
      if (!this.email.isValid) this.confirmationEmail.setInvalid();
      return (this.isValid = false);
    }
    if (
      !(
        this.email.value.toString().toLowerCase() ===
        this.confirmationEmail.value.toString().toLowerCase()
      )
    ) {
      this.confirmationEmail.setInvalid('Must match a valid email.');
      return (this.isValid = false);
    }

    if (
      this.email.value.toString().trim().length < 1 ||
      this.confirmationEmail.value.toString().trim().length < 1
    ) {
      this.email.validate();

      return (this.isValid = false);
    }

    return (this.isValid = true);
  }
}
