import IContact from './contact';
import ILogo from './logo';
import IRoom from './room';
import ILocation from './location';
import ICancellationPolicy from '../internal/cancellation-policy/cancellation-policy';

export interface IAvailableProperty {
  additionalDescription?: string;
  availabilityChecked?: boolean;
  availableCapacity?: number;
  availableRooms?: number;
  checkInEndTime?: string;
  checkInStartTime?: string;
  contact: IContact;
  description: string;
  featuredImageIndex?: number;
  fullAvailability?: boolean;
  id?: number;
  location: ILocation;
  logo?: ILogo;
  coverImage?: ILogo;
  images?: ILogo[];
  name: string;
  possibleToFitGuests: boolean;
  pricesLoaded?: boolean;
  propertyTypeCode: string;
  propertyTypeName: string;
  rooms: IRoom[];
  sameDayBooking?: boolean;
  totalCapacity?: number;
  totalRooms?: number;
  fromPrice?: number;
  propertyAmenityCodes?: string[];
  cancellationPolicies?: ICancellationPolicy[];
}

export default class AvailableProperty {
  additionalDescription?: string;
  availabilityChecked?: boolean;
  availableCapacity?: number;
  availableRooms?: number;
  checkInEndTime?: string;
  checkInStartTime?: string;
  contact: IContact;
  description: string;
  featuredImageIndex?: number;
  fullAvailability?: boolean;
  id?: number;
  location: ILocation;
  logo?: ILogo;
  coverImage?: ILogo;
  images: ILogo[];
  name: string;
  possibleToFitGuests: boolean;
  pricesLoaded?: boolean;
  propertyTypeCode: string;
  propertyTypeName: string;
  rooms: IRoom[];
  sameDayBooking?: boolean;
  totalCapacity?: number;
  totalRooms?: number;
  fromPrice?: number;
  propertyAmenityCodes: string[] = [];
  cancellationPolicies?: ICancellationPolicy[];

  constructor(data: IAvailableProperty) {
    this.additionalDescription = data.additionalDescription;
    this.availabilityChecked = data.availabilityChecked;
    this.availableCapacity = data.availableCapacity;
    this.availableRooms = data.availableRooms;
    this.checkInEndTime = data.checkInEndTime;
    this.checkInStartTime = data.checkInStartTime;
    this.contact = data.contact;
    this.description = data.description;
    this.featuredImageIndex = data.featuredImageIndex;
    this.fullAvailability = data.fullAvailability;
    this.id = data.id;
    this.location = data.location;
    this.logo = data.logo;
    this.coverImage = data.coverImage;
    this.images = data.images ?? [];
    this.name = data.name;
    this.pricesLoaded = data.pricesLoaded;
    this.propertyTypeCode = data.propertyTypeCode;
    this.propertyTypeName = data.propertyTypeName;
    this.possibleToFitGuests = data.possibleToFitGuests;
    this.rooms = data.rooms;
    this.sameDayBooking = data.sameDayBooking;
    this.totalCapacity = data.totalCapacity;
    this.totalRooms = data.totalRooms;
    this.propertyAmenityCodes =
      data.propertyAmenityCodes ?? this.propertyAmenityCodes;
    this.cancellationPolicies = data.cancellationPolicies;
  }
}
