
import { defineComponent } from '@vue/runtime-core';
import Switch from '../../../models/components/elements/switch/switch';

export default defineComponent({
  name: 'SwitchCmp',
  props: {
    switchModel: {
      type: Object as () => Switch,
      required: true
    }
  }
});
