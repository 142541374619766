import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["checked", "disabled"]
const _hoisted_2 = {
  key: 0,
  class: "ms-2",
  for: "flexCheckDefault"
}
const _hoisted_3 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_4 = {
  id: "flexCheckDefault",
  class: "invalid-feedback"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("input", {
      id: "flexCheckDefault",
      type: "checkbox",
      class: _normalizeClass(_ctx.getInputClass()),
      checked: _ctx.checkbox.selected,
      disabled: _ctx.checkbox.isDisabled,
      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleCheckbox()))
    }, null, 42, _hoisted_1),
    (_ctx.checkbox.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, [
          (_ctx.checkbox.withValidation)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(_ctx.checkbox.label), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.checkbox.validationMessage), 1)
  ]))
}