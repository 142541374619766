import {
  isValidNumberForRegion,
  parsePhoneNumber,
  CountryCode,
  ParseError
} from 'libphonenumber-js';
import Input, { IInputArgs } from '../../../internal/input/input';
import countries from './phone-input-countries';

export interface IPhoneInputArgs extends IInputArgs {
  countryCodeSelected?: string;
  rawInput?: string;
  preferredCountries?: string[];
}

export default class PhoneInput extends Input {
  countryCodeSelected: string;
  rawInput: string;
  preferredCountries: string[];
  searchQuery: string;

  constructor(data: IPhoneInputArgs) {
    super(data);

    this.searchQuery = '';
    this.validationMessage =
      data?.defaultMessage ?? 'Enter a valid phone number';
    this.label = data?.label ?? 'Phone number';
    this.placeholder = data?.placeholder ?? 'Enter your phone number';
    this.countryCodeSelected = data?.countryCodeSelected ?? 'US';
    this.rawInput = data?.rawInput ?? '';
    this.preferredCountries = data?.preferredCountries ?? [
      'US',
      'CN',
      'DE',
      'GB'
    ];
  }

  get countries() {
    return countries.map((country) => ({
      ...country,
      preferred: this.preferredCountries.includes(country.id)
    }));
  }

  get selectedCountry() {
    const country = countries.filter(
      ({ id }) => id === this.countryCodeSelected
    )[0];
    return country;
  }

  get searchMatches() {
    return countries.filter(({ name }) =>
      name.toLowerCase().includes(this.searchQuery.toLowerCase())
    );
  }

  selectCountry(countryId) {
    this.countryCodeSelected = countryId;
    this.searchQuery = '';
    if (this.rawInput) this.validate();
  }

  validate() {
    const isEmpty = this.rawInput === '';
    const isEmptyAndNotRequired = isEmpty && !this.required;
    const countryCode = this.countryCodeSelected as CountryCode;

    if (isEmpty) {
      this.isValid = !this.required;
      return this.isValid;
    }

    try {
      const phoneUtil = parsePhoneNumber(this.rawInput, countryCode);
      const hasCountryCodeInput = this.rawInput.includes('+');
      const isValidForRegion = isValidNumberForRegion(
        phoneUtil.formatNational(),
        countryCode
      );

      if (phoneUtil.isValid() && !isValidForRegion) {
        // if user enters the phone number manually for a different country
        // automatically set the country based on the users input
        this.countryCodeSelected = phoneUtil.country as string;
        this.isValid = true;
        this.rawInput = phoneUtil.nationalNumber;
        return this.isValid;
      }

      if (phoneUtil.isValid() && hasCountryCodeInput) {
        // if user inputs the country code manually,
        // format set the raw input to national number without
        // the country code as it is redundant.
        this.rawInput = phoneUtil.nationalNumber;
      }

      this.isValid =
        isEmptyAndNotRequired || (phoneUtil.isValid() && isValidForRegion);
      this.value = phoneUtil.number;
      return this.isValid;
    } catch (error) {
      const isParsingError = error instanceof ParseError;
      this.isValid = !isParsingError;
      return this.isValid;
    }
  }
}
