import IDateRangeValidation from '../../validation/date-range-validation';
import moment, { Moment } from 'moment';

interface IDateRange {
  startDate?: Date | Moment | null;
  endDate?: Date | Moment | null;
}

export interface IDateRangeWithValidation {
  dateRange: IDateRange;
  validation: IDateRangeValidation;
}

export default class DateRange implements IDateRangeValidation {
  withValidation = true;
  earliestDate?: Date | Moment | undefined;
  latestDate?: Date | Moment | undefined;
  exceptDates?: Date[] | Moment[] | undefined;
  isValid = true;

  private _startDate: Date | Moment | null = null;
  private _endDate: Date | Moment | null = null;

  get numberOfNights(): number {
    if (this.endDate === undefined) return 1;

    return moment(this.endDate).diff(moment(this.startDate), 'd');
  }

  get startDate(): Date | Moment | null {
    return this._startDate;
  }
  set startDate(date: Date | Moment | null) {
    this._startDate = date;
    this.endDate = null;
  }

  get endDate(): Date | Moment | null {
    return this._endDate;
  }
  set endDate(date: Date | Moment | null) {
    this._endDate = date;
  }

  get asDates(): Record<'startDate' | 'endDate', Date | null> {
    return {
      startDate:
        this.startDate !== null
          ? this.momentStartOfDay(this.startDate).toDate()
          : null,
      endDate:
        this.endDate !== null
          ? this.momentStartOfDay(this.endDate).toDate()
          : null
    };
  }

  // make sure to use this only when checking if the range is defined (and is true)
  get asDatesDefinite(): Record<'startDate' | 'endDate', Date> {
    return {
      startDate: this.momentStartOfDay(
        this.startDate as Date | Moment
      ).toDate(),
      endDate: this.momentStartOfDay(this.endDate as Date | Moment).toDate()
    };
  }

  get asMoments(): Record<'startDate' | 'endDate', Moment | null> {
    return {
      startDate:
        this.startDate !== null
          ? this.momentStartOfDay(this.startDate)
          : null,
      endDate:
        this.endDate !== null
          ? this.momentStartOfDay(this.endDate)
          : null
    };
  }

  // make sure to use this only when checking if the range is defined (and is true)
  get asMomentsDefinite(): Record<'startDate' | 'endDate', Moment> {
    return {
      startDate: this.momentStartOfDay(this.startDate as Date | Moment),
      endDate: this.momentStartOfDay(this.endDate as Date | Moment)
    };
  }

  get rangeSelected(): boolean {
    const moments = this.asMoments;
    return moments.startDate !== undefined && moments.endDate !== undefined;
  }

  constructor(data?: IDateRange, validation?: IDateRangeValidation) {
    if (validation) {
      this.withValidation = validation.withValidation;
      this.earliestDate = moment(validation.earliestDate).utc().startOf('day');
      validation.exceptDates?.forEach(
        (date: Date | Moment) => (date = moment(date).utc())
      );
      this.exceptDates = validation.exceptDates;
      this.latestDate = moment(validation.latestDate).utc().startOf('day');
      this.isValid = validation.isValid ?? true;
    } else {
      this.withValidation = false;
      this.isValid = true;
    }

    const iDateRange = data as IDateRange;
    
    this.startDate = iDateRange?.startDate
        ? moment(iDateRange.startDate)
        : null;
    this.endDate = iDateRange?.startDate
        ? moment(iDateRange.endDate)
        : null;
  }

  onSelectedDay(date: Date | Moment) {
    if (this.startDate && this.endDate) {
      this.startDate = moment(date).utc();
    } else if (this.startDate) {
      if (this.momentStartOfDay(this.startDate) >= this.momentStartOfDay(date)) {
        this.startDate = moment(date).utc();
      } else {
        this.endDate = moment(date).utc();
      }
    } else {
      this.startDate = moment(date).utc();
    }
  }

  momentStartOfDay(date: Date | Moment) {
    return moment.isMoment(date)
      ? date.utc().startOf('d')
      : moment(date).utc().startOf('d');
  }

  isInRange(date: Date | Moment): boolean {
    if (!this.rangeSelected) return false;

    date = this.momentStartOfDay(date);
    const dates = this.asMomentsDefinite;
    return (
      dates.startDate.utc() <= moment(date).utc() &&
      moment(date).utc() <= dates.endDate.utc()
    );
  }

  validate(): boolean {
    if (!this.withValidation) {
      this.isValid = true;
      return this.isValid;
    }

    if (!this.rangeSelected) {
      this.isValid = false;
      return this.isValid;
    }

    const moments = this.asMoments as Record<'startDate' | 'endDate', Moment>;
    if (moments.startDate > moments.endDate) {
      this.isValid = false;
      return this.isValid;
    }

    if (this.earliestDate && this.earliestDate > moments.startDate) {
      this.isValid = false;
      return this.isValid;
    }

    if (this.latestDate && this.latestDate > moments.endDate) {
      this.isValid = false;
      return this.isValid;
    }

    if (
      this.exceptDates &&
      this.exceptDates.some((date: Date | Moment) => {
        if (moment.isDate(date)) date = moment(date);

        return (
          date.startOf('day') === moments.startDate?.startOf('day') ||
          date.startOf('day') === moments.endDate?.startOf('day')
        );
      })
    ) {
      this.isValid = false;
      return this.isValid;
    }

    this.isValid = true;
    return this.isValid;
  }

  public getIDateRange(): IDateRange {
    return {
      startDate: this.startDate,
      endDate: this.endDate
    };
  }

  public getValidationModel(): IDateRangeValidation {
    return {
      isValid: this.isValid,
      earliestDate: this.earliestDate,
      latestDate: this.latestDate,
      exceptDates: this.exceptDates,
      withValidation: this.withValidation
    };
  }

  public asIDateRangeWithValidation(): IDateRangeWithValidation {
    return {
      dateRange: this.getIDateRange(),
      validation: this.getValidationModel()
    };
  }
}
