import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-964220d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-start" }
const _hoisted_2 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_3 = { class: "position-relative text-start" }
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_6 = {
  key: 3,
  id: "select",
  class: "option-list g__shadow-strong"
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "d-flex align-items-center" }
const _hoisted_9 = ["id"]
const _hoisted_10 = {
  key: 0,
  class: "option-list-option p-2"
}
const _hoisted_11 = {
  id: "inputValidationFeedback",
  class: "invalid-feedback"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.dropdown.label && _ctx.dropdown.withInput)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          id: "inputValidation",
          class: "g__text--label text-gray4 form-label",
          style: _normalizeStyle(_ctx.styleObject.labelStyle),
          for: "inputValidation"
        }, [
          (_ctx.dropdown.required)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, "*"))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(_ctx.$translate(_ctx.dropdown.label)), 1)
        ], 4))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.dropdown.withInput)
        ? _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 0,
            id: "inputValidation",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dropdown.value) = $event)),
            autoComplete: "off",
            type: "text",
            class: _normalizeClass(`form-control focus ${
          _ctx.dropdown.isValid ? 'g__btn-valid' : 'is-invalid'
        }`),
            placeholder: _ctx.buttonValue(),
            onFocusin: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dropdown.dropdownActive = true)),
            onKeyup: _cache[2] || (_cache[2] = 
          () => {
            _ctx.dropdown.filterOptions();
            _ctx.dropdown.validate();
          }
        ),
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.dropdown.dropdownActive = !_ctx.dropdown.dropdownActive)),
            onBlur: _cache[4] || (_cache[4] = ($event: any) => (_ctx.dropdown.checkInList()))
          }, null, 42, _hoisted_4)), [
            [_vModelText, _ctx.dropdown.value]
          ])
        : _createCommentVNode("", true),
      (_ctx.dropdown.label && !_ctx.dropdown.withInput)
        ? (_openBlock(), _createElementBlock("label", {
            key: 1,
            class: "g__text--label text-gray4 form-label",
            style: _normalizeStyle(_ctx.styleObject.labelStyle),
            for: "inputValidationButton"
          }, [
            (_ctx.dropdown.required)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, "*"))
              : _createCommentVNode("", true),
            _createTextVNode(" " + _toDisplayString(_ctx.$translate(_ctx.dropdown.label)), 1)
          ], 4))
        : _createCommentVNode("", true),
      (!_ctx.dropdown.withInput)
        ? (_openBlock(), _createElementBlock("button", {
            key: 2,
            id: "inputValidationButton",
            class: _normalizeClass(`w-100 form-control focus button text-start ${
          _ctx.dropdown.isValid ? 'button-border g__btn-valid' : 'is-invalid'
        }`),
            list: "options",
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.dropdown.dropdownActive = !_ctx.dropdown.dropdownActive))
          }, _toDisplayString(_ctx.buttonValue()), 3))
        : _createCommentVNode("", true),
      (_ctx.dropdown.dropdownActive)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dropdown.filteredList, (option, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                style: _normalizeStyle(_ctx.styleObject),
                class: _normalizeClass([
            option.selected ? 'selected' : '',
            'option-list-option px-3 py-2 dynamic-hover',
            option.disabled ? 'disabled' : ''
          ]),
                onClick: ($event: any) => (_ctx.selectValue(option.value))
              }, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", {
                    class: _normalizeClass({
                'g__text--label text-start fw-normal': true,
                'g__text--primary text-primary fw-bolder':
                  option.selected && 'selected'
              })
                  }, _toDisplayString(_ctx.$translate(option.name)), 3)
                ]),
                _createElementVNode("i", {
                  id: option.selected && 'selected',
                  style: _normalizeStyle(_ctx.styleObject),
                  class: _normalizeClass([
              option.selected ? 'text-success' : '',
              'bi bi-check-lg',
              'option-list-option-icon dynamic-hover'
            ])
                }, null, 14, _hoisted_9)
              ], 14, _hoisted_7))
            }), 128)),
            (!_ctx.dropdown.filteredList.some((x) => !!x))
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, " No results "))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.dropdown.validationMessage), 1)
    ])
  ]))
}