
import { defineComponent, reactive } from "vue";
import { useStore } from "vuex";

import CarSearchPanelCmp from '../components/block/search-panels/car-search-panel.vue'
import {openInNewTab} from "../router";
import { ICarSearchProperties } from "../models/internal/global/car-search-properties";
import CarSearchPanel from "../models/components/block/car-search-panel";
import vehicleControllerService from "../services/controllers/vehicle-controller-service";
import locationControllerService from "../services/controllers/location-controller-service";
import { SelectOption } from "../models/components/elements/options/select-option";
import Notification from "../models/internal/notification/notification";

export default defineComponent({
    name: "CarSearchPanel",
    components: {
        CarSearchPanelCmp
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    beforeRouteEnter(_to, _from) {
        document.body.style.overflow = '';
        document.body.style.paddingRight = '';
    },
    setup() {
        const store = useStore();
        const carSearchPropertiesModel = store.getters.carSearchProperties as ICarSearchProperties;
        const model = carSearchPropertiesModel
        ? CarSearchPanel.fromCarSearchProperties(carSearchPropertiesModel)
        : new CarSearchPanel();

        return {
            carSearchPanelInputs: reactive(model)
        };
    },
    mounted(){
        this.getLocations();
    },
    methods: {
        onSearchClicked() {
            openInNewTab('car-results', this.carSearchPanelInputs.emitCarQueryParams(), null, null);
        },
        getLocations(): void {
            vehicleControllerService.getRentalList()
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .then(([aa, caren]) => {
                this.carSearchPanelInputs.isLoading = true;
                /**
                 * Selecting caren's rentalId from the response.
                 * Replace caren to AA when needed.
                 */
                const { id } = caren; 
                this.carSearchPanelInputs.rentalId = id;
                /** Retrieve all locations */
                this.getDropOffLocations();
                this.getPickUpLocations();
            })
            .catch((err) => {
                this.$notify(Notification.error(err.message));
            })
        },
        getDropOffLocations(): void {
            const { rentalId } = this.carSearchPanelInputs;
            locationControllerService.getDropOffList(rentalId).then((dropOffLocations) => {
                    this.carSearchPanelInputs.dropOffDrodown.options = SelectOption.fromDropOffPickUpLocations(dropOffLocations, this.carSearchPanelInputs.selectedDropOffLocationId);
            })
            .catch((err) => {
                this.$notify(Notification.error(err.message));
                this.carSearchPanelInputs.dropOffDrodown.options = [];
            })
            .finally(() => {
                this.carSearchPanelInputs.dropOffDrodown.filterOptions();
            })
        },
        getPickUpLocations(): void {
            const { rentalId } = this.carSearchPanelInputs;
            locationControllerService.getPickUpList(rentalId).then((pickUpLocations) => {
                this.carSearchPanelInputs.pickUpDropdown.options = SelectOption.fromDropOffPickUpLocations(pickUpLocations, this.carSearchPanelInputs.selectedPickUpLocationId);
            })
            .catch((err) => {
                this.$notify(Notification.error(err.message));
                this.carSearchPanelInputs.pickUpDropdown.options = [];
            })
            .finally(() => {
                this.carSearchPanelInputs.pickUpDropdown.filterOptions();
                this.carSearchPanelInputs.isLoading = false;
            })
        },
    }
});
