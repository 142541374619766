export const BING_ACTIONS = {
  ADD_TO_CART: 'add_to_cart',
  REMOVE_FROM_CART: 'remove_from_cart',
  BEGIN_CHECKOUT: 'begin_checkout',
  PURCHASE: 'purchase'
};

export const BING_EVENT_CATEGORY = {
  CART: 'cart',
  CALENDAR: 'calendar'
};
