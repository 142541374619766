
import { reactive, defineComponent } from 'vue';
import TextArea from '../../../models/components/elements/text-area/text-area';

export default defineComponent({
  name: 'TextArea',
  props: {
    textAreaInputModel: {
      type: Object as () => TextArea,
      required: true
    }
  },
  setup(props) {
    return {
      textArea: reactive(props.textAreaInputModel)
    };
  }
});
