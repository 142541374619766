import { ref, onMounted } from 'vue';
import AppConstants from '../constants/app-constants';
import AppUtils from '../utils/app-utils';

export default function useTranslation() {
  const lang = localStorage.getItem(AppConstants.APP_TRANSLATION) ?? 'en';
  const isLoading = ref(true);

  const { i18next } = AppUtils.initTranslation(lang);
  onMounted(() => {
    i18next.store.on('added', () => {
      isLoading.value = !i18next.hasResourceBundle(lang, 'translations');
    });
  });

  return {
    lang,
    isLoading
  };
}
