import BaseHttpClientService from '../base-http-client-service';
import AvailableProperty from '../../models/travia/available-property';
import { IPropertyInfoDto } from '../../models/dtos/requests/property-info-dto';
import {
  IGuestsInRoomPrice,
  IGuestsInRoomPriceReq
} from '../../models/internal/room-allocation/guests-in-room-price';
import {CancelTokenSource} from "axios";

interface IPropertyControllerService {
  getPropertyInfoAsync(model: IPropertyInfoDto): Promise<AvailableProperty>;
  getPropertyRoomPricesAsync(
    model: IGuestsInRoomPriceReq,
    cancelToken: CancelTokenSource
  ): Promise<IGuestsInRoomPrice[]>;
}

class PropertyControllerService
  extends BaseHttpClientService
  implements IPropertyControllerService
{
  async getPropertyInfoAsync(
    model: IPropertyInfoDto
  ): Promise<AvailableProperty> {
    return this.httpClient
      .post(`api/property/v1/properties/get-property-info`, model)
      .then((res) => {
        const resp = this.prepareResponse(res);
        if (resp.statusCode === 200) return new AvailableProperty(resp.content);
        else throw resp.message;
      })
      .catch
      /* todo: proper handle */
      ();
  }

  async getPropertyRoomPricesAsync(
    model: IGuestsInRoomPriceReq,
    cancelToken: CancelTokenSource
  ): Promise<IGuestsInRoomPrice[]> {
    return this.httpClient
      .post(`api/property/v1/properties/get-property-room-prices`, model, {
          cancelToken: cancelToken.token
      })
      .then((res) => {
        const resp = this.prepareResponse(res);
        if (resp.statusCode === 200) {
          return resp.content;
        } else throw resp.message;
      })
      .catch
      /* todo: proper handle */
      ();
  }
}

export default new PropertyControllerService() as IPropertyControllerService;
