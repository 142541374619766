import {
  GA_PLUGINS,
  EVENT_ACTIONS,
  ECOMMERCE_COMMANDS,
  PRODUCT_ACTIONS
} from './constants';

export default class GoogleTracker {
  isEnabled = false;
  trackerName = '';

  init(config) {
    const isInWindow =
      window.ga &&
      typeof window.ga === 'function' &&
      typeof window.ga.getAll === 'function';

    if (isInWindow && !config.googleAnalyticsDisabled) {
      const trackers = window.ga.getAll();
      if (trackers.length > 0) {
        this.isEnabled = true;
        this.trackerName = trackers[0].get('name');

        window.ga(`${this.trackerName}.require`, GA_PLUGINS.ECOMMERCE);
      } else {
        this.isEnabled = false;
      }
    }
  }

  trackPageView() {
    if (!this.isEnabled) return;

    const { pathname, search } = window.location;
    window.ga(
      `${this.trackerName}.send`,
      EVENT_ACTIONS.PAGE_VIEW,
      `${pathname}${search}`
    );
  }

  addProducts(products) {
    const ecommerceCommand = `${this.trackerName}.${GA_PLUGINS.ECOMMERCE}:${ECOMMERCE_COMMANDS.ADD_PRODUCT}`;

    (products || []).forEach((product) => window.ga(ecommerceCommand, product));
  }

  removeFromCart(products) {
    if (!this.isEnabled) return;

    this.addProducts(products);
    const ecommerceCommand = `${this.trackerName}.${GA_PLUGINS.ECOMMERCE}:${ECOMMERCE_COMMANDS.SET_ACTION}`;

    window.ga(ecommerceCommand, PRODUCT_ACTIONS.REMOVE);
    window.ga(`${this.trackerName}.send`, 'event', {
      eventCategory: 'UX',
      eventAction: PRODUCT_ACTIONS.CLICK,
      eventLabel: PRODUCT_ACTIONS.REMOVE_FROM_CART
    });
  }

  checkout(products, step) {
    if (!this.isEnabled) return;

    this.addProducts(products);

    const ecommerceCommand = `${this.trackerName}.${GA_PLUGINS.ECOMMERCE}:${ECOMMERCE_COMMANDS.SET_ACTION}`;

    window.ga(ecommerceCommand, PRODUCT_ACTIONS.CHECKOUT, { step });
    this.trackPageView();
  }

  purchase(products, actionData) {
    if (!this.isEnabled) return;

    this.addProducts(products);

    const ecommerceCommand = `${this.trackerName}.${GA_PLUGINS.ECOMMERCE}:${ECOMMERCE_COMMANDS.SET_ACTION}`;

    window.ga(ecommerceCommand, PRODUCT_ACTIONS.PURCHASE, actionData);
    window.ga(`${this.trackerName}.send`, 'event', {
      eventCategory: 'UX',
      eventAction: PRODUCT_ACTIONS.CLICK,
      eventLabel: PRODUCT_ACTIONS.PURCHASE
    });
    window.ga(`${this.trackerName}.send`, 'event', {
      eventCategory: PRODUCT_ACTIONS.PURCHASE,
      eventAction: PRODUCT_ACTIONS.BUY_TOUR,
      eventLabel: PRODUCT_ACTIONS.PURCHASE
    });
  }

  viewTermsAndConditions(bookingId) {
    if (!this.isEnabled) return;

    window.ga(`${this.trackerName}.send`, 'event', {
      eventCategory: PRODUCT_ACTIONS.CHECKOUT,
      eventAction: EVENT_ACTIONS.VIEW_TERMS_AND_CONDITIONS,
      eventLabel: PRODUCT_ACTIONS.VIEW_TERMS,
      transaction_id: bookingId
    });
  }
}
