import BaseHttpClientService from '../base-http-client-service';
import ICart from '../../models/internal/cart/cart';
import IBookingRequest from '../../models/internal/booking/booking-request';
import { CurrencyType } from '../../models/internal/currency/currency';
import IUnifiedCart from '../../models/internal/cart/unified-cart';
import { IActivityBookingCount } from 'src/models/internal/activity/activity-booking-count';
import { IBookingQuestion } from '../../models/internal/booking/booking-question';
import { ICurrencyRate } from 'src/models/internal/currency/currency-rate';

interface ICartControllerService {
  getUnifiedCart(cartId: number): Promise<IUnifiedCart>;
  getBookingCart(
    cartId: number,
    currencyCode: CurrencyType,
    withCancellationPolicies?: boolean
  ): Promise<ICart>;
  addBookingToCart(bookingRequest: IBookingRequest): Promise<IUnifiedCart>;
  addPromoCode(cartId: number, code: string): Promise<number>;
  removePromoCode(cartId: number): Promise<number>;
  setCartCurrency(cartId: number, currency: CurrencyType): Promise<number>;
  setExtraCount(
    cartId: number,
    activityId: string,
    extra: IActivityBookingCount
  ): Promise<boolean>;
  addRoomsToBooking(
    bookingRequest: IBookingRequest,
    bookingId: number
  ): Promise<ICart>;
  getContactQuestions(cartId: number): Promise<IBookingQuestion[]>;
  getCurrencies(): Promise<ICurrencyRate[]>;
  deleteItemFromCart(cartId: number, itemId: number): Promise<boolean>;
  deleteRoomFromBooking(
    cartId: number,
    bookingId: number,
    roomId: number,
    currencyCode: CurrencyType
  ): Promise<ICart>;
}

class CartControllerService
  extends BaseHttpClientService
  implements ICartControllerService
{
  getCurrencies(): Promise<ICurrencyRate[]> {
    return this.httpClient.get(`api/cart/v1/get-currencies`).then((resp) => {
      return this.prepareResponse(resp).content;
    });
  }
  getUnifiedCart(cartId: number): Promise<IUnifiedCart> {
    return this.httpClient
      .get(`api/cart/v1/get-unified-cart/cart/${cartId}`)
      .then((resp) => {
        return this.prepareResponse(resp).content;
      });
  }

  getBookingCart(
    cartId: number,
    currencyCode: CurrencyType,
    withCancellationPolicies = false
  ): Promise<ICart> {
    return this.httpClient
      .get(
        `api/cart/v1/get-booking-cart/${cartId}?currencyCode=${currencyCode}&withCancellationPolicies=${withCancellationPolicies}`
      )
      .then((resp) => this.prepareResponse(resp).content);
  }

  addBookingToCart(bookingRequest: IBookingRequest): Promise<IUnifiedCart> {
    return this.httpClient
      .post(`api/cart/v1/add-booking-to-cart`, bookingRequest)
      .then((resp) => this.prepareResponse(resp).content);
  }

  addRoomsToBooking(
    bookingRequest: IBookingRequest,
    bookingId: number
  ): Promise<ICart> {
    return this.httpClient
      .post(`api/cart/v1/add-rooms-to-booking/${bookingId}`, bookingRequest)
      .then((resp) => this.prepareResponse(resp).content);
  }

  addPromoCode(cartId: number, code: string): Promise<number> {
    return this.httpClient
      .post(`api/cart/v1/add-promo-code/cart/${cartId}`, { code })
      .then((resp) => this.prepareResponse(resp).statusCode);
  }

  removePromoCode(cartId: number): Promise<number> {
    return this.httpClient
      .delete(`api/cart/v1/remove-promo-code/cart/${cartId}`)
      .then((resp) => resp.status);
  }

  setCartCurrency(cartId: number, currency: CurrencyType): Promise<number> {
    return this.httpClient
      .put(`api/cart/v1/set-cart-currency/cart/${cartId}/currency/${currency}`)
      .then((resp) => resp.status);
  }

  setExtraCount(
    cartId: number,
    activityId: string,
    extra: IActivityBookingCount
  ): Promise<boolean> {
    return this.httpClient
      .put(
        `api/cart/v1/set-activity-extra-count/cart/${cartId}/activity/${activityId}`,
        extra
      )
      .then((resp) => this.prepareResponse(resp).content);
  }

  deleteItemFromCart(cartId: number, itemId: number): Promise<boolean> {
    return this.httpClient
      .delete(`api/cart/v1/delete-item-from-cart/cart/${cartId}/item/${itemId}`)
      .then((resp) => this.prepareResponse(resp).content);
  }

  deleteRoomFromBooking(
    cartId: number,
    bookingId: number,
    roomId: number,
    currencyCode: CurrencyType
  ): Promise<ICart> {
    return this.httpClient
      .delete(
        `api/cart/v1/delete-room-from-booking/cart/${cartId}/booking/${bookingId}/room/${roomId}?currencyCode=${currencyCode}`
      )
      .then((resp) => this.prepareResponse(resp).content);
  }

  getContactQuestions(cartId: number): Promise<IBookingQuestion[]> {
    return this.httpClient
      .get(`/api/payment/v1/get-contact-questions/cart/${cartId}`)
      .then((resp) => this.prepareResponse(resp).content);
  }

  private deleteBookingCart(cartId: number): Promise<boolean> {
    return this.httpClient
      .delete(`api/cart/v1/delete-booking-cart/${cartId}`)
      .then((resp) => this.prepareResponse(resp).content);
  }
}

export default new CartControllerService() as ICartControllerService;
