
import { defineComponent, reactive } from 'vue';
import ResultsPage from '../components/block/results-page/results-page.vue';
import searchController from '../services/controllers/search-controller-service';
import filterController from '../services/controllers/filter-controller-service';

import PropertySearchPanel from '../models/components/block/property-search-panel';
import ResultsPageModel from '../models/components/block/results-page';
import Sidebar from '../models/components/block/sidebar';
import ProductItem from '../models/components/standalone/product/product-item';
import { PropertySearchResults } from '../models/travia/property-search-results';
import { SelectOption } from '../models/components/elements/options/select-option';
import LocationOrProperty from '../models/travia/locations-and-properties';
import Notification from '../models/internal/notification/notification';
import SearchFilters from '../models/travia/search-filters';
import PropertyProductItem from '../models/components/standalone/product/property-product-item';
import { SortTypeEnum } from '../models/enums/sort-type';
import SidebarFilters from '../models/components/block/sidebar/sidebar-filters';
import { toCheckout, toHome } from '../router';
import { PropertySearch } from '../models/travia/property-search';
import { useRoute } from 'vue-router';
import { notify } from '@kyvg/vue3-notification';
import { SearchQueryParams } from 'src/models/internal/query-params/search-query';
import axios, { CancelTokenSource } from 'axios';
import useCurrencies from '../composables/useCurrencies';
import useTranslation from '../composables/useTranslation';

export default defineComponent({
  name: 'ResultsComponent',
  components: {
    'results-page': ResultsPage
  },
  beforeRouteEnter(to, from) {
    document.body.style.overflow = '';
    document.body.style.paddingRight = '';

    const isFromHomeOrRefreshed =
      from.name === 'home' || from.name === undefined; // specifiying undefined since it can be null
    /**
     * Only validate query params if page is coming from home to results.
     * Otherwise, allow navigation to proceed.
     */
    if (isFromHomeOrRefreshed && to.name === 'results') {
      if (!PropertySearchPanel.isQueryParamsValid(to.query)) {
        notify(
          Notification.warning(
            'Some details in the URL seems to be invalid. Please try again.',
            '',
            -1,
            'centered'
          )
        );
        return { path: '/' };
      }
    }
    return true;
  },
  setup() {
    useCurrencies();
    useTranslation();

    const route = useRoute();
    const params = route.query as SearchQueryParams;

    const model = new ResultsPageModel({
      sidebar: new Sidebar({
        propertySearchPanel: PropertySearchPanel.fromSearchProperties({
          accommodation: {
            guests: Number(params?.guests),
            rooms: Number(params?.rooms),
            children: Number(params?.children)
          },
          locationList: JSON.parse(params?.locations) as number[],
          properties: JSON.parse(params?.properties) as number[],
          startDate: params?.startDate,
          endDate: params?.endDate
        })
      })
    });

    return {
      resultsPageModel: reactive(model) as ResultsPageModel
    };
  },
  watch: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'resultsPageModel.sidebar.propertySearchPanel.loading'(newValue) {
      if (!this.resultsPageModel.sidebar.propertySearchPanel.loading) {
        this.resultsPageModel.sidebar.sidebarFilters = new SidebarFilters();
        this.getPropertyTypesAsync();
        this.getProperties();
      }
    }
  },
  mounted() {
    if (
      !this.resultsPageModel.sidebar.propertySearchPanel.loading &&
      !this.resultsPageModel.sidebar.propertySearchPanel.validate()
    ) {
      this.$notify(
        Notification.warning('Please provide proper search parameters')
      );
      this.$router.push({ name: 'home' });
    } else {
      this.getLocations();
      this.getBookedRoomsCount();
      document.addEventListener('visibilitychange', this.getBookedRoomsCount);

      if (
        this.$store.getters.queryUpdated &&
        !this.resultsPageModel.sidebar.propertySearchPanel.loading
      ) {
        this.resultsPageModel.sidebar.sidebarFilters = new SidebarFilters();
        this.getPropertyTypesAsync();
        this.getProperties();
      } else {
        this.resultsPageModel.sidebar.sidebarFilters = new SidebarFilters(
          this.$store.getters.sidebarFilters
        );
        this.resultsPageModel.searchResult.productItems =
          this.$store.getters.loadedProperties;
      }
    }
  },
  unmounted() {
    document.removeEventListener('visibilitychange', this.getBookedRoomsCount);
  },
  methods: {
    toCheckoutClick() {
      this.resultsPageModel.cancellationTokens.getPropertiesResult.cancel();
      toCheckout();
    },
    onSortingChanged() {
      this.sortSearchResults();
      this.filterSearchResults();
    },
    onSidebarFiltersChanged() {
      this.$store.commit(
        'sidebarFiltersUpdated',
        this.resultsPageModel.sidebar.sidebarFilters.sidebarFilters
      );
      this.filterSearchResults();
      this.filterOptions();
    },
    onPropertyClicked(product: ProductItem) {
      this.resultsPageModel.cancellationTokens.getPropertiesResult.cancel();
      this.$router.push({
        name: 'property',
        params: { id: product.id },
        query: this.$route.query
      });
    },
    onSearchClicked() {
      this.$router.push({
        name: 'results',
        query: this.resultsPageModel.sidebar.propertySearchPanel.emitValues()
      });
      this.resultsPageModel.searchResult.loading = true;

      this.getProperties();
    },
    sortSearchResults() {
      this.resultsPageModel.searchResult.sort(
        this.resultsPageModel.sidebar.sorting.selectedValue ??
          SortTypeEnum.PriceFromLowest
      );
    },
    filterSearchResults() {
      this.resultsPageModel.searchResult.filter(
        this.resultsPageModel.sidebar.sidebarFilters.sidebarFilters
      );
    },
    filterOptions() {
      /** Shows the list of property type and amenity "filters" based on the "filtered" results. */
      const filteredResults =
        this.resultsPageModel.searchResult.filteredItems.map(
          (item) => item as PropertyProductItem
        );
      this.resultsPageModel.sidebar.sidebarFilters.filterOptions(
        filteredResults
      );
    },
    getProperties() {
      this.resultsPageModel.searchResult.loading = true;
      if (!this.resultsPageModel.sidebar.propertySearchPanel.validate()) return;

      this.resultsPageModel.cancellationTokens.getPropertiesResult.cancel();
      searchController
        .searchForPropertiesAsync(
          PropertySearch.getPropertySearchModel(
            this.resultsPageModel.sidebar.propertySearchPanel,
            this.$store.getters.selectedCurrency
          ),
          this.resultsPageModel.cancellationTokens.getPropertiesResult.setRunning(
            axios.CancelToken.source()
          )
        )
        .then(async (res: PropertySearchResults) => {
          this.resultsPageModel.searchResult.productItems =
            PropertyProductItem.fromAvailableProperties(
              res.availableProperties,
              this.$store.getters.selectedCurrency
            );

          this.resultsPageModel.sidebar.sidebarFilters.availablePropertyAmenityOptions =
            res.availabilityFilters.propertyAmenities;
          this.resultsPageModel.sidebar.sidebarFilters.availablePropertyTypeOptions =
            res.availabilityFilters.propertyTypes;
          this.resultsPageModel.searchResult.loading = false;
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            this.resultsPageModel.searchResult.productItems = [];
            this.resultsPageModel.searchResult.loading = true;
            return;
          }

          if (err.statusCode) {
            if (err.statusCode === 404)
              this.$notify(Notification.warning(err.message));
            else this.$notify(Notification.error(err.message));
          } else {
            this.$notify(Notification.error(err.message));
          }

          this.resultsPageModel.searchResult.productItems = [];
        })
        .finally(() => {
          this.resultsPageModel.searchResult.loading = false;
          this.$store.commit(
            'searchCompleted',
            this.resultsPageModel.searchResult.productItems
          );
          this.$store.commit(
            'sidebarFiltersUpdated',
            this.resultsPageModel.sidebar.sidebarFilters.sidebarFilters
          );

          this.sortSearchResults();
          this.filterSearchResults();
        });
    },
    getLocations() {
      this.resultsPageModel.searchResult.loading = true;
      searchController
        .getLocationsAsync()
        .then(async (res: LocationOrProperty[]) => {
          this.resultsPageModel.sidebar.propertySearchPanel.locationInput.models =
            res;
          this.resultsPageModel.sidebar.propertySearchPanel.locationInput.mapIdsToLocationOrProperty();
        })
        .then(() => {
          if (
            this.resultsPageModel.sidebar.propertySearchPanel.locationInput
              .selectedModels.length === 0
          ) {
            notify(
              Notification.warning(
                'No location has been selected. Please try again.',
                '',
                -1,
                'centered'
              )
            );
            toHome();
          }
        })
        .catch((err) => {
          this.$notify(Notification.error(err.message));
          this.resultsPageModel.sidebar.propertySearchPanel.locationInput.models =
            [];
        })
        .finally(() => {
          this.resultsPageModel.sidebar.propertySearchPanel.loading = false;
        });
    },
    getPropertyTypesAsync() {
      this.resultsPageModel.sidebar.loading = true;
      filterController
        .getSearchFiltersAsync()
        .then(async (res: SearchFilters) => {
          this.resultsPageModel.sidebar.sidebarFilters.propertyTypeOptions =
            SelectOption.fromPropertyTypes(res.propertyTypes);
          this.resultsPageModel.sidebar.sidebarFilters.propertyAmenityOptions =
            SelectOption.fromPropertyAmenities(res.propertyAmenities);
          this.$store.commit('updatePropertyMap', res.getAsMap());
        })
        .catch((err) => {
          this.$notify(Notification.error(err.message));
          this.resultsPageModel.sidebar.sidebarFilters.propertyTypeOptions = [];
          this.resultsPageModel.sidebar.sidebarFilters.propertyAmenityOptions =
            [];
        })
        .finally(() => {
          this.$store.commit(
            'sidebarFiltersUpdated',
            this.resultsPageModel.sidebar.sidebarFilters.sidebarFilters
          );
          this.resultsPageModel.sidebar.loading = false;
        });
    },
    getBookedRoomsCount(): void {
      if (!document.hidden) {
        this.resultsPageModel.cartFabBadge.content =
          this.$store.getters.bookedRoomsCount;
      }
    }
  }
});
