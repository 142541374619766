class GoogleAnalyticsV4 {
  isEnabled = false;

  init(config) {
    this.isEnabled =
      !!window.gtag &&
      typeof window.gtag === 'function' &&
      !config.googleAnalyticsDisabled;
  }

  beginCheckout(items) {
    if (!this.isEnabled) return;
    window.gtag('event', 'begin_checkout', { ...items });
  }

  purchase(items) {
    if (!this.isEnabled) return;
    window.gtag('event', 'purchase', { ...items });
  }

  removeFromCart(items) {
    if (!this.isEnabled) return;
    window.gtag('event', 'remove_from_cart', { ...items });
  }

  viewTermsAndConditions(bookingId) {
    if (!this.isEnabled) return;
    window.gtag('event', 'view_terms_and_conditions', { bookingId });
  }
}

export default GoogleAnalyticsV4;
