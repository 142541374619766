export interface IUserConsent {
  hasTermsConsent: boolean;
  hasCancellationPolicyConsent: boolean;
  hasNewstellerConsent: boolean;
}

export default class UserConsent implements IUserConsent {
  hasTermsConsent: boolean;
  hasCancellationPolicyConsent: boolean;
  hasNewstellerConsent: boolean;

  constructor() {
    this.hasCancellationPolicyConsent = false;
    this.hasTermsConsent = false;
    this.hasNewstellerConsent = false;
  }
}
