import FuelDto from './fuel-dto';

export interface IFuelsArgs {
  fuelsList: FuelDto[];
}

export default class Fuels {
  fuelsList: FuelDto[];

  constructor(data: FuelDto[]) {
    this.fuelsList = data;
  }
}
