import { Nullable } from './../../generics/generics';
import { IBookingQuestion } from '../booking/booking-question';
import { IBundleAvailability } from '../bundle/bundle-availability';
import { IBundleItem } from '../bundle/bundle-item';
import { IActivityBookableExtra } from '../extras/activity-extras';
import { IActivityRatePrices } from './activity-rate-prices';
import { IActivityRate } from './activity-rates';

export enum BookingType {
  DATE = 'DATE',
  DATE_AND_TIME = 'DATE_AND_TIME',
  PASS = 'PASS'
}

export enum MeetingType {
  MEET_ON_LOCATION = 'MEET_ON_LOCATION',
  PICK_UP = 'PICK_UP',
  MEET_ON_LOCATION_OR_PICK_UP = 'MEET_ON_LOCATION_OR_PICK_UP'
}

export interface IActivity {
  id: string;
  bookableExtras: IActivityBookableExtra[];
  bookingType: BookingType;
  bundleItems: IBundleItem[];
  bundleAvailability: Nullable<IBundleAvailability>;
  meetingType: MeetingType;
  productCode: string;
  customDropoffAllowed: boolean;
  customPickupAllowed: boolean;
  dropoffService: boolean;
  pickupService: boolean;
  pickupMinutesBefore: number;
  title: string;
  bookingQuestions: Array<IBookingQuestion>;
  ratePrices: IActivityRatePrices;
  rates: IActivityRate[];
}
