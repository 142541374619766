import Dropdown, { IDropdownArgs } from '../dropdown/dropdown';

export type IDropdownSelectArgs = IDropdownArgs

export default class DropdownSelect extends Dropdown {
  constructor(data?: IDropdownSelectArgs) {
    super(data);
  }

  get selectedOption() {
    return this.options.find((option) => option.selected);
  }

  select(value: string | number): void {
    this.options.forEach((option) => (option.selected = false));
    this.options
      .find((option) => option.value.toString() === value.toString())
      ?.toggle();
  }

  validate() {
    if (this.withValidation) {
      return (this.isValid = this.options.some((option) => option.selected));
    } else {
      return (this.isValid = true);
    }
  }
}
