import { Language } from './language';

export interface ILanguagesArgs {
  languages: Language[];
}

export default class Languages {
  languageList: Language[];

  constructor(data: ILanguagesArgs) {
    this.languageList = data.languages;
  }

  public static getLanguageName(code: string) {
    const languageName = new Intl.DisplayNames(['en'], { type: 'language' });
    return languageName.of(code) ?? '';
  }
}
